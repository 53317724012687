import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { AccessRequestsComponent } from './ip-whitelist/access-requests/access-requests.component';
import { AgmCoreModule } from '@agm/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthLayoutComponent } from './common/components/auth-layout.component';
import { AuthLogsComponent } from './AuthLogs/auth-logs.component';
import { AuthService } from './common/auth/auth.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { BranchChooseComponent } from './shared/components/BranchChoose/branch-choose.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ChargerFormComponent } from './charger/charger-form.component';
import { CookieService } from 'ngx-cookie-service';
import { CradleMapComponent } from './cradle-maps/cradle-map.component';
import { CustomerFormComponent } from './customer/customer-form.component';
import { CustomerListComponent } from './customer/customer-list.component';
import { DashboardComponent } from './sysadmin/dashboard/dashboard.component';
import { DateRangeComponent } from './report/platform-performance/date-range/date-range.component';
import { DiaryFormComponent } from './patient/diary-form.component';
import { DragulaModule } from 'ng2-dragula';
import { EditableModule } from '@ngneat/edit-in-place';
import { FirmwareComponent } from './firmware/firmware.component';
import { InstantComponent } from './report/platform-performance/instant/instant.component';
import { IpWhiteListFormComponent } from './ip-whitelist/ip-whitelist-form.component';
import { IpWhitelistsComponent } from './ip-whitelist/ip-whitelists.component';
import { LocalStorageModule } from 'angular-2-local-storage';
import { LogHistoryListComponent } from './report/log-history/log-history-list.component';
import { ManualComponent } from './manual/manual.component';
import { NgbDateFRParserFormatter } from './patient/dateFormat';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { NoteFormComponent } from './note/note-form.component';
import { NoteListComponent } from './note/note-list.component';
import { NotificationComponent } from './notification/notification.component';
import { PatientDiaryComponent } from './patient-diary/patient-diary.component';
import { PcFormComponent } from './pc/pc-form.component';
import { PcListComponent } from './pc/pc-list.component';
import { PlatformPerformanceComponent } from './report/platform-performance/platform-performance.component';
import { PublicLayoutComponent } from './common/components/public-layout.component';
import { PublicModule } from './public/public.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RecorderFormComponent } from './recorder/recorder-form.component';
import { ReportErrorFormEditComponent } from './report/report-error-log-list/report-error-form-edit.component';
import { ReportErrorLogListComponent } from './report/report-error-log-list/report-error-log-list.component';
import { ReportListComponent } from './report/report-list.component';
import { ReportListFormEditComponent } from './report/report-list-form-edit.component';
import { ServerHealthCheckComponent } from './server-health-check/server-health-check.component';
import { ServerHealthPageComponent } from './server-health-page/server-health-page.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SettingsComponent } from './settings/settings.component';
import { SharedModule } from './shared/shared.module';
import { StorageModule } from '@ngx-pwa/local-storage';
import { TextMaskModule } from 'angular2-text-mask';
import { TokenResult } from './common/auth/security/token';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UploadDagboekComponent } from './patient/upload-dagboek/upload-dagboek.component';
import { environment } from '../environments/environment';
import localeNl from '@angular/common/locales/nl';
import localeNlExtra from '@angular/common/locales/extra/nl';
import localeTr from '@angular/common/locales/tr';
import localeTrExtra from '@angular/common/locales/extra/tr';
import { OrderByPipe } from './shared/pipes/orderBy.pipe';
import { InvoicesModule } from './invoices/invoices.module';

registerLocaleData(localeNl, 'nl', localeNlExtra);
registerLocaleData(localeTr, 'tr', localeTrExtra)

export function initAuthService(authService: AuthService) {
  return (): Promise<TokenResult> => {
    return authService.init();
  };
}
@NgModule({
  declarations: [
    AppComponent,
    DiaryFormComponent,
    CustomerListComponent,
    CustomerFormComponent,
    PcListComponent,
    PcFormComponent,
    ServerHealthPageComponent,
    UploadDagboekComponent,
    FirmwareComponent,
    DashboardComponent,
    NotificationComponent,
    PublicLayoutComponent,
    AuthLayoutComponent,
    RecorderFormComponent,
    ServerHealthCheckComponent,
    CradleMapComponent,
    BranchChooseComponent,
    PatientDiaryComponent,
    IpWhitelistsComponent,
    IpWhiteListFormComponent,
    AccessRequestsComponent,
    NoteListComponent,
    NoteFormComponent,
    ReportListComponent,
    ReportListFormEditComponent,
    ReportErrorFormEditComponent,
    ReportErrorLogListComponent,
    AuthLogsComponent,
    ChargerFormComponent,
    ManualComponent,
    PlatformPerformanceComponent,
    InstantComponent,
    DateRangeComponent,
    LogHistoryListComponent,
    SettingsComponent,
    OrderByPipe
  ],
  imports: [
    CKEditorModule,
    SharedModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CommonModule,
    TranslateModule.forRoot({
      defaultLanguage: localStorage.getItem('language') || 'nl',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    LocalStorageModule.forRoot({
      prefix: 'ecg-app',
      storageType: 'localStorage'
    }),
    TextMaskModule,
    StorageModule.forRoot({
      IDBNoWrap: true,
      IDBDBName: 'ecg-app'
    }),
    PublicModule,
    AppRoutingModule,
    ChartsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgxCsvParserModule,
    NgbModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAOkhpPFqpYXeuDsSd8mZCOdXXPLyWOv2U', 
      libraries: ['places']
    }),
    EditableModule,
    DragulaModule.forRoot(),
    InvoicesModule
  ],
  exports:[

  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initAuthService, deps: [AuthService], multi: true },
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: LOCALE_ID, useFactory: LocalFactory, deps: [TranslateService] },
    CookieService,
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter },
    BnNgIdleService,
    ThemeService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function LocalFactory(translateService: TranslateService) {
  return translateService.currentLang;
}
