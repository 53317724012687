<ul class="nav nav-tabs">
  <li class="nav-item">
    <a class="nav-link {{router.url == '/ip-whitelist' ? 'active' : '' }}" [routerLink]=""
      (click)="router.navigate(['ip-whitelist'])">{{'ipList.ipList' | translate }}</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [routerLink]="" (click)="router.navigate(['ip-whitelist/access-requests'])">{{'accessRequest.accessRequest' | translate}}</a>
  </li>
</ul>
<div *ngIf="(service.data | async) as ipList">
  <nav class="navbar navbar-light bg-light">
    <div class="row m-0 w-100">
      <div class="col-3 pl-0 pt-1 pr-0">
        <button class="btn btn-info mr-2 filterBttn" (click)="showFilter = !showFilter" i18n>
        <i class="fa fa-filter"></i> {{'filterButton' | translate}}
        </button>
        <button type="button" class="btn btn-outline-secondary" (click)="filterReset();" *ngIf="showFilter" i18n>{{'filterReset' | translate }}</button>
      </div>
      <div class="col-6 align-self-center text-center">
        <h3 i18n>{{'ipList.ipList' | translate }}</h3>
      </div>
      <div class="col-3 pt-1 pr-0">
        <button type="button" class="btn btn-info float-right" (click)="add()"
          i18n>{{'ipList.new' | translate }}</button>
        <div *ngIf="!auth.hasRole(['Support', 'Support DK'])" class="form-group d-flex align-items-center col-lg-auto float-right pt-1">
          <label class="switch">
            <input type="checkbox" class="form-control" [checked]="whiteListStatus" (click)="preventDefault($event);!this.whiteListStatus ? modal.open(confirmIpWhiteListOn) : ipFilterSwitch()">
            <span style="margin-bottom:3%;" class="slider round"></span>
          </label>
          <label class="required ml-2">{{'ipWhiteListForm.toggleText' | translate }}</label>
        </div>
      </div>

      <ng-template #confirmIpWhiteListOn let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-title" i18n>{{'ipWhiteListForm.turnOnIpWhiteList' | translate }}</h4>
          <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p><strong i18n>{{'ipWhiteListForm.youSure' | translate }}</strong></p>
          <p i18n>
            {{'ipWhiteListForm.ipWhiteListTurningOn' | translate }}
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" (click)="ipFilterSwitch(); modal.dismiss('ipfilteron');"
            i18n>{{'profileForm.ok' | translate }}</button>
          <button type="button" ngbAutofocus class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')"
            i18n>{{'cancel' | translate }}
          </button>
        </div>
      </ng-template>
    </div>
  </nav>

  <div class="card" *ngIf="showFilter">
    <div class="card-body">
      <ecg-filter [metaData]="filterMetaData" (filterChange)="filterChange($event);" [reset]="reset"></ecg-filter>
    </div>
  </div>
  <div [class]="(dataCount | async) > 10 ? 'table-responsive' : 'table-responsive table-responsive-no-paginate'">
    <table class="table table-hover" i18n-aria-describedby aria-describedby="incoming file list">
      <thead>
        <tr>
          <th scope="col" sortable="ipAddress" (sort)="onSort($event)" i18n>{{'ipList.ip' | translate }}</th>
          <th scope="col" sortable="customer.name" (sort)="onSort($event)" i18n>{{'ipList.customerName' | translate }}
          </th>
        </tr>
      </thead>
      <tbody *ngIf="ipList !== undefined ||  ipList.length!=0 ; else norecord">
        <tr class="clickable-row cursorProp" *ngFor="let ip of ipList; let index=index" [tabIndex]="index + 1" (click)="edit(ip)"
          (keydown.enter)="edit(ip)">
          <td>{{ ip.ipAddress }}</td>
          <td *ngIf="ip.customer">{{ip.customer.name}}</td>
          <td *ngIf="!ip.customer">-</td>
        </tr>
      </tbody>
      <ng-template #norecord>
        <tbody>
          <tr>
            <td colspan="10" class="text-danger" i18n>{{'ipList.noRecords' | translate }}</td>
          </tr>
        </tbody>
      </ng-template>
    </table>
  </div>
  <div *ngIf="(dataCount | async) as count">
    <div class="bg-ecg fix-to-bottom text-white px-3 pt-lg-3" *ngIf="count>=10">
      <div class="row justify-content-between w-100 m-0">
        <div class="col-12 d-block d-lg-none">
          <div class="pt-1 text-center" i18n>{{count}} {{'itemsIn' | translate }}
            {{this | func:pageCount:count:paging.size}} {{'pages'
            | translate }}
          </div>
        </div>
        <div class="col-auto pl-0 pr-0">
          <ngb-pagination [boundaryLinks]="true" [rotate]="true" [maxSize]="3" [(page)]="paging.index"
            [pageSize]="paging.size" [collectionSize]="count" (pageChange)="refresh();pagingSave();" #pagination>
          </ngb-pagination>
        </div>
        <div class="col-12 col-lg d-none d-lg-block">
          <div class="pt-2 text-center" i18n>{{count}} {{'itemsIn' | translate }}
            {{this | func:pageCount:count:paging.size}} {{'pages'
            | translate }}
          </div>
        </div>
        <div class="col pr-0 pl-0 pagination-dropdown-container">
          <div>
            <select class="custom-select pagination-dropdown cursorProp" [(ngModel)]="paging.size" (change)="refresh()">
              <option [ngValue]="10" i18n>{{'records10' | translate }}</option>
              <option selected [ngValue]="15" i18n>{{'records15' | translate }}</option>
              <option [ngValue]="20" i18n>{{'records20' | translate }}</option>
              <option [ngValue]="50" i18n>{{'records50' | translate }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
