import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { BaseListComponent } from '../common/components/base-list.component';
import { AuthService } from '../common/auth/auth.service';
import { AuthLogs } from '../models/authLogs';
import { AuthLogsService } from './auth-logs.service';

@Component({
  selector: 'ecg-auth-logs',
  templateUrl: './auth-logs.component.html',
  styleUrls: ['./auth-logs.component.scss'],
})
export class AuthLogsComponent extends BaseListComponent<AuthLogs> implements OnInit {
  constructor(
    public service: AuthLogsService,
    spinner: NgxSpinnerService,
    router: Router,
    route: ActivatedRoute,
    public auth: AuthService,
  ) {
    super({
      service,
      spinner,
      router,
      route,
      idColumn: 'id',
      formRoute: '/auth-logs'
    });
  }
}
