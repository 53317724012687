import * as tslib_1 from "tslib";
import { BaseListComponent } from '../common/components/base-list.component';
export class FirmwareComponent extends BaseListComponent {
    constructor(service, spinner, router, route, logger, signalRService) {
        super({
            service, spinner, router, route,
            idColumn: 'id',
            formRoute: '/cradle',
            namePipe: (d) => d && d.cradleSerialNumber ? d.cradleSerialNumber : d.id.toString()
        });
        this.logger = logger;
        this.signalRService = signalRService;
        this.statusFilter = '';
        this.createConnection();
        service.data.subscribe((data) => {
            this.cradleNew = data;
        });
    }
    createConnection() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.hubConnection = yield this.signalRService.connect('/loghub');
            yield this.hubConnection.start();
            this.hubConnection.on('updateCradle', res => {
                const jsonRes = JSON.parse(res);
                const index = this.cradleNew.findIndex(cradle => cradle.id === jsonRes.Id);
                if (index > 0) {
                    this.cradleNew[index].cradleStatus = jsonRes.CradleStatus;
                    this.cradleNew[index].updatedDateTime = jsonRes.UpdatedDateTime;
                }
            });
            this.hubConnection.on('cradleForm', res => {
                var resParse = JSON.parse(res);
                const index = this.cradleNew.findIndex(cradle => cradle.id === resParse.Id);
                if (index > 0) {
                    this.cradleNew[index].room = resParse.Room;
                    this.cradleNew[index].cradleStatus = resParse.CradleStatus;
                    this.cradleNew[index].systoleSerialNumber = resParse.SystoleSerialNumber;
                    this.cradleNew[index].linkedPC = resParse.LinkedPC;
                    this.cradleNew[index].updatedDateTime = resParse.UpdatedDateTime;
                    this.cradleNew[index].customer.name = resParse.Customer.Name;
                }
            });
        });
    }
    changeStatus(event) {
        const newPaging = {
            size: this.paging.size,
            index: this.paging.index,
            status: event
        };
        this.service.getSearch(newPaging, { filters: this.filters });
        this.service.data.subscribe(r => {
            this.cradleNew = r;
        });
    }
}
