import * as tslib_1 from "tslib";
import { AfterContentChecked, OnDestroy } from '@angular/core';
import { BaseFormComponent } from '../common/components/base-form.component';
import { OrganizationType } from '../models/organization-type.enum';
import { DeviceSetting } from '../models/deviceSetting';
import { DeviceTimeSetting } from '../models/deviceTimeSetting';
export class CustomerFormComponent extends BaseFormComponent {
    constructor(service, logger, location, route, router, spinner, toast, translate, modal, signalRService, logoutService, localStorageService) {
        super({ service, logger, location, route, router, spinner, toast });
        this.translate = translate;
        this.modal = modal;
        this.signalRService = signalRService;
        this.logoutService = logoutService;
        this.localStorageService = localStorageService;
        this.newBranchList = [];
        this.organizationType = OrganizationType;
        this.key = 'value';
        this.options = {
            width: '300',
            multiple: true,
            tags: true,
            minimumInputLength: 4,
            maximumInputLength: 4,
        };
        this.firstTime = false;
        this.disabledButton = true;
        this.codes = [];
        this.paging = {
            size: 10,
            index: 1,
            search: null,
        };
        this.deviceSettings = [];
        this.sampleRate = [
            { id: 125, value: '125' },
            { id: 250, value: '250' }
        ];
        this.highFilter = [
            { id: "0.05", value: '0.05' },
            { id: "0.06", value: '0.06' },
            { id: "0.07", value: '0.07' },
            { id: "0.08", value: '0.08' },
            { id: "0.09", value: '0.09' },
            { id: "0.1", value: '0.1' },
        ];
        this.devices = [
            {
                id: 1,
                type: 'deviceHolter',
                time: [
                    { id: 1, value: '24Hour', active: true, },
                    { id: 2, value: '48Hour', active: true, },
                    { id: 3, value: '72Hour', active: true, },
                    { id: 4, value: '96Hour', active: true, },
                    { id: 5, value: '120Hour', active: true, }
                ],
                active: true,
            },
            {
                id: 2,
                type: 'deviceEvent',
                time: [
                    { id: 7, value: '7Day', active: true, },
                    { id: 14, value: '14Day', active: true, }
                ],
                active: true,
            },
            {
                id: 4,
                type: 'deviceWearable',
                time: [
                    { id: 10, value: '10Minute', active: true, },
                    { id: 1, value: '30Minute', active: true, },
                    { id: 7, value: '7Day', active: true, },
                    { id: 14, value: '14Day', active: true, },
                    { id: 21, value: '21Day', active: true, },
                    { id: 28, value: '28Day', active: true, }
                ],
                active: true,
            },
            {
                id: 5,
                type: 'device8Channel',
                time: [
                    { id: 10, value: '10Minute', active: true, },
                    { id: 1, value: '30Minute', active: true, },
                    { id: 2, value: '24Hour', active: true, }
                ],
                active: true,
            }
        ];
        this.pageCount = (count, size) => Math.ceil(count / size);
        this.service = service;
        this.createConnection().catch(() => undefined);
    }
    ngOnDestroy() {
        if (this.hubConnection) {
            this.hubConnection.stop().catch(() => undefined);
        }
        super.ngOnDestroy();
    }
    createConnection() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.hubConnection = yield this.signalRService.connect('/loghub');
            yield this.hubConnection.start();
            this.hubConnection.on('DisconnectedUser', (mac, inheritingUserName, operation) => {
                if (this.localStorageService.get('mac') === mac) {
                    this.logoutService.logoutUser(inheritingUserName, operation);
                }
            });
            // this.hubConnection.on('DisconnectedUserByUUId', (uuid) => {
            //   if(this.localStorageService.get("user-uuid")== uuid)
            //   {
            //       this.logoutService.logoutUser("", "disconnected");
            //   }
            // });
        });
    }
    setInitialDataValue(model) {
        model.type = model.type;
        model.priority = 72;
        model.codes = model.codes || [];
    }
    formValid(form) {
        if (this.model.pidNumberLength < 6 || this.model.pidNumberLength > 15) {
            return false;
        }
        if (super.formValid(form)) {
            return this.model.codes && this.model.codes.length > 0;
        }
        return false;
    }
    getCustomerCode(model) {
        if (model && model.codes[0]) {
            return model.codes[0].code;
        }
        else {
            return '';
        }
    }
    setCustomerCode(code) {
        if (this.model) {
            var customerCode = this.model.codes && this.model.codes[0];
            if (!customerCode) {
                this.model.codes = [{ customerId: this.model.id, code: code }];
            }
            else {
                customerCode.code = code;
            }
            return customerCode;
        }
        else {
            return '';
        }
    }
    toggleActive(item) {
        item.active = !item.active;
    }
    saveOrganisation() {
        var deviceSettingsModel = [];
        this.devices.forEach(device => {
            if (device.active) {
                var item = new DeviceSetting();
                item.id = device.id;
                item.value = device.type;
                item.times = [];
                device.time.forEach(time => {
                    if (time.active) {
                        var itemTime = new DeviceTimeSetting();
                        itemTime.id = time.id;
                        itemTime.value = time.value;
                        item.times.push(itemTime);
                    }
                });
                deviceSettingsModel.push(item);
            }
        });
        this.model.deviceSettings = JSON.stringify(deviceSettingsModel);
        this.setInitialDataValue(this.model);
        this.service.setDataValue(this.model);
        super.save();
    }
    setData(model) {
        super.setData(model);
        this.route.params.subscribe(params => {
            if (params && params.op && params.op === "add") {
                this.model.pidNumberLength = 6;
            }
        });
        if (this.model.deviceSettings) {
            this.deviceSettings = JSON.parse(this.model.deviceSettings);
        }
        else {
            this.deviceSettings = [];
        }
        this.devices.forEach(device => {
            var item = this.deviceSettings.find(x => x.id == device.id);
            if (item != null) {
                device.active = true;
                device.time.forEach(time => {
                    var timeItem = item.times.find(x => x.id == time.id);
                    if (timeItem != null) {
                        time.active = true;
                    }
                    else {
                        time.active = false;
                    }
                });
            }
            else {
                device.active = false;
                device.time.map(x => x.active = false);
            }
        });
    }
    ngAfterContentChecked() {
        if (!this.firstTime && this.model &&
            this.model.codes !== undefined &&
            this.operation === 'edit' &&
            this.model.codes.length !== 0) {
            this.getHospitalId();
            this.firstTime = true;
            this.getCustomerCount();
        }
    }
    getHospitalId() {
        this.service.setHospitalId(this.route.queryParams[this.key].id, this.paging);
        this.service.hospitalUserList.subscribe((res) => {
            this.hospitalUserList = res;
        });
    }
    getCustomerCount() {
        this.service.getCustomerCount(this.route.queryParams[this.key].id);
        this.service.customerCount.subscribe((res) => {
            this.dataCount = res;
        });
    }
    refresh(event) {
        if (event && event.direction !== '' && event != 'pageRefresh') {
            this.paging.orders = undefined;
            this.paging.orders = event.column.split(', ').map(column => {
                return { column, type: event.direction };
            });
            const searchPaging = Object.assign({}, this.paging);
            searchPaging.orders = (searchPaging.orders || []).map(o => `${o.type || 'asc'}.${o.column}`);
            this.paging = searchPaging;
        }
        else if (event.direction === '') {
            this.paging.orders = undefined;
        }
        this.getHospitalId();
    }
    inputKeyPress(event) {
        if (event.keyCode == 13) {
            this.addBranchtoTable();
        }
    }
    addBranch() {
        if (this.route.queryParams[this.key].id) {
            for (var i = 0; i < this.getBranchsNotDelete(this.model.branchs).length; i++) {
                var index = this.newBranchList.indexOf(this.model.branchs[i].branchName);
                if (index != -1) {
                    this.toast.showDanger(this.getBranchsNotDelete(this.model.branchs)[i].branchName + ' branch already exist!');
                    return false;
                }
            }
            this.service.getAddBranchService(this.route.queryParams[this.key].id, this.newBranchList);
            this.service.addBranchService.subscribe(res => {
                this.branchNamee = '';
                if (res) {
                    this.toast.showSuccess(this.translate.instant('branchSaved'));
                    this.newBranchList = [];
                    this.ngOnInit();
                }
                else {
                    this.toast.showDanger(this.translate.instant('branchCanNotSaved'));
                }
            });
        }
    }
    clearBranchList() {
        this.newBranchList = [];
    }
    editBranchModal(branch) {
        this.branchModal = {
            id: branch.id,
            branchName: branch.branchName,
            customerId: branch.customerId
        };
    }
    editBranch() {
        this.service.getExistBranchControlService(this.route.queryParams[this.key].id, this.branchModal.branchName);
        this.service.existBranchControlService.subscribe(res => {
            if (res.length > 0) {
                this.toast.showDanger('Branch already exist!');
            }
            else {
                this.service.getEditBranchService(this.branchModal.id, this.branchModal.branchName);
                this.service.editBranchService.subscribe(res => {
                    this.branchModal.branchName = '';
                    if (res) {
                        this.toast.showSuccess(this.translate.instant('branchSaved'));
                        this.ngOnInit();
                    }
                    else {
                        this.toast.showDanger(this.translate.instant('branchCanNotSaved'));
                    }
                });
            }
        });
    }
    deleteBranch() {
        this.service.getDeleteBranchService(this.branchModal.id);
        this.service.deleteBranchService.subscribe(res => {
            if (res) {
                if (res) {
                    this.toast.showSuccess(this.translate.instant('branchDeleted'));
                    this.ngOnInit();
                }
                else {
                    this.toast.showDanger(this.translate.instant('branchCanNotDeleted'));
                }
            }
        });
    }
    addBranchtoTable() {
        var index = this.newBranchList.indexOf(this.branchNamee);
        if (this.branchNamee != '' && this.branchNamee && index == -1) {
            this.newBranchList.push(this.branchNamee);
            this.branchNamee = '';
        }
    }
    removeBranchtoTable(value) {
        var index = this.newBranchList.indexOf(value);
        this.newBranchList.splice(index, 1);
    }
    getBranchsNotDelete(value) {
        if (!value || (value && value.length < 1))
            return [];
        const response = value.filter(x => x.deletedDateTime == undefined);
        if (response.length < 1)
            return [];
        return response;
    }
}
