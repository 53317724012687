import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getVersion } from 'src/app/common/utilities/getVersion.utility';
export class BranchChooseComponent {
    constructor(service, localStorageService, auth, router, storageMap, http, modal, translate, logoutService, signalRService) {
        this.service = service;
        this.localStorageService = localStorageService;
        this.auth = auth;
        this.router = router;
        this.storageMap = storageMap;
        this.modal = modal;
        this.translate = translate;
        this.logoutService = logoutService;
        this.signalRService = signalRService;
        this.showMessage = false;
        this.branchId = 0;
        this.nextButtonActive = false;
        this.showBackButton = true;
        this.takeOverCradle = false;
        this.step = 'recordsOrCradle';
        getVersion(http).subscribe(res => this.version = res);
        this.year = new Date().getFullYear();
    }
    ngOnInit() {
        let stepStorage = '';
        this.storageMap.get('step', { type: 'string' }).subscribe((result) => {
            stepStorage = result;
            if (!stepStorage || stepStorage == null || stepStorage != 'recordsOrCradle') {
                this.getBranchList();
            }
            else {
                this.router.navigate(['/']);
            }
        });
        this.createConnection().catch(() => undefined);
    }
    createConnection() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.hubConnection = yield this.signalRService.connect('/loghub');
            yield this.hubConnection.start();
            this.hubConnection.on('DisconnectedUser', (mac, inheritingUserName, operation) => {
                if (this.localStorageService.get('mac') === mac) {
                    this.logoutService.logoutUser(inheritingUserName, operation);
                }
            });
            // this.hubConnection.on('DisconnectedUserByUUId', (uuid) => {
            //   if(this.localStorageService.get("user-uuid")== uuid)
            //   {
            //     this.logoutService.logoutUser("", "disconnected");
            //   }
            // });
        });
    }
    ngOnDestroy() {
        if (this.hubConnection) {
            this.hubConnection.stop().catch(() => undefined);
        }
    }
    getBranchList() {
        this.service.getBranchListService();
        this.service.branchListService.subscribe(res => {
            this.branchList = res;
            if (this.branchList && this.branchList.length > 0) {
                this.service.getBranchIdService();
                this.service.branchIdService.subscribe(response => {
                    if (response) {
                        this.branchId = response;
                    }
                });
                if (this.branchList.length == 1) {
                    this.branchId = this.branchList[0].id;
                    this.nextCradleChoose(false);
                }
                else {
                    this.setStorageMapStep('branch');
                }
            }
            else {
                this.service.getGetCradlesByCustomer(this.auth.user.customerId);
                this.service.getCradleByCustomer.subscribe(response => {
                    if (response && response.length > 0) {
                        if (response.length == 1) {
                            this.branchId = 0;
                            this.cradleList = response;
                            this.cradleSerialNumber = response[0].cradleSerialNumber;
                            this.showBackButton = false;
                            if (this.auth.hasRole(['Super User', 'System Admin']))
                                this.usedCradleControl(false);
                            else
                                this.usedCradleControl(true);
                        }
                        else {
                            this.cradleList = response;
                            this.setStorageMapStep('cradleChoose');
                            this.showBackButton = false;
                        }
                    }
                    else {
                        this.setStorageMapStep('recordsOrCradle');
                        this.router.navigate(['/']);
                    }
                });
            }
        });
    }
    changeBranch(id) {
        this.showMessage = false;
        const index = this.branchList.findIndex(branch => branch.id === +id);
        this.branchId = this.branchList[index].id;
    }
    setUserBranchandCradle() {
        var mac = this.localStorageService.get('mac');
        if (mac === null || mac === undefined || mac === 'null' || mac === 'undefined' || mac === '') {
            this.localStorageService.set('mac', this.auth.user.username);
            mac = this.auth.user.username;
        }
        const uuid = this.create_UUID();
        this.localStorageService.set('cradleSerialNumber', this.cradleSerialNumber);
        this.service.getSetUserBranchAndCradle(this.branchId, this.cradleSerialNumber, mac, uuid);
        this.service.setUserBranchAndCradleService.subscribe(res => {
            if (res) {
                if (this.takeOverCradle) {
                    this.takeOverCradleCreateLog(this.previousUser, this.auth.user.username, this.cradleSerialNumber);
                }
                this.localStorageService.set('uuid', uuid);
                this.setStorageMapStep('recordsOrCradle');
                this.router.navigate(['/']);
            }
        });
    }
    changeCradle(serialNumber) {
        const index = this.cradleList.findIndex(cradle => cradle.cradleSerialNumber === serialNumber);
        this.cradleSerialNumber = this.cradleList[index].cradleSerialNumber;
        this.usedCradleControl(false);
    }
    nextCradleChoose(isBefore) {
        this.showBackButton = isBefore;
        this.service.getGetCradlesByBranch(this.branchId);
        this.service.getCradleByBranch.subscribe(res => {
            this.cradleList = res;
            if (this.cradleList && this.cradleList.length > 0) {
                if (this.cradleList.length == 1) {
                    this.cradleSerialNumber = this.cradleList[0].cradleSerialNumber;
                    this.usedCradleControl(true);
                }
                else {
                    this.setStorageMapStep('cradleChoose');
                }
            }
            else {
                this.skipCradleChoose();
            }
        });
    }
    backBranchChoose() {
        this.getBranchList();
    }
    usedCradleControl(control) {
        this.nextButtonActive = false;
        this.service.getUsedControl(this.cradleSerialNumber);
        this.service.usedControl.subscribe(res => {
            if (res) {
                //takeover aktif edilir.
                this.setStorageMapStep('cradleChoose');
                var date = new Date(res.userConnectedDate);
                var hourOffset = date.getTimezoneOffset() / 60;
                date.setHours(date.getHours() - hourOffset);
                this.connectedUserName = res.connectedUser;
                this.message = this.translate.instant('inUseBy') + " " + res.connectedUser;
                this.messageDate = date;
                this.showMessage = true;
                this.previousUser = res.linkedPc;
                this.takeOverCradle = true;
            }
            else {
                this.takeOverCradle = false;
                if (control) {
                    this.setUserBranchandCradle();
                }
                else {
                    this.showMessage = false;
                    this.nextButtonActive = true;
                    this.setStorageMapStep('cradleChoose');
                }
            }
        });
    }
    skipCradleChoose() {
        this.localStorageService.set('cradleSerialNumber', "skip");
        this.showMessage = false;
        this.setStorageMapStep('recordsOrCradle');
        this.router.navigate(['/']);
    }
    isSuperUserOrCradleWithout() {
        return (this.auth.hasRole(['Super User', 'Doctor Without Cradle']));
    }
    setStorageMapStep(value) {
        this.step = value;
        this.storageMap.set('step', this.step).subscribe(() => { });
    }
    takeOverCradleCreateLog(previousUserName, inheritingUserName, cradleSerialNumber) {
        this.service.getCradleTakeOverLogCrateService(previousUserName, inheritingUserName, cradleSerialNumber);
        this.service.createCradleTakeOverLog.subscribe(res => { });
    }
    create_UUID() {
        var dt = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }
}
