<ng-container>
    <nav class="navbar navbar-light bg-light">
      <div class="col-xs-2">
        <ul class="nav navbar px-0">
          <li class="m-1">
            <button (click)="back()" class="btn btn-secondary" i18n type="button">
              <span>{{ "back" | translate }}</span>
            </button>
          </li>
        </ul>
      </div>
      <div class="col-xs-4 d-md-none">
        <ul class="nav navbar-right">
          <li *ngIf="operation === 'edit'" class="m-1">
            <button
              (click)="modal.open(confirmDeleteModal)"
              [disabled]="!mainForm.form.valid"
              class="btn btn-danger"
              i18n
              type="button"
            >
              {{ "delete" | translate }}
            </button>
          </li>
          <li class="m-1">
            <button
              (click)="save()"
              [disabled]="!formValid(mainForm.form)"
              class="btn btn-info"
              i18n
              type="button"
            >
              {{ "save" | translate }}
            </button>
          </li>
        </ul>
      </div>
      <div
        class="col-xs-12 col-md-6 align-items-center d-flex justify-content-center"
      >
        <div class="text-center">
          <h3 i18n>{{ "chargerForm.title" | translate }}</h3>
        </div>
      </div>
      <div class="col-xs-4 d-none d-md-flex">
        <ul class="nav navbar-right">
          <li *ngIf="operation === 'edit'" class="m-1">
            <button
              (click)="modal.open(confirmDeleteModal)"
              [disabled]="!mainForm.form.valid"
              class="btn btn-danger"
              i18n
              type="button"
            >
              {{ "delete" | translate }}
            </button>
          </li>
          <li class="m-1">
            <button
              (click)="save()"
              [disabled]="!formValid(mainForm.form)"
              class="btn btn-info"
              i18n
              type="button"
            >
              {{ "save" | translate }}
            </button>
          </li>
        </ul>
      </div>
    </nav>
  
    <form #mainForm="ngForm" class="card border-0">
      <div class="row m-0">
        <div class="col-lg">
          <div class="card m-3">
            <div class="card-body">
              <div class="row justify-content">
                <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12 mb-3">
                  <label
                    class="required"
                    class="required"
                    for="serialNumber"
                    i18n
                    >{{ "recorderForm.serialNumber" | translate }}</label
                  >
                  <input
                    #serialNumber="ngModel"
                    oncopy="return false"
                    oncut="return false"
                    onpaste="return false"
                    [(ngModel)]="model.serialNumber"
                    class="form-control"
                    id="serialNumber"
                    name="serialNumber"
                    required
                    type="text"
                    onkeypress="return !(event.keyCode > 31 && (event.keyCode < 48 || event.keyCode > 57))"
                    minlength="5"
                    [disabled]="operation === 'edit'"
                  />
                  <div
                    *ngIf="
                      serialNumber.invalid &&
                      (serialNumber.dirty || serialNumber.touched)
                    "
                    class="alert alert-danger"
                  >
                    <div *ngIf="serialNumber.errors['required']" i18n>
                      {{ "required.serialNumber" | translate }}
                    </div>
                    <div *ngIf="!serialNumber.errors['required']" i18n>
                      {{ "required.minSerialNumber" | translate }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12 mb-3">
                  <label class="required" i18n>{{
                    "profileForm.organization" | translate
                  }}</label>
                  <select
                    #organization="ngModel"
                    (ngModelChange)="changeOrganisation($event)"
                    [(ngModel)]="model.customerCodeId"
                    class="custom-select cursorProp "
                    name="selectedContinent"
                    [disabled]="operation === 'add'"
                    required
                  >
                    <option
                      *ngFor="let hospital of hospitalList"
                      [value]="hospital.id"
                      selected
                    >
                      {{ hospital.name }}
                    </option>
                  </select>
                  <div
                    *ngIf="
                      organization.invalid &&
                      (organization.dirty || organization.touched)
                    "
                    class="alert alert-danger"
                  >
                    <div *ngIf="organization.errors['required']" i18n>
                      {{ "required.organization" | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
  
  <ng-template #confirmDeleteModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-title" i18n>
        {{ "recorderForm.deletion" | translate }}
      </h4>
      <button
        type="button"
        class="close"
        aria-label="Close button"
        aria-describedby="modal-title"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>
        <strong i18n>{{ "recorderForm.deleteRecorder" | translate }}</strong>
      </p>
      <p i18n>
        {{ "allInfoDeleted" | translate }}
      </p>
      <span class="text-danger" i18n> {{ "cannotUndone" | translate }} </span>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-danger"
        (click)="delete(); modal.dismiss('deleted')"
        i18n
      >
        {{ "profileForm.ok" | translate }}
      </button>
      <button
        type="button"
        ngbAutofocus
        class="btn btn-outline-secondary"
        (click)="modal.dismiss('cancel click')"
        i18n
      >
        {{ "cancel" | translate }}
      </button>
    </div>
  </ng-template>
  
  <ng-template #savedSuccessMessage>
    <span i18n>{{ "recorderForm.savedSuccess" | translate }}</span>
  </ng-template>
  <ng-template #savedErrorMessage>
    <span i18n>{{ "recorderForm.savedFailed" | translate }}</span>
  </ng-template>
  <ng-template #deletedSuccessMessage>
    <span i18n>{{ "recorderForm.deletedSuccess" | translate }}</span>
  </ng-template>
  <ng-template #deletedErrorMessage>
    <span i18n>{{ "recorderForm.deletedFailed" | translate }}</span>
  </ng-template>
  