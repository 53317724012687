<nav ngbNav #nav="ngbNav" class="nav-tabs">
  <ng-container ngbNavItem>
    <a ngbNavLink>{{'report.systemPerformance' | translate }}</a>
    <ng-template ngbNavContent style="border: 1px solid #dee2e6; border-top: 0; height: calc(100% - 42px);">
      <ecg-date-range></ecg-date-range>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem>
    <a ngbNavLink>{{'report.liveStream' | translate}}</a>
    <ng-template ngbNavContent>
      <ecg-instant></ecg-instant>
    </ng-template>
  </ng-container>
</nav>
<div [ngbNavOutlet]="nav" class="mt-2"></div>
