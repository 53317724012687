import { DataListService } from 'src/app/common/data/data-list.service';
import * as i0 from "@angular/core";
import * as i1 from "../../common/auth/auth-api-http-client";
export class DashboardService extends DataListService {
    constructor(httpClient) {
        super({
            httpClient,
            url: 'health/dashboard',
            initial: []
        });
    }
    getSearch(paging, params, url, options) {
        return super.getSearch(paging, params, url || "get-organisations", options);
    }
}
DashboardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DashboardService_Factory() { return new DashboardService(i0.ɵɵinject(i1.AuthApiHttpClient)); }, token: DashboardService, providedIn: "root" });
