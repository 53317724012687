import { BehaviorSubject, Observable, Subscription, combineLatest, Subject } from 'rxjs';
import { HttpRequestOptions } from './api-http-options';
import { Loading, LoadingStates, LoadingState } from './result';
import { ApiHttpClient } from './api-http-client';
import { map, pairwise } from 'rxjs/operators';

export interface DataServiceArgs<T> {
  httpClient: ApiHttpClient;
  url: string;
  initial?: T;
}

export interface ResultObject<T> {
  data: T;
  error: any;
}

export abstract class DataService<T, R = T> {
  protected dataStore: BehaviorSubject<T>;
  protected errorStore: BehaviorSubject<any>;
  protected loadingStore: Subject<LoadingState>;
  protected subscribtion: Subscription;
  protected httpClient: ApiHttpClient;

  constructor(protected args: DataServiceArgs<T>) {
    this.httpClient = args.httpClient;
    this.dataStore = new BehaviorSubject<T>(args.initial);
    this.errorStore = new BehaviorSubject<any>(null);
    this.loadingStore = new BehaviorSubject<LoadingState>({ url: null, status: null, state: Loading.idle });
  }

  get result(): Observable<ResultObject<T>> {
    return combineLatest([this.data, this.error]).pipe(
      map(de => {
        return { data: de[0], error: de[1] };
      })
    );
  }

  get data(): Observable<T> {
    return this.dataStore.asObservable();
  }

  get error(): Observable<any> {
    return this.errorStore.asObservable();
  }

  get loading(): Observable<LoadingState> {
    return this.loadingStore.asObservable();
  }

  get loadingStates(): Observable<LoadingStates> {
    return this.loading.pipe(
      pairwise(),
      map(states => {
        return { old: states[0], new: states[1] };
      })
    );
  }

  cancel() {
    this.unsubscribe(null);
  }

  getDataValue(): T {
    return this.dataStore.getValue();
  }

  setDataValue(value: T) {
    return this.dataStore.next(value);
  }

  protected setNextData(current: R) {
    this.dataStore.next(current as any as T);
  }

  protected setNextError(error: any) {
    this.errorStore.next(error);
  }

  protected setNextLoading(state: LoadingState) {
    this.loadingStore.next(state);
  }

  protected unsubscribe(url: string, state: Loading = Loading.idle) {
    if (this.subscribtion) {
      this.subscribtion.unsubscribe();
    }
    this.subscribtion = null;
    this.setNextLoading({ url, state, status: null });
  }

  protected pathCombine(...urls: string[]): string {
    return (urls || []).filter(s => s).join('/');
  }

  protected getData(url?: string, options?: HttpRequestOptions) {
    const actionUrl = this.pathCombine(this.args.url, url);
    this.unsubscribe(actionUrl, Loading.getting);
    this.subscribtion = this.args.httpClient.get<R>(actionUrl, options).subscribe(
      r => this.onGetDataCompleted(r, actionUrl, options),
      e => this.onGetDataError(e, actionUrl, options)
    );
  }

  protected onGetDataCompleted(current: R, url?: string, options?: HttpRequestOptions) {
    this.setNextData(current);
    this.setNextLoading({ url, state: Loading.idle, status: true });
  }

  protected onGetDataError(error: any, url?: string, options?: HttpRequestOptions) {
    this.setNextError(error);
    this.setNextLoading({ url, state: Loading.idle, status: false });
  }

  protected postData(url?: string, options?: HttpRequestOptions) {
    const actionUrl = this.pathCombine(this.args.url, url);
    this.unsubscribe(actionUrl, Loading.posting);
    this.subscribtion = this.args.httpClient.post<R, T>(this.getDataValue(), actionUrl, options).subscribe(
      r => this.onPostDataCompleted(r, actionUrl, options),
      e => this.onPostDataError(e, actionUrl, options)
    );
  }

  protected onPostDataCompleted(current: R, url?: string, options?: HttpRequestOptions) {
    this.setNextData(current);
    this.setNextLoading({ url, state: Loading.idle, status: true });
  }

  protected onPostDataError(error: any, url?: string, options?: HttpRequestOptions) {
    this.setNextError(error);
    this.setNextLoading({ url, state: Loading.idle, status: false });
  }

  protected putData(url?: string, options?: HttpRequestOptions) {
    const actionUrl = this.pathCombine(this.args.url, url);
    this.unsubscribe(actionUrl, Loading.putting);
    this.subscribtion = this.args.httpClient.put<R, T>(this.getDataValue(), actionUrl, options).subscribe(
      r => this.onPutDataCompleted(r, actionUrl, options),
      e => this.onPutDataError(e, actionUrl, options)
    );
  }

  protected onPutDataCompleted(current: R, url?: string, options?: HttpRequestOptions) {
    this.setNextData(current);
    this.setNextLoading({ url, state: Loading.idle, status: true });
  }

  protected onPutDataError(error: any, url?: string, options?: HttpRequestOptions) {
    this.setNextError(error);
    this.setNextLoading({ url, state: Loading.idle, status: false });
  }

  protected deleteData(url?: string, options?: HttpRequestOptions) {
    const actionUrl = this.pathCombine(this.args.url, url);
    this.unsubscribe(actionUrl, Loading.deleting);
    this.subscribtion = this.args.httpClient.delete<T>(null, actionUrl, options).subscribe(
      r => this.onDeleteDataCompleted(null, actionUrl, options),
      e => this.onDeleteDataError(e, actionUrl, options)
    );
  }

  protected onDeleteDataCompleted(current: R, url?: string, options?: HttpRequestOptions) {
    this.setNextData(null);
    this.setNextLoading({ url, state: Loading.idle, status: true });
  }

  protected onDeleteDataError(error: any, url?: string, options?: HttpRequestOptions) {
    this.setNextError(error);
    this.setNextLoading({ url, state: Loading.idle, status: false });
  }

}
