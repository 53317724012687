import { Directive, ElementRef, AfterContentInit, Input } from '@angular/core';

@Directive({
  selector: '[ecgAutoFocus]'
})
export class AutoFocusDirective implements AfterContentInit {
  private canFocus = true;
  @Input()
  set ecgAutoFocus(condition: boolean) {
    this.canFocus = condition !== false;

    if (this.canFocus) {
      this.setFocus();
    }
  }

  constructor(private elementRef: ElementRef) {
    // noop
  }

  ngAfterContentInit() {
    if (this.canFocus) {
      this.setFocus();
    }
  }

  private setFocus() {
    if (this.canFocus) {
      setTimeout(() => this.elementRef.nativeElement.focus(), 100);
    }
  }
}
