import {AccessToken, AccessTokenKeys, LoginModel, TokenResult} from './security/token';
import {ApiResult, DataApiResult, UserAccount} from '../data/result';
import {BehaviorSubject, EMPTY, Observable, Subscription, from, of, throwError} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, finalize, first, map, share, switchMap, tap} from 'rxjs/operators';

import {ApiLocations} from '../../app.config';
import {Injectable} from '@angular/core';
import {JwtHelper} from './security/jwt-helper';
import {Logger} from '../logging/logger.service';
import {Profile} from '../../models/profile';
import {StorageMap} from '@ngx-pwa/local-storage';
import appConfig from '../../../environments/app.config.json';

@Injectable({providedIn: 'root'})
export class AuthService {
  protected readonly api: ApiLocations;
  protected token: BehaviorSubject<TokenResult>;
  protected jwtHelper: JwtHelper;
  protected refreshTokenModel: LoginModel;
  protected refreshObservable: Observable<TokenResult>;
  protected loginUrl: string;
  protected validateUrl: string;
  protected logoutUrl: string;
  protected tokenKey = 'token';
  protected httpHeaders = new HttpHeaders();
  private internalUser: UserAccount;
  private internalJwtToken: AccessToken;
  private cacheTokenSubscription: Subscription;
  protected changeForgetPasswordUrl: string;
  protected updatePasswordUrl: string;
  protected validateEmailCode: string;
  protected createPasswordUrl: string;
  public showNavMenu: boolean = false;
  public time: any;
  private rolesArray: any;
  protected get jwtToken(): AccessToken {
    return this.internalJwtToken;
  }

  protected set jwtToken(value: AccessToken) {
    this.internalJwtToken = value;
    if (value) {
      const aToken = value[AccessTokenKeys.role];
      const roleToken: string[] = typeof (aToken) === 'string' ? [aToken] : aToken as string[];
      const roles = roleToken && roleToken.length ?
        roleToken.map(name => {
          return {name};
        }) :
        [];
      this.internalUser = {
        id: value[AccessTokenKeys.serialnumber],
        name: value[AccessTokenKeys.givenname],
        customerId: value[AccessTokenKeys.groupsid],
        customerCodeId: value[AccessTokenKeys.primarysid],
        nameIdentifier: value[AccessTokenKeys.nameidentifier],
        username: value.sub,
        authenticatorAsked: value.authenticatorAsked != null ? value.authenticatorAsked : 'false',
        eMail: null,
        phone: null,
        roles
      };
    } else {
      this.internalUser = null;
    }
  }

  get user(): UserAccount {
    return this.internalUser;
  }

  constructor(
    protected http: HttpClient, protected storageMap: StorageMap,
    protected logger: Logger
  ) {
    this.api = appConfig.apiLocations;
    this.loginUrl = `${this.api.auth}/token/login`;
    this.logoutUrl = `${this.api.auth}/token/logout`;
    this.changeForgetPasswordUrl = `${this.api.auth}/useraccounts/craeteemailcode`;
    this.validateEmailCode = `${this.api.auth}/token/validateemailcode`;
    this.updatePasswordUrl = `${this.api.auth}/token/createpassword`;
    this.validateUrl = `${this.api.auth}/token/`;
    this.jwtHelper = new JwtHelper();
    this.token = new BehaviorSubject<TokenResult>(null);
    this.token.subscribe(event => {
      if (event !== null && event) {
        this.time = event.expiresIn;
      }
    });
    this.refreshTokenModel = {grantType: 'refreshtoken', mac: ''};
  }

  init(): Promise<any> {
    return this.getCachedToken().pipe(
      catchError(e => {
        this.logger.error(e);
        return of(null);
      }),
      map(t => {
        this.saveToken(t);
        return t;
      })
    ).toPromise();
  }

  // TODO UM: shall check refresh expire duration
  get needLogin(): boolean {
    const value = this.token.getValue();
    let url = window.location.pathname;
    if (value == null && (url != '/login' && url != '/' && url != '/forgot' && url != '/createpassword' && url != '' && url != '/changepassword')) {
      window.location.href = '/login';
    }
    if (value != null && value.refreshToken != null) {
      return false;
    }
    return true;
  }
  controlRole(name) {
    const index = this.rolesArray.findIndex(role => role === name);
    if (index > -1) {
      return true;
    } else {
      return false;
    }
  }

  get needRefresh(): boolean {
    if (this.jwtToken != null) {
      return this.jwtHelper.isTokenExpired(this.jwtToken, 60);
    }
    return true;
  }

  hasRole(roles: string[]): boolean {

    if (!this.needLogin && this.user && this.user.roles && this.user.roles.length) {
      this.rolesArray = roles;
      return (roles && roles.length) ?
        this.user.roles.some((v, i, a) => this.controlRole(v.name)) :
        true;
    }
    return false;
  }

  login(model: LoginModel): Observable<TokenResult> {
    this.setToken(null);
    return this.http.post<DataApiResult<TokenResult>>(this.loginUrl, model).pipe(
      map(r => {
        this.saveToken(r.data);
        return r.data;
      }));
  }

  authValidation(model, type) {
    this.setToken(null);
    return this.http.post<DataApiResult<TokenResult>>(this.validateUrl + (type == 1 ? 'validate-google-authenticator' : 'validate-email-authenticator'), model).pipe(
      map(r => {
        this.saveToken(r.data);
        return r.data;
      }));
  }

  restorePassword(model: Profile) {
    return this.http.post<Profile>(this.changeForgetPasswordUrl, model);
  }
  forgotPassword(data, type) {
    return this.http.post(this.changeForgetPasswordUrl + '?email=' + data.username + '&type=' + type, {});
  }
  controlPassword(code) {
    if (code) {
      return this.http.post(this.validateEmailCode + '/' + code, {});
    } else {
      window.location.href = "/login";
    }
  }
  updatePassword(model, code, type) {
    return this.http.post(this.updatePasswordUrl + '/' + code + '?type=' + type , model);
  }
  setCreatePassword(emailcode: string, model: any, type: string) {
    return this.http.post<any>( '/api/v1/auth/token/createpassword/' + emailcode + type, model);
  }
  getCreatePassword(code) {
    return this.http.get<UserAccount[]>('api/v1/auth/useraccounts/email-reset-code/' + code);
  }

  logout(): Observable<any> {
    const forRefresh = this.needRefresh ? from(this.internalRefresh()) : this.token;
    return forRefresh.pipe(
      switchMap(async (accessToken) => {
        await this.performLogout(accessToken);
      }),
      first(),
      finalize(() => {
        this.saveToken(null);
        window.location.reload();
      })
    );
  }

  private async performLogout(token: any): Promise<any> {
    const data = await this.internalLogout(token);
    if(data === null) {
      window.location.reload();
      return;
    }

    return data;
  }

  getAccessToken(): Observable<string> {
    if (this.needLogin) {
      console.warn('Need login to refresh token!');

      return EMPTY;
    }
    return this.refreshToken().pipe(first());
  }

  protected async internalLogout(at: TokenResult): Promise<any> {
    console.log("internalLogout");
    let headers = this.httpHeaders;

    if (at && at.accessToken) {
      headers = this.httpHeaders.set('Authorization', `Bearer ${at.accessToken}`);

    }

    const promiseData: Promise<any> = this.http.post<ApiResult>(this.logoutUrl, null, {headers}).toPromise();
    promiseData.then()
      .catch((error: any) => console.log(error));

    return await promiseData;
  }

  protected refreshToken(): Observable<string> {
    if (!this.needRefresh) {
      return this.token.asObservable().pipe(
        map(t => t ? t.accessToken : null)
      );
    }

    return (this.refreshObservable || this.internalRefresh()).pipe(
      map(t => t ? t.accessToken : null)
    );
  }
  public forceRefresh() {
    return this.internalRefresh();
  }
  protected internalRefresh(): Observable<TokenResult> {
    console.log("internalRefresh");
    this.refreshTokenModel.refreshToken = this.token.getValue().refreshToken;
    this.refreshObservable = this.http.post<DataApiResult<TokenResult>>(
      this.loginUrl, this.refreshTokenModel
    ).pipe(
      map(r => r.data),
      catchError(e => {
        this.logger.error(e);
        return of(null);
      }),
      tap(t => {
        this.saveToken(t);
        this.refreshObservable = null;
      }),
      share()
    );
    return this.refreshObservable;
  }

  protected getCachedToken(): Observable<TokenResult> {
    return this.storageMap.get(this.tokenKey).pipe(
      map(d => {
        return d as TokenResult;
      })
    );
  }

  protected cacheToken(token: TokenResult): void {
    if (this.cacheTokenSubscription) {
      this.cacheTokenSubscription.unsubscribe();
    }
    this.cacheTokenSubscription = (token ?
        this.storageMap.set(this.tokenKey, token) :
        this.storageMap.delete(this.tokenKey)
    ).pipe(
      first()
    ).subscribe(
      d => {
      },
      e => this.logger.error(e)
    );
  }

  protected setToken(token: TokenResult): void {
    this.jwtToken = null;

    if (token && token.accessToken) {
      this.jwtToken = this.jwtHelper.decodeToken(token.accessToken);
      this.token.next(token);
    } else {
      this.token.next(null);
    }
  }

  public saveToken(token: TokenResult): void {
    this.setToken(token);
    this.cacheToken(token);
  }

}
