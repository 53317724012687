export const AccessTokenKeys = {
  // sid: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid',
  serialnumber: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/serialnumber',
  // name: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
  actor: 'http://schemas.xmlsoap.org/ws/2009/09/identity/claims/actor',
  givenname: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname',
  nameidentifier: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier',
  groupsid: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/groupsid',
  primarysid: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/primarysid',
  // locality: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/locality',
  role: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
};

export interface AccessToken {
  [key: string]: any;
  sub: string;
  jti: string;
  iat: number;
  nbf: number;
  exp: number;
  iss: string;
  aud: string;
}

export interface TokenResult {
  accessToken: string;
  expiresIn: string;
  refreshToken: string;
  authenticatorType?: number;
  authenticatorKey?: string;
  userName?: string;
  uuId : string;
  userRoles?: any[];
}


export interface LoginModel {
  grantType: GrantType;
  username?: string;
  password?: string;
  refreshToken?: string;
  culture?: string;
  mac?: string;
  FingerPrintKey?: string;
}

export type GrantType = 'password' | 'refreshtoken';
