import { Component } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import {AuthApiHttpClient} from '../common/auth/auth-api-http-client';
import Swal from "sweetalert2";
import {TranslateService} from '@ngx-translate/core';
import {DataApiResult} from "../common/data/result";
import {Location} from '@angular/common';

@Component({
  selector: 'ecg-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})

export class NotificationComponent {

  public Editor = ClassicEditor;
  public editorData = null;
  editorValid = false;
  public config = {
    toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
    heading: {
      options: [
        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
      ]
    }
  }
  constructor(
    private location: Location,
    protected translateService: TranslateService,
    protected httpClient: AuthApiHttpClient
  ) {
  }

  save() {
    this.showLoading();
    const model = {
      content: this.editorData
    };
    this.httpClient.post<DataApiResult<any>,{}>(model,'auth/useraccounts/send-notification').subscribe(res => {
      Swal.close();
      if(res.statusCode == 200)
      {
        return Swal.fire({
          position: 'center',
          icon: 'success',
          title: this.translateService.instant('done'),
          showConfirmButton: false,
          timer: 2000
        });
      }
      else
      {
        return Swal.fire({
          position: 'center',
          icon: 'error',
          title: res.message,
          showConfirmButton: false,
          timer: 5000
        });
      }
    }, error => {
      Swal.close();
      return Swal.fire({
        position: 'center',
        icon: 'error',
        title: error.error.message,
        showConfirmButton: false,
        timer: 5000
      });
    });
  }

  public onChange( { editor }: ChangeEvent ) {
    this.editorValid = true;
    this.editorData = editor.getData();
  }

  showLoading() {
    Swal.fire({
      position: 'center',
      imageUrl: 'assets/gifs/loading.gif',
      showConfirmButton: false,
      timer: 44000,
    });
  }

  back() {
    this.location.back();
  }
}
