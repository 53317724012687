<div *ngIf="(service.data | async) as invoices">
    <nav class="navbar navbar-light bg-light">
      <div class="row m-0 w-100">
        <div class="col-3 pl-0 pt-1 pr-0">
          <button class="btn btn-info mr-2 filterBttn" (click)="showFilter = !showFilter" i18n>
            <i class="fa fa-filter"></i> {{'filterButton' | translate}}
          </button>
          <button type="button" class="btn btn-outline-secondary" (click)="filterReset();" *ngIf="showFilter"  i18n>{{'filterReset' | translate }}</button>
        </div>
        <div class="col-6 align-self-center text-center">
          <h3 i18n>{{'invoiceForm.invoices' | translate }}</h3>
        </div>
      </div>
    </nav>
    <div class="card" *ngIf="showFilter">
      <div class="card-body">
        <ecg-filter (filterChange)="pagingReset();filterChange($event);" [metaData]="filterMetaData" [reset]="reset"></ecg-filter>
      </div>
    </div>
    <div [class]="(dataCount | async) > 10 ? 'table-responsive' : 'table-responsive table-responsive-no-paginate'">
      <table class="table table-hover" i18n-aria-describedby aria-describedby="doctor list">
        <thead>
          <tr>
            <th scope="col" sortable="month" (sort)="onSort($event)" i18n>{{'invoiceForm.month' | translate }}</th>
            <th scope="col" sortable="year" i18n>{{'invoiceForm.year' | translate }}</th>
            <th class="text-center" scope="col" sortable="customerCode.code" (sort)="onSort($event)" i18n>{{'invoiceForm.customerCode' | translate }} </th>
            <th class="text-center" scope="col" sortable="createdDateTime" (sort)="onSort($event)" i18n>{{'invoiceForm.createdDate' | translate }}</th>
            <th class="text-center" scope="col" (sort)="onSort($event)" i18n>{{'invoiceForm.attachmentInvoice' | translate }}</th>
            <th class="text-center" scope="col"></th>
          </tr>
        </thead>

        <tbody *ngIf="invoices && invoices.length; else norecord">
        <tr class="clickable-row cursorProp" *ngFor="let invoice of invoices; let index=index" [tabindex]="index + 1">
          <td>{{ invoice.month }}</td>
          <td>{{ invoice.year }}</td>
          <td class="text-center">{{invoice.customerCode}}</td>
          <td class="text-center">{{ invoice.createdDateTime | date: 'd-M-yyyy HH:mm'}}</td>
          <td class="text-center">
            <button type="button" class="btn csv-btn" ngbTooltip="Open" placement="bottom"
              (click)="open(content, invoice)"
              [disabled]="invoice.invoiceFiles.length === 0" >
                <i class="fa fa-file x2 csv-icon" aria-hidden="true"></i>
            </button>            
            <button type="button" class="btn csv-btn" ngbTooltip="Download" placement="bottom"
              (click)="csvFileView($event, invoice)"
              [disabled]="invoice.invoiceFiles.length === 0">
                <i class="fa fa-download" aria-hidden="true"></i>
            </button>
          </td>
          <td class="text-center">
              <i *ngIf="auth.hasRole(['System Admin', 'Super User', 'Financial Admin'])" ngbTooltip="Delete" placement="bottom" (click)="checkBeforeDelete(invoice.id)" style="color: red; cursor: pointer;" class="fa fa-trash fa-2x"></i>
          </td>
        </tr>
        </tbody>

        <ng-template #norecord>
          <tbody>
          <tr>
            <td colspan="10" class="text-danger" i18n>{{'patientList.noRecords' | translate }}</td>
          </tr>
          </tbody>
        </ng-template>

        <ng-template #content let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Invoice</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">        
            <div class="iframe-wrapper" [style.display]="previewFile ? 'block' : 'none'">
              <iframe id="previewIframe"></iframe>
            </div>
        
            <div class="table-responsive" *ngIf="csvTable">
              <table class="table table-striped csv-table">
                <thead>
                    <tr>
                      <th class="text-center" *ngFor="let title of csvTable[0]">{{title}}</th>
                    </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let line of csvTableBody">
                    <td class="text-center" *ngFor="let cell of line">{{cell}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
        
          </div>
        </ng-template>

      </table>
    </div>

    <div *ngIf="(dataCount | async) as count">
      <div class="bg-ecg fix-to-bottom text-white px-3 pt-lg-3" *ngIf="count>=10">
        <div class="row justify-content-between w-100 m-0">
          <div class="col-12 d-block d-lg-none">
            <div class="pt-1 text-center"
                 i18n>{{count}} {{'itemsIn' | translate }} {{this | func:pageCount:count:paging.size}} {{'pages'
              | translate }}
            </div>
          </div>
          <div class="col-auto pl-0 pr-0">
            <ngb-pagination [boundaryLinks]="true" [rotate]="true" [maxSize]="3" [(page)]="paging.index"
                            [pageSize]="paging.size" [collectionSize]="count" (pageChange)="refresh();pagingSave();"
                            #pagination></ngb-pagination>
          </div>
          <div class="col-12 col-lg d-none d-lg-block">
            <div class="pt-2 text-center"
                 i18n>{{count}} {{'itemsIn' | translate }} {{this | func:pageCount:count:paging.size}} {{'pages'
              | translate }}
            </div>
          </div>
          <div class="col pr-0 pl-0 pagination-dropdown-container">
            <div>
              <select class="custom-select pagination-dropdown cursorProp" [(ngModel)]="paging.size" (change)="refresh();pagingSave();">
                <option [ngValue]="10" i18n>{{'records10' | translate }}</option>
                <option selected [ngValue]="15" i18n>{{'records15' | translate }}</option>
                <option [ngValue]="20" i18n>{{'records20' | translate }}</option>
                <option [ngValue]="50" i18n>{{'records50' | translate }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  