<ul class="nav nav-tabs">
  <li class="nav-item">
    <a class="nav-link"
       [routerLink]="" (click)="router.navigate(['ip-whitelist'])" i18n>{{'ipList.ipList' | translate}}</a>
  </li>
  <li class="nav-item">
    <a class="nav-link {{router.url.endsWith('access-requests') ? 'active' : '' }}" [routerLink]="" (click)="router.navigate(['ip-whitelist/access-requests'])">{{'accessRequest.accessRequest' | translate}}</a>
  </li>
</ul>
<div *ngIf="(service.data | async) as ipAddressKeys">
  <nav class="navbar navbar-light bg-light">
    <div class="row m-0 w-100">
      <div class="col-3 pl-0 pt-1 pr-0">
        <button class="btn btn-info mr-2" (click)="showFilter = !showFilter" i18n>
          <i class="fa fa-filter"></i> {{'filterButton' | translate}}
        </button>
        <button type="button" class="btn btn-outline-secondary" (click)="filterReset();" *ngIf="showFilter" i18n>{{'filterReset' | translate }}</button>
      </div>
      <div class="col-6 align-self-center text-center">
        <h3 i18n>{{'accessRequest.accessRequest' | translate}}</h3>
      </div>
      <div class="col-3 pt-1 pr-0">
      </div>
    </div>
  </nav>

  <div class="card" *ngIf="showFilter">
    <div class="card-body">
      <ecg-filter [metaData]="filterMetaData" (filterChange)="filterChange($event);" [reset]="reset"></ecg-filter>
    </div>
  </div>
  <div [class]="(dataCount | async) > 10 ? 'table-responsive' : 'table-responsive table-responsive-no-paginate'">
    <table class="table table-hover" i18n-aria-describedby aria-describedby="incoming file list">
      <thead>
        <tr>
          <th scope="col" sortable="fullName" (sort)="onSort($event)" i18n>{{'accessRequest.requester' | translate}}</th>
          <th scope="col" sortable="email" (sort)="onSort($event)" i18n>{{'accessRequest.email' | translate}}</th>
          <th scope="col" sortable="customer.name" (sort)="onSort($event)" i18n>{{'accessRequest.organisation' | translate}}</th>
          <th scope="col" sortable="ipAddress" (sort)="onSort($event)" i18n>{{'accessRequest.address' | translate}}</th>
          <th i18n>Operation</th>
        </tr>
      </thead>
      <tbody *ngIf="ipAddressKeys !== undefined || ipAddressKeys.length != 0; else norecord">
        <tr *ngFor="let request of ipAddressKeys; let index=index" [tabIndex]="index + 1">
          <td>{{ request.fullName}}</td>
          <td>{{request.email}}</td>
          <td>{{request.customer != null ? request.customer.name : '-'}}</td>
          <td>{{request.ipAddress}}</td>
          <td style="margin-right: 30px;">
            <button type="button" class="btn btn-sm btn-success mr-2" (click)="allow(request, true)">
              <i class="fa fa-check"></i>
            </button>
            <button type="button" class="btn btn-sm btn-danger" (click)="allow(request,false)">
              <i class="fa fa-times"></i>
            </button>
          </td>
        </tr>
      </tbody>
      <ng-template #norecord>
        <tbody>
          <tr>
            <td colspan="10" class="text-danger" i18n>{{'ipList.noRecords' | translate }}</td>
          </tr>
        </tbody>
      </ng-template>
    </table>
  </div>
  <div *ngIf="(dataCount | async) as count">
    <div class="bg-ecg fix-to-bottom text-white px-3 pt-lg-3" *ngIf="count>=10">
      <div class="row justify-content-between w-100 m-0">
        <div class="col-12 d-block d-lg-none">
          <div class="pt-1 text-center" i18n>
            {{count}} {{'itemsIn' | translate }}
            {{this | func:pageCount:count:paging.size}} {{'pages' | translate }}
          </div>
        </div>
        <div class="col-auto pl-0 pr-0">
          <ngb-pagination [boundaryLinks]="true" [rotate]="true" [maxSize]="3" [(page)]="paging.index"
                          [pageSize]="paging.size" [collectionSize]="count" (pageChange)="refresh();pagingSave();" #pagination>
          </ngb-pagination>
        </div>
        <div class="col-12 col-lg d-none d-lg-block">
          <div class="pt-2 text-center" i18n>
            {{count}} {{'itemsIn' | translate }}
            {{this | func:pageCount:count:paging.size}} {{'pages' | translate }}
          </div>
        </div>
        <div class="col pr-0 pl-0 pagination-dropdown-container">
          <div>
            <select class="custom-select pagination-dropdown " [(ngModel)]="paging.size" (change)="refresh()">
              <option [ngValue]="10" i18n>{{'records10' | translate }}</option>
              <option selected [ngValue]="15" i18n>{{'records15' | translate }}</option>
              <option [ngValue]="20" i18n>{{'records20' | translate }}</option>
              <option [ngValue]="50" i18n>{{'records50' | translate }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
