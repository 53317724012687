import {Injectable} from '@angular/core';
import {AuthApiHttpClient} from '../common/auth/auth-api-http-client';
import {DataItemService} from '../common/data/data-item.service';
import {ActivatedRoute} from '@angular/router';
import { Recorder } from '../models/recorder';

@Injectable({providedIn: 'root'})
export class RecorderItemService extends DataItemService<Recorder> {
  public hospitalList: any;
  constructor(httpClient: AuthApiHttpClient, route: ActivatedRoute) {
    super({
      httpClient,
      url: 'health/recorders/item'
    });

    this.hospitalList = this.httpClient.get<Array<string>>('health/cradles/customerCodes');
  }
}
