import { HttpHeaders } from '@angular/common/http';
import appConfig from '../../../environments/app.config.json';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ApiHttpClient {
    constructor(http) {
        this.http = http;
        this.api = appConfig.apiLocations;
    }
    static getDefaultOptions(responseType = 'json', body = 'body') {
        return { body, responseType };
    }
    get(url, options = ApiHttpClient.getDefaultOptions()) {
        options.headers = (options.headers || new HttpHeaders())
            .set('Cache-control', 'not-store, no-cache')
            .set('Pragma', 'no-cache')
            .set('Expires', '0');
        return this.request('GET', null, url, options);
    }
    post(body, url, options) {
        return this.request('POST', body, url, options);
    }
    put(body, url, options) {
        return this.request('PUT', body, url, options);
    }
    delete(body, url, options) {
        return this.request('DELETE', body, url, options);
    }
    request(method, body, url, options = ApiHttpClient.getDefaultOptions()) {
        if (body) {
            options.body = body;
            options.headers = (options.headers || new HttpHeaders()).append('Content-Type', 'application/json');
        }
        return this.http.request(method, this.getPath(url), options);
    }
    getBlob(url, options = ApiHttpClient.getDefaultOptions('blob')) {
        return this.requestBlob('GET', null, url, options);
    }
    requestBlob(method, body, url, options = ApiHttpClient.getDefaultOptions('blob')) {
        if (body) {
            options.body = body;
        }
        return this.http.request(method, this.getPath(url, options), options);
    }
    getPath(url, options) {
        if (options && options.getPath) {
            return options.getPath(url);
        }
        return url ? `${this.api.base}/${url}` : this.api.base;
    }
}
ApiHttpClient.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiHttpClient_Factory() { return new ApiHttpClient(i0.ɵɵinject(i1.HttpClient)); }, token: ApiHttpClient, providedIn: "root" });
