import {Component} from '@angular/core';
import {UserAccountItemService} from '../sysadmin/user-account/user-account-item.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserAccount} from '../models/user-account';
import {BaseFormComponent} from '../common/components/base-form.component';
import {Logger} from '../common/logging/logger.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastService} from '../shared/services/toast.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Location} from '@angular/common';
import {PcsItemService} from './pc-form-item.service';


@Component({
  selector: 'ecg-pc-form',
  templateUrl: './pc-form.component.html',
  styleUrls: ['./pc-form.component.scss']
})
export class PcFormComponent extends BaseFormComponent<UserAccount> {


  constructor(
    public accountService: PcsItemService, logger: Logger, location: Location,
    route: ActivatedRoute, router: Router,
    spinner: NgxSpinnerService, toast: ToastService,
    public modal: NgbModal
  ) {
    super({service: accountService, logger, location, route, router, spinner, toast});
  }
  protected setData(model: UserAccount) {
    super.setData(model);
  }
}
