<nav class="navbar navbar-light bg-light">
  <div class="row m-0 w-100 justify-content-end">
    <div class="col nav navbar-left" style="margin-top: 4px; padding: unset !important;">
      <button class="btn btn-info mr-2 smaller-filter-btn filterBttn" (click)="showFilter = !showFilter" i18n><i class="fa fa-filter"></i> {{'filterButton' | translate}}</button>
      <div class="imgup">
        <button type="button" class="btn btn-outline-secondary" (click)="filterReset();" *ngIf="showFilter" i18n>{{'filterReset' | translate }}</button>
      </div>
    </div>
    <div class="col-auto pt-1 pr-0">
      <ecg-button (onClick)="updateCharts('daily', false)" [class]="dateType == 'daily' ? 'btn btn-light filter-button first-filter-button active' : 'btn btn-light filter-button first-filter-button'">
          {{'dashboard.daily' | translate}}
      </ecg-button>   
      <ecg-button (onClick)="updateCharts('weekly', false)" [class]="dateType == 'weekly' ? 'btn btn-light filter-button active' : 'btn btn-light filter-button'">
          {{'dashboard.weekly' | translate}}
      </ecg-button>
      <ecg-button (onClick)="updateCharts('monthly', false)" [class]="dateType == 'monthly' ? 'btn btn-light filter-button active' : 'btn btn-light filter-button'">
          {{'dashboard.monthly' | translate}}
      </ecg-button>
      <ecg-button (onClick)="updateCharts('yearly', false)" [class]="dateType == 'yearly' ? 'btn btn-light filter-button active' : 'btn btn-light filter-button'">
          {{'dashboard.yearly' | translate}}
      </ecg-button>
    </div>
  </div>
</nav>

<div class="card" *ngIf="showFilter">
  <div  class="card-body">
    <div class="row">
      <div class="col-2 form-group">
        <input type="text" [(ngModel)]="searchText" class="form-control" [name]="searchText" [placeholder]="'search' | translate">
      </div>
      <div class="col-1 form-group filter-button">
        <ecg-button (click)="filter()" class="btn btn-block btn-primary" i18n>{{'filterButton' | translate }}</ecg-button>
      </div>
    </div>
  </div>
</div>

<div class="dashboard-container">
  <div class="row m-0">
    <div class="col-xl-4 d-flex align-items-stretch col-md-12 chart-card-responsive mt-2">
      <div class="card">
        <div class="card-body">
          <div class="row d-flex h-100 chart-card">
            <div class="col-12 col-sm-12 responsive-title d-block" *ngIf="dashboard">
              <p class="text-muted mb-0 chart-title">
                {{"dashboard.totalPrepared" | translate}} 
                <span class=" float-right ml-3" style="font-size: 24px;">
                  {{getArrayTotal(dashboard.patientPreparedCount)}}
                </span>
              </p>
              <p class="text-muted mb-0 chart-title mt-1 mb-3" style="font-size: 14px;" *ngIf="activeValues['Prepared']">
                {{"dashboard.prepared" | translate}} 
                <span class="ml-3" style="font-size: 20px;">
                  {{activeValues['Prepared']}}
                </span>
              </p>
            </div>
            <div class="col-12 align-self-end responsive-chart d-block">
              <div id="PreparedContainer">
                <canvas  id="PreparedGraph"></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 d-flex align-items-stretch col-md-12 chart-card-responsive mt-2">
      <div class="card">
        <div class="card-body">
          <div class="row d-flex h-100 chart-card">
            <div class="col-12 col-sm-12 responsive-title d-block" *ngIf="dashboard">
              <p class="text-muted mb-0 chart-title">
                {{"dashboard.totalWaiting" | translate}} 
                <span class=" float-right ml-3" style="font-size: 24px;" *ngIf="waitingArray && waitingArray.length > 0; else noWaiting">
                  {{getArrayTotal(waitingArray)}}
                </span>
                <ng-template #noWaiting>
                  <span class=" float-right ml-3" style="font-size: 24px;">
                    0
                  </span>
                </ng-template>
              </p>
              <p class="text-muted mb-0 chart-title mt-1 mb-3" style="font-size: 14px;" *ngIf="activeValues['Waiting']">
                {{"dashboard.waiting" | translate}} 
                <span class="ml-3" style="font-size: 20px;">
                  {{activeValues['Waiting']}}
                </span>
              </p>
            </div>
            <div class="col-12 align-self-end responsive-chart d-block">
              <div id="WaitingContainer">
                <canvas  id="WaitingGraph"></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 d-flex align-items-stretch col-md-12 chart-card-responsive mt-2">
      <!-- <ng-container *ngIf="dashboard">
        <ecg-chart-card [datum]="dashboard.patientCompletedCount" [chart]="'completed'"></ecg-chart-card>
      </ng-container> -->
      <div class="card">
        <div class="card-body">
          <div class="row d-flex h-100 chart-card">
            <div class="col-12 col-sm-12 responsive-title d-block" *ngIf="dashboard">
              <p class="text-muted mb-0 chart-title" style="font-size: 18px;">
                {{"dashboard.totalCompleted" | translate}} 
                <span class=" float-right ml-3" style="font-size: 24px;">
                  {{getArrayTotal(dashboard.patientCompletedCount)}}
                </span>
              </p>
              <p class="text-muted mb-0 chart-title mt-1 mb-3" style="font-size: 14px;" *ngIf="activeValues['Completed']">
                {{"dashboard.completed" | translate}} 
                <span class="ml-3" style="font-size: 20px;">
                  {{activeValues['Completed']}}
                </span>
              </p>
            </div>
            <div class="col-12 align-self-end responsive-chart d-block">
              <div id="CompletedContainer">
                <canvas  id="CompletedGraph"></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row m-0" *ngIf="(service.data | async) as dashboardOrganisation">
    <div class="col-12">
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
          <tr>
            <th class="font-w500">{{"organisationForm.organisation" | translate}}</th>
            <th class="font-w500">{{"organisationForm.organisationCode" | translate}}</th>
            <th class="font-w500">{{"dashboard.completed" | translate}}</th>
          </tr>
          </thead>
          <tbody *ngIf="dashboardOrganisation !== undefined && dashboardOrganisation.length != 0 ; else norecord">
          <tr *ngFor="let organisation of dashboardOrganisation">
            <td [innerText]="organisation.organisationName">
            </td>
            <td [innerText]="organisation.organisationCode">
            </td>
            <td class="font-size-h5 " [innerText]="organisation.value">
            </td>
          </tr>
          </tbody>
          <ng-template #norecord>
            <tbody>
            <tr>
              <td colspan="10" class="text-danger" i18n>{{'patientList.noRecords' | translate }}</td>
            </tr>
            </tbody>
          </ng-template>
        </table>
      </div>
    </div>
  </div>
</div>

<div *ngIf="(dataCount | async) as count">
  <div class="bg-ecg fix-to-bottom text-white px-3 pt-lg-3" *ngIf="count>=10">
    <div class="row justify-content-between w-100 m-0">
      <div class="col-12 d-block d-lg-none">
        <div class="pt-1 text-center" i18n>{{count}} {{'itemsIn' | translate }} {{this | func:pageCount:count:paging.size}} {{'pages'
          | translate }}
        </div>
      </div>
      <div class="col-auto pl-0 pr-0">
        <ngb-pagination [boundaryLinks]="true" [rotate]="true" [maxSize]="3" [(page)]="paging.index"
        [pageSize]="paging.size" [collectionSize]="count" (pageChange)="refresh()"
        #pagination></ngb-pagination>
      </div>
      <div class="col-12 col-lg d-none d-lg-block">
        <div class="pt-2 text-center" i18n>{{count}} {{'itemsIn' | translate }} {{this | func:pageCount:count:paging.size}} {{'pages'
          | translate }}
        </div>
      </div>
      <div class="col pr-0 pl-0 pagination-dropdown-container">
        <div>
          <select class="custom-select pagination-dropdown pt-2" [(ngModel)]="paging.size" (change)="refresh()">
            <option selected [ngValue]="10" i18n>{{'records10' | translate }}</option>
            <option selected [ngValue]="15" i18n>{{'records15' | translate }}</option>
            <option [ngValue]="20" i18n>{{'records20' | translate }}</option>
            <option [ngValue]="50" i18n>{{'records50' | translate }}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>