import { Injectable } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { Observable } from "rxjs";
import { AuthApiHttpClient } from "src/app/common/auth/auth-api-http-client";
import {
  getOptionsForDataKeyParams,
  HttpRequestOptions,
} from "src/app/common/data/api-http-options";
import { DataItemService } from "src/app/common/data/data-item.service";
import {
  DataApiResult,
  DataList,
  Filter,
  Filters,
  FilterType,
  Loading,
  Paging,
} from "src/app/common/data/result";
import { SearchParam } from "src/app/models";
import { Report } from "src/app/models/report";

@Injectable({ providedIn: "root" })
export class DateRangeService extends DataItemService<string> {
  public projectList: any;
  public reportList: any;
  
  constructor(httpClient: AuthApiHttpClient, route: ActivatedRoute) {
    super({
      httpClient,
      url: "",
    });
    this.projectList = this.httpClient.get<Array<string>>(
      "health/platformPerformance/GetProjects"
    );
  }
  getModuleList(projectName) {
    return this.httpClient.get<Array<string>>(
      "health/platformPerformance/GetModules?projectName=" + projectName
    );
  }
  getErrorLogModuleList(projectName) {
    return this.httpClient.get<Array<string>>(
      "health/errorLogReport/GetModules?projectName=" + projectName
    );
  }
  getLineData(params?: SearchParam) {
    const actionUrl = "health/PlatformPerformance/Search";
    const needPost = params && (params.columns || params.filters);
    const method = needPost
      ? this.args.httpClient.post<DataApiResult<DataList<Report>>, SearchParam>(
          params,
          actionUrl
        )
      : this.args.httpClient.get<DataApiResult<DataList<Report>>>(actionUrl);
    return method;
  }
}
