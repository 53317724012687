/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./range-datepicker.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "@angular/common";
import * as i5 from "./range-datepicker.component";
var styles_RangeDatepickerComponent = [i0.styles];
var RenderType_RangeDatepickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RangeDatepickerComponent, data: {} });
export { RenderType_RangeDatepickerComponent as RenderType_RangeDatepickerComponent };
function View_RangeDatepickerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "custom-day"]], [[2, "focused", null], [2, "range", null], [2, "faded", null]], [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = ((_co.hoveredDate = _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = ((_co.hoveredDate = null) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.focused; var currVal_1 = _co.isRange(_v.context.$implicit); var currVal_2 = (_co.isHovered(_v.context.$implicit) || _co.isInside(_v.context.$implicit)); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _v.context.$implicit.day; _ck(_v, 1, 0, currVal_3); }); }
export function View_RangeDatepickerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 6, "input", [["class", "form-control"], ["name", "datepicker"], ["ngbDatepicker", ""], ["outsideDays", "hidden"], ["readonly", "readonly"]], [[8, "value", 0], [8, "placeholder", 0], [8, "disabled", 0]], [[null, "dateSelect"], [null, "click"], [null, "input"], [null, "change"], [null, "focus"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).manualDateChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).manualDateChange($event.target.value, true) !== false);
        ad = (pd_1 && ad);
    } if (("focus" === en)) {
        var pd_2 = (i1.ɵnov(_v, 5).onFocus() !== false);
        ad = (pd_2 && ad);
    } if (("blur" === en)) {
        var pd_3 = (i1.ɵnov(_v, 5).onBlur() !== false);
        ad = (pd_3 && ad);
    } if (("dateSelect" === en)) {
        var pd_4 = (_co.onDateSelection($event) !== false);
        ad = (pd_4 && ad);
    } if (("click" === en)) {
        var pd_5 = (i1.ɵnov(_v, 5).toggle() !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.NgbInputDatepicker]), i1.ɵprd(5120, null, i2.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i3.NgbInputDatepicker]), i1.ɵprd(6144, null, i3.NgbDatepickerConfig, null, [i3.NgbInputDatepickerConfig]), i1.ɵdid(5, 671744, [["datepicker", 4]], 0, i3.NgbInputDatepicker, [i3.NgbDateParserFormatter, i1.ElementRef, i1.ViewContainerRef, i1.Renderer2, i1.ComponentFactoryResolver, i1.NgZone, i3.NgbCalendar, i3.NgbDateAdapter, i4.DOCUMENT, i1.ChangeDetectorRef, i3.NgbInputDatepickerConfig], { autoClose: [0, "autoClose"], dayTemplate: [1, "dayTemplate"], displayMonths: [2, "displayMonths"], minDate: [3, "minDate"], maxDate: [4, "maxDate"], outsideDays: [5, "outsideDays"] }, { dateSelect: "dateSelect" }), i1.ɵpod(6, { year: 0, month: 1, day: 2 }), i1.ɵpod(7, { year: 0, month: 1, day: 2 }), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "input-group-append"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "button", [["class", "btn btn-outline-secondary calendar"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(0, [["t", 2]], null, 0, null, View_RangeDatepickerComponent_1))], function (_ck, _v) { var _co = _v.component; var currVal_3 = "outside"; var currVal_4 = i1.ɵnov(_v, 10); var currVal_5 = 2; var currVal_6 = _ck(_v, 6, 0, _co.minDate.getFullYear(), (_co.minDate.getMonth() + 1), _co.minDate.getDate()); var currVal_7 = _ck(_v, 7, 0, _co.maxDate.getFullYear(), (_co.maxDate.getMonth() + 1), _co.maxDate.getDate()); var currVal_8 = "hidden"; _ck(_v, 5, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getDate(_co.fromDate, _co.toDate); var currVal_1 = _co.placeholder; var currVal_2 = i1.ɵnov(_v, 5).disabled; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_RangeDatepickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ecg-range-datepicker", [], null, [[null, "keyup.backspace"], [null, "keyup.delete"]], function (_v, en, $event) { var ad = true; if (("keyup.backspace" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).clearDate() !== false);
        ad = (pd_0 && ad);
    } if (("keyup.delete" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).clearDate() !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_RangeDatepickerComponent_0, RenderType_RangeDatepickerComponent)), i1.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.RangeDatepickerComponent]), i1.ɵdid(2, 49152, null, 0, i5.RangeDatepickerComponent, [i3.NgbDateParserFormatter, i3.NgbDateAdapter], null, null)], null, null); }
var RangeDatepickerComponentNgFactory = i1.ɵccf("ecg-range-datepicker", i5.RangeDatepickerComponent, View_RangeDatepickerComponent_Host_0, { placeholder: "placeholder", maxDate: "maxDate", minDate: "minDate" }, {}, []);
export { RangeDatepickerComponentNgFactory as RangeDatepickerComponentNgFactory };
