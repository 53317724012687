import { DataItemService } from '../common/data/data-item.service';
import * as i0 from "@angular/core";
import * as i1 from "../common/auth/auth-api-http-client";
import * as i2 from "@angular/router";
export class CustomerItemService extends DataItemService {
    constructor(httpClient, route) {
        super({
            httpClient,
            url: 'health/customers/item'
        });
    }
    setHospitalId(id, paging) {
        this.hospitalUserList = this.httpClient.get('auth/useraccounts/customerusers?customerId=' + id + '&size=' + paging.size + '&index=' + paging.index + (paging.orders ? '&orders=' + paging.orders : ''));
    }
    getAddBranchService(organisationId, branchList) {
        this.addBranchService = this.httpClient.post(branchList, 'health/customers/add-branch/' + organisationId);
    }
    getCustomerCount(id) {
        this.customerCount = this.httpClient.get('auth/useraccounts/countcustomerusers/' + id);
    }
    getEditBranchService(id, branchName) {
        this.editBranchService = this.httpClient.put({}, 'health/customers/edit-branch/' + id + '/' + branchName);
    }
    getDeleteBranchService(id) {
        this.deleteBranchService = this.httpClient.delete({}, 'health/customers/delete-branch/' + id);
    }
    getExistBranchControlService(organisationId, branchName) {
        this.existBranchControlService = this.httpClient.get('health/customers/exist-branch/' + organisationId + '/' + branchName);
    }
}
CustomerItemService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CustomerItemService_Factory() { return new CustomerItemService(i0.ɵɵinject(i1.AuthApiHttpClient), i0.ɵɵinject(i2.ActivatedRoute)); }, token: CustomerItemService, providedIn: "root" });
