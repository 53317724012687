import { DataService, DataServiceArgs } from './data.service';
import {DataList, DataApiResult, SearchParam, Paging, Loading, FilterMetaData} from './result';
import { HttpRequestOptions, getOptionsForDataKeyParams } from './api-http-options';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { RecordFilter } from 'src/app/models/recordFilter';

export abstract class DataListService<T> extends DataService<T[], DataApiResult<DataList<T>>> {
  protected apiResultStore: BehaviorSubject<DataApiResult<DataList<T>>>;

  get dataResult() {
    return this.apiResultStore.asObservable().pipe(
      filter(d => d && !!d.data),
      map(d => d.data)
    );
  }

  get filterMetaData() :Observable<FilterMetaData[]>{
    return this.apiResultStore.asObservable().pipe(
      filter(d => d && !!d.data),
      map(d => d.data.filterMetaData)
    );
  }

  constructor(protected args: DataServiceArgs<T[]>) {
    super(args);
    this.apiResultStore = new BehaviorSubject<DataApiResult<DataList<T>>>(null);
  }

  protected setNextData(current: DataApiResult<DataList<T>>) {
    this.apiResultStore.next(current);
    this.dataStore.next(current.data.list);
  }

  get(url?: string, options?: HttpRequestOptions) {
    return this.getData(url, options);
  }

  getSearch(paging?: Paging, params?: SearchParam, url?: string, options?: HttpRequestOptions) {
    const actionUrl = this.pathCombine(this.args.url, url || 'search');
    this.unsubscribe(actionUrl, Loading.getting);
    const newPaging = Object.assign({}, paging) as any;
    newPaging.orders = (newPaging.orders || []).map(o => `${o.type || 'asc'}.${o.column}`);
    const actionOptions = getOptionsForDataKeyParams({ method: 'getSearch', url, options, data: newPaging });
    const needPost = params && (params.columns || params.filters);
    const method = needPost ?
      this.args.httpClient.post<DataApiResult<DataList<T>>, SearchParam>(params, actionUrl, actionOptions) :
      this.args.httpClient.get<DataApiResult<DataList<T>>>(actionUrl, actionOptions);
    this.subscribtion = method.subscribe(
      r => this.onGetDataCompleted(r, actionUrl, options),
      e => this.onGetDataError(e, actionUrl, options)
    );
  }
}
