import { Component, EventEmitter, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ecg-delete-invoice-modal',
  templateUrl: './delete-invoice-modal.component.html',
  styleUrls: ['./delete-invoice-modal.component.scss']
})
export class DeleteInvoiceModalComponent{

  deleteEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(public modal: NgbModal, public activeModal: NgbActiveModal) { }

  confirm() {
    this.deleteEvent.emit();
    this.activeModal.close();
  }
}
