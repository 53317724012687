import {Component, AfterViewChecked, AfterContentChecked} from '@angular/core';
import { Location } from "@angular/common";
import { Recorder } from '../models/recorder';
import { BaseFormComponent } from '../common/components/base-form.component';
import { Router, ActivatedRoute } from '@angular/router';
import { RecorderItemService } from './recorder-item.service';
import { Logger } from '../common/logging/logger.service';
import { ToastService } from '../shared/services/toast.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataApiResult } from '../common/data/result';
import { AuthApiHttpClient } from '../common/auth/auth-api-http-client';
import { RecorderExistResponse } from '../models/recorderExistResponse';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ecg-recorder-form',
  templateUrl: './recorder-form.component.html',
  styleUrls: ['./recorder-form.component.scss']
})
export class RecorderFormComponent extends BaseFormComponent<Recorder> implements AfterContentChecked {

  hospitalList: any = [];
  customerName: any;
  orgNumber: number;

  constructor(
    public httpClient: AuthApiHttpClient,
    public service: RecorderItemService,
    logger: Logger,
    location: Location,
    route: ActivatedRoute,
    router: Router,
    public spinner: NgxSpinnerService,
    toast: ToastService,
    private translate: TranslateService,
    public modal: NgbModal
  ) {
    super({ service, logger, location, route, router, spinner, toast });

    service.hospitalList.subscribe(res => {
      for (const hospital of res) { 
        this.hospitalList.push({ id: hospital.id, name: hospital.customer.name + ' - ' + hospital.code, customerId: hospital.customerId, customerName: hospital.customer.name });

      }
    }
    );
    
    const path = window.location.href;
    if (path.includes('add')) {
      const pathSplit = path.split('=');
      this.orgNumber = parseInt(pathSplit[1]);
    }
  }

  changeOrganisation(id) {
    const index = this.hospitalList.findIndex(hospital => hospital.id === +id);
    this.model.customerId = this.hospitalList[index].customerId;
    this.customerName = this.hospitalList[index].customerName;
  }

  ngAfterContentChecked() {
    if (this.model && this.hospitalList.length > 0 && this.operation == 'add' && this.orgNumber) {
      const index = this.hospitalList.findIndex(hospital => hospital.customerId === this.orgNumber);
      this.model.customerCodeId = this.hospitalList[index].id;
      this.model.customerId = this.orgNumber;
    }
  }

  save() {
    if (this.orgNumber != this.model.customerId) {
      super.save();
    }
    else {
      this.httpClient.get<RecorderExistResponse>('health/recorders/exist-recorder-by-serialNumber/' + this.model.serialNumber).subscribe(res => {
        if (res.isThere) {
          this.toast.showDanger(this.translate.instant('recorderForm.recorderAlreadyExist') + res.organisationName + '!');
        } else {
          super.save();
        }
      });
    }
  }
}
