import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import { StorageMap } from '@ngx-pwa/local-storage';

@Injectable({providedIn: 'root'})
export class RedirectGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, protected storageMap: StorageMap) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {    
    if (this.authService.needLogin) {
      if (route.routeConfig.path === 'login') {
        return true;
      }
      return this.router.parseUrl('/login');
    } else {
      if (this.authService.hasRole(['System Admin', 'Support', 'Support DK','Super User'])) {
        return this.router.parseUrl('/cradles');
      }
      else if (this.authService.hasRole(['Diary User'])) {
        return this.router.parseUrl('/diary-upload');
      }
      else if (this.authService.hasRole(['Staff Fysiologic','Fysiologic Assistant'])) {
        return this.router.parseUrl('/records');
      } 
      else if (this.authService.hasRole(['Finance', 'Financial Admin'])) {
        return this.router.navigate(['/specifications'], { queryParams: {} });
      }
      else {
        this.storageMap.get('step', { type: 'string' }).subscribe((result) => {
          if ((!result || result == null || result != 'recordsOrCradle') && !this.authService.hasRole(['Super User','System Admin'])) {
            this.router.navigate(['/branch'], { queryParams: {} });
          } else {
            if (this.authService.hasRole(['Super User'])) {
              this.router.navigate(['/cradles'], { queryParams: {} });
            } else {
              this.router.navigate(['/records'], { queryParams: {} });
            }
          }
        });
      }
    }
  }
}
