import {BaseListComponent} from '../../common/components/base-list.component';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../common/auth/auth.service';
import {DomSanitizer} from '@angular/platform-browser';
import {ToastService} from '../../shared/services/toast.service';
import {Component, OnInit} from '@angular/core';
import { Filters } from '../../common/data/result';
import { IpAddressKey } from '../../models/ipAddressKey';
import { AccessRequestsService } from './access-requests.service';

@Component({
  selector: 'ecg-access-requests',
  templateUrl: './access-requests.component.html',
  styleUrls: ['./access-requests.component.scss'],
})
export class AccessRequestsComponent extends BaseListComponent<IpAddressKey> implements OnInit {

  accessRequests: [IpAddressKey];
  whiteListStatus: unknown = false;

  constructor(
    public service: AccessRequestsService,
    spinner: NgxSpinnerService,
    public router: Router,
    route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public auth: AuthService,
    protected toast: ToastService
  ) {
    super({
      service,
      spinner,
      router,
      route,
      idColumn: 'id',
      formRoute: 'ipAddressKey'
    });


  }

  allow(ipAddress: any, bool: boolean) {
    const model = {
      IpAddressKey: ipAddress,
      allow: bool
    };
    this.service.setAllowAccess(model);
    this.service.allowAccessSubscribe.subscribe(res => {
      if (res) {
        this.refresh();
      }
    });
  }
}
