import { DataListService } from '../common/data/data-list.service';
import * as i0 from "@angular/core";
import * as i1 from "../common/auth/auth-api-http-client";
import * as i2 from "../common/auth/auth.service";
export class PatientDiaryService extends DataListService {
    constructor(httpClient, auth) {
        super({
            httpClient,
            url: 'health/patientDiary',
            initial: []
        });
        this.authUser = auth.user;
    }
}
PatientDiaryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PatientDiaryService_Factory() { return new PatientDiaryService(i0.ɵɵinject(i1.AuthApiHttpClient), i0.ɵɵinject(i2.AuthService)); }, token: PatientDiaryService, providedIn: "root" });
