import { Location } from '@angular/common';
import { LocalStorageService } from 'angular-2-local-storage';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { debug } from 'console';
import { Device } from './model/device';
import { RequiredService } from './model/requiredService';
import { ServerHealthService } from './server-health.service';
import { ToastService } from '../shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { RedisServerHealthService } from './server-health-redis.service';
import { workerServiceState } from './model/workerServiceState';

@Component({
  selector: 'ecg-server-health-check',
  templateUrl: './server-health-check.component.html',
  styleUrls: ['./server-health-check.component.scss']
})
export class ServerHealthCheckComponent implements OnInit, OnDestroy {
  tab = true;
  appuptime;
  fileuptime;
  serverInfo;
  status;
  toast;
  redisServerInfo;
  redisServerIntegrationInfo;
  appServerDevices = new Array<Device>();
  fileServerDevices = new Array<Device>();
  fileServerDevicesSorted = new Array<Device>();
  workerServiceState=new Array<workerServiceState>();
  integrationWorkerServiceState=new Array<workerServiceState>();

  appServerServices = new Array<RequiredService>();

  constructor(
    private healthService: ServerHealthService,
    private redisHealthService:RedisServerHealthService,
    location: Location,
    private localStorageService: LocalStorageService,
    toast: ToastService,
    private translate: TranslateService
  ) {
    this.toast = toast;
    //this.translate = translate;
    this.healthService = healthService;
    this.redisHealthService=redisHealthService;
  }

  ngOnInit() {
    this.getServerHealth();
    this.status = setInterval(() => {
      this.getServerHealth();
    }, 60000);
  }

  tabSelect(tabname: string) {
    tabname === 'app' ? this.tab = true : this.tab = false;

  }
  ngOnDestroy() {
    if (this.status) {
      clearInterval(this.status);
    }
  }

  getServerHealth() {
    this.healthService.getServerHealth().subscribe(res => {
      this.serverInfo = res;

      this.appServerDevices = this.serverInfo.devices.filter(x => x.serverName == "AppServer");

      const index: number = this.appServerDevices.findIndex(
        d => d.deviceName === "PC"
      );
      if (index > -1) {
        this.appuptime = this.appServerDevices[index];
        this.appServerDevices.splice(index, 1);
      }
      this.fileServerDevices = this.serverInfo.devices.filter(x => x.serverName == "FileServer");

      const index1: number = this.fileServerDevices.findIndex(
        d => d.deviceName === "PC"
      );
      if (index1 > -1) {
        this.fileuptime = this.fileServerDevices[index1];
        this.fileServerDevices.splice(index1, 1);
      }

      this.fileServerDevicesSort();

      this.appServerServices = this.serverInfo.requiredServices;

    }, err => {
      console.log(err);
    });

    //Redis ten WorkerServislerin çalışma durumunu alır.
    this.redisHealthService.getServerHealth("Services").subscribe(res => {
     this.redisServerInfo=res;
     this.workerServiceState=this.redisServerInfo;
    }, err => {

      console.log(err);
    });

    this.redisHealthService.getServerHealth("HL7 Integration Services").subscribe(res => {
      this.redisServerIntegrationInfo=res;
      this.integrationWorkerServiceState=this.redisServerIntegrationInfo;
     }, err => {

       console.log(err);
     });
  }

  fileServerDevicesSort(): void {
    this.fileServerDevicesSorted = [];
    if (this.fileServerDevices.find(x => x.deviceType == "serverHealth.diskUsage"))
      this.fileServerDevicesSorted.push(this.fileServerDevices.find(x => x.deviceType == "serverHealth.diskUsage"));

    if (this.fileServerDevices.find(x => x.deviceType == "serverHealth.totalMemory"))
      this.fileServerDevicesSorted.push(this.fileServerDevices.find(x => x.deviceType == "serverHealth.totalMemory"));

    if (this.fileServerDevices.find(x => x.deviceType == "serverHealth.cpuUsages"))
      this.fileServerDevicesSorted.push(this.fileServerDevices.find(x => x.deviceType == "serverHealth.cpuUsages"));
  }

  getPercent(data) {
    const usedValue = data.totalSize - data.freeSize;
    return (100 * usedValue) / data.totalSize;
  }

  restartService() {
    this.healthService.restartService().subscribe(res => {

        if (res) {
          this.toast.showSuccess(this.translate.instant('serverHealth.serviceRestarted'));
          this.ngOnInit();
        } else {
          this.toast.showDanger(this.translate.instant('serverHealth.serviceCanNotRestarted'));
        }

    }, err => {
      this.toast.showDanger(this.translate.instant('serverHealth.serviceCanNotRestarted'));
      console.log(err);
    });
  }
}
