import * as tslib_1 from "tslib";
import * as i0 from "@angular/core";
import * as i1 from "@angular/service-worker";
export class PwaUpdateService {
    constructor(update) {
        this.update = update;
    }
    checkForUpdate() {
        const interval = setInterval(() => {
            if (this.update.isEnabled) {
                clearInterval(interval);
                return this.updateApp(this.update);
            }
        }, 1000);
    }
    updateApp(update) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            update.available.subscribe(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
                yield update.activateUpdate();
                location.reload();
            }));
            return update.checkForUpdate();
        });
    }
}
PwaUpdateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PwaUpdateService_Factory() { return new PwaUpdateService(i0.ɵɵinject(i1.SwUpdate)); }, token: PwaUpdateService, providedIn: "root" });
