import { Color, Label } from "ng2-charts";
import {
  ColumnType,
  FilterMetaData,
  FilterType,
  Filters,
  OperatorType,
  Paging,
} from "src/app/common/data/result";
import { Component, OnInit } from "@angular/core";
import { NgbDate, NgbDateAdapter } from "@ng-bootstrap/ng-bootstrap";
import { degrees, parseDate } from "pdf-lib";

import { ChartDataSets, Tooltip } from "chart.js";
import { DateRangeService } from "./date-range.service";
import { Report } from "src/app/models/report";
import Swal from "sweetalert2";
import {TranslateService} from '@ngx-translate/core';
import { __core_private_testing_placeholder__ } from "@angular/core/testing";
import moment from "moment";
import * as _ from 'lodash';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "ecg-date-range",
  templateUrl: "./date-range.component.html",
  styleUrls: ["./date-range.component.scss"],
})
export class DateRangeComponent {
  showFilter: boolean = false;
  initialDate = new Date();
  minDate: Date = this.setMinDate();
  maxDate: Date = new Date();
 
  datePickerValue?: any = [this.minDate, this.maxDate];
  selectionFilter: any;
  itemKey = "createdDateTime";

  project: string = "";
  module: string = "";
  modules: Array<string> = [];
  projects: Array<string> = [];
  isSelectProject: Boolean = false;
  filters: Filters;
  emptyFilter: Filters = {};
  
  public lineChartData: ChartDataSets[] = [];
  public lineChartRawData: Map<string, Report[]> = new Map<string, Report[]>();
  public lineChartLabels: Label[] = [];
  public lineChartOptions = {
    responsive: true,
    pointDot: false,
    animationEnabled: true,
    animation: {
      easing: 'linear',
      animateScale: true,
      animateRotate: true,
      duration: 1200
    },
    scales: {
      yAxes: [
        {
          display: true,
          scaleLabel: {
            display: true,
            labelString: "Response Time (second)",
          },
          ticks: {
            suggestedMin: 0,
            suggestedMax: 0.2,
          }
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Date",
          },
        },
      ],
    },
  };
  public lineChartColors: Color[] = [
    {
      borderColor: "black",
      backgroundColor: "#FF6666",
    },
  ];
  public lineChartLegend = true;
  public lineChartPlugins = [];
  public lineChartType = "line";

  constructor(
    private service: DateRangeService,
    private adapter: NgbDateAdapter<Date>,
    protected translateService: TranslateService,
    private router: Router,
    protected route: ActivatedRoute
  ) {
    this.service.projectList.subscribe((_) => {
      _.map((p) => this.projects.push(p.project));
    });
    this.setFilters();
    this.getLineData();
  }
  dateTimeValue: Date;
  filter() {
    this.showFilter = true;
  }
  customFilter() {
    this.setFilters();
    this.getLineData();
  }
  filterReset() {
    this.showFilter = false;
    this.filters = this.emptyFilter;
    (this.project = null),
      (this.module = null),
      (this.datePickerValue = [null, null]);
    this.getLineData();
  }
  getLineData() {

    Swal.fire({
      position: 'center',
      title: this.translateService.instant("report.wait"),
      imageUrl: 'assets/gifs/loading.gif',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    });

    this.service.getLineData({ filters: this.filters }).subscribe(res => {
      this.setLineData(res.data.list);
      Swal.close();
    });
  }

  setFilters() {
    let f: Filters = {};
    
    if (this.module && this.module != "All") {
      const moduleVal = [
        {
          filterType: FilterType.Search,
          normalizer: null,
          columnType: ColumnType.String,
          left: "Module",
          operator: OperatorType.Like,
          right: this.module,
          conjunctionType: 0,
        },
      ];
      f.module = moduleVal;
    }
    if (this.project && this.project != "All") {
      const projectVal = [
        {
          filterType: FilterType.Search,
          normalizer: null,
          columnType: ColumnType.String,
          left: "Project",
          operator: OperatorType.Equals,
          right: this.project,
          conjunctionType: 0,
        },
      ];
      f.project = projectVal;
    }

    if (this.datePickerValue && this.datePickerValue[1]) {
      let fromDate = this.datePickerValue[1];
      var dateFilter = new Date();
      dateFilter.setDate(fromDate.getDate()+1);
      const fromDateVal = {
        filterType: FilterType.DateItem,
        normalizer: null,
        columnType: ColumnType.DateTime,
        left: "createdDateTime",
        right: dateFilter,
        conjunctionType: 1,
      };
      f.createdDateTime = [];
      f.createdDateTime.push(fromDateVal);
    }
    if (
      this.datePickerValue &&
      this.datePickerValue[0]
    ) {
      let toDate = this.datePickerValue[0];
      var dateFilter = new Date();
      dateFilter.setDate(toDate.getDate()+1);

      const toDateVal = {
        filterType: FilterType.DateItem,
        normalizer: null,
        columnType: ColumnType.DateTime,
        left: "createdDateTime",
        right: dateFilter,
        conjunctionType: 1,
      };
      f.createdDateTime.push(toDateVal);
    }
    this.filters = f;    
  }
  setLineData(data: Report[]) {
    this.lineChartData = [];
    this.lineChartLabels = [];
    this.lineChartRawData = new Map<string, Report[]>();
    data.forEach(x=>{
      if (this.lineChartLabels.indexOf(moment(x.createdDateTime.toString(), "YYYY-MM-DD, HH:mm:ss").format("DD-MM-YYYY, HH:mm:ss")) < 0)
      this.lineChartLabels.push(moment(x.createdDateTime.toString(), "YYYY-MM-DD, HH:mm:ss").format("DD-MM-YYYY, HH:mm:ss"));
    });

    var grouped = [];
    var isProjectSearch = false;
    if(this.project == "")
    {
      grouped = _.groupBy(data, 'Project');
      isProjectSearch = true;
    }
    else
      grouped = _.groupBy(data, 'Module');
    
    var dateGrouped = _.groupBy(data, 'createdDateTime');

    for(let date in dateGrouped) {
        var dList = dateGrouped[date];
        for(let module in grouped) {
          var modName = "";
          if(isProjectSearch)
            modName = dList[0].Project 
          else
            modName = dList[0].Module;

           if(modName == module)
            this.addData(module, dList[0]);
           else 
            this.addData(module, undefined);
        }
    }
  
  }

  addData(module, data) {
    var labelList = this.lineChartData.map(x => x.label);
    var ind = labelList.indexOf(module);
    var val = (data === undefined) ? undefined : data.TimeSpentTotalSecond;
    if (ind == -1) {

      this.lineChartData.push({
        data: [val],
        label: module,
        //backgroundColor: this.dynamicColors(module),
        //borderColor : "orange",
        borderWidth:0,
        spanGaps: false,
        // pointRadius : 5,
        // hoverRadius : 5,
        // pointBorderWidth : 5,
        pointStyle : 'circle'
      });
    }
    else {
      this.lineChartData[ind].data.push(val);
    }

    if (this.lineChartRawData.has(module)) {
      this.lineChartRawData.get(module).push(data);
    }
    else {
      this.lineChartRawData.set(module, [data]);
    }
  }

  dynamicColors(module: string) {
    var color = "";
    switch (module) {
      case "PrepCfg":
        color = "#ffce56";
        break;
      case "PrepValueCfg":
        color = "#dc00be";
        break;
      case "RecorderSettings":
        color = "#36c38e";
        break;
      case "CradleStatProcess":
        color = "#09cadd";
        break;
      case "CradleOnline":
        color = "#5cdc09";
        break;
      case "CradleLocation":
        color = "#302a15";
        break;
      case "DeviceHub":
        color = "#d5d6d9";
        break;
      default:
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
        color = "rgb(" + r + "," + g + "," + b + ")";
        break;
    }
    return color;
  };

  setDefaultFilterData() {
    const endDate = new Date();
    const startDate = endDate.getDay() - 1;
    this.datePickerValue = [startDate, endDate];
  }
  projectChange(event) {
    this.module = "";
    this.modules = [];
    this.isSelectProject = false;
    this.project = event;
    this.service
      .getModuleList(event)
      .subscribe((_) => _.map((m) => this.modules.push(m)));
  }
  moduleChange(event) {
    this.module = event;
  }
  setMinDate():Date{
    const date = new Date();
    date.setDate(date.getDate() - 1);
    return date;
  }

  public chartClicked(e: any): void {

    if (e.active.length > 0) {
      var dataIndex = e.active[0]._index;
      var dataSetIndex = e.active[0]._datasetIndex;
      console.log("dataSetIndex:" + dataSetIndex);
      console.log("dataIndex:" + dataIndex);
      var gid = this.lineChartRawData.get(this.lineChartData[dataSetIndex].label)[dataIndex].GId;
      console.log("gid:" + gid);

      const url = this.router.serializeUrl(this.router.createUrlTree(['/report'], {
        queryParams: {
          GId: gid
        }
      }))
      const newTab = window.open(url, '_blank');
      if (newTab) {
        newTab.opener = null;
      }
    }
  }


  public chartHover(e: any): void {
    console.log(e);
  }
}
