<ng-container>
  <nav class="navbar navbar-light bg-light">
    <div class="col-xs-2">
      <ul class="nav navbar px-0">
        <li class="m-1">
          <button (click)="back()" class="btn btn-secondary" i18n type="button">
            <span>{{ "back" | translate }}</span>
          </button>
        </li>
      </ul>
    </div>
    <div class="col-xs-4 d-md-none">
      <ul class="nav navbar-right">
        <li *ngIf="op == 'edit'" class="m-1">
          <button
            (click)="modal.open(confirmDeleteModal)"
            [disabled]="!mainForm.form.valid"
            class="btn btn-danger"
            i18n
            type="button"
          >
            {{ "delete" | translate }}
          </button>
        </li>
        <li class="m-1">
          <button
            (click)="save()"
            [disabled]="!formValid(mainForm.form)"
            class="btn btn-info btnSave"
            i18n
            type="button"
          >
            {{ "save" | translate }}
          </button>
        </li>
      </ul>
    </div>
    <div
      class="col-xs-12 col-md-6 align-items-center d-flex justify-content-center"
    >
      <div class="text-center">
        <h3 i18n>{{ "noteForm.title" | translate }}</h3>
      </div>
    </div>
    <div class="col-xs-4 d-none d-md-flex">
      <ul class="nav navbar-right">
        <li *ngIf="op == 'edit'" class="m-1">
          <button
            (click)="modal.open(confirmDeleteModal)"
            class="btn btn-danger"
            i18n
            type="button"
          >
            {{ "delete" | translate }}
          </button>
        </li>
        <li class="m-1">
          <button
            (click)="save()"
            [disabled]="!formValid(mainForm.form)"
            class="btn btn-info btnSave"
            i18n
            type="button"
          >
            {{ "save" | translate }}
          </button>
        </li>
      </ul>
    </div>
  </nav>

  <form #mainForm="ngForm" class="card border-0">
    <div class="row m-0">
      <div class="col-lg">
        <div class="card m-3">
          <div class="card-body">
            <div class="row justify-content">
              <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12 mb-3">
                <label class="required" for="subject" i18n>{{
                  "noteForm.subject" | translate
                }}</label>
                <input
                  oncut="return false"
                  onpaste="return false"
                  #subject="ngModel"
                  [(ngModel)]="noteModel.subject"
                  class="form-control"
                  id="subject"
                  name="subject"
                  type="text"
                  maxlength="60"
                  required
                />
                <div
                  *ngIf="subject.invalid && (subject.dirty || subject.touched)"
                  class="alert alert-danger"
                >
                  <div *ngIf="subject.errors['required']" i18n>
                    {{ "required.subject" | translate }}
                  </div>
                </div>
              </div>

              <div
                *ngIf="
                  (op == 'edit' && noteModel.customerCodeId != undefined) ||
                  op != 'edit'
                "
                class="col-lg-2 col-md-12 col-sm-12 col-xs-12 mb-3"
              >
                <label class="required" i18n>{{
                  "noteForm.customerCode" | translate
                }}</label>
                <select
                  #organisation="ngModel"
                  (ngModelChange)="changeOrganisation($event)"
                  [(ngModel)]="noteModel.customerCodeId"
                  class="custom-select cursorProp"
                  name="selectedContinent"
                  id="content"
                  required
                >
                  <option
                    *ngFor="let customerCode of hospitalList"
                    [value]="customerCode.id"
                  >
                    {{ customerCode.name }}
                  </option>
                </select>
                <div
                  *ngIf="
                    organisation.invalid &&
                    (organisation.dirty || organisation.touched)
                  "
                  class="alert alert-danger"
                >
                  <div *ngIf="organisation.errors['required']" i18n>
                    {{ "required.organizationCode" | translate }}
                  </div>
                </div>
              </div>

              <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12 mb-3">
                <label i18n>{{ "noteForm.branch" | translate }}</label>
                <select
                  #branch="ngModel"
                  (ngModelChange)="changeBranch($event)"
                  [(ngModel)]="noteModel.customerBranchId"
                  class="custom-select cursorProp"
                  name="customerBranch"
                  id="customerBranch"
                >
                  <option [ngValue]="-1">None</option>
                  <option
                    *ngFor="let branch of branchList"
                    [ngValue]="branch.id"
                    selected
                  >
                    {{ branch.name }}
                  </option>
                </select>
              </div>

              <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12 mb-3">
                <label i18n>{{ "noteForm.user" | translate }}</label>
                <select
                  #user="ngModel"
                  (ngModelChange)="changeUser($event)"
                  [(ngModel)]="noteModel.userAccountId"
                  class="custom-select cursorProp"
                  name="userAcc"
                  id="userAcc"
                >
                  <option [ngValue]="-1">None</option>
                  <option
                    *ngFor="let user of hospitalUserList"
                    [value]="user.id"
                    selected
                  >
                    {{ user.name }}
                  </option>
                </select>
              </div>

              <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12 mb-3">
                <label class="required" i18n>{{
                  "noteForm.type" | translate
                }}</label>

                <label class="col-12 p-0" for="noteModel.type" i18n>
                  <input
                    type="radio"
                    id="noteModel.type"
                    name="type"
                    #type="ngModel"
                    [value]="30"
                    [(ngModel)]="noteModel.type"
                    i18n
                  />
                  {{ "noteForm.order" | translate }}
                </label>

                <label class="col-12 p-0" for="noteModel.type" i18n>
                  <input
                    type="radio"
                    id="noteModel.type"
                    name="type"
                    #type="ngModel"
                    [value]="10"
                    [(ngModel)]="noteModel.type"
                    i18n
                  />
                  {{ "noteForm.complaint" | translate }}
                </label>

                <label class="col-12 p-0" for="noteModel.type" i18n>
                  <input
                    type="radio"
                    id="noteModel.type"
                    name="type"
                    #type="ngModel"
                    [value]="20"
                    [(ngModel)]="noteModel.type"
                    i18n
                  />
                  {{ "noteForm.noteCustomerVisit" | translate }}
                </label>

                <div
                  *ngIf="type.invalid && (type.dirty || type.touched)"
                  class="alert alert-danger"
                >
                  <div *ngIf="type.errors['required']" i18n>
                    {{ "required.type" | translate }}
                  </div>
                </div>
              </div>

              <div class="col-12 mt-10">
                <label class="col-12 p-0" for="noteText" i18n>
                  {{ "noteForm.note" | translate }}
                </label>
                <ckeditor
                  [(ngModel)]="noteModel.noteText"
                  [ngModelOptions]="{ standalone: true }"
                  [editor]="Editor"
                  [config]="config"
                ></ckeditor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>

<ng-template #confirmDeleteModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title" i18n>
      {{ "noteForm.deletion" | translate }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close button"
      aria-describedby="modal-title"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      <strong i18n>{{ "noteForm.deleteNote" | translate }}</strong>
    </p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-danger"
      (click)="delete(); modal.dismiss('deleted')"
      i18n
    >
      {{ "profileForm.ok" | translate }}
    </button>
    <button
      type="button"
      ngbAutofocus
      class="btn btn-outline-secondary"
      (click)="modal.dismiss('cancel click')"
      i18n
    >
      {{ "cancel" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #savedSuccessMessage>
  <span i18n>{{ "ipAddressForm.success" | translate }}</span>
</ng-template>
<ng-template #savedErrorMessage>
  <span i18n>{{ "ipAddressForm.notSuccess" | translate }}</span>
</ng-template>
<ng-template #deletedSuccessMessage>
  <span i18n>{{ "ipAddressForm.deleted" | translate }}</span>
</ng-template>
<ng-template #deletedErrorMessage>
  <span i18n>{{ "ipAddressForm.notDeleted" | translate }}</span>
</ng-template>
