import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject, Subscription} from "rxjs";
import {debounceTime, throttleTime} from "rxjs/operators";

@Component({
  selector: 'ecg-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  host: {
    "(click)": "click($event)",
    "[class.disabled]": "disabled"
  }
})
export class ButtonComponent {
  @Output()
  onClick = new EventEmitter();
  @Input()
  disabled: boolean;
  private debounceTime = 1500;

  constructor() {
  }

  click($event) {
    if (this.disabled)
      return;

    this.onClick.emit($event);
    let state = this.disabled;
    this.disabled = true;
    setTimeout(() => this.disabled = state, this.debounceTime)
  }

}
