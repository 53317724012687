import { Injectable } from '@angular/core';
import { AuthApiHttpClient } from 'src/app/common/auth/auth-api-http-client';

@Injectable({
  providedIn: 'root'
})
export class ChangeVersionService {

  constructor(public httpClient: AuthApiHttpClient) { }

  setVersion() {
    return this.httpClient.get('ChangeLogForRedisApi/set-platform-version');
  }

  getVersion() {
    return this.httpClient.get('ChangeLogForRedisApi/get-platform-version');
  }
}
