import {Injectable} from '@angular/core';
import {Customer} from '../models';
import {AuthApiHttpClient} from '../common/auth/auth-api-http-client';
import {DataItemService} from '../common/data/data-item.service';
import {ActivatedRoute} from '@angular/router';
import {Paging} from '../common/data/result';
import {RecorderExistResponse} from '../models/recorderExistResponse';

@Injectable({providedIn: 'root'})
export class CustomerItemService extends DataItemService<Customer> {
  hospitalUserList: any;
  customerCount: any;
  addBranchService: any;
  editBranchService: any;
  deleteBranchService: any;
  existBranchControlService: any;

  constructor(httpClient: AuthApiHttpClient, route: ActivatedRoute) {
    super({
      httpClient,
      url: 'health/customers/item'
    });
  }

  setHospitalId(id, paging: Paging) {
    this.hospitalUserList = this.httpClient.get<Array<string>>('auth/useraccounts/customerusers?customerId=' + id + '&size=' + paging.size + '&index=' + paging.index + (paging.orders ? '&orders=' + paging.orders : ''));
  }

  getAddBranchService(organisationId, branchList) {
    this.addBranchService = this.httpClient.post(branchList, 'health/customers/add-branch/' + organisationId);
  }

  getCustomerCount(id) {
    this.customerCount = this.httpClient.get<number>('auth/useraccounts/countcustomerusers/' + id);
  }

  getEditBranchService(id, branchName) {
    this.editBranchService = this.httpClient.put({}, 'health/customers/edit-branch/' + id + '/' + branchName);
  }

  getDeleteBranchService(id) {
    this.deleteBranchService = this.httpClient.delete({}, 'health/customers/delete-branch/' + id);
  }

  getExistBranchControlService(organisationId, branchName) {
    this.existBranchControlService = this.httpClient.get<number>('health/customers/exist-branch/' + organisationId + '/' + branchName);
  }
}
