import * as tslib_1 from "tslib";
import { AccessTokenKeys } from './security/token';
import { BehaviorSubject, EMPTY, Observable, Subscription, from, of } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { catchError, finalize, first, map, share, switchMap, tap } from 'rxjs/operators';
import { JwtHelper } from './security/jwt-helper';
import appConfig from '../../../environments/app.config.json';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngx-pwa/local-storage";
import * as i3 from "../logging/logger.service";
export class AuthService {
    constructor(http, storageMap, logger) {
        this.http = http;
        this.storageMap = storageMap;
        this.logger = logger;
        this.tokenKey = 'token';
        this.httpHeaders = new HttpHeaders();
        this.showNavMenu = false;
        this.api = appConfig.apiLocations;
        this.loginUrl = `${this.api.auth}/token/login`;
        this.logoutUrl = `${this.api.auth}/token/logout`;
        this.changeForgetPasswordUrl = `${this.api.auth}/useraccounts/craeteemailcode`;
        this.validateEmailCode = `${this.api.auth}/token/validateemailcode`;
        this.updatePasswordUrl = `${this.api.auth}/token/createpassword`;
        this.validateUrl = `${this.api.auth}/token/`;
        this.jwtHelper = new JwtHelper();
        this.token = new BehaviorSubject(null);
        this.token.subscribe(event => {
            if (event !== null && event) {
                this.time = event.expiresIn;
            }
        });
        this.refreshTokenModel = { grantType: 'refreshtoken', mac: '' };
    }
    get jwtToken() {
        return this.internalJwtToken;
    }
    set jwtToken(value) {
        this.internalJwtToken = value;
        if (value) {
            const aToken = value[AccessTokenKeys.role];
            const roleToken = typeof (aToken) === 'string' ? [aToken] : aToken;
            const roles = roleToken && roleToken.length ?
                roleToken.map(name => {
                    return { name };
                }) :
                [];
            this.internalUser = {
                id: value[AccessTokenKeys.serialnumber],
                name: value[AccessTokenKeys.givenname],
                customerId: value[AccessTokenKeys.groupsid],
                customerCodeId: value[AccessTokenKeys.primarysid],
                nameIdentifier: value[AccessTokenKeys.nameidentifier],
                username: value.sub,
                authenticatorAsked: value.authenticatorAsked != null ? value.authenticatorAsked : 'false',
                eMail: null,
                phone: null,
                roles
            };
        }
        else {
            this.internalUser = null;
        }
    }
    get user() {
        return this.internalUser;
    }
    init() {
        return this.getCachedToken().pipe(catchError(e => {
            this.logger.error(e);
            return of(null);
        }), map(t => {
            this.saveToken(t);
            return t;
        })).toPromise();
    }
    // TODO UM: shall check refresh expire duration
    get needLogin() {
        const value = this.token.getValue();
        let url = window.location.pathname;
        if (value == null && (url != '/login' && url != '/' && url != '/forgot' && url != '/createpassword' && url != '' && url != '/changepassword')) {
            window.location.href = '/login';
        }
        if (value != null && value.refreshToken != null) {
            return false;
        }
        return true;
    }
    controlRole(name) {
        const index = this.rolesArray.findIndex(role => role === name);
        if (index > -1) {
            return true;
        }
        else {
            return false;
        }
    }
    get needRefresh() {
        if (this.jwtToken != null) {
            return this.jwtHelper.isTokenExpired(this.jwtToken, 60);
        }
        return true;
    }
    hasRole(roles) {
        if (!this.needLogin && this.user && this.user.roles && this.user.roles.length) {
            this.rolesArray = roles;
            return (roles && roles.length) ?
                this.user.roles.some((v, i, a) => this.controlRole(v.name)) :
                true;
        }
        return false;
    }
    login(model) {
        this.setToken(null);
        return this.http.post(this.loginUrl, model).pipe(map(r => {
            this.saveToken(r.data);
            return r.data;
        }));
    }
    authValidation(model, type) {
        this.setToken(null);
        return this.http.post(this.validateUrl + (type == 1 ? 'validate-google-authenticator' : 'validate-email-authenticator'), model).pipe(map(r => {
            this.saveToken(r.data);
            return r.data;
        }));
    }
    restorePassword(model) {
        return this.http.post(this.changeForgetPasswordUrl, model);
    }
    forgotPassword(data, type) {
        return this.http.post(this.changeForgetPasswordUrl + '?email=' + data.username + '&type=' + type, {});
    }
    controlPassword(code) {
        if (code) {
            return this.http.post(this.validateEmailCode + '/' + code, {});
        }
        else {
            window.location.href = "/login";
        }
    }
    updatePassword(model, code, type) {
        return this.http.post(this.updatePasswordUrl + '/' + code + '?type=' + type, model);
    }
    setCreatePassword(emailcode, model, type) {
        return this.http.post('/api/v1/auth/token/createpassword/' + emailcode + type, model);
    }
    getCreatePassword(code) {
        return this.http.get('api/v1/auth/useraccounts/email-reset-code/' + code);
    }
    logout() {
        const forRefresh = this.needRefresh ? from(this.internalRefresh()) : this.token;
        return forRefresh.pipe(switchMap((accessToken) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.performLogout(accessToken);
        })), first(), finalize(() => {
            this.saveToken(null);
            window.location.reload();
        }));
    }
    performLogout(token) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const data = yield this.internalLogout(token);
            if (data === null) {
                window.location.reload();
                return;
            }
            return data;
        });
    }
    getAccessToken() {
        if (this.needLogin) {
            console.warn('Need login to refresh token!');
            return EMPTY;
        }
        return this.refreshToken().pipe(first());
    }
    internalLogout(at) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log("internalLogout");
            let headers = this.httpHeaders;
            if (at && at.accessToken) {
                headers = this.httpHeaders.set('Authorization', `Bearer ${at.accessToken}`);
            }
            const promiseData = this.http.post(this.logoutUrl, null, { headers }).toPromise();
            promiseData.then()
                .catch((error) => console.log(error));
            return yield promiseData;
        });
    }
    refreshToken() {
        if (!this.needRefresh) {
            return this.token.asObservable().pipe(map(t => t ? t.accessToken : null));
        }
        return (this.refreshObservable || this.internalRefresh()).pipe(map(t => t ? t.accessToken : null));
    }
    forceRefresh() {
        return this.internalRefresh();
    }
    internalRefresh() {
        console.log("internalRefresh");
        this.refreshTokenModel.refreshToken = this.token.getValue().refreshToken;
        this.refreshObservable = this.http.post(this.loginUrl, this.refreshTokenModel).pipe(map(r => r.data), catchError(e => {
            this.logger.error(e);
            return of(null);
        }), tap(t => {
            this.saveToken(t);
            this.refreshObservable = null;
        }), share());
        return this.refreshObservable;
    }
    getCachedToken() {
        return this.storageMap.get(this.tokenKey).pipe(map(d => {
            return d;
        }));
    }
    cacheToken(token) {
        if (this.cacheTokenSubscription) {
            this.cacheTokenSubscription.unsubscribe();
        }
        this.cacheTokenSubscription = (token ?
            this.storageMap.set(this.tokenKey, token) :
            this.storageMap.delete(this.tokenKey)).pipe(first()).subscribe(d => {
        }, e => this.logger.error(e));
    }
    setToken(token) {
        this.jwtToken = null;
        if (token && token.accessToken) {
            this.jwtToken = this.jwtHelper.decodeToken(token.accessToken);
            this.token.next(token);
        }
        else {
            this.token.next(null);
        }
    }
    saveToken(token) {
        this.setToken(token);
        this.cacheToken(token);
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.StorageMap), i0.ɵɵinject(i3.Logger)); }, token: AuthService, providedIn: "root" });
