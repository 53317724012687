<nav class="navbar sticky-top navbar-expand-lg navbar-dark" style="background-color: #3AA8E0;">
  <a class="navbar-brand" [style.pointer-events]="controlUrlLogo() ? 'auto' : 'none'"  [routerLink]="[ '/' ]">
    <ecg-logo width="100px"></ecg-logo>
  </a>
  <button *ngIf="!needlogin()" class="navbar-toggler" type="button" data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
          (click)="toggleCollapse()">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngbCollapse]="isCollapsed">
    <!--    <ng-container *ngIf="!auth.needLogin; else notLogin">-->
    <ng-container *ngIf="!needlogin()">
      <ul class="navbar-nav mr-auto">
        <li *ngIf="controlUrl() && !auth.hasRole(['Finance', 'Financial Admin'])" class="nav-item active my-1">
          <a class="nav-link color-me" routerLink="/records" routerLinkActive="active" style="cursor:pointer"
             i18n>{{ 'navBar.records' | translate }}</a>
        </li>
        <li *ngIf="auth.hasRole(['System Admin', 'Super User', 'Assistant']) && controlUrl()" class="nav-item active my-1">
          <a class="nav-link color-me" routerLink="/worklist" routerLinkActive="active" style="cursor:pointer"
             i18n>{{ 'navBar.worklist' | translate }}</a>
        </li>
        <li *ngIf="auth.hasRole(['System Admin', 'Super User', 'Support', 'Support DK']) && controlUrl()" class="nav-item active my-1">
          <div ngbDropdown class="my-lg-0 d-inline-block responsive-margin">
            <a class="nav-link color-me" routerLinkActive="active" ngbDropdownToggle style="cursor:pointer"
               i18n>{{ 'navBar.organisationManagement' | translate }}</a>
            <div class="w-100" ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <a ngbDropdownItem [routerLink]="[ '/customers' ]" i18n>{{ 'navBar.organisations' | translate }}</a>
              <a ngbDropdownItem [routerLink]="[ '/sysadmin/user-accounts' ]" i18n>{{'navBar.user' | translate }}</a>
              <!--              <a ngbDropdownItem [routerLink]="[ '/sysadmin/email-settings' ]" i18n>{{'navBar.email-settings' | translate }}</a>-->
            </div>
          </div>
        </li>
        <li *ngIf="auth.hasRole(['System Admin', 'Super User', 'Support', 'Support DK']) && controlUrl()" class="nav-item active my-1">
          <div ngbDropdown class="my-lg-0 d-inline-block">
            <a class="nav-link color-me" routerLinkActive="active" ngbDropdownToggle style="cursor:pointer"
               i18n>{{'navBar.hardwareManegement' | translate }}</a>
            <div class="w-100" ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <a ngbDropdownItem [routerLink]="[ '/cradles' ]" style="cursor:pointer"
                 i18n>{{'navBar.cradles' | translate }}</a>
              <a ngbDropdownItem [routerLink]="[ '/serverCheck' ]" style="cursor:pointer"
                 i18n>{{'navBar.serverHealthPage' | translate }}</a>
              <a ngbDropdownItem [routerLink]="[ '/ip-whitelist' ]" style="cursor:pointer"
                 i18n>{{'navBar.ipWhiteList' | translate }}</a>
              <!-- <a ngbDropdownItem [routerLink]="[ '/cradle-map' ]" style="cursor:pointer"
           i18n>{{'navBar.cradleMaps' | translate }}</a> -->
            </div>
          </div>
        </li>
        <li *ngIf="(auth.hasRole(['Super User']) || auth.hasRole(['Staff Fysiologic'])) && controlUrl()" class="nav-item active4 my-1">
          <a class="nav-link color-me" routerLink="/dashboard" routerLinkActive="active" style="cursor:pointer"
             i18n>{{ 'navBar.dashboard' | translate }}</a>
        </li>
        <li *ngIf="auth.hasRole(['Super User']) && controlUrl()" class="nav-item active4 my-1">
          <a class="nav-link color-me" routerLink="/notification" routerLinkActive="active" style="cursor:pointer"
             i18n>{{ 'navBar.notification' | translate }}</a>
        </li>
        <li *ngIf="auth.hasRole(['Diary User','Assistant']) && controlUrl()"
            class="nav-item active4 my-1">
          <a class="nav-link color-me" routerLink="/diary-upload" routerLinkActive="active" style="cursor:pointer"
             i18n>{{ 'navBar.diaryUpload' | translate }}</a>
        </li>
        <li *ngIf="organisationSettings && auth.hasRole(['Assistant','Fysiologic Assistant']) && controlUrl() && organisationSettings.canUseHolterAtHome"
            class="nav-item active4 my-1">
          <a class="nav-link color-me" routerLink="/records/add" [queryParams]="{mode: 'hah'}" routerLinkActive="active" style="cursor:pointer"
             i18n>{{ 'navBar.holterAtHome' | translate }}</a>
        </li>
        <li *ngIf="auth.hasRole(['System Admin', 'Super User', 'Staff Fysiologic']) && controlUrl()"
            class="nav-item active4 my-1">
          <a class="nav-link color-me" routerLink="/notes" routerLinkActive="active" style="cursor:pointer"
             i18n>{{ 'navBar.notes' | translate }}</a>
        </li>
        <!-- <li *ngIf="auth.hasRole(['System Admin']) && controlUrl()"
            class="nav-item active4 my-1">
          <a class="nav-link color-me" routerLink="/settings" routerLinkActive="active" style="cursor:pointer"
             i18n>{{ 'navBar.settings' | translate }}</a>
        </li> -->
        <li *ngIf="auth.hasRole(['System Admin', 'Super User', 'Staff Fysiologic']) && controlUrl()" class="nav-item active my-1">
          <div ngbDropdown class="my-lg-0 d-inline-block">
            <a class="nav-link color-me" routerLinkActive="active" ngbDropdownToggle style="cursor:pointer"
               i18n>{{'navBar.report' | translate }}</a>
            <div class="w-200" ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <a ngbDropdownItem [routerLink]="[ '/auth-logs' ]" style="cursor:pointer"
                 i18n>{{'navBar.authLogs' | translate }}</a>
              <a ngbDropdownItem [routerLink]="[ '/report' ]" style="cursor:pointer"
                 i18n>{{'navBar.logInformation' | translate }}</a>
              <a ngbDropdownItem [routerLink]="[ '/error-log-report' ]" style="cursor:pointer"
                 i18n>{{'navBar.errorLogInformation' | translate }}</a>
              <a ngbDropdownItem [routerLink]="[ '/platform-performance' ]" style="cursor:pointer"
                 i18n>{{'navBar.systemPerformance' | translate }}</a>
              <a ngbDropdownItem [routerLink]="[ '/log-history' ]" style="cursor:pointer"
                 i18n>{{'profileForm.logHistory' | translate }}</a>
            </div>
          </div>
        </li>
        <li *ngIf="auth.hasRole(['System Admin', 'Super User', 'Finance', 'Financial Admin']) && controlUrl()"
            class="nav-item active4 my-1">
          <a class="nav-link color-me" routerLink="/specifications" routerLinkActive="active" style="cursor:pointer"
             i18n>{{ 'navBar.invoice' | translate }}</a>
        </li>
        <li *ngIf="auth.hasRole(['System Admin', 'Super User', 'Financial Admin']) && controlUrl()"
            class="nav-item active4 my-1">
          <a class="nav-link color-me" routerLink="/upload-specifications" routerLinkActive="active" style="cursor:pointer"
             i18n>{{ 'navBar.uploadInvoice' | translate }}</a>
        </li>
      </ul>
      <div ngbDropdown class="mr-md-4 my-lg-0 d-inline-block my-1">
        <a class="nav-link color-me p-0 my-2" id="dropdownBasic1" ngbDropdownToggle style="cursor:pointer"
           i18n>{{auth.user.name}}</a>
        <div class="w-100" ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <a *ngIf="controlUrl()" ngbDropdownItem i18n [routerLink]="[ '/users/profile' ]"
             (click)="toggleCollapse(true)">{{ 'navBar.profile' | translate }}</a>
             <a *ngIf="auth.hasRole(['System Admin', 'Super User']) && controlUrl()" ngbDropdownItem i18n [routerLink]="[ '/about' ]"
             (click)="toggleCollapse(true)">{{ 'navBar.about' | translate }}</a>
          <a *ngIf="controlUrl()" ngbDropdownItem i18n [routerLink]="[ '/manual' ]"
             (click)="toggleCollapse(true)">{{ 'navBar.manual' | translate }}</a>
         
          <button ngbDropdownItem (click)="toggleCollapse(true); logout()"
                  i18n>
            {{ 'navBar.logOut' | translate }}
          </button>
        </div>
      </div>
    </ng-container>
    <ng-template *ngIf="!needlogin()" #notLogin>
      <ul class="navbar-nav mr-auto"></ul>
      <ul class="navbar-nav">
        <li class="nav-item active">
          <a class="nav-link btn btn-outline-primary" [routerLink]="[ '/login' ]" routerLinkActive="active"
             (click)="toggleCollapse(true)" i18n>{{ 'navBar.login' | translate }}</a>
        </li>
      </ul>
    </ng-template>
  </div>
</nav>
