import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LogoutModalComponent } from '../components/logout-modal/logout-modal.component';
import { AuthLogoutService } from '../../common/auth/auth-logout.service';


@Injectable({ providedIn: 'root' })
export class LogoutService {

  constructor(
    protected storageMap: StorageMap, public modal: NgbModal, private authLogoutService: AuthLogoutService) {
  }

  async logoutUser(inheritingUserName, operation) {
    const modalRef = this.modal.open(LogoutModalComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true
    });
    modalRef.componentInstance.inheritingUserName = inheritingUserName;
    modalRef.componentInstance.operation = operation;
    await this.delay(1000);
    this.authLogoutService.logout();
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}


