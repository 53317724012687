import { DataListService } from '../common/data/data-list.service';
import * as i0 from "@angular/core";
import * as i1 from "../common/auth/auth-api-http-client";
export class AuthLogsService extends DataListService {
    constructor(httpClient) {
        super({
            httpClient,
            url: 'log/log/auth-logs',
            initial: []
        });
    }
}
AuthLogsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthLogsService_Factory() { return new AuthLogsService(i0.ɵɵinject(i1.AuthApiHttpClient)); }, token: AuthLogsService, providedIn: "root" });
