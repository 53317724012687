<ng-container>
  <nav class="navbar navbar-light bg-light">
    <div class="col-xs-2">
      <ul class="nav navbar px-0">
        <li class="m-1">
          <button type="button" class="btn btn-secondary" (click)="back()" i18n>{{'back' | translate }}</button>
        </li>
      </ul>
    </div> 
    <div class="d-none d-md-block col-md-6">
      <div class="text-center">
        <h3 i18n>{{'notification.title' | translate }}</h3>
      </div>
    </div>
    <div class="col-xs-4 creadle-header-menu d-md-flex">
      <ul class="nav navbar-right">
        <button type="button" class="btn btn-info" [disabled]="!editorValid" (click)="save()"
                i18n>{{'notification.sendButton' | translate }}
        </button>
      </ul>
    </div>
  </nav>
  <form class="card border-0" #mainForm="ngForm" [ecgMustMatch]="['password', 'confirmPassword']">
    <div class="row m-0">
      <div class="col-lg" style="flex-direction: column; display: flex">
        <ckeditor [config]="config"  (change)="onChange($event)" [editor]="Editor" data="<table border='0' cellpadding='0' cellspacing='0' class='body' style='border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%; background-color: #f6f6f6;'> <tr> <td style='font-family: sans-serif; font-size: 14px; vertical-align: top;'>&nbsp;</td> <td class='container' style='font-family: sans-serif; font-size: 14px; vertical-align: top; display: block; Margin: 0 auto; max-width: 580px; padding: 10px; width: 580px;'> <div class='content' style='box-sizing: border-box; display: block; Margin: 0 auto; max-width: 580px; padding: 10px;'> <table class='main' style='border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%; background: #ffffff; border-radius: 3px;'> <tr> <td class='wrapper' style='font-family: sans-serif; font-size: 14px; vertical-align: top; box-sizing: border-box; padding: 20px;'> <table border='0' cellpadding='0' cellspacing='0' style='border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;'> <div class='login-logo-area' style='display: flex; justify-content: center'> <img class='logo-image' src='https://ecgaccessv2.fysiologic.nl/assets/images/mail-logo.png' style='width: auto;height: 150px;' i18n-alt alt='Login Logo' /> </div> <tr> <td style='font-family: sans-serif; font-size: 14px; vertical-align: top;'> <div> <p style='font-family: sans-serif; font-weight: bold !important; font-size: 17px; font-weight: normal; Margin-bottom: 15px; text-align: center;'> Beste gebruiker </p> </div> </td> </tr> </table> </td> </tr> </table> </div> </td> <td style='font-family: sans-serif; font-size: 14px; vertical-align: top;'>&nbsp;</td> </tr> </table>"></ckeditor>
      </div>
    </div>
  </form>
</ng-container>
