import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { apiLocations } from '../../../environments/app.config.json';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../auth/auth.service";
export class SignalRService {
    constructor(authService) {
        this.authService = authService;
    }
    connect(url) {
        const response = this.authService.getAccessToken().toPromise().then(token => {
            const uri = `${apiLocations.hub}${url}`;
            return new HubConnectionBuilder()
                .withUrl(uri, {
                accessTokenFactory: () => token,
                logger: environment.production ? LogLevel.Critical : LogLevel.Trace,
            })
                .withAutomaticReconnect()
                .build();
        });
        return response;
    }
}
SignalRService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SignalRService_Factory() { return new SignalRService(i0.ɵɵinject(i1.AuthService)); }, token: SignalRService, providedIn: "root" });
