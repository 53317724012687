import { Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import Swal from 'sweetalert2';
import { AuthService } from '../common/auth/auth.service';
import { ToastService } from '../shared/services/toast.service';
import { ManualService } from './manual.service';

import { LocalStorageService } from 'angular-2-local-storage';
import { CradleItemService } from '../cradles/cradle-item.service';
import { Cradle } from "../models";
import { LogoutService } from "../shared/services/logout-service";


class ImageSnippet {
  pending = false;
  status = 'init';

  constructor(public src: string, public file: File) {
  }
}

@Component({
  selector: 'ecg-manual',
  templateUrl: './manual.component.html',
  styleUrls: ['./manual.component.scss']
})
export class ManualComponent implements OnInit {

  selectedFile: ImageSnippet;
  files: File[] = [];
  pdfFile: any;
  manualFiles: string[];
  selectedManualFile: string = null;

  constructor(
    private authService: AuthService,
    private manualService: ManualService,
    private toastService: ToastService,
    private _domSanitizer: DomSanitizer,
    public localStorageService: LocalStorageService,
    private cradleItemService: CradleItemService,
    private logoutService: LogoutService,
    private sanitizer: DomSanitizer
  ) { 

  }

  ngOnInit() {

    this.cradleItemService.getCradleByLinkedMac(this.localStorageService.get("mac"),this.localStorageService.get("cradleSerialNumber"))
    .subscribe((cradle: Cradle) => {
     
      if (cradle && cradle.linkedPC !== this.localStorageService.get("mac")) {
        //Farklı browserdan cradle alırsa atsın
        if (cradle && this.localStorageService.get("cradleSerialNumber") !== null && this.localStorageService.get("cradleSerialNumber") !== "skip") 
        {
          console.log("logoutUser-manuel");
          this.logoutService.logoutUser(cradle.connectedUser, "takeOver");
        }
      }
    });

    this.loadFiles();
  }

  loadFiles() {
    this.manualService.files().subscribe(response => {
      this.manualFiles = response.data;
      console.log(this.manualFiles);
      this.selectedManualFile = response.data;
      console.log(this.selectedManualFile);
    }, err => {
      Swal.close();
      this.toastService.showDanger(err.error.message);
    });
  }

  showLoading() {
    Swal.fire({
      position: 'center',
      imageUrl: 'assets/gifs/loading.gif',
      showConfirmButton: true,
      allowOutsideClick: true,
      allowEscapeKey: true,
      timer: 44000,
    });
  }

  uploadFile() {
    this.showLoading();
    this.manualService.uploadPdf(this.selectedFile.src, this.selectedFile.file.name)
      .subscribe(res => {
        if (res.data) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Successful.',
            showConfirmButton: true,
            timer: 3000,
            onClose: () => {
            }
          });
          this.clearData();
          this.loadFiles();
        }
      },
        err => {
          Swal.close();
          console.warn(err);

          this.toastService.showDanger(err.error.message);
        });
  }

  onSelect(event: { addedFiles: any[]; }) {
    if (this.files.length != 0) {
      this.files = [];
    }
    this.files.push(...event.addedFiles);
    this.processFile(event.addedFiles[0]);
  }

  onRemove(event) {
    this.clearData();
  }
  clearData() {
    this.files = [];
    this.pdfFile = undefined;
    this.selectedFile = undefined;
  }

  processFile(file:any) {
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.selectedFile = new ImageSnippet(event.target.result, file);
      this.selectedFile.pending = true;
      this.pdfFile = this._domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file));
    });
    reader.readAsDataURL(file);
  }

  deleteFile(file: any) {
    this.showLoading();
    this.manualService.delete(file).subscribe(response => {
      this.manualFiles = response.data;
      this.selectedManualFile = response.data;
      this.loadFiles();
      Swal.close();
    }, err => {
      Swal.close();
      this.toastService.showDanger(err.error.message);
    });
  }

  viewFile(file: string):string {
    
    this.pdfFile = this._domSanitizer.bypassSecurityTrustResourceUrl(file);
    return this.pdfFile;
  }

  sanitizeUrl(file: string) :string{
    return this._domSanitizer.sanitize(SecurityContext.RESOURCE_URL, this._domSanitizer.bypassSecurityTrustResourceUrl(file));
  }

  isAdmin() {
    return (this.authService.hasRole(['System Admin']) || this.authService.hasRole(['Super User']));
  }
}
