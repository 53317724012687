import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService } from 'angular-2-local-storage';
import { AuthService } from 'src/app/common/auth/auth.service';
import { BranchChooseService } from './branch-choose.service';
import { Version } from '../../../models/version';
import { getVersion } from 'src/app/common/utilities/getVersion.utility';
import { HttpClient } from '@angular/common/http';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SignalRService } from '../../../common/signalr/signal-r.service';
import { HubConnection } from '@microsoft/signalr';
import { LogoutService } from '../../../shared/services/logout-service';

@Component({
  selector: 'ecg-branch-choose',
  templateUrl: './branch-choose.component.html',
  styleUrls: ['./branch-choose.component.scss'],
})
export class BranchChooseComponent implements OnInit {
  version: Version;
  message: string;
  messageDate: Date;
  showMessage: boolean = false;
  linkedCradle: string;
  cradleSerialNumber: string;
  branchId = 0;
  branchList: any;
  cradleList: any;
  nextButtonActive: boolean = false;
  showBackButton: boolean = true;
  takeOverCradle: boolean = false;
  connectedUserName: "";
  previousUser: "";
  inheritingUser: "";
  step: 'recordsOrCradle' | 'cradleChoose' | 'branch' = 'recordsOrCradle';
  private hubConnection: HubConnection;
  year: number;

  constructor(
    public service: BranchChooseService,
    public localStorageService: LocalStorageService,
    public auth: AuthService,
    public router: Router,
    protected storageMap: StorageMap,
    http: HttpClient,
    public modal: NgbModal,
    private translate: TranslateService,
    private logoutService: LogoutService,
    protected signalRService: SignalRService
  ) {
    getVersion(http).subscribe(res => this.version = res);
    this.year = new Date().getFullYear();
  }

  ngOnInit() {

    let stepStorage = '';
    this.storageMap.get('step', { type: 'string' }).subscribe((result) => {
      stepStorage = result;
      if (!stepStorage || stepStorage == null || stepStorage != 'recordsOrCradle') {
        this.getBranchList();
      } else {
        this.router.navigate(['/']);
      }
    });
    this.createConnection().catch(() => undefined);
  
  }

  protected async createConnection() {
    this.hubConnection = await this.signalRService.connect('/loghub');
    await this.hubConnection.start();

    this.hubConnection.on('DisconnectedUser', (mac, inheritingUserName, operation) => {
      if (this.localStorageService.get('mac') === mac) {
        this.logoutService.logoutUser(inheritingUserName, operation);
      }
    });

    // this.hubConnection.on('DisconnectedUserByUUId', (uuid) => {
    //   if(this.localStorageService.get("user-uuid")== uuid)
    //   {
    //     this.logoutService.logoutUser("", "disconnected");
    //   }
    // });
  }

  ngOnDestroy() {
    if (this.hubConnection) {
      this.hubConnection.stop().catch(() => undefined);
    }
  }
  
  getBranchList() {
    this.service.getBranchListService();
    this.service.branchListService.subscribe(res => {
      this.branchList = res;
      if (this.branchList && this.branchList.length > 0) {
        this.service.getBranchIdService();
        this.service.branchIdService.subscribe(response => {
          if (response) {
            this.branchId = response;
          }
        });
        if (this.branchList.length == 1) {
          this.branchId = this.branchList[0].id;
          this.nextCradleChoose(false);
        }
        else {
          this.setStorageMapStep('branch');
        }
      }
      else {
        this.service.getGetCradlesByCustomer(this.auth.user.customerId);
        this.service.getCradleByCustomer.subscribe(response => {
          if (response && response.length > 0) {
            if (response.length == 1) {
              this.branchId = 0;
              this.cradleList = response;
              this.cradleSerialNumber = response[0].cradleSerialNumber;
              this.showBackButton = false;

              if(this.auth.hasRole(['Super User', 'System Admin']))
                this.usedCradleControl(false);
              else 
                this.usedCradleControl(true);

            } else {
              this.cradleList = response;
              this.setStorageMapStep('cradleChoose');
              this.showBackButton = false;
            }
          } else {
            this.setStorageMapStep('recordsOrCradle');
            this.router.navigate(['/']);
          }
        });
      }
    });
  }

  changeBranch(id) {
    this.showMessage = false;
    const index = this.branchList.findIndex(branch => branch.id === +id);
    this.branchId = this.branchList[index].id;
  }

  setUserBranchandCradle() {
    var mac = this.localStorageService.get('mac');
    if (mac === null || mac === undefined || mac === 'null' || mac === 'undefined' || mac === '') {
      this.localStorageService.set('mac', this.auth.user.username);
      mac = this.auth.user.username;
    }
    const uuid = this.create_UUID();
    this.localStorageService.set('cradleSerialNumber', this.cradleSerialNumber);
    this.service.getSetUserBranchAndCradle(this.branchId, this.cradleSerialNumber, mac, uuid);
    this.service.setUserBranchAndCradleService.subscribe(res => {
      if (res) {
        if (this.takeOverCradle) {
          this.takeOverCradleCreateLog(this.previousUser, this.auth.user.username, this.cradleSerialNumber);
        }
        
        this.localStorageService.set('uuid', uuid);
        this.setStorageMapStep('recordsOrCradle');
        this.router.navigate(['/']);
      }
    });
    
  }

  changeCradle(serialNumber) {
    const index = this.cradleList.findIndex(cradle => cradle.cradleSerialNumber === serialNumber);
    this.cradleSerialNumber = this.cradleList[index].cradleSerialNumber;
    this.usedCradleControl(false);
  }

  nextCradleChoose(isBefore: boolean) {
    this.showBackButton = isBefore;
    this.service.getGetCradlesByBranch(this.branchId);
    this.service.getCradleByBranch.subscribe(res => {
      this.cradleList = res;
      if (this.cradleList && this.cradleList.length > 0) {
        if (this.cradleList.length == 1) {
          this.cradleSerialNumber = this.cradleList[0].cradleSerialNumber;
          this.usedCradleControl(true);
        } else {
          this.setStorageMapStep('cradleChoose');
        }
      }
      else {
        this.skipCradleChoose();
      }
    });
  }

  backBranchChoose() {
    this.getBranchList();
  }

  usedCradleControl(control: boolean) {
    this.nextButtonActive = false;
    this.service.getUsedControl(this.cradleSerialNumber);
    this.service.usedControl.subscribe(res => {
      if (res) {
        //takeover aktif edilir.
        
        this.setStorageMapStep('cradleChoose');
        var date = new Date(res.userConnectedDate);
        var hourOffset = date.getTimezoneOffset() / 60;

        date.setHours(date.getHours() - hourOffset);
        this.connectedUserName = res.connectedUser;
        this.message = this.translate.instant('inUseBy') + " " + res.connectedUser;
        this.messageDate = date;
        this.showMessage = true;
        this.previousUser = res.linkedPc;
        this.takeOverCradle = true;
      } else {
        
        this.takeOverCradle = false;
        if (control) {
          
          this.setUserBranchandCradle();
        } else {
          this.showMessage = false;
          this.nextButtonActive = true;
          this.setStorageMapStep('cradleChoose');
        }

      }
    });
  }

  skipCradleChoose() {
    this.localStorageService.set('cradleSerialNumber', "skip");
    this.showMessage = false;
    this.setStorageMapStep('recordsOrCradle');
    this.router.navigate(['/']);

  }

  isSuperUserOrCradleWithout() {
    return (this.auth.hasRole(['Super User', 'Doctor Without Cradle']));
  }

  setStorageMapStep(value) {
    this.step = value;
    this.storageMap.set('step', this.step).subscribe(() => { });
  }

  takeOverCradleCreateLog(previousUserName, inheritingUserName, cradleSerialNumber) {
    this.service.getCradleTakeOverLogCrateService(previousUserName, inheritingUserName, cradleSerialNumber);
    this.service.createCradleTakeOverLog.subscribe(res => {});
  }

  create_UUID() {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }
}
