import {Injectable} from '@angular/core';
import {AuthApiHttpClient} from '../../common/auth/auth-api-http-client';
import {DataListService} from '../../common/data/data-list.service';
import { IpAddressKey } from '../../models/ipAddressKey';

@Injectable({ providedIn: 'root' })
export class AccessRequestsService extends DataListService<IpAddressKey> {

  accessRequestSubscribe: any;
  allowAccessSubscribe: any;

  constructor(httpClient: AuthApiHttpClient) {
    super({
      httpClient,
      url: 'auth/accessRequest',
      initial: []
    });
  }

  setAllowAccess(model) {
    this.allowAccessSubscribe = this.httpClient.post(model, 'auth/ipwhitelist/set-allow-access');
  }
}
