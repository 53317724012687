import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { BaseFormComponent } from '../common/components/base-form.component';
import { Logger } from '../common/logging/logger.service';
import { ToastService } from '../shared/services/toast.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FormOperations } from '../common/components/form';
import { TranslateService } from '@ngx-translate/core';
import { IpWhitelist } from '../models/ip-whitelist';
import { IpWhiteListItemService } from './ip-whitelist-item.service';
import { IpWhiteListsService } from './ip-whitelists.service';

@Component({
  selector: 'ecg-ip-whitelist-form',
  templateUrl: './ip-whitelist-form.component.html',
  styleUrls: ['./ip-whitelist-form.component.scss'],
})
export class IpWhiteListFormComponent extends BaseFormComponent<IpWhitelist> {
  hospitalList: any = [];
  customerName: any;
  ipAddress: any;

  rangeModel = {
    range: '',
    rangeBody: '',
    rangeStart: '',
    rangeEnd: '',
    customerId: -1,
    customerCodeId: -1
  };

  rangeMode = false;

  ranges = [];

  constructor(
    service: IpWhiteListItemService,
    public whiteListSvc: IpWhiteListsService,
    logger: Logger,
    location: Location,
    route: ActivatedRoute,
    public router: Router,
    spinner: NgxSpinnerService,
    toast: ToastService,
    private translate: TranslateService,
    public modal: NgbModal
  ) {

    super({ service, logger, location, route, router, spinner, toast });
    this.service = service;

    service.hospitalList.subscribe(res => {
      for (const hospital of res) {
        this.hospitalList.push({ id: hospital.id, name: hospital.customer.name + ' - ' + hospital.code, customerId: hospital.customerId, customerName: hospital.customer.name });
      }
    });
    for (let i = 254; i > 0; i--) {
      this.ranges.push(i);
    }
  }

  ipOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode === 47) {
      return false;
    }
    return !(charCode !== 46 && (charCode < 42 || charCode > 57));
  }

  save() {
    if (this.rangeMode) {
      this.saveRange();
    } else {
      const reg = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
      // @ts-ignore
      if (!reg.test(this.model.ipAddress)) {
        this.toast.showDanger(this.translate.instant('ipWhiteListForm.IpAddressNotValid'));
        return;
      }
      this.successMessage = this.savedSuccessMessage;
      this.errorMessage = this.savedErrorMessage;
      if (this.operation === FormOperations.add || this.operation === undefined) {
        this.service.post();
      } else {
        this.service.put();
      }
    }
  }

  saveRange() {
    const reg = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    // @ts-ignore
    if (!reg.test(this.rangeModel.rangeBody)) {
      this.toast.showDanger('Body not valid');
      return;
    }

    if (this.rangeModel.range.split('-').length != 2) {
      this.toast.showDanger('Range not valid');
      return;
    }

    this.rangeModel.rangeStart = this.rangeModel.range.split('-')[0];
    this.rangeModel.rangeEnd = this.rangeModel.range.split('-')[1];
    this.rangeModel.customerCodeId = this.model.customerCodeId;
    this.rangeModel.customerId = this.model.customerId;
    this.whiteListSvc.postRangeIps(this.rangeModel).subscribe(res => {
      if (res) {
        this.toast.showSuccess('Range successfully added!');
      }
    });
  }


  changeOrganisation(id) {
    const index = this.hospitalList.findIndex(hospital => hospital.id === +id);
    this.model.customerId = this.hospitalList[index].customerId;
    this.customerName = this.hospitalList[index].customerName;
  }
}
