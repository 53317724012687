import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BaseListComponent } from 'src/app/common/components/base-list.component';
import { Invoice } from 'src/app/models/invoice';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from 'src/app/common/logging/logger.service';
import { AuthService } from 'src/app/common/auth/auth.service';
import { InvoiceUploadService } from '../services/invoice-upload.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { PDFDocument } from 'pdf-lib';
import { TranslateService } from '@ngx-translate/core';
import { UploadInvoice } from 'src/app/models/upload-invoice';
import { InvoiceService } from '../services/invoice.service';
import { DeleteInvoiceFileModalComponent } from '../components/delete-invoice-file-modal/delete-invoice-file-modal.component';
import Swal from 'sweetalert2';
import { DeleteInvoiceModalComponent } from '../components/delete-invoice-modal/delete-invoice-modal.component';

class ImageSnippet {
  pending = false;
  status = 'init';
  constructor(public src: string, public file: File) {
  }
}

@Component({
  selector: 'ecg-invoice-upload',
  templateUrl: './invoice-upload.component.html',
  styleUrls: ['./invoice-upload.component.scss']
})
export class InvoiceUploadComponent extends BaseListComponent<Invoice> {

  selectedMonth: number;
  selectedYear: number;

  selectedFile: ImageSnippet;
  pdfFile: any;
  files: File[] = [];
  pdfPages: [];
  allOptions = [];

  customerCodeId: number;
  customerName: string;

  datas: any;

  constructor(service: InvoiceUploadService,
    spinner: NgxSpinnerService,
    router: Router,
    route: ActivatedRoute,
    public logger: Logger,
    public auth: AuthService,
    private modal: NgbModal,
    public sanitizer: DomSanitizer,
    private translate: TranslateService,
    private invoiceService: InvoiceService,
    private modalService: NgbModal,
    private translateService: TranslateService
  ) {
    super({
      service, spinner, router, route,
      idColumn: 'id',
      formRoute: '/invoices'
    });

    service.data.subscribe((res) => {
      this.datas = res;
    })
  }

  onSelectionChanged(selection: { month: number, year: number }) {
    this.selectedMonth = selection.month;
    this.selectedYear = selection.year;

    if (this.selectedMonth > 0 && this.selectedYear > 0) {
      this.datas.forEach(organization => {
        this.checkExistingInvoice(organization);
      });
    }
  }

  checkExistingInvoice(organization: any) {
    organization.hasExistingInvoice = false;

    if (organization.invoices.length > 0 && organization.invoiceFiles.length > 0) {
      const existingInvoice = organization.invoices.find(invoice =>
        invoice.month === this.selectedMonth && invoice.year === this.selectedYear
      );

      if (existingInvoice != undefined) {
        const existingInvoiceFile = organization.invoiceFiles.find(invoiceFile =>
          invoiceFile.invoiceId === existingInvoice.id
        );

        if (existingInvoice && existingInvoiceFile) {
          organization.hasExistingInvoice = true;
        } else {
          organization.hasExistingInvoice = false;
        }
      }
    }
  }

  isClickable(): boolean {
    return this.selectedMonth > 0 && this.selectedYear > 0;
  }

  uploadItemModal(modal, id: any, name: string) {
    this.customerCodeId = id;
    this.customerName = name;

    if (!this.isClickable())
      return;

    this.modal.open(modal, {
      centered: true
    });
  }

  processFile(file) {
    if (!file.name.includes(this.customerName)) {
      this.fileNotContainsCustomerCodeModal();
      this.closeModal();
    } else {
      const reader = new FileReader();
      reader.addEventListener('load', (event: any) => {
        this.selectedFile = new ImageSnippet(event.target.result, file);
        this.selectedFile.pending = true;
        this.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file));
        this.getAllOptions(this.selectedFile);
      });
      reader.readAsDataURL(file);
    }
  }

  closeModal() {
    this.modal.dismissAll();
    this.clearData();
  }

  onSelect(event) {
    if (this.files.length != 0) {
      this.files = [];
    }

    this.files.push(...event.addedFiles);
    this.processFile(event.addedFiles[0]);
  }

  async getAllOptions(file) {
    this.allOptions = [];
    const pdfDoc = await PDFDocument.load(file.src);
    for (var i = 0; i < pdfDoc.getPageCount(); i++) {
      this.allOptions.push(i + 1);
    }
  }

  onRemove(event) {
    this.clearData();
  }

  clearData() {
    this.files = [];
    this.pdfFile = undefined;
    this.selectedFile = undefined;
    this.allOptions = [];
  }

  async uploadFile() {
    this.spinner.show();
    const uploadInvoice: UploadInvoice = new UploadInvoice();
    uploadInvoice.customerCodeId = this.customerCodeId;
    uploadInvoice.fileName = this.selectedFile.file.name;
    uploadInvoice.pdfPath = this.selectedFile.src;
    uploadInvoice.year = this.selectedYear.toString();
    uploadInvoice.month = this.selectedMonth.toString();

    await this.invoiceService.uploadInvoice(uploadInvoice, () => {
      this.spinner.hide();
      this.showUploadModal();
      this.refresh();
    }, (error: string) => {
      Swal.close();
      console.warn(error);
    });
  }

  checkBeforeDelete(customerCode: any) {
    const matchingInvoice = customerCode.invoices.find(invoice => {
      return customerCode.invoices.some(invoiceItem => customerCode.customerCodeId == invoiceItem.customerCodeId && invoice.month == this.selectedMonth && invoice.year == this.selectedYear);
    });
    
    const modalRef = this.modalService.open(DeleteInvoiceModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false
    });

    modalRef.componentInstance.deleteEvent.subscribe(() => {
      this.deleteInvoice(matchingInvoice.id);
    });
  }

  async deleteInvoice(id: number) {
    this.spinner.show();
    await this.invoiceService.remove(id, () => {
      this.spinner.hide();
      this.showDeleteModal();
    });
  }

  showDeleteModal() {
    return Swal.fire({
      position: 'center',
      icon: 'success',
      title: this.translateService.instant('invoiceForm.deleted'),
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: true,
      timer: 1500,
      onClose: () => {
        this.refresh();
      }
    });
  }

  fileNotContainsCustomerCodeModal() {
    Swal.fire({
      position: 'center',
      title: this.translate.instant('invoiceForm.containsCustomerCode'),
      icon: 'error',
      showCloseButton: true,
      timer: 5000,
      allowOutsideClick: false,
    });
  }

  showUploadModal() {
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Successful.',
      showConfirmButton: true,
      timer: 3000,
      onClose: () => {
        this.closeModal();
      }
    });
  }
}
