import * as i0 from "@angular/core";
import * as i1 from "../../common/auth/auth-api-http-client";
export class ChangeVersionService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    setVersion() {
        return this.httpClient.get('ChangeLogForRedisApi/set-platform-version');
    }
    getVersion() {
        return this.httpClient.get('ChangeLogForRedisApi/get-platform-version');
    }
}
ChangeVersionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChangeVersionService_Factory() { return new ChangeVersionService(i0.ɵɵinject(i1.AuthApiHttpClient)); }, token: ChangeVersionService, providedIn: "root" });
