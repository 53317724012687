import { Injectable } from '@angular/core';
import { AuthApiHttpClient } from 'src/app/common/auth/auth-api-http-client';
import { DataListService } from 'src/app/common/data/data-list.service';
import { EntityHistory } from 'src/app/models/entityHistory';


@Injectable({ providedIn: 'root' })
export class LogHistoryListService extends DataListService<EntityHistory> {

  constructor(httpClient: AuthApiHttpClient) {
    super({
      httpClient,
      url: 'health/history',
      initial: []
    });
  }


  getHistoryById(id){
    return this.httpClient.get<Array<any>>('health/history/get-history-by-id?id='+id);
  }
}
