import { BaseListComponent } from '../../common/components/base-list.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../common/auth/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastService } from '../../shared/services/toast.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Report } from '../../models/report';
import { DateRangeService } from '../platform-performance/date-range/date-range.service';
import { ReportErrorLogListService } from './report-error-log-list.service';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

interface MetaData {
  event: string,
  key: 'Project' | 'Module'
}

@Component({
  selector: 'ecg-report-error-log-list',
  templateUrl: './report-error-log-list.component.html',
  styleUrls: ['./report-error-log-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ReportErrorLogListComponent extends BaseListComponent<Report> implements OnInit {
  _dateRangeService :DateRangeService;

  constructor(
    public service: ReportErrorLogListService,
    spinner: NgxSpinnerService,
    router: Router,
    route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public auth: AuthService,
    protected toast: ToastService,
    dateRangeService: DateRangeService
  ) {
    super({
      service,
      spinner,
      router,
      route,
      idColumn: 'id',
      formRoute: '/ErrorLogReport'
    }, dateRangeService);
    this._dateRangeService=dateRangeService;
  }
  onErrorSelectChange($event: MetaData) {
    if (!$event || $event.key === 'Module') return;

    this.currentProjectSelection = $event.event;

    this._dateRangeService.getErrorLogModuleList(this.currentProjectSelection)
      .pipe(switchMap((moduleList) => of(moduleList)))
      .subscribe((moduleList) => {
        this.filterMetaData.subscribe(filterMetaData => {
          const setFilters = (_moduleList: string[]) => {
            const result = filterMetaData
              .filter(x => x.title === 'Report')
              .map(y => ({ ...y, items: y.items.map(z => z.key === 'Module' ? { ...z, values: this.currentProjectSelection === '' ? [''] : _moduleList } : z)  }))

            this.filterMetaData = of(result);
            this._filterMetaData = result
          }

          setFilters(moduleList);

          this._dateRangeService.getErrorLogModuleList(this.currentProjectSelection).subscribe(setFilters)

        })
      })
  }
  /**
   * @description splits a string at every nth character
   * @param {string} message
   * @param {number} [indexToSplit]
   * @return {*}  {string}
   * @memberof ReportListComponent
   */
   splitToNextLine([message, indexToSplit]: [string, number?]): string {
    const rgx = new RegExp(`.{1,${indexToSplit || 30}}`, 'g');
    return ((message || '').match(rgx) || []).map(c => `${c}\n`).join();
  }

  copyText(message:string)
  {
    navigator.clipboard.writeText(message);
  }

  copyGId(gId:string)
  {
    if(gId == null)
    return;
    const url = this.router.serializeUrl(this.router.createUrlTree(['/report'], {
      queryParams: {
        GId: gId
      }
    }))
    const newTab = window.open(url, '_blank');
    if(newTab) {
        newTab.opener = null;
    }
  }

  columnMessage(message:string)
  {
    if(this.projectChoosed() || this.moduleChoosed())
      return message;
    if(message && message.length>15)
      return message.substring(0,15).replace("\n","").replace("\r","");
    else
      return message;
  }

  projectChoosed()
  {
      var input = document.querySelector(
        '[id="Project"]',
      ) as HTMLSelectElement | null;
      if(input != null)
      {
          return input.selectedIndex > 0;
      }
      return false;
  }

  moduleChoosed()
  {
      var input = document.querySelector(
        '[id="Module"]',
      ) as HTMLSelectElement | null;
      if(input != null)
      {
          return input.selectedIndex > 0;
      }
      return false;
  }

}
