import { DataListService } from '../../common/data/data-list.service';
import * as i0 from "@angular/core";
import * as i1 from "../../common/auth/auth-api-http-client";
export class ReportErrorLogListService extends DataListService {
    constructor(httpClient) {
        super({
            httpClient,
            url: 'health/errorLogReport',
            initial: []
        });
    }
}
ReportErrorLogListService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReportErrorLogListService_Factory() { return new ReportErrorLogListService(i0.ɵɵinject(i1.AuthApiHttpClient)); }, token: ReportErrorLogListService, providedIn: "root" });
