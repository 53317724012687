import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import appConfig from '../../../environments/app.config.json';
import { ApiLocations } from '../../app.config';
import { HttpRequestOptions, HttpResponseType, HttpObserve, HttpMethod } from './api-http-options';

@Injectable({ providedIn: 'root' })
export class ApiHttpClient {
  protected readonly api: ApiLocations;

  static getDefaultOptions(responseType: HttpResponseType = 'json', body: HttpObserve = 'body'): HttpRequestOptions {
    return { body, responseType };
  }

  constructor(protected http: HttpClient) {
    this.api = appConfig.apiLocations;
  }

  get<T>(url?: string, options: HttpRequestOptions = ApiHttpClient.getDefaultOptions()): Observable<T> {
    options.headers = (options.headers || new HttpHeaders())
      .set('Cache-control', 'not-store, no-cache')
      .set('Pragma', 'no-cache')
      .set('Expires', '0');
    return this.request<T>('GET', null, url, options);
  }

  post<T, TBody>(body: TBody, url?: string, options?: HttpRequestOptions): Observable<T> {
    return this.request<T>('POST', body, url, options);
  }

  put<T, TBody>(body: TBody, url?: string, options?: HttpRequestOptions): Observable<T> {
    return this.request<T>('PUT', body, url, options);
  }

  delete<TBody>(body?: TBody, url?: string, options?: HttpRequestOptions): Observable<object> {
    return this.request<object>('DELETE', body, url, options);
  }

  request<T>(
    method: HttpMethod, body: any, url?: string, options: HttpRequestOptions = ApiHttpClient.getDefaultOptions()
  ): Observable<T> {
    if (body) {
      options.body = body;
      options.headers = (options.headers || new HttpHeaders()).append('Content-Type', 'application/json');
    }
    return this.http.request(method, this.getPath(url), options) as Observable<T>;
  }

  getBlob(url?: string, options: HttpRequestOptions = ApiHttpClient.getDefaultOptions('blob')): Observable<Blob> {
    return this.requestBlob('GET', null, url, options);
  }

  requestBlob(
    method: HttpMethod, body: any, url?: string, options: HttpRequestOptions = ApiHttpClient.getDefaultOptions('blob')
  ): Observable<Blob> {
    if (body) {
      options.body = body;
    }
    return this.http.request(method, this.getPath(url, options), options) as Observable<Blob>;
  }

  protected getPath(url?: string, options?: HttpRequestOptions): string {
    if (options && options.getPath) {
      return options.getPath(url);
    }
    return url ? `${this.api.base}/${url}` : this.api.base;
  }
}
