import { AgmInfoWindow, AgmMarker } from '@agm/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RESOURCE_CACHE_PROVIDER } from '@angular/platform-browser-dynamic';
import { ActivatedRoute, Router } from '@angular/router';
import { timeStamp } from 'console';
import { resolve } from 'dns';
import { ThemeService } from 'ng2-charts';
import { interval, Observable, of, timer } from 'rxjs';
import { delay, filter, map, shareReplay, startWith, switchMap, timeInterval } from 'rxjs/operators';
import { CradleMapService } from './cradle-map.service';

@Component({
  selector: 'ecg-cradle-map',
  templateUrl: './cradle-map.component.html'
})
export class CradleMapComponent implements OnInit {
  //https://angular-maps.com/api-docs/agm-core/directives/agmmarker
  
  //#region  variables
  
  previousInfoWindow: AgmInfoWindow;
  cradleConnected : string ="CRADLE CONNECTED";
  cradleNotConnected : string = "CRADLE NOT CONNECTED";
  cradleConnectedUrl :string ="/assets/icons/cradle_connected.png";
  cradleNotConnectedUrl :string ="/assets/icons/cradle_notConnected.png"
  markerSelectIndex = -1;
  
  timeLeft: number = 60;
  interval;
  
  data : any = [
  ];
  
  // initial center position for the map
  lat: number = 40.28;
  lng: number = 26.43;
  
  // google maps zoom level
  zoom: number = 7;
  //#endregion
  
   constructor(public mapService:CradleMapService,
   public route: ActivatedRoute, 
   public router: Router) {
   }



  ngOnInit(): void {
    this.syncCradles();
  }

  private syncCradles() {
    const parseStat = (results = []) => results.map((val) => ({ ...val, cradleLastLog: `<p>${(val.cradleLastLog || '').replace(/\n/g, '<br/>')}<p/>` }));

      const observable = this.mapService.getCradles().pipe(filter(x=> x.latitude>0 && x.longitude>0))
      .subscribe(result => {
        
        this.data = parseStat(result);
      
        if(this.data.length) {
          this.lat =this.data[0].latitude
          this.lng=this.data[0].lng;
        }
      });

      const seconds = interval(1000 * 60);

      seconds
      .pipe(timeInterval(), startWith(this.data))
      .subscribe(
          value => this.mapService.getCradles().pipe(
            shareReplay(2), 
            map(res => (res || []).filter(x => x.latitude>0 && x.longitude>0)),
            switchMap((result) => {
              this.data = parseStat(result);
             
              if(this.data.length) {
                this.lat =this.data[0].latitude
                this.lng=this.data[0].longitude;
              }
              
              return of(result)
            })
          ).subscribe(res => {
            if (!this.previousInfoWindow || this.previousInfoWindow.isOpen) return;

            this.previousInfoWindow.open();
          }),
          err => console.log(err),
      );
  }


    GetCradles(){
        
      // Get Cradle Information
        this.mapService.getCradles().subscribe(response => {
         
        this.data=response.filter(x=> x.latitude>0 && x.longitude>0);

        if(this.data.length>0)
        {
          this.lat =this.data[0].latitude
          this.lng=this.data[0].lng;
        }
       
      });
    }

  

    markerClicked(agmMarker : AgmMarker, index: number){
      // if (this.previousInfoWindow && this.previousInfoWindow.isOpen) {
      //   this.previousInfoWindow.close();
      // }

      if (agmMarker) { 
        (agmMarker.infoWindow || []).forEach(x => {
          x.isOpen = false;
          x.close();
        });
      }

      this.markerSelectIndex = index;

      if(agmMarker.infoWindow.length)
      {
        this.previousInfoWindow=agmMarker.infoWindow.first as AgmInfoWindow;
      }
    }

    redirectCradleDetail(id:number):void{
      const extras = { queryParams: { "id": id } };
      this.router.navigate(['cradles/edit'], extras);
    }




}
