<br>
<h3 style="text-align:center;">
  {{'serverHealth.serverHealthTitle' | translate }}
</h3>
<br>
<link href="//maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css" rel="stylesheet">
<div class="container bootstrap snippet">
    <div class="row margin-bottom-10">
          <div class="col-lg-4 col-md-12 col-sm-12 mb-md-3 mb-sm-3 mb-xs-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title" style="text-align: center;">
                  {{'serverHealth.diskUsage' | translate }}
                </h5>
                <div class="card-text">
                  <div class="col-md-12">
                    <div class="d-flex justify-content-between">
                      <div>
                        {{disk2}}
                      </div>
                      <div>
                        {{size}}
                      </div>
                 </div>
                    <div class="progress">
                        <div class="progress-bar" style="background:#4765a0;" [style.width]='disk2'>
                        </div>
                    </div>
                    <p style="margin-top:2%;"> {{ecgFile}} </p>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12 mb-md-3 mb-sm-3 mb-xs-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title" style="text-align: center;">
                  {{'serverHealth.cpuUsages' | translate }}
                </h5>
              <div class="row" style="margin-right:10px;">
                <div class="col-sm-4">
                  <div class="progressBar"  id='{{system*10}}'>
                    <span class="progress-left">
                      <span class="progress-bar"></span>
                    </span>
                    <span class="progress-right">
                      <span class="progress-bar"></span>
                    </span>
                    <div class="progress-value">
                      <div>
                        {{system}}%<br>
                        <span>
                          {{'serverHealth.system' | translate }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="progressBar"  id='{{user*10}}'>
                    <span class="progress-left">
                      <span class="progress-bar"></span>
                    </span>
                    <span class="progress-right">
                      <span class="progress-bar"></span>
                    </span>
                    <div class="progress-value">
                      <div>
                        {{user}}%<br>
                        <span>
                          {{'serverHealth.user' | translate }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="progressBar"  id='{{idle*10}}'>
                    <span class="progress-left">
                      <span class="progress-bar"></span>
                    </span>
                    <span class="progress-right">
                      <span class="progress-bar"></span>
                    </span>
                    <div class="progress-value">
                      <div>
                        {{idle}}%<br>
                        <span>
                          {{'serverHealth.idle' | translate }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title" style="text-align: center;">
                  {{'serverHealth.totalMemory' | translate }}
                </h5>
                <div class="card-text">
                  <div class="col-md-12">
                    <div class="d-flex justify-content-between">
                      <div>
                       Used: {{memUsed}} Mb
                      </div>
                      <div>
                        {{memTotal}} Gb
                      </div>
                 </div>
                    <div class="progress">
                        <div class="progress-bar" style="background:#4765a0;" [style.width]='disk2'>
                        </div>
                    </div>
                    <p style="margin-top:2%;">&nbsp;</p>
                </div>
                </div>
              </div>
            </div>
          </div>
    </div>
<br>
<h3 style="text-align:center;">
  {{'serverHealth.network' | translate }}
</h3>
    <div class="row">
        <div class="col-12" style="height: 340px">
          <canvas height="100%" id="myChart2"></canvas>
        </div>
    </div>
</div>
