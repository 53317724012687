
<ng-container *ngIf="model">
  <nav class="navbar navbar-light bg-light navbarHeaderforCenter">
    <ul class="nav navbar px-0">
      <li class="m-1">
        <button type="button" class="btn btn-secondary" routerLink="/pc" i18n>
          {{'back' | translate }}
        </button>
      </li>
    </ul>
    <div class="text-center divHeaderforCenter">
      <h3 i18n>PC Detail for {{model.name}}</h3>
    </div>
  </nav>
  <form #mainForm="ngForm">
    <div class="row m-0">
      <div class="col-lg" style="flex-direction: column; display: flex">
        <div class="card m-3">
          <div class="card-body">
            <div class="row no-gutters">
              <div class="form-group col-12 col-md-6 col-lg-3 pl-2 pr-2">
                <label for="surname" i18n>
                  {{'username' | translate }}
                </label>
                <input type="text" class="form-control" id="surname"
                       name="surname" #surname="ngModel" [(ngModel)]="model.username" readonly>
              </div>
              <div class="form-group col-12 col-md-6 col-lg-3 pl-2 pr-2">
                <label for="organization" i18n>
                  {{'organisation' | translate }}
                </label>
                <input type="text" class="form-control" id="organization"
                       name="organization" #organization="ngModel" [(ngModel)]="model.customer.name" readonly>
              </div>
              <div class="form-group col-12 col-md-6 col-lg-3 pl-2 pr-2">
                <label for="PcId" i18n>Pc Id</label>

                <input type="text" class="form-control" id="PcId"
                       name="PcId" #PcId="ngModel" [(ngModel)]="model.pcLinked" readonly>
              </div>
              <div class="form-group col-12 col-md-6 col-lg-3 pl-2 pr-2">
                <label  i18n>
                  {{'role' | translate }}
                </label>
                <h5><span class="badge badge-secondary">{{model.userAccountRoles[0].userRole.name}}</span></h5>
              </div>


            </div>

          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>
<ng-template #savedSuccessMessage>
  <span i18n>
    {{'cradleForm.savedSuccess' | translate }}
  </span>
</ng-template>
<ng-template #savedErrorMessage>
  <span i18n>
    {{'cradleForm.savedFailed' | translate }}
  </span>
</ng-template>
<ng-template #deletedSuccessMessage>
  <span i18n>
    {{'cradleForm.deletedSuccess' | translate }}
  </span>
</ng-template>
<ng-template #deletedErrorMessage>
  <span i18n>
    {{'cradleForm.deletedFailed' | translate }}
    </span>
</ng-template>
