import {Injectable} from '@angular/core';
import {AuthApiHttpClient} from '../common/auth/auth-api-http-client';
import {DataListService} from '../common/data/data-list.service';
import {Cradle} from '../models';

@Injectable({providedIn: 'root'})
export class CradlesListService extends DataListService<Cradle> {

  constructor(httpClient: AuthApiHttpClient) {
    super({
      httpClient,
      url: 'health/cradles',
      initial: []
    });
  }

}
