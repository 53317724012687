import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import { AuthGuard } from "./common/auth/auth.guard";
import { DiaryFormComponent } from "./patient/diary-form.component";
import { CustomerListComponent } from "./customer/customer-list.component";
import { CustomerFormComponent } from "./customer/customer-form.component";
import { LoginComponent } from "./public/login/login.component";
import { NotFoundComponent } from "./public/not-found/not-found.component";
import { PcListComponent } from "./pc/pc-list.component";
import { PcFormComponent } from "./pc/pc-form.component";
import { ForgotPasswordComponent } from "./public/forgot-password/forgot-password.component";
import { AboutComponent } from "./public/about/about.component";
import { ActivityComponent } from "./public/activity/activity.component";
import { CreatePasswordComponent } from "./public/create-password/create-password.component";
import { NewPasswordComponent } from "./public/new-password/new-password.component";
import { ServerHealthPageComponent } from "./server-health-page/server-health-page.component";
import { FirmwareComponent } from "./firmware/firmware.component";
import { DashboardComponent } from "./sysadmin/dashboard/dashboard.component";
import { PublicLayoutComponent } from "./common/components/public-layout.component";
import { AuthLayoutComponent } from "./common/components/auth-layout.component";
import { RedirectGuard } from "./common/auth/redirect.guard";
import { NotificationComponent } from "./notification/notification.component";
import { RecorderFormComponent } from "./recorder/recorder-form.component";
import { ServerHealthCheckComponent } from "./server-health-check/server-health-check.component";
import { BranchChooseComponent } from "./shared/components/BranchChoose/branch-choose.component";
import { PatientDiaryComponent } from "./patient-diary/patient-diary.component";
import { IpWhitelistsComponent } from "./ip-whitelist/ip-whitelists.component";
import { IpWhiteListFormComponent } from "./ip-whitelist/ip-whitelist-form.component";
import { AccessRequestsComponent } from "./ip-whitelist/access-requests/access-requests.component";
import { NoteListComponent } from "./note/note-list.component";
import { NoteFormComponent } from "./note/note-form.component";
import { AccessPermissionComponent } from "./ip-whitelist/access-allow/access-permission.component";
import { AuthLogsComponent } from "./AuthLogs/auth-logs.component";
import { ChargerFormComponent } from "./charger/charger-form.component";
import { ManualComponent } from "./manual/manual.component";
import { CradleMapComponent } from "./cradle-maps/cradle-map.component";
import { ReportListComponent } from "./report/report-list.component";
import { PlatformPerformanceComponent } from "./report/platform-performance/platform-performance.component";
import { InstantComponent } from "./report/platform-performance/instant/instant.component";
import { DateRangeComponent } from "./report/platform-performance/date-range/date-range.component";
import { LogHistoryListComponent } from "./report/log-history/log-history-list.component";
import { ReportErrorLogListComponent } from "./report/report-error-log-list/report-error-log-list.component";
import { ReportListFormEditComponent } from "./report/report-list-form-edit.component";
import { ReportErrorFormEditComponent } from "./report/report-error-log-list/report-error-form-edit.component";
import { SettingsComponent } from "./settings/settings.component";
import { InvoiceUploadComponent } from "./invoices/invoice-upload/invoice-upload.component";

const routes: Routes = [
  { path: "", pathMatch: "full", canActivate: [RedirectGuard], children: [] },
  { path: "access-permission", component: AccessPermissionComponent },
  {
    path: "",
    component: PublicLayoutComponent,
    children: [
      {
        path: "login",
        component: LoginComponent,
        canActivate: [RedirectGuard],
      },
      { path: "forgot", component: ForgotPasswordComponent },
      { path: "changepassword", component: NewPasswordComponent },
      { path: "createpassword", component: CreatePasswordComponent },
    ],
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "users",
        canActivate: [AuthGuard],
        loadChildren: "./users/users.module#UsersModule",
      },
      {
        path: "about",
        canActivate: [AuthGuard],

        data: { roles: ["System Admin", "Super User"] },
        component: AboutComponent,
      },
      {
        path: "activity",
        canActivate: [AuthGuard],
        component: ActivityComponent,
      },
      {
        path: "sysadmin",
        canActivate: [AuthGuard],
        loadChildren: "./sysadmin/sysadmin.module#SysadminModule",
        data: {
          roles: ["System Admin", "Super User", "Support", "Support DK"],
        },
      },
      {
        path: "records",
        loadChildren: "./patient/patient.module#PatientModule",
      },
      {
        path: "branch",
        canActivate: [AuthGuard],
        component: BranchChooseComponent,
      },
      {
        path: "worklist",
        loadChildren: "./patient/patient-worklist.module#PatientWorkListModule",
      },
      {
        path: "diary-upload",
        canActivate: [AuthGuard],
        data: { roles: ["Diary User", "Assistant"] },
        component: PatientDiaryComponent,
      },
      {
        path: "diary/:op",
        canActivate: [AuthGuard],
        data: { roles: ["System Admin", "Super User"] },
        component: DiaryFormComponent,
      },
      {
        path: "customers",
        canActivate: [AuthGuard],
        data: {
          roles: ["System Admin", "Super User", "Support", "Support DK"],
        },
        component: CustomerListComponent,
      },
      {
        path: "ip-whitelist",
        canActivate: [AuthGuard],
        data: {
          roles: ["System Admin", "Super User", "Support", "Support DK"],
        },
        component: IpWhitelistsComponent,
      },
      {
        path: "ip-whitelist/access-requests",
        canActivate: [AuthGuard],
        data: {
          roles: ["System Admin", "Super User", "Support", "Support DK"],
        },
        component: AccessRequestsComponent,
      },
      {
        path: "ip-whitelist/:op",
        canActivate: [AuthGuard],
        data: {
          roles: ["System Admin", "Super User", "Support", "Support DK"],
        },
        component: IpWhiteListFormComponent,
      },
      {
        path: "cradles",
        canActivate: [AuthGuard],
        data: {
          roles: ["System Admin", "Super User", "Support", "Support DK"],
        },
        loadChildren: "./cradles/cradle.module#CradleModule",
      },
      {
        path: "customer/:op",
        canActivate: [AuthGuard],
        data: {
          roles: ["System Admin", "Super User", "Support", "Support DK"],
        },
        component: CustomerFormComponent,
      },
      {
        path: "pc",
        canActivate: [AuthGuard],
        data: { roles: ["System Admin", "Super User"] },
        component: PcListComponent,
      },
      {
        path: "recorder/:op",
        canActivate: [AuthGuard],
        data: { roles: ["System Admin", "Super User"] },
        component: RecorderFormComponent,
      },
      {
        path: "charger/:op",
        canActivate: [AuthGuard],
        data: { roles: ["System Admin", "Super User"] },
        component: ChargerFormComponent,
      },
      {
        path: "auth-logs",
        canActivate: [AuthGuard],
        data: {
          roles: ["System Admin", "Super User", "Support", "Support DK"],
        },
        component: AuthLogsComponent,
      },
      {
        path: "notes",
        canActivate: [AuthGuard],
        data: { roles: ["System Admin", "Super User", "Staff Fysiologic"] },
        component: NoteListComponent,
      },
      {
        path: "notes/:op",
        canActivate: [AuthGuard],
        data: { roles: ["System Admin", "Super User", "Staff Fysiologic"] },
        component: NoteFormComponent,
      },
      {
        path: "report",
        canActivate: [AuthGuard],
        data: { roles: ["System Admin", "Super User", "Staff Fysiologic"] },
        component: ReportListComponent,
      },
      {
        path: "report/:op",
        canActivate: [AuthGuard],
        data: {
          roles: ["System Admin", "Super User", "Support", "Support DK"],
        },
        component: ReportListFormEditComponent,
      },
      {
        path: "error-log-report",
        canActivate: [AuthGuard],
        data: { roles: ["System Admin", "Super User", "Staff Fysiologic"] },
        component: ReportErrorLogListComponent,
      },
      {
        path: "ErrorLogReport/:op",
        canActivate: [AuthGuard],
        data: {
          roles: ["System Admin", "Super User", "Support", "Support DK"],
        },
        component: ReportErrorFormEditComponent,
      },
      {
        path: "log-history",
        canActivate: [AuthGuard],
        data: { roles: ["System Admin", "Super User", "Staff Fysiologic"] },
        component: LogHistoryListComponent,
      },
      {
        path: "platform-performance/date-range",
        canActivate: [AuthGuard],
        data: { roles: ["System Admin", "Super User", "Staff Fysiologic"] },
        component: DateRangeComponent,
      },
      {
        path: "platform-performance/instant",
        canActivate: [AuthGuard],
        data: { roles: ["System Admin", "Super User", "Staff Fysiologic"] },
        component: InstantComponent,
      },
      {
        path: "platform-performance",
        canActivate: [AuthGuard],
        data: { roles: ["System Admin", "Super User", "Staff Fysiologic"] },
        component: PlatformPerformanceComponent,
      },
      {
        path: "pc/:op",
        canActivate: [AuthGuard],
        data: { roles: ["System Admin", "Super User"] },
        component: PcFormComponent,
      },
      {
        path: "dashboard",
        canActivate: [AuthGuard],
        data: { roles: ["Super User", "Staff Fysiologic"] },
        component: DashboardComponent,
      },
      {
        path: "notification",
        canActivate: [AuthGuard],
        data: { roles: ["Super User"] },
        component: NotificationComponent,
      },
      // {
      //   path: "settings",
      //   canActivate: [AuthGuard],
      //   data: { roles: ["System Admin"] },
      //   component: SettingsComponent,
      // },
      {
        path: "serverHealth",
        component: ServerHealthPageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "serverCheck",
        component: ServerHealthCheckComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "cradle-map",
        component: CradleMapComponent,
        canActivate: [AuthGuard],
      },
      { path: "firmware", component: FirmwareComponent },
      {
        path: "manual",
        canActivate: [AuthGuard],
        component: ManualComponent,
      },
      {
        path: "materials",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./shop/materials.module").then((m) => m.MaterialsModule),
      },
      {
        path: "specifications",
        loadChildren: () => import('./invoices/invoices.module').then((module) => module.InvoicesModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'upload-specifications',  component: InvoiceUploadComponent,
        data: { roles: ["System Admin", "Super User", "Financial Admin"], },
        canActivate: [AuthGuard],
      }
    ],
  },
  { path: "**", component: NotFoundComponent },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
