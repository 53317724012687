<div *ngIf="(service.data | async) as patientsNew">
  <nav class="navbar navbar-light bg-light">
    <div class="row m-0 w-100">
      <div class="col-3 pl-0 pt-1 pr-0" *ngIf="step == 'fileUpload'">
        <button type="button" class="btn btn-info float-left" (click)="backSelectedRecords()" i18n>{{'patientList.back' | translate }}</button>
      </div>
      <div class="col-3 pl-0 pt-1 pr-0" *ngIf="step == 'selectRecords'">
        <button class="btn btn-info mr-2" (click)="showFilter = !showFilter" i18n><i class="fa fa-filter"></i> {{'filterButton' | translate}}</button>
        <button type="button" class="btn btn-outline-secondary" (click)="filterReset();" *ngIf="showFilter"  i18n>{{'filterReset' | translate }}</button>
      </div>
      <div *ngIf="step == 'selectRecords'" class="col-6 align-self-center text-center">
        <h3 i18n>{{'patientList.records' | translate }}</h3>
      </div>
      <div class="col pt-1 pr-0">
        <button *ngIf="step == 'selectRecords'" [disabled]="selectedRecords.length < 1" type="button" class="btn btn-info float-right" (click)="nextUpload()" i18n>{{'patientList.next' | translate }}</button>
        <button  type="submit" class="btn btn-info float-right" *ngIf="step === 'fileUpload' && selectedFile" (click)="pdfSplit()"
                i18n>
          {{'upload' | translate }}
        </button>
      </div>
    </div>
  </nav>
  <div class="card" *ngIf="showFilter && step == 'selectRecords'">
    <div class="card-body">
      <ecg-filter [metaData]="filterMetaData" (filterChange)="pagingReset();filterChange($event);" [reset]="reset"></ecg-filter>
    </div>
  </div>

  <div *ngIf="step === 'selectRecords'">
    <div [class]="(dataCount | async) > 10 ? 'table-responsive' : 'table-responsive table-responsive-no-paginate'">
      <table class="table table-hover" i18n-aria-describedby aria-describedby="incoming file list">
        <thead>
          <tr>
            <th>
            </th>
            <th scope="col" sortable="name" (sort)="onSort($event)" i18n>{{'patientList.patientName' | translate }}</th>
            <th scope="col" sortable="pidNo" (sort)="onSort($event)" i18n>{{'patientForm.pidNo' | translate }}</th>
            <th scope="col" sortable="birthDate" (sort)="onSort($event)" i18n>{{'patientForm.dateOfBirth' | translate }} </th>
            <th scope="col" sortable="recorderSerialNumber" (sort)="onSort($event)" i18n>{{'patientList.recorderSn' | translate }} </th>
          </tr>
        </thead>
        <tbody *ngIf="patientsNew !== undefined ||  patientsNew.length!=0 ; else norecord">
          <tr class="clickable-row" *ngFor="let patient of patientsNew; let index=index" [tabIndex]="index + 1" (click)="stopNavigating($event);selectedRecordsChange(patient);">
            <td>
              <i class="fa fa-plus text-success cursorProp" (click)="stopNavigating($event);selectedRecordsChange(patient);" *ngIf="!visibleControl(patient)"></i>
              <i class="fa fa-minus text-danger cursorProp" (click)="stopNavigating($event);selectedRecordsChange(patient);" *ngIf="visibleControl(patient)"></i>
            </td>
            <td>{{ patient.name }} {{patient.lastName}}</td>
            <td>{{ patient.pidNo }}</td>
            <td>{{ patient.birthDate | date:'d-M-yyyy' }}</td>
            <td>{{patient.recorderSerialNumber}}</td>
          </tr>
        </tbody>
        <ng-template #norecord>
          <tbody>
            <tr>
              <td colspan="10" class="text-danger" i18n>{{'patientList.noRecords' | translate }}</td>
            </tr>
          </tbody>
        </ng-template>
      </table>
    </div>
    <div *ngIf="(dataCount | async) as count">
      <div class="bg-ecg fix-to-bottom text-white px-3 pt-lg-3" *ngIf="count>=10">
        <div class="row justify-content-between w-100 m-0">
          <div class="col-12 d-block d-lg-none">
            <div class="pt-1 text-center" i18n>
              {{count}} {{'itemsIn' | translate }} {{this | func:pageCount:count:paging.size}} {{ 'pages' | translate }}
            </div>
          </div>
          <div class="col-auto pl-0 pr-0">
            <ngb-pagination [boundaryLinks]="true" [rotate]="true" [maxSize]="3" [(page)]="paging.index"
                            [pageSize]="paging.size" [collectionSize]="count" (pageChange)="refresh();pagingSave();"
                            #pagination></ngb-pagination>
          </div>
          <div class="col-12 col-lg d-none d-lg-block">
            <div class="pt-2 text-center" i18n>
              {{count}} {{'itemsIn' | translate }} {{this | func:pageCount:count:paging.size}} {{'pages' | translate }}
            </div>
          </div>
          <div class="col pr-0 pl-0 pagination-dropdown-container">
            <div>
              <select class="custom-select pagination-dropdown cursorProp" [(ngModel)]="paging.size" (change)="refresh()">
                <option [ngValue]="10" i18n>{{'records10' | translate }}</option>
                <option selected [ngValue]="15" i18n>{{'records15' | translate }}</option>
                <option [ngValue]="20" i18n>{{'records20' | translate }}</option>
                <option [ngValue]="50" i18n>{{'records50' | translate }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="step === 'fileUpload'">
    <div class="row">
      <div class="col-7">
        <nav class="navbar navbar-light justify-content-center text-center">
          <h4 i18n>
            {{'patientForm.dagBoek' | translate }}
          </h4>
        </nav>
        <div class="card m-3">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
              </div>
            </div>
            <ngx-dropzone style="height:75px;" class="mt-2" (change)="onSelect($event)" [accept]="'.pdf'"
                          [multiple]="false">
              <ngx-dropzone-label i18n>{{'dropFilesUpload' | translate}}</ngx-dropzone-label>
              <ngx-dropzone-preview class="pdf-upload-box" *ngFor="let f of files" [removable]="true"
                                    (removed)="onRemove(f)">
                <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
              </ngx-dropzone-preview>
            </ngx-dropzone>
            <object class="pdf-view mt-2" *ngIf="selectedFile" [data]="pdfFile" type="application/pdf">
            </object>
          </div>
        </div>
      </div>

      <div class="col-5">
        <nav class="navbar navbar-light justify-content-center text-center">
          <h4 i18n> 
            {{'patientList.selectedRecords' | translate }}
          </h4>
        </nav>
        <div class="card border-0 sticky-top m-0 p-0" style="top:65px;">
          
          <div [class]="'table-responsive mt-0'" class="col-10">
            <table class="table table-hover" i18n-aria-describedby aria-describedby="incoming file list">
              <thead>
                <tr>
                  <th>
                  </th>
                  <th>{{'patientList.patientName' | translate }}</th>
                  <th>{{'patientForm.pidNo' | translate }}</th>
                  <th>{{'patientList.pageRange' | translate }}</th>
                </tr>
              </thead>
              <tbody *ngIf="selectedRecords !== undefined ||  selectedRecords.length!=0 ; else norecord">
                <tr class="clickable-row" *ngFor="let patient of selectedRecords; let index=index" [tabIndex]="index + 1">
                  <td>
                    <i class="fa fa-minus text-danger" (click)="stopNavigating($event);selectedRecordsChange(patient);"></i>
                  </td>
                  <td>{{ patient.name }} {{patient.lastName}}</td>
                  <td>{{ patient.pidNo }}</td>
                  <td>
                    <!--<select (change)="selectPageNumberStart(patient, $event)">
                      <option *ngFor="let number of allOptions" [ngValue]="number">{{number}}</option>
                    </select>
                    <span>-</span>
                    <select (change)="selectPageNumberEnd(patient, $event)">
                      <option *ngFor="let number of allOptions" [ngValue]="number">{{number}}</option>
                    </select>-->
                    <input (keypress)="numberOnly($event)" [(ngModel)]="patient.pdfFilePage" style="font-size: 0.9em;" type="text" size="12" placeholder="e.g. 1-5, 1, 11-13" >
                  </td>
                </tr>
              </tbody>
              <ng-template #norecord>
                <tbody>
                  <tr>
                    <td colspan="10" class="text-danger" i18n>{{'patientList.noRecords' | translate }}</td>
                  </tr>
                </tbody>
              </ng-template>
            </table>
          </div>

        </div>
      </div>
      
    </div>
  </div>
</div>
