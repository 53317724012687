<div class="row justify-content-center mt-2">
  <div class="col-4">
    <div class="row pl-4">
      <div class="col-10">
        <h4 class="pl-1  justify-content-lg-start ">
          {{'navBar.manual' | translate }}
          <button type="button" class="btn btn-info " *ngIf="isAdmin() && selectedFile" (click)="uploadFile()">
            {{'upload' | translate }}
          </button>
        </h4>
        <div class="card m-12" *ngIf="isAdmin()">
          <div class="card-body" style="padding-left:20px;">
            <div class="row">
              <div class="col-12">
              </div>
            </div>
            <ngx-dropzone class="mt-1" (change)="onSelect($event)" [accept]="'.pdf'" [multiple]="false">
              <ngx-dropzone-label>Drop files here or click to upload.</ngx-dropzone-label>
              <ngx-dropzone-preview class="pdf-upload-box" *ngFor="let f of files" [removable]="true"
                                    (removed)="onRemove(f)">
               
                <ngx-dropzone-label >{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
              </ngx-dropzone-preview>
            </ngx-dropzone>
           
          </div>
        </div>
      </div>
      <div class="col-10">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>File</th>
              <th *ngIf="isAdmin()"></th>
            </tr>
          </thead>
          <tbody *ngIf="manualFiles && manualFiles.length > 0">
            <tr *ngFor="let file of manualFiles">
              <td [innerText]="file.replace('\\', '').replace('Files','').split('/')[1]" (click)="sanitizeUrl(file);"></td>
              <td  style="width: 1%;">
                <button (click)="viewFile(file)" class="btn btn-primary">View</button>
              </td>
              <td *ngIf="isAdmin()" style="width: 1%;">
                <button (click)="deleteFile(file)" class="btn btn-danger">{{'delete' | translate}}</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-7 card m-7" style="border:none;">
    <object class="pdf-view mt-6 col-11" *ngIf="pdfFile"  [attr.data]="pdfFile" type="application/pdf"  style="padding-top:57px;margin-left:50px;height:100%;"> </object>
    <div class="card-img-bottom col-2" style="border:none;" *ngIf="selectedManualFile">

    </div>
  </div>
  </div>
