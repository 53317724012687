import { DataService, DataServiceArgs } from './data.service';
import { DataApiResult } from './result';
import { HttpRequestOptions, getOptionsForDataKeyParams } from './api-http-options';
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export abstract class DataItemService<T> extends DataService<T, DataApiResult<T>> {
  protected apiResultStore: BehaviorSubject<DataApiResult<T>>;

  constructor(protected args: DataServiceArgs<T>) {
    super(args);
    this.apiResultStore = new BehaviorSubject<DataApiResult<T>>(null);
  }

  get dataResult() {
    return this.apiResultStore.asObservable().pipe(
      filter(d => d && !!d.data),
      map(d => d.data)
    );
  }

  protected setNextData(current: DataApiResult<T>) {
    this.apiResultStore.next(current);
    this.dataStore.next(current ? current.data : null);
  }

  get(data?: { [key: string]: any }, url?: string, options?: HttpRequestOptions) {
    return this.getData(url, getOptionsForDataKeyParams({ method: 'get', url, options, data }));
  }

  post(data?: { [key: string]: any }, url?: string, options?: HttpRequestOptions) {
    return this.postData(url, getOptionsForDataKeyParams({ method: 'post', url, options, data }));
  }

  put(data?: { [key: string]: any }, url?: string, options?: HttpRequestOptions) {
    return this.putData(url, getOptionsForDataKeyParams({ method: 'put', url, options, data }));
  }

  delete(data?: { [key: string]: any }, url?: string, options?: HttpRequestOptions) {
    return this.deleteData(url, getOptionsForDataKeyParams({ method: 'delete', url, options, data }));
  }
}
