import { Component, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { BaseListComponent } from '../common/components/base-list.component';
import { Customer } from '../models';
import { Logger } from '../common/logging/logger.service';
import { CustomerListService } from './customer-list.service';
import { Filters } from '../common/data/result';
import { AuthService } from '../common/auth/auth.service';
import { LocalStorageService } from 'angular-2-local-storage';

@Component({
  selector: 'ecg-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent extends BaseListComponent<Customer> {

  constructor(service: CustomerListService,
    spinner: NgxSpinnerService,
    router: Router,
    route: ActivatedRoute,
    public logger: Logger,
    public auth: AuthService,
  ) {
    super({
      service, spinner, router, route,
      idColumn: 'id',
      formRoute: '/customer',
      namePipe: (d) => d && d.name ? d.name : d.id.toString()
    });


  }
}
