import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../common/auth/auth.service';
import { BaseFormComponent } from '../../common/components/base-form.component';
import { Logger } from '../../common/logging/logger.service';
import { Report } from '../../models/report';
import { ToastService } from '../../shared/services/toast.service';
import { Location } from "@angular/common";
import { ReportErrorFormEditService } from './report-error-form-edit.service';
class ImageSnippet {
  pending = false;
  status = "init";

  constructor(public src: string, public file: File) {}
}

@Component({
  selector: "report-error-form-edit",
  templateUrl: "./report-error-form-edit.component.html",
  styleUrls: ["./report-error-form-edit.component.scss"],
})

export class ReportErrorFormEditComponent extends BaseFormComponent<Report> implements  OnInit {
  report:Report;
  route: ActivatedRoute;
  router: Router;

  constructor(
    public reportListService: ReportErrorFormEditService,
    router: Router,
    route: ActivatedRoute,
    spinner: NgxSpinnerService,
    logger: Logger,
    location: Location,
    public sanitizer: DomSanitizer,
    public auth: AuthService,
    protected toast: ToastService,
   ) {
      super({
        service : reportListService,
        spinner,
        location,
        route,
        router,
        logger,
        toast,
      });
      this.refreshLog();
    }

  ngOnInit() {
    
  }

  refreshLog() {
    this.reportListService
      .getItemId(
        this.route.snapshot.queryParams.id
      )
      .subscribe((res) => {
        this.report = res.data;
      });
  }
 

}
