import { Component, OnInit } from '@angular/core';
import { AuthApiHttpClient } from '../common/auth/auth-api-http-client';
  import Chart from 'chart.js';
import { CpuRoot } from '../models/cpuRoot';
import { DiskRoot } from '../models/diskRoot';
import { MemoryRoot } from '../models/memoryRoot';
import { NetworkRoot } from '../models/networkRoot';
@Component({
  selector: 'ecg-server-health-page',
  templateUrl: './server-health-page.component.html',
  styleUrls: ['./server-health-page.component.scss']
})
export class ServerHealthPageComponent implements OnInit {
  cpu:CpuRoot[];
  disk:DiskRoot[];
  memory:MemoryRoot[];
  network:NetworkRoot[];
  doughnutChartLabels:string[];
  demodoughnutChartData:any[];
  doughnutChartType:string;
  disk2:string;
  disk2Name:string;
  memTotal:number;
  memUsed:number
  system:number;
  user:number;
  idle:number;
  ecgFile:string;
  size:string
  memPercent:string;
  constructor(public httpClient: AuthApiHttpClient){}
  ngOnInit() {
    this.getsrsmDisk();
    this.getSrsmMem();
    this.getSrsmNetw();
    this.getSrsmcpu();
  }
private getSrsmcpu()
{
  this.httpClient.get<CpuRoot[]>('ServerHealth/getSrsmcpu').subscribe(p=>{
  this.cpu=p;
  this.system =this.cpu[0].cpu[1].system+ this.cpu[0].cpu[3].wait;
  this.user = this.cpu[0].cpu[0].user;
  this.idle = this.cpu[0].cpu[2].idle;
});
}
private getSrsmMem()
{
  this.httpClient.get<MemoryRoot[]>('ServerHealth/getSrsmMem').subscribe(p=>{
  this.memory=p;
  this.memTotal = Math.round((this.memory[0].mem[0].memTotal)/1000)/1000;
  this.memUsed = Math.round((this.memory[0].mem[1].memUsed)/1000);
});
}
private getsrsmDisk()
{
  this.httpClient.get<DiskRoot[]>('ServerHealth/getsrsmDisk').subscribe(p=>{
    this.disk=p;
    this.disk2=this.disk[0].percent[1].percent.toString()+'%';
    this.disk2Name = this.disk[0].percent[0].mount;
    this.ecgFile= 'Ecg Files: '+ this.disk[0].ecgfile;
    this.size= this.disk[0].size[1].size+'b'
});
}
private getSrsmNetw()
{
  this.httpClient.get<NetworkRoot[]>('ServerHealth/getSrsmNetw').subscribe(p=>{
  this.network=p;
  this.loadLineChart();

});
}
loadLineChart()
{
  let networkTime = this.network.map(({ entry_datetime }) => entry_datetime);
  let received = this.network.map(({ received }) => Math.round((received /1000)/1000));
  let send = this.network.map(({ sent }) => Math.round((sent /1000)/1000));
  let errors = this.network.map(({ errors }) => Math.round((errors /1000)/1000));
  let dropped = this.network.map(({ dropped }) => Math.round((dropped /1000)/1000));
  let canvas:any = document.getElementById('myChart2');
  let ctx = canvas.getContext('2d');
  let myChart = new Chart(ctx, {
    type: 'line',
    data: {
      labels: networkTime,
      datasets: [
        {
          data: received,
          label: "Received",
          borderColor: "#3cba9f",
          fill: false
        }, {
          data: send,
          label: "Sent",
          borderColor: "#3aa8e0",
          fill: false
        }, {
          data: errors,
          label: "Errors",
          borderColor: "#c45850",
          fill: false
        },
         {
          data: dropped,
          label: "Dropped",
          borderColor: "#c93852",
          fill: false
        }
      ]
    },
    options: {
      title: {
        display: true,
        text: 'Network information (Mb)'
      },
      scales: {
        xAxes: [{
          type: 'time'
        }]
      },
      responsive:true,
      maintainAspectRatio: false,
    }
  });
}
}
