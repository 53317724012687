import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import { AuthService } from './auth.service';
import { ApiHttpClient } from '../data/api-http-client';
import { HttpMethod, HttpRequestOptions } from '../data/api-http-options';
import {Router} from "@angular/router";

@Injectable({ providedIn: 'root' })
export class AuthApiHttpClient extends ApiHttpClient {
  constructor(http: HttpClient, public auth: AuthService, public router: Router) {
    super(http);
  }

  request<T>(
    method: HttpMethod, body: any, url?: string, options: HttpRequestOptions = ApiHttpClient.getDefaultOptions()
  ): Observable<T> {
    return this.auth.getAccessToken().pipe(
      switchMap(at => {
        if (at) {
          options.headers = options.headers || new HttpHeaders();
          options.headers = options.headers.set('Authorization', `Bearer ${at}`);
          return super.request<T>(method, body, url, options).pipe( tap(() => {},
            (err: any) => {
              if (err instanceof HttpErrorResponse) {
                if (err.status !== 401) {
                  return;
                }
                this.auth.saveToken(null);
                this.router.navigate(['login']);
              }
            }));
        }
        return EMPTY;
      })
    );
  }

  requestBlob(
    method: HttpMethod, body: any, url?: string, options: HttpRequestOptions = ApiHttpClient.getDefaultOptions('blob')
  ): Observable<Blob> {
    return this.auth.getAccessToken().pipe(
      switchMap(at => {
        if (at) {
          options.headers = options.headers || new HttpHeaders();
          options.headers = options.headers.set('Authorization', `Bearer ${at}`);
          return super.requestBlob(method, body, url, options);
        }
        return EMPTY;
      })
    );
  }

}
