<ng-container>
    <div class="nexo-root container" style=" margin-top: calc(50vh - 295px);" *ngIf="step!='recordsOrCradle'">
      <div class="row content my-3">
        <div class="col-12">
          <form class="form-signin col-4">
            <div class="login-logo-area">
              <img class="logo-image" src="assets/images/login-logo.png" i18n-alt alt="Login Logo" />
            </div>
            <p></p>
            <div *ngIf="step=='branch'" class="form-label-group">
              <label class="required" i18n> {{'cradleForm.branch' | translate }}</label>
              <select class="custom-select" id="branch" name="branch"
                      #branch="ngModel" [(ngModel)]='branchId'
                      (ngModelChange)="changeBranch($event)" required>
                <option *ngFor="let branch of branchList" selected [value]="branch.id">
                  {{branch.branchName}}
                </option>
              </select>
            </div>
  
            <div *ngIf="step=='cradleChoose'" class="form-label-group">
              <label class="required" i18n> {{'cradleForm.rooms' | translate }}</label>
              <select class="custom-select" id="cradles" name="cradles"
                      #branch="ngModel" [(ngModel)]='cradleSerialNumber'
                      (ngModelChange)="changeCradle($event)" required>
                <option *ngFor="let cradle of cradleList" selected [value]="cradle.cradleSerialNumber">
                  {{cradle.room}} - {{cradle.cradleSnNumber}}
                </option>
              </select>
            </div>
  
            <p class="text-danger ml-4" style="margin-top:10px" *ngIf="showMessage"> {{message}} </p>
            <div class="checkbox mb-3">
            </div>
            <hr />
            <div class="form-label-group">
              <button *ngIf="step=='branch'" [disabled]="!branchId" class="btn btn-lg btn-primary btn-block" type="submit"
                      (click)="nextCradleChoose(true)"
                      i18n>
                {{'loginPage.next' | translate}}
              </button>
              <button *ngIf="step=='cradleChoose'" [disabled]="!cradleSerialNumber || !nextButtonActive" class="btn btn-lg btn-primary btn-block" type="submit"
                      (click)="usedCradleControl(true)"
                      i18n>
                {{'loginPage.next' | translate}}
              </button>
              <button *ngIf="step=='cradleChoose' && takeOverCradle" [disabled]="!cradleSerialNumber" class="btn btn-lg btn-primary btn-block" type="submit"
                      (click)="modal.open(confirmTakeOverModal)"
                      i18n>
                {{'loginPage.takeOverCradle' | translate}}
              </button>
              <button *ngIf="step=='cradleChoose' && showBackButton" class="btn btn-lg btn-light btn-block"
                      (click)="backBranchChoose()"
                      i18n>
                {{'loginPage.back' | translate}}
              </button>
              <button *ngIf="isSuperUserOrCradleWithout()" class="btn btn-lg btn-light btn-block btn-sm" (click)="skipCradleChoose();"
                      type="submit" i18n>
                {{'skip' | translate}}
              </button>
            </div>
            <hr>
            <hr />
            <div class="login-footer-area">
              <p>Fysiologic&#174; ECGAccess &copy; {{year}}</p>
              <small>ECGAccess Version <span *ngIf="version">{{version.appVersion}}</span></small>
            </div>
          </form>
        </div>
      </div> 
    </div>
  </ng-container>


<ng-template #confirmTakeOverModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title" i18n>{{'loginPage.takeOverCradle' | translate }}</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
            (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p><strong i18n>{{'takeOverModal1' | translate }} {{connectedUserName}} {{'takeOverModal2' | translate }}</strong></p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-info" (click)="setUserBranchandCradle();modal.dismiss()"
            i18n>
      {{'profileForm.ok' | translate }}
    </button>
    <button type="button" ngbAutofocus class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')" i18n>
      {{'cancel' | translate}}
    </button>
  </div>
</ng-template>
