import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'ecg-access-permission',
  templateUrl: './access-permission.component.html',
  styleUrls: ['./access-permission.component.scss']
})
export class AccessPermissionComponent implements OnInit, OnDestroy {

  permission = false;
  ipAddressKey = {key: '', ipAddress: ''};

  constructor(public route: ActivatedRoute, public httpClient: HttpClient, public translate:TranslateService) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.ipAddressKey.ipAddress = params['ipAddress'];
      this.ipAddressKey.key = params['key'];
      this.httpClient.post('api/v1/auth/ipwhitelist/access-permission', this.ipAddressKey).subscribe(res => {
        if (res) {
          this.permission = true;
        } else {
          this.permission = false;
        }
      });
    });

  }

  ngOnDestroy() {
  }
}
