import { BaseListComponent } from '../common/components/base-list.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../common/auth/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastService } from '../shared/services/toast.service';
import { Component, OnInit } from '@angular/core';
import { Note } from '../models/note';
import { NoteListService } from './note-list.service';

@Component({
  selector: 'ecg-note-list',
  templateUrl: './note-list.component.html',
  styleUrls: ['./note-list.component.scss'],
})
export class NoteListComponent extends BaseListComponent<Note> implements OnInit {

  constructor(
    public service: NoteListService,
    spinner: NgxSpinnerService,
    router: Router,
    route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public auth: AuthService,
    protected toast: ToastService
  ) {
    super({
      service,
      spinner,
      router,
      route,
      idColumn: 'id',
      formRoute: '/notes'
    });


  }
}
