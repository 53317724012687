import { DataItemService } from '../common/data/data-item.service';
import * as i0 from "@angular/core";
import * as i1 from "../common/auth/auth-api-http-client";
import * as i2 from "@angular/router";
export class NoteItemService extends DataItemService {
    constructor(httpClient, route) {
        super({
            httpClient,
            url: 'health/notes/item'
        });
        this.hospitalList = this.httpClient.get('health/cradles/customerCodes');
    }
    getBranchListService(customerId) {
        this.branchList = this.httpClient.get('health/cradles/customerBranchs/' + customerId);
    }
    getHospitalUser(id, paging) {
        this.hospitalUserList = this.httpClient.get('auth/useraccounts/customerusers?customerId=' + id + '&size=' + paging.size + '&index=' + paging.index + (paging.orders ? '&orders=' + paging.orders : ''));
    }
}
NoteItemService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NoteItemService_Factory() { return new NoteItemService(i0.ɵɵinject(i1.AuthApiHttpClient), i0.ɵɵinject(i2.ActivatedRoute)); }, token: NoteItemService, providedIn: "root" });
