/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./logout-modal.component";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@ngx-translate/core";
var styles_LogoutModalComponent = [];
var RenderType_LogoutModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LogoutModalComponent, data: {} });
export { RenderType_LogoutModalComponent as RenderType_LogoutModalComponent };
export function View_LogoutModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-sign-out fa-5x mt-5"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "p", [["class", "m-2 mb-5"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 3, 0, currVal_0); }); }
export function View_LogoutModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ecg-logout-modal", [], null, null, null, View_LogoutModalComponent_0, RenderType_LogoutModalComponent)), i0.ɵdid(1, 114688, null, 0, i1.LogoutModalComponent, [i2.NgbActiveModal, i3.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogoutModalComponentNgFactory = i0.ɵccf("ecg-logout-modal", i1.LogoutModalComponent, View_LogoutModalComponent_Host_0, { inheritingUserName: "inheritingUserName", operation: "operation" }, {}, []);
export { LogoutModalComponentNgFactory as LogoutModalComponentNgFactory };
