import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Log, LogParams, LogLevel } from './log';

@Injectable({ providedIn: 'root' })
export class Logger {
  private subject: Subject<Log>;

  get asObservable(): Observable<Log> {
    return this.subject.asObservable();
  }

  get uiObservable(): Observable<Log> {
    return this.subject
      .asObservable()
      .pipe(filter(l => l.params && l.params.find(p => p === LogParams.ui)));
  }

  constructor() {
    this.subject = new Subject<Log>();
  }

  log(logMessage?: any, ...logParams: any[]) {
    this.subject.next({
      level: LogLevel.log,
      message: logMessage,
      params: logParams
    });
  }

  info(logMessage?: any, ...logParams: any[]) {
    this.subject.next({
      level: LogLevel.info,
      message: logMessage,
      params: logParams
    });
  }

  warn(logMessage?: any, ...logParams: any[]) {
    this.subject.next({
      level: LogLevel.warn,
      message: logMessage,
      params: logParams
    });
  }

  error(logMessage?: any, ...logParams: any[]) {
    this.subject.next({
      level: LogLevel.error,
      message: logMessage,
      params: logParams
    });
  }
}
