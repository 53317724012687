import { DataListService } from '../common/data/data-list.service';
import * as i0 from "@angular/core";
import * as i1 from "../common/auth/auth-api-http-client";
export class NoteListService extends DataListService {
    constructor(httpClient) {
        super({
            httpClient,
            url: 'health/notes',
            initial: []
        });
    }
}
NoteListService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NoteListService_Factory() { return new NoteListService(i0.ɵɵinject(i1.AuthApiHttpClient)); }, token: NoteListService, providedIn: "root" });
