import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'string'
})
export class StringPipe implements PipeTransform {

  transform(value: any, ...args: any[]): string {
    return `${value || ''}`.concat(...args.map(arg => `${arg || ''}`)).trim();
  }

}
