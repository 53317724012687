import { Component, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { BaseFormComponent } from '../common/components/base-form.component';
import { Logger } from '../common/logging/logger.service';
import { Diary, Patient } from '../models';

import { DiaryItemService } from './diary-item.service';
import { ToastService } from '../shared/services/toast.service';
import { NgbModal, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DiaryActivity } from '../models/diary-activity';

@Component({
  selector: 'ecg-diary-form',
  templateUrl: './diary-form.component.html',
  styleUrls: ['./diary-form.component.scss']
})
export class DiaryFormComponent extends BaseFormComponent<Diary> implements OnDestroy {
  diaryActivity: DiaryActivity = {} as any;
  startTime: NgbTimeStruct = { hour: 0, minute: 0, second: 0 };
  activityDuration = 0;
  patientSubject = new BehaviorSubject<Patient>(null);
  patientSubscription: Subscription;
  constructor(
    public diaryService: DiaryItemService, logger: Logger, location: Location,
    route: ActivatedRoute, router: Router,
    spinner: NgxSpinnerService, toast: ToastService,
    public modal: NgbModal
  ) {
    super({ service: diaryService, logger, location, route, router, spinner, toast });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.patientSubscription) {
      this.patientSubscription.unsubscribe();
    }
  }

  setData(model: Diary) {
    super.setData(model);
    if (this.patientSubscription) {
      this.patientSubscription.unsubscribe();
    }
    if (model && model.patient && model.patient.id) {
      this.patientSubject.next(model.patient);
    } else if (model && model.patientId) {
      this.patientSubscription = this.diaryService.patient(model.patientId).subscribe(
        r => this.patientSubject.next(r),
        e => this.patientSubject.error(e),
        () => this.patientSubject.complete()
      );
    }
  }

  setInitialDataValue(model: Diary) {
    model.patientId = this.queryParams.patientId;
    super.setInitialDataValue(model);
  }

  addActivity() {
    this.diaryActivity.diaryId = this.model.id;
    const newActivity = JSON.parse(JSON.stringify(this.diaryActivity));
    this.model.activities = this.model.activities || [];
    this.model.activities.push(newActivity);
    this.diaryActivity = {} as any;
    this.startTime = { hour: 0, minute: 0, second: 0 };
    this.activityDuration = 0;
  }

  removeActivity(index) {
    this.model.activities = this.model.activities || [];
    this.model.activities = this.model.activities.filter((v, i, a) => i !== index);
  }

  setStartTime() {
    let startDateTime: Date = this.diaryActivity.startDateTime && typeof this.diaryActivity.startDateTime === 'string' ?
      new Date(this.diaryActivity.startDateTime) :
      this.diaryActivity.startDateTime as any;
    startDateTime = startDateTime || new Date();
    startDateTime.setHours(this.startTime.hour, this.startTime.minute, this.startTime.second);
    this.diaryActivity.startDateTime = startDateTime as any;
    this.setEndTime();
  }

  setEndTime() {
    let startDateTime: Date = this.diaryActivity.startDateTime && typeof this.diaryActivity.startDateTime === 'string' ?
      new Date(this.diaryActivity.startDateTime) :
      this.diaryActivity.startDateTime as any;
    startDateTime = startDateTime || new Date();
    startDateTime.setHours(this.startTime.hour, this.startTime.minute, this.startTime.second);
    const endDateTime = new Date(startDateTime.getTime() + (this.activityDuration * 60000));
    this.diaryActivity.endDateTime = endDateTime as any;
  }

  activityFormValid(form) {
    if (super.formValid(form)) {
      return this.activityDuration > 0;
    }
    return false;
  }
}
