import { Injectable } from '@angular/core';
import { AuthApiHttpClient } from 'src/app/common/auth/auth-api-http-client';
import { AuthService } from 'src/app/common/auth/auth.service';
import { CradleUsedControl } from 'src/app/models/cradleUsedControl';

@Injectable({ providedIn: 'root' })
export class BranchChooseService {
  public authUser: any;
  branchListService: any;
  branchIdService: any;
  setUserBranchAndCradleService: any;
  getCradleByBranch: any;
  getCradleByCustomer: any;
  setUserBranch: any;
  usedControl: any;
  createCradleTakeOverLog: any;

  constructor(public httpClient: AuthApiHttpClient, auth: AuthService) {
    this.authUser = auth.user;
  }

  getBranchListService() {
    this.branchListService = this.httpClient.get<any>('health/customers/get-branchs-by-customer/' + this.authUser.customerId);
  }

  getBranchIdService() {
    this.branchIdService = this.httpClient.get<any>('health/customers/get-branchId-by-user/' + this.authUser.id)
  }

  getSetUserBranchAndCradle(branchId, cradleSerialNumberForBranch, mac, uuid) {
    this.setUserBranchAndCradleService = this.httpClient.post({}, 'auth/useraccounts/set-user-branch-and-cradle/' + branchId + '/' + cradleSerialNumberForBranch + '/' + mac + '/' + uuid)
  }

  getGetCradlesByBranch(branchId) {
    this.getCradleByBranch = this.httpClient.get<any>('auth/useraccounts/get-cradles-by-branch/' + branchId);
  }

  getGetCradlesByCustomer(customerId) {
    this.getCradleByCustomer = this.httpClient.get<any>('auth/useraccounts/get-cradles-by-customer/' + customerId);
  }

  getSetUserBranch(branchId) {
    this.setUserBranch = this.httpClient.post({}, 'auth/useraccounts/set-user-branch/' + branchId);
  }

  getUsedControl(cradleSerialNumberForBranch) {
    this.usedControl = this.httpClient.get<CradleUsedControl>('auth/useraccounts/used-cradle-control/' + cradleSerialNumberForBranch);
  }

  getCradleTakeOverLogCrateService(previousUserName, inheritingUserName, cradleSerialNumber) {
    this.createCradleTakeOverLog = this.httpClient.post({}, 'auth/useraccounts/create-log-takeOverCradle/' + previousUserName + "/" + inheritingUserName + "/" + cradleSerialNumber);
  }
}
