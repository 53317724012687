import { DataListService } from 'src/app/common/data/data-list.service';
import * as i0 from "@angular/core";
import * as i1 from "../../common/auth/auth-api-http-client";
export class InvoiceUploadService extends DataListService {
    constructor(httpClient) {
        super({
            httpClient,
            url: 'invoice/invoiceupload',
            initial: []
        });
    }
}
InvoiceUploadService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InvoiceUploadService_Factory() { return new InvoiceUploadService(i0.ɵɵinject(i1.AuthApiHttpClient)); }, token: InvoiceUploadService, providedIn: "root" });
