import {Component, HostListener, Input} from '@angular/core';
import {
  NgbDate,
  NgbDateAdapter,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {BaseControlValueAccessor} from './base-control-value.accessor';

@Component({
  selector: 'ecg-range-datepicker',
  templateUrl: './range-datepicker.component.html',
  styleUrls: ['./range-datepicker.component.scss'],
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: RangeDatepickerComponent, multi: true}]
})
export class RangeDatepickerComponent extends BaseControlValueAccessor<[Date, Date]> {
  @Input()
  placeholder = '';
  @Input()
  maxDate: Date = new Date();
  @Input()
  minDate: Date = new Date(new Date().setFullYear(1880)); // MSSQL Default date
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  constructor(public formatter: NgbDateParserFormatter, private adapter: NgbDateAdapter<Date>) {
    super();
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.onChange.emit([this.adapter.toModel(this.fromDate), this.adapter.toModel(this.toDate)]);
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  getDate(fromDate: NgbDate, toDate: NgbDate) {
    const formattedDate = this.formatter.format(fromDate);
    if (toDate) {
      return `${formattedDate} - ${this.formatter.format(toDate)}`;
    }
    return formattedDate;
  }
  @HostListener('keyup.backspace')
  @HostListener('keyup.delete')
  clearDate() {
    this.fromDate = this.toDate = undefined;
    this.onChange.emit([undefined, undefined]);
  }

  writeValue(obj: any): void {
    this.fromDate = this.toDate = undefined;
    if (obj && obj.length) {
      const [start, end]: [Date, Date] = obj;
      this.fromDate = NgbDate.from(this.adapter.fromModel(start));
      this.toDate = NgbDate.from(this.adapter.fromModel(end));
    }
  }

  setDisabledState(isDisabled: boolean): void {
  }
}

