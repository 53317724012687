<ng-container *ngIf="report">
    <nav class="navbar ecg-fixed-nav fixed-top navbar-light bg-light">
        <div class="col-xs-2">
            <ul class="nav navbar px-0">
                <li class="m-1">
                    <button type="button" class="btn btn-secondary" routerLink="/report" i18n>{{'back' | translate
                        }}</button>
                </li>
            </ul>
        </div>
        <div class="col-12 align-self-center text-center">
            <h3 i18n>{{'profileForm.report' | translate }}</h3>
        </div>

    </nav>
    <form #mainForm="ngForm" style="margin-top:100px">
        <div>
            <div class="col-lg" style="flex-direction: column; display: flex">
                <div class="card m-3">
                    <tr class="card-body">
                        <div class="row no-gutters">
                            <div class="form-group col-xl-5 col-lg-6  col-md-7 col-sm-9">
                                <div>
                                    <label for="name" style="font-weight: bold;" i18n>{{'GId' |
                                        translate }}:&nbsp;</label>
                                    <label>{{report.GId}}</label>
                                </div>
                                <div>
                                    <label for="name" style="font-weight: bold;" i18n>{{'Project' |
                                        translate }}:&nbsp;</label>
                                    <label>{{report.Project}}</label>
                                </div>
                                <div>
                                    <label for="name" style="font-weight: bold;" i18n>{{'Module' |
                                        translate }}:&nbsp;</label>
                                    <label>{{report.Module}}</label>
                                </div>
                                <div>
                                    <label for="name" style="font-weight: bold;" i18n>{{'Message' |
                                        translate }}:&nbsp;</label>
                                    <label>{{report.Message}}</label>
                                </div>
                                <div>
                                    <label for="name" style="font-weight: bold;" i18n>{{'IsTraceEnd' |
                                        translate }}:&nbsp;</label>
                                    <label>{{report.isTraceEnd}}</label>
                                </div>
                                <div>
                                    <label for="name" style="font-weight: bold;" i18n>{{'IsSendMail' |
                                        translate }}:&nbsp;</label>
                                    <label>{{report.isSendMail}}</label>
                                </div>

                            </div>
                        </div>
                    </tr>
                </div>
            </div>
        </div>
    </form>


</ng-container>
