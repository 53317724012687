import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {BaseListComponent} from '../common/components/base-list.component';
import { UserAccount } from '../models';
import { Logger } from '../common/logging/logger.service';
import {UserAccountListService} from '../sysadmin/user-account/user-account-list.service';
 import {PcListService} from "./pc-list.service";

@Component({
  selector: 'ecg-pc-list',
  templateUrl: './pc-list.component.html',
  styleUrls: ['./pc-list.component.scss']
})
export class PcListComponent extends BaseListComponent<UserAccount> {
  private serviceData: any;
  public userAccounts: any;
    constructor(service: PcListService, spinner: NgxSpinnerService, router: Router, route: ActivatedRoute, public logger: Logger) {
    super({
      service, spinner, router, route,
      idColumn: 'id',
      formRoute: '/pc',
      namePipe: (d) => d && d.name ? d.name : d.id.toString()
    });
  }
}
