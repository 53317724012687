

<div class="container">
  <div class="row mt-5">
    <div class="col-md-12">

      <div class="card p-5">
        <h3 class="card-title">
          {{'serverHealth.appServer' | translate}}  <small>[Uptime: {{appuptime?.deviceType}}]</small>
        </h3>
        <div class="row mt-2">
          <div class="col-md-12" *ngIf="appServerDevices.length==0">

            <h3 class="text-center">{{'serverHealth.nodata' | translate}}!</h3>
          </div>
          <div class="col-md-4" *ngFor="let d of appServerDevices">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title" style="text-align: center;">
                  {{d.deviceType | translate }}
                </h5>
                <div class="progressBar" id="{{getPercent(d)*10 | number:'1.0-0'}}">
                  <span class="progress-left">
                    <span class="progress-bar"></span>
                  </span>
                  <span class="progress-right">
                    <span class="progress-bar"></span>
                  </span>
                  <div class="progress-value">
                    <div>
                      {{getPercent(d) | number:'1.2-2'}}%<br>

                    </div>
                  </div>
                </div>
                <div class="col-md-12 text-center">
                  <small i18n>{{'serverHealth.WorkingDate' | translate}}: {{d.entryDate | date:'d-M-yyyy , HH:mm '}}</small>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="row" style="margin-top: 20px;">
          <div class="col-md-2">
            <h3>Services</h3>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4" *ngFor="let s of workerServiceState">
            <div class="workerServiceState.length > 3 ? mb-3 card p-3 : card p-3">
              <h5 class="car-title text-center">{{s.serviceName}}</h5>
              <div *ngIf="s.durationType==1" class="row">
                <div class="col-md-12 text-center pt-3">
                  <i class="fa fa-circle fa-2x" [ngClass]="s.durationMinutes<20?'text-success':'text-danger'"></i>
                </div>
                <div class="col-md-12 text-center">
                  <!-- <small i18n>{{'serverHealth.WorkingDate' | translate}}: {{s.workDate | date:'d-M-yyyy , HH:mm '}} <i class="fa fa-info" title="{{s.serviceName}}" style="cursor:pointer;"></i></small>
                   -->
                   <!-- <small  i18n>{{'serverHealth.WorkingDate' | translate}}: {{s.workDate | date:'d-M-yyyy , HH:mm '}} </small> &nbsp; <i ngbTooltip="{{s.serviceDescription}}" class='fa fa-info' style="cursor:pointer;" ></i> -->

                   <small  i18n>{{'serverHealth.WorkingDate' | translate}}: {{s.workDate | date:'d-M-yyyy , HH:mm '}} </small> &nbsp; <img width="24px" ngbTooltip="{{s.serviceDescription}}"  src="assets/images/health_info.png"  class="w-10">

                </div>
              </div>

              <div *ngIf="s.durationType==2" class="row">
                <div class="col-md-12 text-center pt-3">
                  <i class="fa fa-circle fa-2x" [ngClass]="s.durationHours<=24?'text-success':'text-danger'"></i>
                </div>
                <div class="col-md-12 text-center">
                  <!-- <small i18n>{{'serverHealth.WorkingDate' | translate}}: {{s.workDate | date:'d-M-yyyy , HH:mm '}} <i class="fa fa-info" title="{{s.serviceName}}" style="cursor:pointer;"></i></small>
                   -->
                   <!-- <small  i18n>{{'serverHealth.WorkingDate' | translate}}: {{s.workDate | date:'d-M-yyyy , HH:mm '}} </small> &nbsp; <i ngbTooltip="{{s.serviceDescription}}" class='fa fa-info' style="cursor:pointer;" ></i> -->

                   <small  i18n>{{'serverHealth.WorkingDate' | translate}}: {{s.workDate | date:'d-M-yyyy , HH:mm '}} </small> &nbsp; <img width="24px" ngbTooltip="{{s.serviceDescription}}"  src="assets/images/health_info.png"  class="w-10">

                </div>
              </div>

            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-md-6">
            <h3>Integration Services</h3>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4" *ngFor="let s of integrationWorkerServiceState">
            <div class="integrationWorkerServiceState.length > 3 ? mb-3 card p-3 : card p-3">
              <h5 class="car-title text-center">{{s.serviceName}}</h5>
              <div *ngIf="s.durationType==1" class="row">
                <div class="col-md-12 text-center pt-3">
                  <i class="fa fa-circle fa-2x" [ngClass]="s.durationMinutes<20?'text-success':'text-danger'"></i>
                </div>
                <div class="col-md-12 text-center">
                  <!-- <small i18n>{{'serverHealth.WorkingDate' | translate}}: {{s.workDate | date:'d-M-yyyy , HH:mm '}} <i class="fa fa-info" title="{{s.serviceName}}" style="cursor:pointer;"></i></small>
                   -->
                   <!-- <small  i18n>{{'serverHealth.WorkingDate' | translate}}: {{s.workDate | date:'d-M-yyyy , HH:mm '}} </small> &nbsp; <i ngbTooltip="{{s.serviceDescription}}" class='fa fa-info' style="cursor:pointer;" ></i> -->

                   <small  i18n>{{'serverHealth.WorkingDate' | translate}}: {{s.workDate | date:'d-M-yyyy , HH:mm '}} </small> &nbsp; <img width="24px" ngbTooltip="{{s.serviceDescription}}"  src="assets/images/health_info.png"  class="w-10">

                </div>
              </div>

              <div *ngIf="s.durationType==2" class="row">
                <div class="col-md-12 text-center pt-3">
                  <i class="fa fa-circle fa-2x" [ngClass]="s.durationHours<=24?'text-success':'text-danger'"></i>
                </div>
                <div class="col-md-12 text-center">
                  <!-- <small i18n>{{'serverHealth.WorkingDate' | translate}}: {{s.workDate | date:'d-M-yyyy , HH:mm '}} <i class="fa fa-info" title="{{s.serviceName}}" style="cursor:pointer;"></i></small>
                   -->
                   <!-- <small  i18n>{{'serverHealth.WorkingDate' | translate}}: {{s.workDate | date:'d-M-yyyy , HH:mm '}} </small> &nbsp; <i ngbTooltip="{{s.serviceDescription}}" class='fa fa-info' style="cursor:pointer;" ></i> -->

                   <small  i18n>{{'serverHealth.WorkingDate' | translate}}: {{s.workDate | date:'d-M-yyyy , HH:mm '}} </small> &nbsp; <img width="24px" ngbTooltip="{{s.serviceDescription}}"  src="assets/images/health_info.png"  class="w-10">

                </div>
              </div>

            </div>
          </div>
        </div>



      </div>

    </div>
  </div>

  <div class="row mt-5">
    <div class="col-md-12">
      <div class="card p-5">
        <h3>
          {{'serverHealth.fileServer' | translate}}  <small>[Uptime: {{fileuptime?.deviceType}}]</small>
        </h3>

        <div class="row">
          <div class="col-md-12" *ngIf="fileServerDevicesSorted.length==0">

            <h3 class="text-center">{{'serverHealth.nodata' | translate}}!</h3>
          </div>
          <div class="col-md-4" *ngFor="let d of fileServerDevicesSorted">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title" style="text-align: center;">
                  {{d.deviceType | translate }}
                </h5>
                <div class="progressBar" id="{{getPercent(d)*10 | number:'1.0-0'}}">
                  <span class="progress-left">
                    <span class="progress-bar"></span>
                  </span>
                  <span class="progress-right">
                    <span class="progress-bar"></span>
                  </span>
                  <div class="progress-value">
                    <div>
                      {{getPercent(d) | number:'1.2-2'}}%<br>

                    </div>
                  </div>
                </div>
                <div class="col-md-12 text-center">
                  <small i18n>{{'serverHealth.WorkingDate' | translate}}: {{d.entryDate | date:'d-M-yyyy , HH:mm '}}</small>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>


