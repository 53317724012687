export enum LogLevel {
  log,
  info,
  warn,
  error
}

export enum LogParams {
  ui = 'ui'
}

export interface Log {
  message?: any;
  level: LogLevel;
  params?: any[];
}
