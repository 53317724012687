import { Injectable } from '@angular/core';
import { DataListService } from 'src/app/common/data/data-list.service';
import { DashboardOrganisation } from 'src/app/models/dashboardOrganisation';
import { AuthApiHttpClient } from 'src/app/common/auth/auth-api-http-client';
import { Paging, SearchParam } from 'src/app/common/data/result';
import { HttpRequestOptions } from 'src/app/common/data/api-http-options';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends DataListService<DashboardOrganisation>{

  constructor(httpClient: AuthApiHttpClient) {
    super({
      httpClient,
      url: 'health/dashboard',
      initial: []
    });
  }
  getSearch(paging?: Paging, params?: SearchParam, url?: string, options?: HttpRequestOptions) {
    return super.getSearch(paging, params, url || "get-organisations", options);
  }

}