import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ecg-month-year-selector',
  templateUrl: './month-year-selector.component.html',
  styleUrls: ['./month-year-selector.component.scss']
})
export class MonthYearSelectorComponent {
  @Output() selectionChanged = new EventEmitter<{ month: number, year: number }>();

  months = [
    { name: this.translate.instant('months.one'), value: 1 },
    { name: this.translate.instant('months.two'), value: 2 },
    { name: this.translate.instant('months.three'), value: 3 },
    { name: this.translate.instant('months.four'), value: 4 },
    { name: this.translate.instant('months.five'), value: 5 },
    { name: this.translate.instant('months.six'), value: 6 },
    { name: this.translate.instant('months.seven'), value: 7 },
    { name: this.translate.instant('months.eight'), value: 8 },
    { name: this.translate.instant('months.nine'), value: 9 },
    { name: this.translate.instant('months.ten'), value: 10 },
    { name: this.translate.instant('months.eleven'), value: 11 },
    { name: this.translate.instant('months.twelve'), value: 12 }
  ];
  
  years: number[] = [];

  selectedMonth: number;
  selectedYear: number;

  constructor(public translate: TranslateService) {
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= currentYear - 10; i--) {
      this.years.push(i);
    }

    this.selectedMonth = 0;
    this.selectedYear = 0;
  }

  onSelectionChange() {
    this.selectionChanged.emit({ month: this.selectedMonth, year: this.selectedYear });
  }

}
