<div *ngIf="(service.data | async) as reports">
  <nav class="navbar navbar-light bg-light">
    <div class="row m-0 w-100">
      <div class="col-3 pl-0 pt-1 pr-0">
        <button class="btn btn-info mr-2" (click)="showFilter = !showFilter" i18n><i class="fa fa-filter"></i>
          {{'filterButton' | translate}}</button>
        <button type="button" class="btn btn-outline-secondary" (click)="filterReset();" *ngIf="showFilter"
          i18n>{{'filterReset' | translate }}</button>
      </div>
      <div class="col-6 align-self-center text-center">
        <h3 i18n>{{'profileForm.errorLogReport' | translate }}</h3>
      </div>
      <!-- <div class="col-3 pt-1 pr-0">
        <button type="button" class="btn btn-info float-right" (click)="add()" i18n>{{'profileForm.new' | translate }}</button>
      </div> -->
    </div>
  </nav>
  <div class="card" *ngIf="showFilter">
    <div class="card-body">
      <ecg-filter (filterChange)="pagingReset();filterChange($event);" (selectChange)="onErrorSelectChange($event)"
        [metaData]="filterMetaData" [reset]="reset"></ecg-filter>
    </div>
  </div>
  <div [class]="(dataCount | async) > 10 ? 'table-responsive' : 'table-responsive table-responsive-no-paginate'">
    <table class="table table-hover user-accounts-table" i18n-aria-describedby aria-describedby="doctor list">
      <thead>
        <tr>
          <th scope="col" sortable="gId" (sort)="onSort($event)"
            i18n>{{'profileForm.gId' | translate }}</th>
          <th *ngIf="!projectChoosed()" scope="col" i18n>
            {{'profileForm.project' | translate }}</th>
          <th *ngIf="!moduleChoosed()" scope="col" i18n>
            {{'profileForm.module' | translate }}</th>
          <th scope="col" sortable="Message" (sort)="onSort($event)" i18n>
            {{'profileForm.message' | translate }}</th>
          <th scope="col" sortable="isSendMail" (sort)="onSort($event)" i18n>
            {{'profileForm.isSendMail' | translate }}</th>
          <th scope="col" sortable="createdDateTime" (sort)="onSort($event)" i18n>
            {{'profileForm.createdate' | translate }}</th>
        </tr>
      </thead>
      <tbody *ngIf="reports && reports.length; else norecord">
        <tr *ngFor="let report of reports; let index=index" [tabindex]="index + 1" class="cursorProp">
          <td (click)="copyGId(report.GId)">{{ report.GId }}</td>
          <td *ngIf="!projectChoosed()">{{ report.Project}}</td>
          <td *ngIf="!moduleChoosed()">{{ report.Module}}</td>

          <td title="{{report?.Message}}" (click)="copyText(report?.Message)"> {{
            columnMessage(report?.Message) }}</td>
          <td> <input type="checkbox" class="" id="report.id"
              [checked]="report.isSendMail"></td>
          <td>{{ report.createdDateTime | date : 'd-M-yyyy HH:mm:ss' }}</td>

        </tr>
      </tbody>
      <ng-template #norecord>
        <tbody>
          <tr>
            <td colspan="10" class="text-danger" i18n>{{'patientList.noRecords' | translate }}</td>
          </tr>
        </tbody>
      </ng-template>
    </table>
  </div>
  <div *ngIf="(dataCount | async) as count">
    <div class="bg-ecg fix-to-bottom text-white px-3 pt-lg-3" *ngIf="count>=10">
      <div class="row justify-content-between w-100 m-0">
        <div class="col-12 d-block d-lg-none">
          <div class="pt-1 text-center" i18n>
            {{count}} {{'itemsIn' | translate }} {{this | func:pageCount:count:paging.size}} {{'pages' | translate}}
          </div>
        </div>
        <div class="col-auto pl-0 pr-0">
          <ngb-pagination [boundaryLinks]="true" [rotate]="true" [maxSize]="3" [(page)]="paging.index"
            [pageSize]="paging.size" [collectionSize]="count" (pageChange)="refresh(); pagingSave();"
            #pagination></ngb-pagination>
        </div>
        <div class="col-12 col-lg d-none d-lg-block">
          <div class="pt-2 text-center" i18n>
            {{count}} {{'itemsIn' | translate }} {{this | func:pageCount:count:paging.size}} {{'pages' | translate}}
          </div>
        </div>
        <div class="col pr-0 pl-0 pagination-dropdown-container">
          <div>
            <select class="custom-select pagination-dropdown cursorProp" [(ngModel)]="paging.size"
              (change)="refresh();pagingSave();">
              <option [ngValue]="10" i18n>{{'records10' | translate }}</option>
              <option selected [ngValue]="15" i18n>{{'records15' | translate }}</option>
              <option [ngValue]="20" i18n>{{'records20' | translate }}</option>
              <option [ngValue]="50" i18n>{{'records50' | translate }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
