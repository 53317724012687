import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'branchsArray'
})
export class BranchsArrayPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any[] {
    if (!value || (value && value.length < 1))
      return null;

    const response = value.filter(x => x.deletedDateTime == undefined);
    if (response.length < 1)
      return null;

    return response;
  }

}
