import { Injectable, TemplateRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: any[] = [];
  private success = { classname: 'bg-success text-light' };
  private danger = { classname: 'bg-danger text-light' };

  showSuccess(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    const newOptions = Object.assign({}, this.success, options);
    this.show(textOrTpl, newOptions);
  }

  showDanger(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    const newOptions = Object.assign({}, this.danger, options);
    this.show(textOrTpl, newOptions);
  }

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    if (textOrTpl !== undefined) {
      this.toasts.push({ textOrTpl, ...options });
    }
  }

  remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
}
