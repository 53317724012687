import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import Swal from 'sweetalert2';
import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js';
const FINGER_PRINT_KEY = "fingerPrintKey";
export class AuthenticationComponent {
    constructor(auth, httpClient, modalService, toast, translate, cookieService, router, storageMap, localStorageService) {
        this.auth = auth;
        this.httpClient = httpClient;
        this.modalService = modalService;
        this.toast = toast;
        this.translate = translate;
        this.cookieService = cookieService;
        this.router = router;
        this.storageMap = storageMap;
        this.localStorageService = localStorageService;
        this.onChange = new EventEmitter();
        this.isActivated = false;
        this.googleQr = false;
        this.emailCode = false;
        this.emailReceived = false;
        this.deactivateEmail = false;
        this.deactivateCode = "";
        this.deactivited = true;
        this.deviceKey = "";
        this.error = false;
        this.errorText = "";
        this.trustDays = 1;
        var fingerPrintKey = localStorage.getItem(FINGER_PRINT_KEY);
        if (fingerPrintKey) {
            this.deviceKey = fingerPrintKey;
            console.warn("loaded");
        }
        else {
            getCurrentBrowserFingerPrint().then((fingerprint) => {
                console.warn("created");
                this.deviceKey = fingerprint;
                localStorage.setItem(FINGER_PRINT_KEY, fingerprint);
            });
        }
    }
    ngOnInit() {
    }
    registerOnChange(fn) {
        this.onChange.subscribe(fn);
    }
    registerOnTouched(fn) {
    }
    writeValue(value) {
        this.model = value;
    }
    change($event) {
        this.model = $event;
        this.onChange.emit($event);
    }
    checkEmail() {
        this.emailReceived = false;
        if (this.emailCode) {
            this.showWait();
            this.httpClient.post({}, 'auth/authenticator/get-email-authenticator').subscribe(res => {
                Swal.close();
                if (res.data) {
                    this.emailReceived = true;
                    this.toast.showSuccess(this.translate.instant('loginPage.codeSentEmail'));
                }
            });
        }
    }
    checkAuth() {
        this.errorText = "";
        if (this.googleQr) {
            this.qrResponse = null;
            this.getQr();
        }
    }
    getQr() {
        this.showWait();
        this.httpClient.get('auth/authenticator/get-google-authenticator').subscribe(res => {
            this.qrResponse = res;
            Swal.close();
        });
    }
    verifyQr(key) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.error = false;
            const res = yield this.httpClient.post({ code: this.qrCode }, 'auth/authenticator/' + key).toPromise();
            if (res.data) {
                this.qrResponse = null;
                this.emailReceived = false;
                this.deactivited = true;
                this.isActivated = true;
                this.model.isActive = true;
                this.model.isActiveEmailAuth = true;
                yield this.auth.forceRefresh().toPromise();
                if (this.page && this.page === 'login') {
                    this.toast.showSuccess(this.translate.instant('profileForm.authenticationDeactivate'));
                    this.getUserLogIn();
                }
            }
            else {
                this.error = true;
                this.errorText = res.message;
            }
            Swal.close();
            this.deactivited = true;
        });
    }
    deactivateQr() {
        this.httpClient.post({}, 'auth/authenticator/authenticator-deactivate?userId=' + this.auth.user.id).subscribe(res => {
            if (res.data) {
                this.toast.showSuccess(this.translate.instant('loginPage.codeSentEmail'));
                this.deactivateEmail = true;
            }
            else {
                this.deactivateEmail = false;
                this.model.isActive = true;
                this.deactivited = true;
                this.toast.showDanger(res.message);
            }
        });
    }
    deactivateAuth() {
        this.httpClient.post({}, 'auth/authenticator/authenticator-deactivate-validate?emailCode=' + this.deactivateCode).subscribe(res => {
            if (res.data) {
                this.authDeactivated();
            }
            else {
                this.error = true;
                this.errorText = res.message;
            }
            Swal.close();
            this.deactivited = true;
        });
    }
    authDeactivated() {
        this.deactivateEmail = false;
        this.model.isActive = false;
        this.isActivated = false;
        this.model.isActiveGoogleAuth = false;
        this.model.isActiveEmailAuth = false;
        this.googleQr = false;
        this.emailCode = false;
        const currentDate = new Date().toISOString();
        this.model.authenticatorLogs = { name: this.model.name, updatedDateTime: currentDate };
    }
    open(content, modalSize) {
        this.modalService.open(content, { size: 'xl', centered: true });
    }
    showWait() {
        Swal.fire({
            position: 'center',
            imageUrl: 'assets/gifs/loading.gif',
            title: 'Please Wait...',
            showConfirmButton: false,
            timer: 44000,
        });
    }
    isAuthenticated() {
        return this.model.isActiveEmailAuth || this.model.isActiveGoogleAuth;
    }
    saveTrustDevice(day, never) {
        never = (day == 0 ? true : false);
        var model = {
            key: this.deviceKey,
            never: never
        };
        this.httpClient.post(model, 'auth/fingerprint/create-fingerprint?periodValidityDay=' + day).subscribe(res => {
            this.toast.showSuccess(this.translate.instant('profileForm.browserSaveSuccess'));
        });
    }
    shouldDisplaySkipAuthenticationButton() {
        return this.auth.hasRole(['System Admin', 'Super User']);
    }
    getUserLogIn() {
        this.cookieService.set('date', Date.now() / 1000 + '');
        if (this.auth.user !== null && this.auth.user.customerCodeId !== null) {
            localStorage.setItem('customerCodeId', this.auth.user.customerCodeId);
        }
        if (this.auth.user !== null && this.auth.user.username) {
            localStorage.setItem('UserEmail', this.auth.user.username);
        }
        if (this.auth.hasRole(['System Admin', 'Support', 'Support DK'])) {
            return this.router.navigate(['/cradles'], { queryParams: {} });
        }
        if (this.auth.hasRole(['Diary User'])) {
            return this.router.navigate(['/diary-upload'], { queryParams: {} });
        }
        if (this.auth.hasRole(['Staff Fysiologic'])) {
            return this.router.navigate(['/records'], { queryParams: {} });
        }
        if (this.auth.hasRole(['Finance', 'Financial Admin'])) {
            return this.router.navigate(['/specifications'], { queryParams: {} });
        }
        this.localStorageService.remove('cradleSerialNumber');
        sessionStorage.clear();
        this.storageMap.delete('step').subscribe(() => {
            let stepStorage = '';
            this.storageMap.get('step', { type: 'string' }).subscribe((result) => {
                stepStorage = result;
                if ((!stepStorage || stepStorage == null || stepStorage == 'branch' || stepStorage == 'cradleChoose') && !this.auth.hasRole(['Doctor Without Cradle', 'Super User', 'System Admin', 'Finance', 'Financial Admin'])) {
                    this.router.navigate(['/branch'], { queryParams: {} });
                }
                else {
                    if (this.auth.hasRole(['Super User'])) {
                        return this.router.navigate(['/cradles'], { queryParams: {} });
                    }
                    else {
                        return this.router.navigate(['/records'], { queryParams: {} });
                    }
                }
            });
        });
    }
}
