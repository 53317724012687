import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoicesComponent } from './invoices.component';
import { RouterModule, Routes } from '@angular/router';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { InvoiceUploadComponent } from './invoice-upload/invoice-upload.component';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MonthYearSelectorComponent } from './components/month-year-selector/month-year-selector.component';
import { DeleteInvoiceModalComponent } from './components/delete-invoice-modal/delete-invoice-modal.component';
import { DeleteInvoiceFileModalComponent } from './components/delete-invoice-file-modal/delete-invoice-file-modal.component';

const routes: Routes = [
  {
    path: '', component: InvoiceListComponent,
    data: { roles: ["System Admin", "Super User", "Finance", "Financial Admin"], },
  },
];

@NgModule({
  declarations: [
    InvoicesComponent,
    InvoiceListComponent,
    InvoiceUploadComponent,
    MonthYearSelectorComponent,
    DeleteInvoiceModalComponent,
    DeleteInvoiceFileModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild(),
    RouterModule.forChild(routes)
  ],
  entryComponents: [DeleteInvoiceModalComponent, DeleteInvoiceFileModalComponent]
})
export class InvoicesModule { }
