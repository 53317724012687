import { Component, TemplateRef, HostListener, HostBinding } from '@angular/core';
import { ToastService } from '../services/toast.service';

@Component({
  selector: 'ecg-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.scss']
})
export class ToastsComponent {
  @HostBinding('class.ngb-toasts') ngbToastsClass = true;

  constructor(public toastService: ToastService) { }

  isTemplate(toast) { return toast.textOrTpl instanceof TemplateRef; }

}
