
import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { Profile } from 'src/app/models';
import { ToastService } from '../../shared/services/toast.service';
import { AbstractControl, FormControl, NgModel, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/common/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Version } from '../../models/version';
import { getVersion } from '../../common/utilities/getVersion.utility'
import { HttpClient } from '@angular/common/http';
import { debug } from 'console';

@Component({
  selector: 'ecg-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit, AfterViewInit {
  @ViewChild('savedSuccessMessage', { static: false }) savedSuccessMessage: TemplateRef<any>;
  @ViewChild('savedErrorMessage', { static: false }) savedErrorMessage: TemplateRef<any>;

  @ViewChild('confirmPassword', { static: false }) confirmPasswordNgModel: NgModel;
  @ViewChild('password', { static: false }) passwordNgModel: NgModel;
  model: Profile = { username: '', secretQuestion: '', secretAnswer: '', password: '', confirmPassword: '', currentPassword: '' };
  version: Version;
  readonly fakePassword = '';
  private loginSubscription: Subscription;
  private controlSubscription: Subscription;

  constructor(private authService: AuthService, private toast: ToastService, private router: Router, public route: ActivatedRoute, httpClient: HttpClient) {
    this.controlSubscription = this.authService.controlPassword(this.route.snapshot.queryParams['email-code']).subscribe(
      r => {
        if (r['message'] == 'Email code is failed') {
          this.router.navigate(['/login']);
        }
        this.model.secretQuestion = r['data'];
      },
      e => {
        if (e.error.text == 'Email code is invalid') {
          this.router.navigate(['/login']);
        }
      }
    );
    getVersion(httpClient).subscribe(res => {
      this.version = res;
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.confirmPasswordNgModel.control.setValidators([Validators.required, this.checkPasswords])
  }

  private checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    return this.passwordNgModel.value === this.confirmPasswordNgModel.value ? null : { misMatch: true }
  }


  ngOnDestroy() {
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
    }
    if (this.controlSubscription) {
      this.controlSubscription.unsubscribe();
    }
  }

  save() {
    this.loginSubscription = this.authService.updatePassword(this.model, this.route.snapshot.queryParams['email-code'], 'change').subscribe(
      r => {
        this.toast.showSuccess('Change password successed!');
        this.router.navigate(['/login']);
      },
      e => {
        if (e.error !== undefined && e.error.text !== undefined) {
          this.toast.showDanger(e.error.text);
        }
        else if (e.error !== undefined && e.error.data !== undefined && e.error.data.message !== undefined) {
          this.toast.showDanger(e.error.data.message);
        } else if (e.message) {
          this.toast.showDanger(e.error.message);
        }
      }
    );

  }

  blurPassword(control: FormControl) {
    if (!control.value) {
      control.setValue(this.fakePassword);
    }
  }

  focusPassword(control: FormControl) {
    if (control.value === this.fakePassword) {
      control.setValue(null);
    }
  }
}

