export interface ApiResult {
  succeded: boolean;
  message: string;
  path: string;
  statusCode: number;
}

export interface DataApiResult<T> extends ApiResult {
  data: T;
  dataType: string;
}

export interface DataList<T> {
  list: T[];
  date?: Date;
  count: number;
  hasNextPage: boolean;
  filterMetaData?: FilterMetaData[];
}

export interface ExceptionDetail {
  message: string;
  data: any;
  innerException?: ExceptionDetail;
  stackTrace: string;
  helpLink: string;
  source: string;
  hResult: number;
}

export interface UserRole {
  name: string;
}

export interface UserAccount {
  id: number;
  name: string;
  nameIdentifier: string;
  customerId: number;
  customerCodeId: string;
  username: string;
  eMail: string;
  phone: string;
  authenticatorAsked: string;
  roles: UserRole[];
}

export enum Loading {
  idle = 1,
  getting = 2,
  posting = 3,
  putting = 4,
  deleting = 5
}

export interface LoadingState {
  url: string;
  state: Loading;
  status: boolean;
}

export interface LoadingStates {
  old: LoadingState;
  new: LoadingState;
}


export interface Filter {
  filterType: FilterType;
  columnType?: ColumnType;
  left?: string;
  operator?: OperatorType;
  right?: any;
  normalizer: string;
}

export interface FilterMetaDataItem {
  _value?: any;
  _disabled?: boolean;
  key: string;
  type: FilterType;
  title?: string;
  values?: any[];
  filters?: Filter[];
}

export interface FilterMetaData {
  _isOpen?: boolean;
  _id?: string;
  title: string;
  items: FilterMetaDataItem[];
}

export type OrderType = 'asc' | 'desc';

export interface Order {
  column: string;
  type?: OrderType;
}

export interface Paging {
  size: number;
  index: number;
  search: string;
  orders?: Order[];
}

export interface Filters {
  [key: string]: Filter[];
}

export interface SelectChange {
  key: string,
  event:any
}

export interface SearchParam {
  columns?: string[];
  filters: Filters;
}
export enum FilterType {
  ListItem,
  Combo,
  Search,
  DateItem
}

export enum OperatorType {
  Equals, // "="
  NotEquals, // "<>"
  Like, // "Like"
  Greater, // ">"
  GreaterOrEqual, // >=
  Lower, // "<"
  LowerOrEqual // "<="
}

export enum ColumnType {
  String,
  Numeric,
  DateTime
}
