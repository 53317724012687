import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { DataItemService } from '../data/data-item.service';
import { Logger } from '../logging/logger.service';
import { ToastService } from '../../shared/services/toast.service';

export enum FormOperations {
  add = 'add',
  edit = 'edit',
}

export interface BaseFormArgs<T> {
  idColumn?: string;
  service: DataItemService<T>;
  spinner: NgxSpinnerService;
  toast: ToastService;
  router: Router;
  route: ActivatedRoute;
  location: Location;
  logger: Logger;
}
