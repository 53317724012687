import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HubConnection } from "@microsoft/signalr";
import { ChartDataSets } from "chart.js";
import moment from "moment";
import { Color, Label } from "ng2-charts";
import { SignalRService } from "src/app/common/signalr/signal-r.service";
import { Report } from "src/app/models/report";
import { InstantService } from "./instant-service";
import * as _ from 'lodash';

@Component({
  selector: "ecg-instant",
  templateUrl: "./instant.component.html",
  styleUrls: ["./instant.component.scss"],
})
export class InstantComponent {
  public startedStream : boolean  = true;
  public streamButtonText: string = "Stop";
  project: string;
  module: string;
  modules: Array<string> = [];
  projects: Array<string> = [];
  isSelectProject: boolean = true;
  lineChartDataItems: number[] = [];
  lineChartLabelItems: Label[] = [];
  lineChartData: ChartDataSets[] = [];
  public lineChartRawData: Map<string, Report[]> = new Map<string, Report[]>();
  lineChartLabels: Label[] = this.lineChartLabelItems;
  modlist: string[] = [];
  lineChartOptions = {
    responsive: true,
    pointDot: false,
    animationEnabled: true,
    animation: {
      easing: 'linear',
      animateScale: true,
      animateRotate: true,
      duration: 1200
    },
    scales: {
      yAxes: [
        {
          display: true,
          scaleLabel: {
            display: true,
            labelString: "Response Time (second)",
          },
          ticks: {
            suggestedMin: 0,
            suggestedMax: 0.2,
          }
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Date",
          },
        },
      ],
    },
  };
  lineChartColors: Color[] = [
    {
      borderColor: "black",
      backgroundColor: "#FF6666",
    },
  ];
  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = "line";

  private hubConnection: HubConnection;

  constructor(
    protected signalRService: SignalRService,
    public service: InstantService,
    private router: Router
  ) {
    this.createConnection();
    this.service.projectList.subscribe((_) => {

      _.map((p) => this.projects.push(p.project));
      this.project = "";
      this.modlist = this.projects;
      var ind = this.lineChartData.map(x => x.label).indexOf(undefined);
      if (ind > -1)
        this.lineChartData.splice(ind, 1);
    });

  }

  moduleChange(event: any) {
    this.modlist = [this.module];
    this.lineChartDataItems.length = 0;
    this.lineChartLabelItems.length = 0;
    this.lineChartData = [];
  }
  projectChange(event: any) {
    this.modules = [];
    this.isSelectProject = false;
    this.project = event;
    this.service.getModuleList(event).subscribe((_) => {
      _.map((m) => this.modules.push(m));
      this.module = "";
      this.modlist = this.modules;
      this.lineChartData = [];
      console.log("change the project");
    }
    );
  }

  protected async createConnection() {
    this.hubConnection = await this.signalRService.connect("/loghub");
    await this.hubConnection.start();
    this.hubConnection.on("performancePlatform", (res) => {
      const resParse: any = JSON.parse(res);
      this.setDataLineChart(resParse);
    });
  }

  startStopStream()
  {
    this.startedStream = !this.startedStream;
    if(this.startedStream)
      this.streamButtonText = "Stop";
    else
      this.streamButtonText = "Start";
  }

  clearStreamData()
  {
    this.lineChartDataItems.length = 0;
    // this.lineChartLabelItems.length = 0;
    this.lineChartData = [];
    // this.lineChartLabelItems = [];
  }

  setDataLineChart(resParse: any) {

    if(!this.startedStream)
    return;


    this.lineChartLabelItems.push(
      moment(
        resParse.CreatedDateTime.toString(),
        "YYYY-MM-DD, HH:mm:ss"
      ).format("DD-MM-YYYY, HH:mm:ss")
    );

    this.modlist.forEach(m => {
      try {
        console.log("Name : " + m);
        if (this.project == '') {
          console.log("Project Name : " + this.project);
          var val = (m != resParse.Project) ? undefined : resParse;
          this.addData(m, val);
        }
        else {
          console.log("Project Name1 : " + this.project);
          var val = (m != resParse.Module) ? undefined : resParse;
          this.addData(m, val);
        }

      } catch (error) {
        console.log(error);
      }

    });

  }

  addData(module, data) {
    var labelList = this.lineChartData.map(x => x.label);
    var ind = labelList.indexOf(module);
    var val = (data === undefined) ? undefined : data.TimeSpentTotalSecond;

    if (ind == -1) {

      this.lineChartData.push({
        data: [val],
        label: module,
        backgroundColor: this.dynamicColors(module),
        spanGaps: false,
        pointRadius : 10,
        hoverRadius : 10,
        pointBorderWidth : 10,
        pointStyle: 'circle'
      });
    }
    else {
      this.lineChartData[ind].data.push(val);
    }

    if (this.lineChartRawData.has(module)) {
      this.lineChartRawData.get(module).push(data);
    }
    else {
      this.lineChartRawData.set(module, [data]);
    }
  }

  dynamicColors(module: string) {
    var color = "";
    switch (module) {
      case "PrepCfg":
        color = "#eb820d";
        break;
      case "PrepValueCfg":
        color = "#dc00be";
        break;
      case "RecorderSettings":
        color = "#36c38e";
        break;
      case "CradleStatProcess":
        color = "#09cadd";
        break;
      case "CradleOnline":
        color = "#5cdc09";
        break;
      case "CradleLocation":
        color = "#302a15";
        break;
      case "DeviceHub":
        color = "#d5d6d9";
        break;
      default:
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
        color = "rgb(" + r + "," + g + "," + b + ")";
        break;
    }
    return color;
  };

  public chartClicked(e: any): void {
    if (e.active.length > 0) {
      var dataIndex = e.active[0]._index;
      var dataSetIndex = e.active[0]._datasetIndex;
      console.log("dataSetIndex:" + dataSetIndex);
      console.log("dataIndex:" + dataIndex);
      var gid = this.lineChartRawData.get(this.lineChartData[dataSetIndex].label)[dataIndex].GId;
      console.log("gid:" + gid);

      const url = this.router.serializeUrl(this.router.createUrlTree(['/report'], {
        queryParams: {
          GId: gid
        }
      }))
      const newTab = window.open(url, '_blank');
      if (newTab) {
        newTab.opener = null;
      }
    }
  }

}
