import { Injectable } from '@angular/core';
import { AuthApiHttpClient } from '../common/auth/auth-api-http-client';
import { DataListService } from '../common/data/data-list.service';
import { Report } from '../models/report';
import {Observable} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ReportListService extends DataListService<Report> {

  constructor(httpClient: AuthApiHttpClient) {
    super({
      httpClient,
      url: 'health/report',
      initial: []
    });
  }
}
