import * as i0 from "@angular/core";
import * as i1 from "../common/auth/auth-api-http-client";
export class ManualService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    files() {
        return this.httpClient.get('health/manual/files');
    }
    uploadPdf(file, name) {
        return this.httpClient.post({ Base64: file, Filename: name }, 'health/manual/upload');
    }
    delete(fileName) {
        return this.httpClient.delete({ Filename: fileName }, 'health/manual/delete');
    }
}
ManualService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ManualService_Factory() { return new ManualService(i0.ɵɵinject(i1.AuthApiHttpClient)); }, token: ManualService, providedIn: "root" });
