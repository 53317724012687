import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { LogParams, LogLevel } from './log';
import * as i0 from "@angular/core";
export class Logger {
    constructor() {
        this.subject = new Subject();
    }
    get asObservable() {
        return this.subject.asObservable();
    }
    get uiObservable() {
        return this.subject
            .asObservable()
            .pipe(filter(l => l.params && l.params.find(p => p === LogParams.ui)));
    }
    log(logMessage, ...logParams) {
        this.subject.next({
            level: LogLevel.log,
            message: logMessage,
            params: logParams
        });
    }
    info(logMessage, ...logParams) {
        this.subject.next({
            level: LogLevel.info,
            message: logMessage,
            params: logParams
        });
    }
    warn(logMessage, ...logParams) {
        this.subject.next({
            level: LogLevel.warn,
            message: logMessage,
            params: logParams
        });
    }
    error(logMessage, ...logParams) {
        this.subject.next({
            level: LogLevel.error,
            message: logMessage,
            params: logParams
        });
    }
}
Logger.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Logger_Factory() { return new Logger(); }, token: Logger, providedIn: "root" });
