import { Injectable } from '@angular/core';
import { AuthApiHttpClient } from '../common/auth/auth-api-http-client';
import { DataListService } from '../common/data/data-list.service';
import { Customer } from '../models';

@Injectable({ providedIn: 'root' })
export class CustomerListService extends DataListService<Customer> {
  constructor(httpClient: AuthApiHttpClient) {
    super({
      httpClient,
      url: 'health/customers',
      initial: []
    });
  }
}
