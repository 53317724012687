import * as tslib_1 from "tslib";
import { Router } from "@angular/router";
import moment from "moment";
export class InstantComponent {
    constructor(signalRService, service, router) {
        this.signalRService = signalRService;
        this.service = service;
        this.router = router;
        this.startedStream = true;
        this.streamButtonText = "Stop";
        this.modules = [];
        this.projects = [];
        this.isSelectProject = true;
        this.lineChartDataItems = [];
        this.lineChartLabelItems = [];
        this.lineChartData = [];
        this.lineChartRawData = new Map();
        this.lineChartLabels = this.lineChartLabelItems;
        this.modlist = [];
        this.lineChartOptions = {
            responsive: true,
            pointDot: false,
            animationEnabled: true,
            animation: {
                easing: 'linear',
                animateScale: true,
                animateRotate: true,
                duration: 1200
            },
            scales: {
                yAxes: [
                    {
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: "Response Time (second)",
                        },
                        ticks: {
                            suggestedMin: 0,
                            suggestedMax: 0.2,
                        }
                    },
                ],
                xAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            labelString: "Date",
                        },
                    },
                ],
            },
        };
        this.lineChartColors = [
            {
                borderColor: "black",
                backgroundColor: "#FF6666",
            },
        ];
        this.lineChartLegend = true;
        this.lineChartPlugins = [];
        this.lineChartType = "line";
        this.createConnection();
        this.service.projectList.subscribe((_) => {
            _.map((p) => this.projects.push(p.project));
            this.project = "";
            this.modlist = this.projects;
            var ind = this.lineChartData.map(x => x.label).indexOf(undefined);
            if (ind > -1)
                this.lineChartData.splice(ind, 1);
        });
    }
    moduleChange(event) {
        this.modlist = [this.module];
        this.lineChartDataItems.length = 0;
        this.lineChartLabelItems.length = 0;
        this.lineChartData = [];
    }
    projectChange(event) {
        this.modules = [];
        this.isSelectProject = false;
        this.project = event;
        this.service.getModuleList(event).subscribe((_) => {
            _.map((m) => this.modules.push(m));
            this.module = "";
            this.modlist = this.modules;
            this.lineChartData = [];
            console.log("change the project");
        });
    }
    createConnection() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.hubConnection = yield this.signalRService.connect("/loghub");
            yield this.hubConnection.start();
            this.hubConnection.on("performancePlatform", (res) => {
                const resParse = JSON.parse(res);
                this.setDataLineChart(resParse);
            });
        });
    }
    startStopStream() {
        this.startedStream = !this.startedStream;
        if (this.startedStream)
            this.streamButtonText = "Stop";
        else
            this.streamButtonText = "Start";
    }
    clearStreamData() {
        this.lineChartDataItems.length = 0;
        // this.lineChartLabelItems.length = 0;
        this.lineChartData = [];
        // this.lineChartLabelItems = [];
    }
    setDataLineChart(resParse) {
        if (!this.startedStream)
            return;
        this.lineChartLabelItems.push(moment(resParse.CreatedDateTime.toString(), "YYYY-MM-DD, HH:mm:ss").format("DD-MM-YYYY, HH:mm:ss"));
        this.modlist.forEach(m => {
            try {
                console.log("Name : " + m);
                if (this.project == '') {
                    console.log("Project Name : " + this.project);
                    var val = (m != resParse.Project) ? undefined : resParse;
                    this.addData(m, val);
                }
                else {
                    console.log("Project Name1 : " + this.project);
                    var val = (m != resParse.Module) ? undefined : resParse;
                    this.addData(m, val);
                }
            }
            catch (error) {
                console.log(error);
            }
        });
    }
    addData(module, data) {
        var labelList = this.lineChartData.map(x => x.label);
        var ind = labelList.indexOf(module);
        var val = (data === undefined) ? undefined : data.TimeSpentTotalSecond;
        if (ind == -1) {
            this.lineChartData.push({
                data: [val],
                label: module,
                backgroundColor: this.dynamicColors(module),
                spanGaps: false,
                pointRadius: 10,
                hoverRadius: 10,
                pointBorderWidth: 10,
                pointStyle: 'circle'
            });
        }
        else {
            this.lineChartData[ind].data.push(val);
        }
        if (this.lineChartRawData.has(module)) {
            this.lineChartRawData.get(module).push(data);
        }
        else {
            this.lineChartRawData.set(module, [data]);
        }
    }
    dynamicColors(module) {
        var color = "";
        switch (module) {
            case "PrepCfg":
                color = "#eb820d";
                break;
            case "PrepValueCfg":
                color = "#dc00be";
                break;
            case "RecorderSettings":
                color = "#36c38e";
                break;
            case "CradleStatProcess":
                color = "#09cadd";
                break;
            case "CradleOnline":
                color = "#5cdc09";
                break;
            case "CradleLocation":
                color = "#302a15";
                break;
            case "DeviceHub":
                color = "#d5d6d9";
                break;
            default:
                var r = Math.floor(Math.random() * 255);
                var g = Math.floor(Math.random() * 255);
                var b = Math.floor(Math.random() * 255);
                color = "rgb(" + r + "," + g + "," + b + ")";
                break;
        }
        return color;
    }
    ;
    chartClicked(e) {
        if (e.active.length > 0) {
            var dataIndex = e.active[0]._index;
            var dataSetIndex = e.active[0]._datasetIndex;
            console.log("dataSetIndex:" + dataSetIndex);
            console.log("dataIndex:" + dataIndex);
            var gid = this.lineChartRawData.get(this.lineChartData[dataSetIndex].label)[dataIndex].GId;
            console.log("gid:" + gid);
            const url = this.router.serializeUrl(this.router.createUrlTree(['/report'], {
                queryParams: {
                    GId: gid
                }
            }));
            const newTab = window.open(url, '_blank');
            if (newTab) {
                newTab.opener = null;
            }
        }
    }
}
