import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../common/auth/auth.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { StorageMap } from '@ngx-pwa/local-storage';
import { AuthApiHttpClient } from '../../common/auth/auth-api-http-client';
import { OrganisationSettings } from 'src/app/models/OrganisationSettings';
import { AuthLogoutService } from '../../common/auth/auth-logout.service';

@Component({
  selector: 'ecg-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnDestroy, OnInit {
  isCollapsed = true;
  private logoutSubscription: Subscription;
  localstorage: LocalStorageService;
  storageModule: any;
  url = '';
  dropDownIpToggle = false;
  organisationSettings: OrganisationSettings;

  constructor(public auth: AuthService,
    public route: ActivatedRoute, private router: Router, public localStorageService: LocalStorageService,
    private translate: TranslateService, protected storageMap: StorageMap, public httpClient: AuthApiHttpClient, private authLogoutService: AuthLogoutService) {
    this.localstorage = localStorageService;

    if (localStorage.getItem('language') === null) {
      localStorage.setItem('language', 'nl');
      this.translate.use('nl');
    } else {
      this.translate.use(localStorage.getItem('language'));
    }
  }
  async ngOnInit() {
    this.organisationSettings = await this.getOrganisationSettings();
  }

  dropDownIp(bool) {
    this.dropDownIpToggle = bool;
  }

  controlUrlLogo() {
    const url = this.router.url;
    return !url.includes("login") && !url.includes("branch") && !url.includes("createpassword")
  }

  controlUrl() {
    var response = !this.router.url.includes('branch');
    var result = !this.router.url.includes('login')
    return response && result;
  }

  ngOnDestroy() {
    if (this.logoutSubscription) {
      this.logoutSubscription.unsubscribe();
    }
  }

  toggleCollapse(value?: any) {
    const newValue = value === null || value === undefined ? !this.isCollapsed : value;
    this.isCollapsed = newValue;
  }

  private async getOrganisationSettings() {

    await this.getOrganisationSettingsOfUser();

    const settings = this.localStorageService.get('organisationSettings');
    if (settings) {
      return settings as OrganisationSettings;
    }
  }

  private async getOrganisationSettingsOfUser() {

    const response = await this.httpClient.get<number>('health/customers/GetOrganisationSettingsOfUser').toPromise();

    if (response) {
      return this.localStorageService.set('organisationSettings', response);
    }
  }

  preventClick(event) {
    event.stopPropagation();
  }

  logout() {
    this.authLogoutService.logout();
  }

  needlogin(): boolean {
    this.url = window.location.pathname;
    const needLogin = this.auth.needLogin;
    if (this.url !== '/login' && this.url !== '' && this.url !== '/createpassword' && this.url !== '/forgot' && this.url !== '/changepassword') {
      if (needLogin) {
        this.deleteAllCookies();
        // this.router.navigate(['login']);
      }
      return needLogin;
    } else {
      return needLogin;
    }
  }

  deleteAllCookies() {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      let eqPos = cookie.indexOf('=');
      let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }
}
