import { Injectable } from '@angular/core';
import { AuthApiHttpClient } from '../common/auth/auth-api-http-client';

@Injectable({
  providedIn: 'root'
})
export class CradleMapService {

  
  constructor(private httpClient: AuthApiHttpClient) { }

  getCradles(){
    return this.httpClient.get<any>('health/cradles/activeCradle');
  }
}
