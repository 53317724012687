import { Injectable } from '@angular/core';
import { AuthApiHttpClient } from '../../common/auth/auth-api-http-client';
import { Report } from '../../models/report';
import {Observable} from 'rxjs';
import { DataItemService } from '../../common/data/data-item.service';
import { DataApiResult } from '../../common/data/result';

@Injectable({ providedIn: 'root' })
export class ReportErrorFormEditService extends DataItemService<Report> {

  constructor(httpClient: AuthApiHttpClient) {
    super({
      httpClient,
      url: 'health/report',
     
    });
  }

  getItemId(id) {
    return this.httpClient.get<DataApiResult<Report>>(`health/errorLogReport/getById/${id}`);
  }
}
