<ng-container>
  <div class="nexo-root container" style=" margin-top: calc(50vh - 295px);">
    <div class="row content my-3">
      <div class="col-12">
        <form #mainForm="ngForm" class="form-signin col-4">
          <div class="login-logo-area">
            <img class="logo-image" src="assets/images/login-logo.png" i18n-alt alt="Login Logo" />
          </div>
          <p></p>
          <ng-container *ngIf="step == 'login' || step=='2fAuthentication'">
            <div *ngIf="step == 'login'">

              <div class="form-label-group">
                <input type="text" #inputEmail="ngModel" id="inputEmail" name="username" autocomplete="off" [(ngModel)]="loginModel.username" (keydown)="hideMessage()"
                      (ngModelChange)="loginModel.username = $event.toLowerCase()"
                       class="form-control" placeholder="{{ 'loginPage.username' | translate }}" required autofocus>
                <div *ngIf="inputEmail.invalid && (inputEmail.dirty || inputEmail.touched)" class="alert alert-danger">
                  <div *ngIf="inputEmail.errors['required']" i18n>
                    {{'required.username' | translate }}
                  </div>
                </div>
              </div>

              <div class="form-label-group">
                <label for="inputPassword" class="sr-only" i18n>
                  {{ 'password' | translate }}"
                </label>
                <input type="password" #inputPassword="ngModel" id="inputPassword" name="password" autocomplete="off" (keydown)="hideMessage()"
                       [(ngModel)]="loginModel.password" placeholder="{{ 'loginPage.password' | translate }}" class="form-control" required autofocus>
                <div *ngIf="inputPassword.invalid && (inputPassword.dirty || inputPassword.touched)" class="alert alert-danger">
                  <div *ngIf="inputPassword.errors['required']" i18n>
                    {{'required.password' | translate }}
                  </div>
                </div>
              </div>

            </div>
            <div *ngIf="step=='2fAuthentication'" class="form-label-group">
              <label for="inputPassword" class="sr-only" i18n>
                {{ 'password' | translate }}"
              </label>
              <input type="text" id="inputPassword" name="password" autocomplete="off" [(ngModel)]="twoFactor.authCode"
                     autofocus placeholder="{{ 'loginPage.authCode' | translate }}"
                     (keydown.enter)="confirmLogin()" class="form-control" required>
              <p class="mb-0 mt-3">
                {{'profileForm.trustDevice' | translate}}
                <select [(ngModel)]="trustDays" #status class="custom-select day-select" id="trust" name="trust">
                  <option i18n selected value="1">1</option>
                  <option i18n value="7">7</option>
                  <option i18n value="14">14</option>
                  <option i18n value="0">Never</option>
                </select>
                {{trustDays != 0 ? 'day' : ''}}
              </p>
            </div>

            <div class="form-label-group">
              <div class="alert alert-danger" *ngIf="message || divShown">
                <div i18n>
                  {{message}}
                </div>
              </div>
            </div>

            <div class="checkbox mb-3">
            </div>
            <hr />
            <div class="form-label-group">
              <button [disabled]="!tempLogin" class="btn btn-lg btn-primary btn-block" type="submit"
                      (click)="!twoFactor.isAuthorise ? login() : confirmLogin()"
                      i18n>
                {{ 'loginPage.login' | translate }}
               &nbsp; <i *ngIf="!tempLogin" id="loginLoading" class="fa fa-circle-o-notch fa-spin"></i>  
              </button>
              <button *ngIf="!twoFactor.isAuthorise" class="btn btn-lg btn-light btn-block btn-sm" routerLink="/forgot"
                      type="submit" i18n>
                {{ 'loginPage.forgotPassword' | translate }}
               
              </button>
              <button *ngIf="twoFactor.isAuthorise" class="btn btn-lg btn-light btn-block btn-sm"
                      (click)="step = 'login';twoFactor.isAuthorise = !twoFactor.isAuthorise;"
                      type="submit" i18n>
                {{ 'back' | translate }}
                
              </button>
             
            </div>
            <hr>
            <div class="form-label-group">
              <div class="d-block" ngbDropdown #myDrop="ngbDropdown">
                <button class="btn btn-outline-primary mr-2 d-flex align-items-center justify-content-between w-100"
                        id="dropdownManual" ngbDropdownToggle>
                  <div *ngIf="loginModel.culture == 'en-US'">
                    <img style="width: 24px;" [src]="'assets/icons/'+loginModel.culture+'.png'" alt="">
                    English (United States)
                  </div>
                  <div *ngIf="loginModel.culture == 'nl-NL'">
                    <img style="width: 24px;" [src]="'assets/icons/'+loginModel.culture+'.png'" alt="">
                    Nederlands (Nederland)
                  </div>
                  <div *ngIf="loginModel.culture == 'tr-TR'">
                    <img style="width: 24px;" [src]="'assets/icons/'+loginModel.culture+'.png'" alt="">
                    Türkçe (Türkiye)
                  </div>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownManual">
                  <button (click)="selectLang('en-US', $event.target.textContent,'en');" ngbDropdownItem>
                    <img style="width: 24px;" src="assets/icons/en-US.png" alt=""> English (United States)
                  </button>
                  <button (click)="selectLang('nl-NL', $event.target.textContent,'nl')" ngbDropdownItem>
                    <img style="width: 24px;" src="assets/icons/nl-NL.png" alt=""> Nederlands (Nederland)
                  </button>
                  <button (click)="selectLang('tr-TR', $event.target.textContent,'tr')" ngbDropdownItem>
                    <img style="width: 24px;" src="assets/icons/tr-TR.png" alt=""> Türkçe (Türkiye)
                  </button>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="step=='2fRegister' ">
            <ecg-authentication [(ngModel)]="profileModel" page="login" name="auth"></ecg-authentication>
            <hr />
            <div class="form-label-group">
              <button *ngIf="!shouldDisplaySkipAuthenticationButton()" class="btn btn-lg btn-light btn-block btn-sm" (click)="open(confirmDeleteModal, 'lg')"
                      type="submit" i18n>
                {{"loginPage.skipAuth" | translate}}
              </button>
            </div>
          </ng-container>


          <hr />
          <div class="login-footer-area">
            <p>Fysiologic&#174; ECGAccess &copy; {{year}}</p>
            <small>ECGAccess Version <span *ngIf="version">{{version.appVersion}}</span></small>
          </div>
        </form>


      </div>
    </div>
  </div>
</ng-container>
<ng-template #confirmDeleteModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" i18n id="modal-title">{{'profileForm.deactivate' | translate}}</h4>
    <button (click)="modal.dismiss('Cross click');" aria-describedby="modal-title"
            aria-label="Close button" class="close"
            type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="two-factor-auth-message">{{ '2fa.deactivateMessage' | translate}}</p>
  </div>
  <div class="modal-footer btn-group">
    <button (click)="deactivateAuthentication();modal.dismiss('cancecl click');" class="btn btn-danger" i18n
            type="button">
      {{'2fa.confirm' | translate}}
    </button>
    <button (click)="modal.dismiss('cancel click');" class="btn btn-outline-secondary" i18n ngbAutofocus type="button">
      {{'2fa.cancel' | translate }}
    </button>
  </div>
</ng-template>
