import { Routes } from "@angular/router";
import { AuthGuard } from "./common/auth/auth.guard";
import { DiaryFormComponent } from "./patient/diary-form.component";
import { CustomerListComponent } from "./customer/customer-list.component";
import { CustomerFormComponent } from "./customer/customer-form.component";
import { LoginComponent } from "./public/login/login.component";
import { NotFoundComponent } from "./public/not-found/not-found.component";
import { PcListComponent } from "./pc/pc-list.component";
import { PcFormComponent } from "./pc/pc-form.component";
import { ForgotPasswordComponent } from "./public/forgot-password/forgot-password.component";
import { AboutComponent } from "./public/about/about.component";
import { ActivityComponent } from "./public/activity/activity.component";
import { CreatePasswordComponent } from "./public/create-password/create-password.component";
import { NewPasswordComponent } from "./public/new-password/new-password.component";
import { ServerHealthPageComponent } from "./server-health-page/server-health-page.component";
import { FirmwareComponent } from "./firmware/firmware.component";
import { DashboardComponent } from "./sysadmin/dashboard/dashboard.component";
import { PublicLayoutComponent } from "./common/components/public-layout.component";
import { AuthLayoutComponent } from "./common/components/auth-layout.component";
import { RedirectGuard } from "./common/auth/redirect.guard";
import { NotificationComponent } from "./notification/notification.component";
import { RecorderFormComponent } from "./recorder/recorder-form.component";
import { ServerHealthCheckComponent } from "./server-health-check/server-health-check.component";
import { BranchChooseComponent } from "./shared/components/BranchChoose/branch-choose.component";
import { PatientDiaryComponent } from "./patient-diary/patient-diary.component";
import { IpWhitelistsComponent } from "./ip-whitelist/ip-whitelists.component";
import { IpWhiteListFormComponent } from "./ip-whitelist/ip-whitelist-form.component";
import { AccessRequestsComponent } from "./ip-whitelist/access-requests/access-requests.component";
import { NoteListComponent } from "./note/note-list.component";
import { NoteFormComponent } from "./note/note-form.component";
import { AccessPermissionComponent } from "./ip-whitelist/access-allow/access-permission.component";
import { AuthLogsComponent } from "./AuthLogs/auth-logs.component";
import { ChargerFormComponent } from "./charger/charger-form.component";
import { ManualComponent } from "./manual/manual.component";
import { CradleMapComponent } from "./cradle-maps/cradle-map.component";
import { ReportListComponent } from "./report/report-list.component";
import { PlatformPerformanceComponent } from "./report/platform-performance/platform-performance.component";
import { InstantComponent } from "./report/platform-performance/instant/instant.component";
import { DateRangeComponent } from "./report/platform-performance/date-range/date-range.component";
import { LogHistoryListComponent } from "./report/log-history/log-history-list.component";
import { ReportErrorLogListComponent } from "./report/report-error-log-list/report-error-log-list.component";
import { ReportListFormEditComponent } from "./report/report-list-form-edit.component";
import { ReportErrorFormEditComponent } from "./report/report-error-log-list/report-error-form-edit.component";
import { InvoiceUploadComponent } from "./invoices/invoice-upload/invoice-upload.component";
const ɵ0 = { roles: ["System Admin", "Super User"] }, ɵ1 = {
    roles: ["System Admin", "Super User", "Support", "Support DK"],
}, ɵ2 = { roles: ["Diary User", "Assistant"] }, ɵ3 = { roles: ["System Admin", "Super User"] }, ɵ4 = {
    roles: ["System Admin", "Super User", "Support", "Support DK"],
}, ɵ5 = {
    roles: ["System Admin", "Super User", "Support", "Support DK"],
}, ɵ6 = {
    roles: ["System Admin", "Super User", "Support", "Support DK"],
}, ɵ7 = {
    roles: ["System Admin", "Super User", "Support", "Support DK"],
}, ɵ8 = {
    roles: ["System Admin", "Super User", "Support", "Support DK"],
}, ɵ9 = {
    roles: ["System Admin", "Super User", "Support", "Support DK"],
}, ɵ10 = { roles: ["System Admin", "Super User"] }, ɵ11 = { roles: ["System Admin", "Super User"] }, ɵ12 = { roles: ["System Admin", "Super User"] }, ɵ13 = {
    roles: ["System Admin", "Super User", "Support", "Support DK"],
}, ɵ14 = { roles: ["System Admin", "Super User", "Staff Fysiologic"] }, ɵ15 = { roles: ["System Admin", "Super User", "Staff Fysiologic"] }, ɵ16 = { roles: ["System Admin", "Super User", "Staff Fysiologic"] }, ɵ17 = {
    roles: ["System Admin", "Super User", "Support", "Support DK"],
}, ɵ18 = { roles: ["System Admin", "Super User", "Staff Fysiologic"] }, ɵ19 = {
    roles: ["System Admin", "Super User", "Support", "Support DK"],
}, ɵ20 = { roles: ["System Admin", "Super User", "Staff Fysiologic"] }, ɵ21 = { roles: ["System Admin", "Super User", "Staff Fysiologic"] }, ɵ22 = { roles: ["System Admin", "Super User", "Staff Fysiologic"] }, ɵ23 = { roles: ["System Admin", "Super User", "Staff Fysiologic"] }, ɵ24 = { roles: ["System Admin", "Super User"] }, ɵ25 = { roles: ["Super User", "Staff Fysiologic"] }, ɵ26 = { roles: ["Super User"] }, ɵ27 = () => import("./shop/materials.module.ngfactory").then((m) => m.MaterialsModuleNgFactory), ɵ28 = () => import("./invoices/invoices.module.ngfactory").then((module) => module.InvoicesModuleNgFactory), ɵ29 = { roles: ["System Admin", "Super User", "Financial Admin"], };
const routes = [
    { path: "", pathMatch: "full", canActivate: [RedirectGuard], children: [] },
    { path: "access-permission", component: AccessPermissionComponent },
    {
        path: "",
        component: PublicLayoutComponent,
        children: [
            {
                path: "login",
                component: LoginComponent,
                canActivate: [RedirectGuard],
            },
            { path: "forgot", component: ForgotPasswordComponent },
            { path: "changepassword", component: NewPasswordComponent },
            { path: "createpassword", component: CreatePasswordComponent },
        ],
    },
    {
        path: "",
        component: AuthLayoutComponent,
        children: [
            {
                path: "users",
                canActivate: [AuthGuard],
                loadChildren: "./users/users.module#UsersModule",
            },
            {
                path: "about",
                canActivate: [AuthGuard],
                data: ɵ0,
                component: AboutComponent,
            },
            {
                path: "activity",
                canActivate: [AuthGuard],
                component: ActivityComponent,
            },
            {
                path: "sysadmin",
                canActivate: [AuthGuard],
                loadChildren: "./sysadmin/sysadmin.module#SysadminModule",
                data: ɵ1,
            },
            {
                path: "records",
                loadChildren: "./patient/patient.module#PatientModule",
            },
            {
                path: "branch",
                canActivate: [AuthGuard],
                component: BranchChooseComponent,
            },
            {
                path: "worklist",
                loadChildren: "./patient/patient-worklist.module#PatientWorkListModule",
            },
            {
                path: "diary-upload",
                canActivate: [AuthGuard],
                data: ɵ2,
                component: PatientDiaryComponent,
            },
            {
                path: "diary/:op",
                canActivate: [AuthGuard],
                data: ɵ3,
                component: DiaryFormComponent,
            },
            {
                path: "customers",
                canActivate: [AuthGuard],
                data: ɵ4,
                component: CustomerListComponent,
            },
            {
                path: "ip-whitelist",
                canActivate: [AuthGuard],
                data: ɵ5,
                component: IpWhitelistsComponent,
            },
            {
                path: "ip-whitelist/access-requests",
                canActivate: [AuthGuard],
                data: ɵ6,
                component: AccessRequestsComponent,
            },
            {
                path: "ip-whitelist/:op",
                canActivate: [AuthGuard],
                data: ɵ7,
                component: IpWhiteListFormComponent,
            },
            {
                path: "cradles",
                canActivate: [AuthGuard],
                data: ɵ8,
                loadChildren: "./cradles/cradle.module#CradleModule",
            },
            {
                path: "customer/:op",
                canActivate: [AuthGuard],
                data: ɵ9,
                component: CustomerFormComponent,
            },
            {
                path: "pc",
                canActivate: [AuthGuard],
                data: ɵ10,
                component: PcListComponent,
            },
            {
                path: "recorder/:op",
                canActivate: [AuthGuard],
                data: ɵ11,
                component: RecorderFormComponent,
            },
            {
                path: "charger/:op",
                canActivate: [AuthGuard],
                data: ɵ12,
                component: ChargerFormComponent,
            },
            {
                path: "auth-logs",
                canActivate: [AuthGuard],
                data: ɵ13,
                component: AuthLogsComponent,
            },
            {
                path: "notes",
                canActivate: [AuthGuard],
                data: ɵ14,
                component: NoteListComponent,
            },
            {
                path: "notes/:op",
                canActivate: [AuthGuard],
                data: ɵ15,
                component: NoteFormComponent,
            },
            {
                path: "report",
                canActivate: [AuthGuard],
                data: ɵ16,
                component: ReportListComponent,
            },
            {
                path: "report/:op",
                canActivate: [AuthGuard],
                data: ɵ17,
                component: ReportListFormEditComponent,
            },
            {
                path: "error-log-report",
                canActivate: [AuthGuard],
                data: ɵ18,
                component: ReportErrorLogListComponent,
            },
            {
                path: "ErrorLogReport/:op",
                canActivate: [AuthGuard],
                data: ɵ19,
                component: ReportErrorFormEditComponent,
            },
            {
                path: "log-history",
                canActivate: [AuthGuard],
                data: ɵ20,
                component: LogHistoryListComponent,
            },
            {
                path: "platform-performance/date-range",
                canActivate: [AuthGuard],
                data: ɵ21,
                component: DateRangeComponent,
            },
            {
                path: "platform-performance/instant",
                canActivate: [AuthGuard],
                data: ɵ22,
                component: InstantComponent,
            },
            {
                path: "platform-performance",
                canActivate: [AuthGuard],
                data: ɵ23,
                component: PlatformPerformanceComponent,
            },
            {
                path: "pc/:op",
                canActivate: [AuthGuard],
                data: ɵ24,
                component: PcFormComponent,
            },
            {
                path: "dashboard",
                canActivate: [AuthGuard],
                data: ɵ25,
                component: DashboardComponent,
            },
            {
                path: "notification",
                canActivate: [AuthGuard],
                data: ɵ26,
                component: NotificationComponent,
            },
            // {
            //   path: "settings",
            //   canActivate: [AuthGuard],
            //   data: { roles: ["System Admin"] },
            //   component: SettingsComponent,
            // },
            {
                path: "serverHealth",
                component: ServerHealthPageComponent,
                canActivate: [AuthGuard],
            },
            {
                path: "serverCheck",
                component: ServerHealthCheckComponent,
                canActivate: [AuthGuard],
            },
            {
                path: "cradle-map",
                component: CradleMapComponent,
                canActivate: [AuthGuard],
            },
            { path: "firmware", component: FirmwareComponent },
            {
                path: "manual",
                canActivate: [AuthGuard],
                component: ManualComponent,
            },
            {
                path: "materials",
                canActivate: [AuthGuard],
                loadChildren: ɵ27,
            },
            {
                path: "specifications",
                loadChildren: ɵ28,
                canActivate: [AuthGuard],
            },
            {
                path: 'upload-specifications', component: InvoiceUploadComponent,
                data: ɵ29,
                canActivate: [AuthGuard],
            }
        ],
    },
    { path: "**", component: NotFoundComponent },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28, ɵ29 };
