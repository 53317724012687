import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthApiHttpClient } from 'src/app/common/auth/auth-api-http-client';
import { DataListService } from 'src/app/common/data/data-list.service';
import { Invoice } from 'src/app/models/invoice';
import { pdfAnalysisResponse } from 'src/app/models/pdfAnalysisResponse';
import { UploadInvoice } from 'src/app/models/upload-invoice';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService extends DataListService<Invoice> {
  constructor(httpClient: AuthApiHttpClient) {
    super({
      httpClient,
      url: 'invoice/invoices',
      initial: []
    });
  }

  async uploadInvoice(uploadInvoice: UploadInvoice, successCallBack?: () => void, errorCallBack?: (message: string) => void): Promise<any> {
    let uri = `Invoice/Invoices/UploadInvoice`;
    const promiseData: Promise<any> = this.httpClient.post<UploadInvoice, {}>(uploadInvoice, uri).toPromise();
    promiseData
      .then(() => successCallBack())
      .catch((errorResponse: HttpErrorResponse) =>
        errorCallBack(errorResponse.message)
      );

    return await promiseData;
  }

  async remove(id: any, successCallBack?: () => void) {
    let uri = `Invoice/Invoices/RemoveInvoice/${id}`;
    await this.httpClient.delete({}, uri).toPromise();
    successCallBack();
  }

  async removeInvoiceFile(invoiceFileId: number, successCallBack?: () => void, errorCallBack?: (message: string) => void) {
    let uri = `Invoice/Invoices/RemoveInvoiceFile/${invoiceFileId}`;

    const promiseData: Promise<any> = this.httpClient.delete({}, uri).toPromise();
    promiseData
      .then(() => successCallBack())
      .catch((errorResponse: HttpErrorResponse) =>
        errorCallBack(errorResponse.message)
      );

    return await promiseData;
  }

  async getInvoiceCsvFile(id: number, successCallBack?: () => void, errorCallBack?: (message: string) => void): Promise<any> {
    let uri = `Invoice/Invoices/GetInvoiceCsvFile/${id}`;
    
    const promiseData: Promise<any> = this.httpClient.get(uri, {
      responseType: 'text'
    }).toPromise();

    promiseData
      .then(() => successCallBack())
      .catch((errorResponse: HttpErrorResponse) =>
        errorCallBack(errorResponse.message)
      );

    return await promiseData;
  }

  async downloadCsvFile(fileName: string, successCallBack?: () => void, errorCallBack?: (message: string) => void): Promise<Blob> {
    let uri = `Invoice/Invoices/DownloadCsvFile/${fileName}`;
    
    const promiseData: Promise<Blob> = this.httpClient.get<Blob>(uri, {
      responseType: "blob"
    }).toPromise();

    promiseData
      .then(() => successCallBack())
      .catch((errorResponse: HttpErrorResponse) =>
        errorCallBack(errorResponse.message)
      );

    return await promiseData;
  }
}
