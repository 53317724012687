<div *ngIf="(service.data | async) as customers">
  <nav class="navbar navbar-light bg-light">
    <div class="row m-0 w-100">
      <div class="col-3 pl-0 pt-1 pr-0">
        <button class="btn btn-info mr-2 filterBttn" (click)="showFilter = !showFilter" i18n>
          <i class="fa fa-filter"></i> {{'filterButton' | translate}}
        </button>
        <button type="button" class="btn btn-outline-secondary" (click)="filterReset();" *ngIf="showFilter"  i18n>{{'filterReset' | translate }}</button>
      </div>
      <div class="col-6 align-self-center text-center">
        <h3 i18n>{{'organisationForm.organisations' | translate }}</h3>
      </div>
      <div class="col-3 pt-1 pr-0">
        <button type="button" class="btn btn-info float-right" *ngIf="!auth.hasRole(['Support', 'Support DK'])" (click)="add()" i18n>{{'new' | translate }}</button>
      </div>
    </div>
  </nav>
  <div class="card" *ngIf="showFilter">
    <div class="card-body">
      <ecg-filter (filterChange)="pagingReset();filterChange($event);" [metaData]="filterMetaData" [reset]="reset"></ecg-filter>
    </div>
  </div>
  <div [class]="(dataCount | async) > 10 ? 'table-responsive' : 'table-responsive table-responsive-no-paginate'">
    <table class="table table-hover customer-list-table" i18n-aria-describedby aria-describedby="doctor list">
      <thead>
      <tr>
        <th scope="col" sortable="name" (sort)="onSort($event)" i18n>{{'organisationForm.organisationName' | translate
          }}
        </th>
        <th scope="col" i18n>{{'organisationForm.code' | translate }}</th>
        <th scope="col" sortable="Type" (sort)="onSort($event)" i18n>{{'organisationForm.type' | translate }}</th>
        <th scope="col" sortable="eMailReport" (sort)="onSort($event)" i18n>{{'organisationForm.emailReport' | translate
          }}
        </th>
        <th scope="col" sortable="eMailGeneral" (sort)="onSort($event)" i18n>{{'organisationForm.emailGeneral' |
          translate }}
        </th>
        <th scope="col" sortable="address1" (sort)="onSort($event)" i18n>{{'organisationForm.adress' | translate }} 1
        </th>
        <th scope="col" sortable="address2" (sort)="onSort($event)" i18n>{{'organisationForm.adress' | translate }} 2
        </th>
      </tr>
      </thead>
      <tbody *ngIf="customers && customers.length; else norecord">
      <tr class="clickable-row cursorProp" *ngFor="let customer of customers; let index=index" [tabindex]="index + 1"
          (click)="edit(customer)" (keydown.enter)="edit(customer)">
        <td>{{ customer.name }}</td>
        <td>
          <ng-container *ngIf="customer?.codes && customer?.codes?.length">
              <span *ngFor="let cCode of customer.codes" class="mr-1">
                <span class="badge badge-secondary">{{cCode.code}}</span>
              </span>
          </ng-container>
        </td>
        <td>
          <span *ngIf="customer?.type === 1" i18n>{{'organisationForm.all' | translate }}</span>
          <span *ngIf="customer?.type === 2" i18n>{{'organisationForm.regular' | translate }}</span>
          <span *ngIf="customer?.type === 3" i18n>{{'organisationForm.children' | translate }}</span>
          <span *ngIf="customer?.type === 4" i18n>{{'organisationForm.dogs' | translate }}</span>
          <span *ngIf="customer?.type === 5" i18n>{{'organisationForm.pacemaker' | translate }}</span>
          <span *ngIf="customer?.type === 6" i18n>{{'organisationForm.twelveChannel' | translate }}</span>
          <span
            *ngIf="customer?.type !== 1 && customer?.type !== 2 && customer?.type !== 3 && customer?.type !== 4 && customer?.type !== 5">
            </span>
        </td>
        <td>{{ customer.eMailReport }}</td>
        <td>{{ customer.eMailGeneral }}</td>
        <td>{{ customer.address1 }}</td>
        <td>{{ customer.address2 }}</td>
      </tr>
      </tbody>
      <ng-template #norecord>
        <tbody>
        <tr>
          <td colspan="10" class="text-danger" i18n>{{'patientList.noRecords' | translate }}</td>
        </tr>
        </tbody>
      </ng-template>
    </table>
  </div>
  <div *ngIf="(dataCount | async) as count">
    <div class="bg-ecg fix-to-bottom text-white px-3 pt-lg-3" *ngIf="count>=10">
      <div class="row justify-content-between w-100 m-0">
        <div class="col-12 d-block d-lg-none">
          <div class="pt-1 text-center"
               i18n>{{count}} {{'itemsIn' | translate }} {{this | func:pageCount:count:paging.size}} {{'pages'
            | translate }}
          </div>
        </div>
        <div class="col-auto pl-0 pr-0">
          <ngb-pagination [boundaryLinks]="true" [rotate]="true" [maxSize]="3" [(page)]="paging.index"
                          [pageSize]="paging.size" [collectionSize]="count" (pageChange)="refresh();pagingSave();"
                          #pagination></ngb-pagination>
        </div>
        <div class="col-12 col-lg d-none d-lg-block">
          <div class="pt-2 text-center"
               i18n>{{count}} {{'itemsIn' | translate }} {{this | func:pageCount:count:paging.size}} {{'pages'
            | translate }}
          </div>
        </div>
        <div class="col pr-0 pl-0 pagination-dropdown-container">
          <div>
            <select class="custom-select pagination-dropdown cursorProp" [(ngModel)]="paging.size" (change)="refresh();pagingSave();">
              <option [ngValue]="10" i18n>{{'records10' | translate }}</option>
              <option selected [ngValue]="15" i18n>{{'records15' | translate }}</option>
              <option [ngValue]="20" i18n>{{'records20' | translate }}</option>
              <option [ngValue]="50" i18n>{{'records50' | translate }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
