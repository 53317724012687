import { ApplicationRef, enableProdMode } from "@angular/core";
import { enableDebugTools } from "@angular/platform-browser";
import { environment } from "./environments/environment";
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
export function getBaseUrl() {
    return document.getElementsByTagName("base")[0].href;
}
const providers = [{ provide: "BASE_URL", useFactory: getBaseUrl, deps: [] }];
if (environment.production) {
    enableProdMode();
}
__NgCli_bootstrap_2.platformBrowser(providers)
    .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .then(moduleRef => {
    if (!environment.debug)
        return;
    const applicationRef = moduleRef.injector.get(ApplicationRef);
    const componentRef = applicationRef.components[0];
    enableDebugTools(componentRef);
})
    .catch((err) => console.error(err));
