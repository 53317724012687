<nav class="navbar navbar-light bg-light navbarHeaderforCenter">
  <ul class="nav navbar">
    <li class="m-1">
      <button (click)="back()" class="btn btn-secondary" i18n type="button">{{'back' | translate }}</button>
    </li>
  </ul>
  <div class="text-center divHeaderforCenter">
    <h3 i18n>{{'navBar.about' | translate }}</h3>
  </div>
  <ul class="nav navbar-right">
    <li class="m-1">
      <button *ngIf="_auth.hasRole(['Super User'])" (click)="addVersion()" class="btn btn-secondary" i18n
        type="button">{{'about.addVersion' | translate }}</button>
    </li>
  </ul>
</nav>

<div class="row m-0 mb-1">

</div>


<div class="row m-0 justify-content-center">
  <div class="col-12 mb-2">
    <div class="card">
      <div class="card-body">
        <div>ECG Access</div>
        <div>Version <span *ngIf="version">{{version.appVersion}} ({{version.versionDate}})</span></div>
      </div>
    </div>
  </div>

  <div class="col-12">
    <nav ngbNav #nav="ngbNav" class="nav-tabs">
      <ng-container ngbNavItem *ngFor="let category of categories">
        <a ngbNavLink (click)="activeCategory = category"> {{ category.title }} </a>
        <ng-template ngbNavContent>

          <div class="row">
            <div class="card-body">
              <div *ngFor="let changeLog of filterChangeLogs(category.id)" class="col-12 mb-2">
                <div class="card">
                  <div class="card-body">
                    <div class="card-title">
                      <h2>
                        <button class="btn btn-icon mr-2" (click)="changeLog.opened = !changeLog.opened">
                          <i *ngIf="!changeLog.opened" class="fa fa-chevron-right"></i>
                          <i *ngIf="changeLog.opened" class="fa fa-chevron-down"></i>
                        </button>
                        <span *ngIf="_auth.hasRole(['Super User'])" class="badge badge-dark">
                          <editable (save)="changeLog.changed = true;">
                            <ng-template viewMode>{{ changeLog.text }}</ng-template>
                            <ng-template editMode>
                              <input editableOnEnter editableOnEscape [(ngModel)]="changeLog.text" />
                            </ng-template>
                          </editable>
                        </span>
                        <span *ngIf="!_auth.hasRole(['Super User'])" class="badge badge-dark">
                          {{ changeLog.text }}
                        </span>
                        <span *ngIf="changeLog.text == version.appVersion" class="badge badge-sm badge-secondary"
                          style="margin-left: 10px; font-size: 15px;">
                          CURRENT
                        </span>

                        <span *ngIf="_auth.hasRole(['Super User'])" class="ml-3 text-muted" style="font-size: 18px;">
                          <editable (save)="changeLog.changed = true;">
                            <ng-template viewMode>{{changeLog.dateTime}}</ng-template>
                            <ng-template editMode>
                              <input placeholder="dd-mm-yyyy" [textMask]="{mask: mask}" [(ngModel)]="changeLog.dateTime"
                                type="text" />
                            </ng-template>
                          </editable>


                        </span>
                        <span *ngIf="!_auth.hasRole(['Super User'])" class="ml-3 text-muted"
                          style="font-size: 18px;">{{changeLog.dateTime}}</span>

                        <div *ngIf="changeLogFiles(changeLog).length > 0" ngbDropdown
                          class="float-right d-inline-block">
                          <button class="btn btn-icon btn-primary mr-2" style="vertical-align: top;" id="dropdownBasic1"
                            ngbDropdownToggle>Files ({{changeLogFiles(changeLog).length}})</button>
                          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <div *ngFor="let changeLogFile of changeLogFiles(changeLog)" ngbDropdownItem
                              class="d-flex align-items-center justify-content-between">
                              <a [href]="changeLogFile.path" target="blank">
                                {{ changeLogFile.text }}
                              </a>
                              <button *ngIf="_auth.hasRole(['Super User'])"
                                (click)="deleteChangeLogFile(changeLog, changeLogFile)"
                                class="btn btn-icon btn-danger btn-sm ml-3" [disabled]="changeLog.loading">
                                <i *ngIf="changeLog.loading" class="fa fa-spinner fa-spin"></i>
                                <i *ngIf="!changeLog.loading" class="fa fa-times"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="changeLogImages(changeLog).length > 0" ngbDropdown
                          class="float-right d-inline-block">
                          <button class="btn btn-icon btn-primary mr-2" style="vertical-align: top;" id="dropdownBasic1"
                            ngbDropdownToggle>Images ({{changeLogImages(changeLog).length}})</button>
                          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <div *ngFor="let changeLogFile of changeLogImages(changeLog)" ngbDropdownItem
                              class="d-flex align-items-center justify-content-between">
                              <a [href]="changeLogFile.path" target="blank">
                                {{ changeLogFile.text }}
                              </a>
                              <button *ngIf="_auth.hasRole(['Super User'])"
                                (click)="deleteChangeLogFile(changeLog, changeLogFile)"
                                class="btn btn-icon btn-danger btn-sm ml-3" [disabled]="changeLog.loading">
                                <i *ngIf="changeLog.loading" class="fa fa-spinner fa-spin"></i>
                                <i *ngIf="!changeLog.loading" class="fa fa-times"></i>
                              </button>
                            </div>
                          </div>
                        </div>

                        <button *ngIf="_auth.hasRole(['Super User'])" (click)="deleteChangeLog(changeLog, changeLog)"
                          class="btn btn-icon btn-danger float-right mr-2" [disabled]="changeLog.loading">
                          <i *ngIf="changeLog.loading" class="fa fa-spinner fa-spin"></i>
                          <i *ngIf="!changeLog.loading" class="fa fa-times"></i>
                        </button>

                        <button *ngIf="_auth.hasRole(['Super User'])" class="btn btn-icon btn-success float-right mr-2"
                          (click)="addChangeLog(changeLog)" [disabled]="changeLog.loading">
                          <i *ngIf="changeLog.loading" class="fa fa-spinner fa-spin"></i>
                          <i *ngIf="!changeLog.loading" class="fa fa-plus"></i>
                        </button>

                        <button *ngIf="changeLog.changed && _auth.hasRole(['Super User'])"
                          (click)="updateChangeLog(changeLog)" class="btn btn-icon btn-success float-right mr-2"
                          [disabled]="changeLog.loading">
                          <i *ngIf="changeLog.loading" class="fa fa-spinner fa-spin"></i>
                          <i *ngIf="!changeLog.loading" class="fa fa-check"></i>
                        </button>

                        <button *ngIf="_auth.hasRole(['Super User'])" class="btn btn-icon btn-primary float-right mr-2"
                          (click)="addFile(addFileModal, changeLog)" [disabled]="changeLog.loading">
                          <i *ngIf="changeLog.loading" class="fa fa-spinner fa-spin"></i>
                          <i *ngIf="!changeLog.loading" class="fa fa-file-o"></i>
                        </button>

                      </h2>
                    </div>

                    <div class="row changeLogItemContainer" *ngIf="changeLog.opened">
                      <div class="col-lg-12 d-flex changeLogItem" *ngFor="let changeLogItem of changeLog.subLogs">
                        <div class="" style="width: 85px;">
                          <span *ngIf="_auth.hasRole(['Super User'])"
                            [class]="'badge badge-lg badge-' + getBadgeClassByStatus(changeLogItem.type) + ' mr-2'">
                            <editable (save)="changeLogItem.changed = true;">
                              <ng-template viewMode>
                                {{getBadgeTextByStatus(changeLogItem.type)}}
                              </ng-template>
                              <ng-template editMode>
                                <select class="form-control" [(ngModel)]="changeLogItem.type">
                                  <option [value]="2" [innerText]="'Added'"></option>
                                  <option [value]="3" [innerText]="'Updated'"></option>
                                  <option [value]="4" [innerText]="'Fixed'"></option>
                                  <option [value]="5" [innerText]="'Removed'"></option>
                                </select>
                              </ng-template>
                            </editable>
                          </span>
                          <span *ngIf="!_auth.hasRole(['Super User'])"
                            [class]="'badge badge-lg badge-' + getBadgeClassByStatus(changeLogItem.type) + ' mr-2'">
                            {{getBadgeTextByStatus(changeLogItem.type)}}
                          </span>
                        </div>
                        <div class="d-flex" style="flex: 1;">
                          <editable *ngIf="_auth.hasRole(['Super User'])" (save)="changeLogItem.changed = true;"
                            style="white-space: pre-line; width: 100%;">
                            <ng-template viewMode>{{changeLogItem.text}}</ng-template>
                            <ng-template editMode>
                              <textarea editableOnEscape (keyup)="autoGrowTextZone($event)"
                                (focus)="autoGrowTextZone($event)" [(ngModel)]="changeLogItem.text"
                                class="form-control"></textarea>
                            </ng-template>
                          </editable>
                          <span *ngIf="!_auth.hasRole(['Super User'])">
                            {{changeLogItem.text}}
                          </span>
                        </div>
                        <div class="">

                          <div *ngIf="changeLogFiles(changeLogItem).length > 0" ngbDropdown
                            class="float-right d-inline-block">
                            <button class="btn btn-icon btn-primary mr-2" style="vertical-align: top;"
                              id="dropdownBasic1" ngbDropdownToggle>Files
                              ({{changeLogFiles(changeLogItem).length}})</button>
                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                              <div *ngFor="let changeLogFile of changeLogFiles(changeLogItem)" ngbDropdownItem
                                class="d-flex align-items-center justify-content-between">
                                <a [href]="changeLogFile.path" target="blank">
                                  {{ changeLogFile.text }}
                                </a>
                                <button *ngIf="_auth.hasRole(['Super User'])"
                                  (click)="deleteChangeLogFile(changeLogItem, changeLogFile)"
                                  class="btn btn-icon btn-danger btn-sm ml-3" [disabled]="changeLogItem.loading">
                                  <i *ngIf="changeLogItem.loading" class="fa fa-spinner fa-spin"></i>
                                  <i *ngIf="!changeLogItem.loading" class="fa fa-times"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="changeLogImages(changeLogItem).length > 0" ngbDropdown
                            class="float-right d-inline-block">
                            <button class="btn btn-icon btn-primary mr-2" style="vertical-align: top;"
                              id="dropdownBasic1" ngbDropdownToggle>Images
                              ({{changeLogImages(changeLogItem).length}})</button>
                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                              <div *ngFor="let changeLogFile of changeLogImages(changeLogItem)" ngbDropdownItem
                                class="d-flex align-items-center justify-content-between">
                                <a [href]="changeLogFile.path" target="blank">
                                  {{ changeLogFile.text }}
                                </a>
                                <button *ngIf="_auth.hasRole(['Super User'])"
                                  (click)="deleteChangeLogFile(changeLogItem, changeLogFile)"
                                  class="btn btn-icon btn-danger btn-sm ml-3" [disabled]="changeLogItem.loading">
                                  <i *ngIf="changeLogItem.loading" class="fa fa-spinner fa-spin"></i>
                                  <i *ngIf="!changeLogItem.loading" class="fa fa-times"></i>
                                </button>
                              </div>
                            </div>
                          </div>


                          <button *ngIf="_auth.hasRole(['Super User'])"
                            (click)="deleteChangeLog(changeLogItem, changeLog)"
                            class="btn btn-icon btn-danger float-right mr-2" [disabled]="changeLogItem.loading">
                            <i *ngIf="changeLogItem.loading" class="fa fa-spinner fa-spin"></i>
                            <i *ngIf="!changeLogItem.loading" class="fa fa-times"></i>
                          </button>

                          <button (click)="updateChangeLog(changeLogItem)"
                            *ngIf="changeLogItem.changed && _auth.hasRole(['Super User'])"
                            class="btn btn-icon btn-success float-right mr-2" [disabled]="changeLogItem.loading">
                            <i *ngIf="changeLogItem.loading" class="fa fa-spinner fa-spin"></i>
                            <i *ngIf="!changeLogItem.loading" class="fa fa-check"></i>
                          </button>


                          <button *ngIf="_auth.hasRole(['Super User'])"
                            class="btn btn-icon btn-primary float-right mr-2"
                            (click)="addFile(addFileModal, changeLogItem)" [disabled]="changeLog.loading">
                            <i *ngIf="changeLogItem.loading" class="fa fa-spinner fa-spin"></i>
                            <i *ngIf="!changeLogItem.loading" class="fa fa-file-o"></i>
                          </button>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </ng-template>
      </ng-container>
    </nav>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
</div>
<div class="container-fluid ml-3 mr-3">
  <p>{{year}}<span>©</span> Fysiologic.</p>
</div>


<ng-template #addFileModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title" i18n>{{'profileForm.deletion' | translate }}</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="form">
      <div class="form-group">
        <label>Type</label>
        <select [(ngModel)]="addFileModel.type" class="form-control">
          <option *ngFor="let item of fileTypes" [value]="item.id" [innerText]="item.name"></option>
        </select>
      </div>

      <div class="form-group">
        <label>Name</label>
        <input [(ngModel)]="addFileModel.text" class="form-control" />
      </div>

      <ngx-dropzone *ngIf="addFileModel.type == 1 || addFileModel.type == 2" (change)="onSelect($event)"
        [multiple]="false">
        <ngx-dropzone-label>Drop files here or click to upload.</ngx-dropzone-label>
        <ngx-dropzone-preview class="pdf-upload-box" *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
          <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
        </ngx-dropzone-preview>
      </ngx-dropzone>

      <div class="form-group" *ngIf="addFileModel.type == 3">
        <label>Url</label>
        <input [(ngModel)]="addFileModel.path" class="form-control" />
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="fileSubmit()" i18n>
      Save
    </button>
    <button type="button" ngbAutofocus class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')" i18n>
      {{'cancel' | translate}}
    </button>
  </div>
</ng-template>
