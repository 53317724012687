import * as i0 from "@angular/core";
import * as i1 from "../common/auth/auth-api-http-client";
export class CradleMapService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    getCradles() {
        return this.httpClient.get('health/cradles/activeCradle');
    }
}
CradleMapService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CradleMapService_Factory() { return new CradleMapService(i0.ɵɵinject(i1.AuthApiHttpClient)); }, token: CradleMapService, providedIn: "root" });
