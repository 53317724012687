import { Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AuthService } from '../../common/auth/auth.service';
import { Location } from '@angular/common';
import { ToastService } from '../../shared/services/toast.service';
import { Logger } from '../../common/logging/logger.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { DataApiResult, ExceptionDetail } from '../../common/data/result';
import { Subscription } from 'rxjs';
import { Profile } from '../../models/profile';
import { Version } from '../../models/version';
import { getVersion } from '../../common/utilities/getVersion.utility'
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { AuthApiHttpClient } from 'src/app/common/auth/auth-api-http-client';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageMap } from '@ngx-pwa/local-storage';
import { LocalStorageService } from 'angular-2-local-storage';

@Component({
  selector: 'ecg-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent implements OnInit, OnDestroy {
  @ViewChild('savedSuccessMessage', { static: false }) savedSuccessMessage: TemplateRef<any>;
  @ViewChild('savedErrorMessage', { static: false }) savedErrorMessage: TemplateRef<any>;
  model: Profile = { username: '', secretQuestion: '', secretAnswer: '', password: '', confirmPassword: '', currentPassword: '', isActiveGoogleAuth: false, isActiveEmailAuth: false };
  userId: number;
  readonly fakePassword = '';
  patientservice: any;
  key = 'value';
  emailcode = 'email-code';
  username = 'username';
  version: Version;
  authPart = false;
  deactivited = true;
  year: number;
  private loadingSubscription: Subscription;
  private resultSubscription: Subscription;
  private loginSubscription: Subscription;
  private navigateParams: Params;


  constructor(protected storageMap: StorageMap, private authService: AuthService, private location: Location, private toast: ToastService, private cookieService: CookieService, private modalService: NgbModal,
    private logger: Logger, private router: Router, public route: ActivatedRoute, public http: HttpClient, public httpClient: AuthApiHttpClient, private localStorageService: LocalStorageService) {
    this.authService.getCreatePassword(route.queryParams[this.key][this.emailcode]).subscribe(
      r => {
        if (r === null) {
          this.router.navigate(['/login']);
        } else {
          this.model.username = r[this.username];
        }
      }
    );
    getVersion(http).subscribe(res => {
      this.version = res;
    });

    this.year = new Date().getFullYear();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
    if (this.resultSubscription) {
      this.resultSubscription.unsubscribe();
    }
  }

  save() {
    this.loginSubscription = this.authService.setCreatePassword(this.route.queryParams[this.key][this.emailcode], this.model, "?type=create").subscribe(
      r => {
        const token = r.data.data.tokenResult ? r.data.data.tokenResult : r.data.data; 
        console.log(token);
        this.authService.saveToken(token);
        this.toast.showSuccess('Password saved successfully');
        this.authPart = true;
      }
    );
  }

  getUserLogIn() {
    this.cookieService.set('date', Date.now() / 1000 + '');

    if (this.authService.user !== null && this.authService.user.customerCodeId !== null) {
      localStorage.setItem('customerCodeId', this.authService.user.customerCodeId);
    }
    if (this.authService.user !== null && this.authService.user.username) {
      localStorage.setItem('UserEmail', this.authService.user.username);
    }

    if (this.authService.hasRole(['System Admin', 'Support', 'Support DK'])) {
      return this.router.navigate(['/cradles'], { queryParams: {} });
    }
    if (this.authService.hasRole(['Diary User'])) {
      return this.router.navigate(['/diary-upload'], { queryParams: {} });
    }
    if (this.authService.hasRole(['Staff Fysiologic'])) {
      return this.router.navigate(['/records'], { queryParams: {} });
    }
    if (this.authService.hasRole(['Finance', 'Financial Admin'])) {
      return this.router.navigate(['/specifications'], { queryParams: {} });
    }

    this.localStorageService.remove('cradleSerialNumber');
    sessionStorage.clear();

    this.storageMap.delete('step').subscribe(() => {
      let stepStorage = '';
      this.storageMap.get('step', { type: 'string' }).subscribe((result) => {
        stepStorage = result;
        if ((!stepStorage || stepStorage == null || stepStorage == 'branch' || stepStorage == 'cradleChoose') && !this.authService.hasRole(['Doctor Without Cradle','Super User','System Admin','Finance', 'Financial Admin'])) {
          this.router.navigate(['/branch'], { queryParams: {} });
        } else {
          if (this.authService.hasRole(['Super User'])) {
            return this.router.navigate(['/cradles'], { queryParams: {}});
          } else {
            return this.router.navigate(['/records'], { queryParams: {}});
          }
        }
      });
    });
  }

  async deactiveAuthentication() {
    const response = await this.httpClient.post<DataApiResult<any>, {}>({}, 'auth/authenticator/authenticator-deactivate-first-login').toPromise();
    if (response.data) {
      await this.authService.forceRefresh().toPromise();
      return this.getUserLogIn();
    }
  }

  shouldDisplaySkipAuthenticationButton() {
    return this.authService.hasRole(['System Admin', 'Super User']);
  }

  open(content, modalSize) {
    this.modalService.open(content, { size: 'xl', centered: true });
  }

  @HostListener('window:keydown.shift.backspace')
  back() {
    this.location.back();
  }
}
