
import {
  AfterViewInit,
  Component,
  EventEmitter, Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,

  OnChanges,
  SimpleChanges
} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {NgbPanelChangeEvent} from '@ng-bootstrap/ng-bootstrap';
import {FilterMetaData, FilterMetaDataItem, Filters, FilterType, OperatorType, SelectChange} from '../../common/data/result';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel} from '@angular/forms';
import {debounce, debounceTime, delay, distinctUntilChanged, last, skip, take} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'ecg-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements  OnDestroy, OnChanges, AfterViewInit {

  @ViewChildren(NgModel) models: QueryList<NgModel>;
  @Input() isAuto = false;
  @Input() metaData: Observable<FilterMetaData[]>;
  @Output() filterChange = new EventEmitter<Filters>();
  @Output() selectChange : EventEmitter<SelectChange>=new EventEmitter<SelectChange>();
  @Input() groupSize = 4;
  @Input() maxDate: Date = new Date();
  @Input() reset: boolean = false;

  get colSize() {
    return 'col-md-' + Math.ceil(12 / this.groupSize);
  }

  constructor(private translateService: TranslateService) {
  }

  private metaData$: Subscription;
  private currentFilters: FilterMetaData[] = [];
  private modelSubscriptions: Subscription[];
  private filterButton = { key: '', type: -1 };
  selectionFilter: any;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reset && changes.reset.currentValue) {
      if (this.currentFilters) {
        this.currentFilters.forEach((filter, filterIndex) => {
          filter.items.forEach((item, itemIndex) => {
            if (item.key == "CreatedDateTime" || item.key == "UpdatedDateTime") {
              item._value = [null, null];
            } else {
              item._value = null;
            }
            if (item.type == 1) {
              item._value = undefined;
            }
          });
        });
      }
      //this.ngOnInit();
    }

    if (changes.metaData && this.metaData) {
      this.metaData.subscribe((data: FilterMetaData[]) => {
        this.modifyMetaData(data);
      });
    }
  }
  onSelectChange(event,metaKey){
   this.selectChange.emit( {event : event,key: metaKey});
  }
  ngOnDestroy() {
    if (this.metaData$) {
      this.metaData$.unsubscribe();
    }
    this.clearModelSubscriptions();
  }

  private modifyMetaData(data: FilterMetaData[]) {
    //debugger;
    if (data[0].title == "Patient") {
      this.selectionFilter = JSON.parse(localStorage.getItem("recordsFilter"));
    } else if (data[0].title == "Cradle") {
      this.selectionFilter = JSON.parse(localStorage.getItem('cradlesFilter'));
    } else if (data[0].title == "Organisations") {
      this.selectionFilter = JSON.parse(localStorage.getItem('customersFilter'));
    } else if (data[0].title == "UserAccounts") {
      this.selectionFilter = JSON.parse(localStorage.getItem('sysadmin/user-accountsFilter'));
    } else if (data[0].title == "PatientDiary") {
      this.selectionFilter = JSON.parse(localStorage.getItem('diary-uploadFilter'));
    } else if (data[0].title == "IpWhiteList") {
      this.selectionFilter = JSON.parse(localStorage.getItem('ip-whitelistFilter'));
    } else if (data[0].title == "Notes") {
      this.selectionFilter = JSON.parse(localStorage.getItem('notesFilter'));
    } else if (data[0].title == "AccessRequest") {
      this.selectionFilter = JSON.parse(localStorage.getItem('ip-whitelist/access-requestsFilter'));
    } else if (data[0].title == "WorkList") {
      this.selectionFilter = JSON.parse(localStorage.getItem('worklistFilter'));
    } else if (data[0].title == "AuthLogs") {
      this.selectionFilter = JSON.parse(localStorage.getItem('auth-logsFilter'));
    } else if (data[0].title == "Invoice") {
      this.selectionFilter = JSON.parse(localStorage.getItem('invoicesFilter'));
    } 
    else if (data[0].title == "InvoiceUpload") {
      this.selectionFilter = JSON.parse(localStorage.getItem('invoiceUploadFilter'));
    }
    // else if (data[0].title == "Report") {
    //   this.selectionFilter = JSON.parse(localStorage.getItem('reportFilter'));
    // }
    if (this.currentFilters) {
      data.forEach((filter, filterIndex) => {
        const currentFilter = this.currentFilters[filterIndex];
        filter.items.forEach((item, itemIndex) => {
          if (this.selectionFilter && this.selectionFilter[item.key] && this.selectionFilter[item.key][0] && this.selectionFilter[item.key][0].right) {
            if (item.key == "CreatedDateTime" || item.key == "UpdatedDateTime" || item.key == "BirthDate" || item.key == "AppointmentInformation.StartDate") {
              item._value = [null, null];
              if (this.selectionFilter[item.key][0]) {
                item._value[1] = new Date(this.selectionFilter[item.key][0].right.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));
              }
              if (this.selectionFilter[item.key][1]) {
                item._value[0] = new Date(this.selectionFilter[item.key][1].right.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));
              }
              if (item._value[0] == null && item._value[1]) {
                item._value[0] = item._value[1];
                item._value[1] = null;
              }
            }else {
              item._value = this.selectionFilter[item.key][0].right;
            }
          } else {
            item._value = currentFilter && currentFilter.items[itemIndex]._value;
          }
        }
        );
      });
    }
    if (!this.isAuto) {
      const lastData = data[data.length - 1];
      if (lastData && !lastData.items.find(item => (item.type as number) === -1)) {
        lastData.items = [...lastData.items, this.filterButton];
      }
    }
    this.currentFilters = data;
  }

  clearModelSubscriptions() {
    if (this.modelSubscriptions) {
      this.modelSubscriptions.forEach(m => m.unsubscribe());
    }
  }

  ngAfterViewInit(): void {
    if (this.models && this.isAuto) {
      this.subscribeModels(this.models);
      this.models.changes.subscribe(models => {
        this.subscribeModels(models);
      });
    }
  }

  subscribeModels(models: QueryList<NgModel>) {
    this.clearModelSubscriptions();
    this.modelSubscriptions = models.map(model => {
      return model.valueChanges.pipe(skip(1), debounceTime(500), distinctUntilChanged()).subscribe((va) => {
        this.emitFilters();
        debugger;
      }, err => console.log(err));
    });
  }

  getTranslate(meta: FilterMetaData, ...args) {
    return this.translateService.instant('filterForm.' + meta.title + '.' + args.join('.'));
  }
  onKeyUp(x) { // appending the updated value to the variable
  
    this.emitFilters();
  }

  emitFilters() {
   this.showLoading();
    if (this.currentFilters) {
      const newFilters = this.currentFilters
        .map(item => item.items.map(filterItem => { // set filter item hidden value to filters so we can use it on server side
            if (filterItem.type >= 0) {
              if (filterItem.type === FilterType.DateItem) {
                this.setDateFilter(filterItem);
              } else {
                filterItem.filters.forEach(filter => {
                  if (filter.normalizer) {
                    filter.right = this[filter.normalizer](filterItem._value);
                  } else {
                    filter.right = filterItem._value;
                  }
                });
              }
              return filterItem;
            }
          }).filter(filterItem => filterItem)
        )
        .reduce((prev, curr) => [...prev, ...curr], []) // Flatten all filters so we can send them as filter object.
        .map(item => ({filters: item.filters.filter(f => f.right), key: item.key}))
        .reduce((prev, curr) => {
          if (prev.hasOwnProperty(curr.key)) {
            prev[curr.key] = [...prev[curr.key], ...curr.filters];
          } else {
            prev[curr.key] = [...curr.filters];
          }
          return prev;
        }, {});
      this.filterChange.emit(newFilters);
      Swal.close();
    }
  }

  showLoading() {
    Swal.fire({
      position: 'center',
      text:this.translateService.instant("cradleForm.searching"),
      imageUrl: 'assets/gifs/loading.gif',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      timer: 44000,
    });
  }

  /***
   * Normalizer function
   * @param value string
   */
  name(value: string) {
    return value && value.split(' ')[0];
  }

  /***
   * Normalizer function
   * @param value string
   */
  secondName(value: string) {
    if (value) {
      const parts = value.split(' ');
      if (parts.length > 2) {
        return parts[1];
      }
    }
  }

  /***
   * Normalizer function
   * @param value string
   */
  lastName(value: string) {
    if (value) {
      return value && value.split(' ')[0];
    }
  }

  toggleFilter(type: 'open' | 'close' | 'toggle' = 'toggle') {
  }

  private setDateFilter(filterItem: FilterMetaDataItem) {
    if (filterItem._value) {
      const [startDate, endDate]: [Date, Date] = filterItem._value;
      const start = filterItem.filters.find(f => [OperatorType.Greater, OperatorType.GreaterOrEqual].indexOf(f.operator) > -1);
      const end = filterItem.filters.find(f => [OperatorType.Lower, OperatorType.LowerOrEqual].indexOf(f.operator) > -1);
      if (start && startDate) {
        startDate.setHours(0, 0);
        start.right = startDate;
      }
      if (end && endDate) {
        endDate.setHours(23, 59);
        end.right = endDate;
      }
    }
  }
}

