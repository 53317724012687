import { Component, OnInit } from '@angular/core';
import Chart from 'chart.js';
import { AuthApiHttpClient } from 'src/app/common/auth/auth-api-http-client';
import { first } from 'rxjs/operators';
import { Dashboard } from 'src/app/models/dashboard';
import { DataApiResult, Paging } from 'src/app/common/data/result';
import { DashboardOrganisation } from 'src/app/models/dashboardOrganisation';
import { BaseListComponent } from 'src/app/common/components/base-list.component';
import { DashboardService } from './dashboard.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from 'src/app/common/logging/logger.service';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';

export enum Range {
  daily = 10,
  weekly = 20,
  monthly = 30,
  yearly = 40
}

@Component({
  selector: 'ecg-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent extends BaseListComponent<DashboardOrganisation> {
  dateType = "daily";
  dashboard: Dashboard;
  dashboardOrganisation: Array<DashboardOrganisation>;
  charts = {};
  activeValues = {};
  waitingArray = [];

  searchText: string = "";

  constructor(service: DashboardService,
    spinner: NgxSpinnerService,
    router: Router,
    route: ActivatedRoute,
    public logger: Logger,
    private translateService: TranslateService,
    public httpClient: AuthApiHttpClient
  ) {
    super({
      service, spinner, router, route,
      idColumn: 'organisationName',
      formRoute: '/dashboard',
    });
  }

  ngOnInit() {
    this.updateCharts("daily", true);
    super.ngOnInit();
  }

  refresh() {

    //dont need to call super ın here
    this.paging.orders = [{column: "value", type: "desc"}]
    this.service.getSearch(this.paging, { filters: this.filters }, "get-organisations?range=" + Range[this.dateType] + (this.searchText ? "&searchText=" + this.searchText : ""));
  }
  updateCharts(key, firstTime) {
    this.dateType = key;
    if(!firstTime){
      this.refresh();
    }
    this.httpClient.get<DataApiResult<Dashboard>>('health/dashboard/get-status-count?range=' + Range[key] + (this.searchText ? "&searchText=" + this.searchText : "")).pipe(first()).subscribe(res => {
      this.dashboard = res.data;
      this.setChart(this.dashboard.patientPreparedCount, "Prepared", this.dashboard.dateLabels);
      this.setChart(this.dashboard.patientCompletedCount, "Completed", this.dashboard.dateLabels);
    });
    this.httpClient.get<DataApiResult<any>>('health/dashboard/get-waiting-patient?range=' + Range[key] + (this.searchText ? "&searchText=" + this.searchText : "")).pipe(first()).subscribe(res => {
      this.waitingArray = res.data.list;
      this.setChart(this.waitingArray, "Waiting", res.data.dateLabels);
    });
  }

  getArrayTotal(datum: Array<number>) {
    return datum.reduce(function (acc, cur) { return acc + cur; })
  }

  setChart(datum: Array<number>, chart: string, dateLabels: Array<string>) {
    document.getElementById(chart + "Graph").remove();
    let container = document.getElementById(chart + "Container");
    let newCanvas = document.createElement('canvas');
    newCanvas.setAttribute("id", chart + "Graph");
    container.appendChild(newCanvas);

    // let newCanvas:any = document.getElementById(chart + "Graph");
    let ctx = newCanvas.getContext('2d');
    var that = this;
    that.activeValues[chart] = undefined;
    this.charts[chart] = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: dateLabels,
        datasets: [{
          label: '',
          borderColor: '#3AA8E0',
          backgroundColor: 'rgb(58,168,224,1)',
          data: datum,
          maxBarThickness: 20
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          point: {
            radius: 0
          }
        },
        scales: {
            xAxes: [{
                stacked: true,
                gridLines: {
                  display: false
                },
                
            }],
            yAxes: [{
                stacked: true,
                ticks: {
                  beginAtZero: true,
                  maxTicksLimit: 3,
                },
            }],
        },
        onClick: function(event){
          var activeElement = this.getElementAtEvent(event)[0];
          if(!activeElement) return;
          var value = this.data.datasets[0].data[activeElement._index];
          that.activeValues[chart] = value;
        },
        legend: {
          display: false
        },
        tooltips: {
          mode: 'x-axis',
          titleFontSize: 10,
          displayColors: false,
          callbacks: {
            label: function (data) {
              return chart + " " + data.value;
            },
          },
        },

      }
    });

    // Update chart data (Did not work)
    // this.charts[chart].config.data.datasets[0] = datum;
    // this.charts[chart].config.data.labels = new Array(datum.length).fill("");
    // this.charts[chart].update();
  }

  
  pagingReset() {
    this.showLoading();
    this.paging = { size: 10, index: 1, search: null };
    Swal.close();
  }

  filter(){
    this.showLoading();
    this.updateCharts(this.dateType,false);
    Swal.close();
  }

  filterReset() {
    this.showFilter = true;
    this.searchText = "";
    this.pagingReset();
    this.filter();
    this.reset = false;
    localStorage.removeItem(this.router.url.substring(1) + "Filter");
  }

  showLoading() {
    Swal.fire({
      position: 'center',
      text:this.translateService.instant("cradleForm.searching"),
      imageUrl: 'assets/gifs/loading.gif',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    });
  }
}
