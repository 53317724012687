import { Injectable } from '@angular/core';
import {DataListService} from '../common/data/data-list.service';
import {AuthApiHttpClient} from '../common/auth/auth-api-http-client';
import {UserAccount} from '../models';

@Injectable({
  providedIn: 'root'
})
export class PcListService extends DataListService<UserAccount> {
  constructor(httpClient: AuthApiHttpClient) {
    super({
      httpClient,
      url: 'auth/pcs',
      initial: []
    });
  }
}
