import { DataItemService } from '../../common/data/data-item.service';
import * as i0 from "@angular/core";
import * as i1 from "../../common/auth/auth-api-http-client";
export class ReportErrorFormEditService extends DataItemService {
    constructor(httpClient) {
        super({
            httpClient,
            url: 'health/report',
        });
    }
    getItemId(id) {
        return this.httpClient.get(`health/errorLogReport/getById/${id}`);
    }
}
ReportErrorFormEditService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReportErrorFormEditService_Factory() { return new ReportErrorFormEditService(i0.ɵɵinject(i1.AuthApiHttpClient)); }, token: ReportErrorFormEditService, providedIn: "root" });
