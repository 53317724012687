import * as tslib_1 from "tslib";
import { OnDestroy } from "@angular/core";
import { BaseListComponent } from '../common/components/base-list.component';
import Swal from 'sweetalert2';
import { PDFDocument } from 'pdf-lib';
class ImageSnippet {
    constructor(src, file) {
        this.src = src;
        this.file = file;
        this.pending = false;
        this.status = 'init';
    }
}
export class PatientDiaryComponent extends BaseListComponent {
    /**
     *
     */
    constructor(localStorageService, httpClient, service, modal, spinner, router, route, sanitizer, auth, dagboekService, toast, translate, signalRService, storageMap, cradleItemService, logoutService) {
        super({
            service,
            spinner,
            router,
            route,
            idColumn: 'id',
            formRoute: 'diary-upload'
        });
        this.localStorageService = localStorageService;
        this.httpClient = httpClient;
        this.service = service;
        this.modal = modal;
        this.sanitizer = sanitizer;
        this.auth = auth;
        this.dagboekService = dagboekService;
        this.toast = toast;
        this.translate = translate;
        this.signalRService = signalRService;
        this.storageMap = storageMap;
        this.cradleItemService = cradleItemService;
        this.logoutService = logoutService;
        this.step = 'selectRecords';
        this.selectedRecords = [];
        this.files = [];
        this.allOptions = [];
        this.uploadFilePageModel = [];
        if (!this.auth.hasRole(['Diary User'])) {
            this.createConnection().catch(() => undefined);
        }
        this.cradleItemService.getCradleByLinkedMac(this.localStorageService.get('mac'), this.localStorageService.get('cradleSerialNumber')).subscribe((cradle) => {
            if (cradle && (cradle.linkedPC !== this.localStorageService.get('mac') && this.localStorageService.get('cradleSerialNumber') !== null && this.localStorageService.get('cradleSerialNumber') !== 'skip')) {
                this.logoutService.logoutUser(cradle.connectedUser, "takeOver");
            }
        });
    }
    ngOnDestroy() {
        if (this.hubConnection) {
            this.hubConnection.stop().catch(() => undefined);
        }
    }
    createConnection() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.hubConnection = yield this.signalRService.connect('/loghub');
            yield this.hubConnection.start();
            this.hubConnection.on('DisconnectedUser', (mac, inheritingUserName, operation) => {
                if (this.localStorageService.get('mac') === mac) {
                    this.logoutService.logoutUser(inheritingUserName, operation);
                }
            });
            // this.hubConnection.on('DisconnectedUserByUUId', (uuid) => {
            //   if(this.localStorageService.get("user-uuid")== uuid)
            //   {
            //     this.logoutService.logoutUser("", "disconnected");
            //   }
            // });
        });
    }
    isThere() {
        return true;
    }
    selectedRecordsChange(patient) {
        const selectedPatient = this.selectedRecords.find(x => x.id === patient.id);
        if (selectedPatient) {
            this.selectedRecords.splice(this.selectedRecords.indexOf(selectedPatient), 1);
        }
        else {
            this.selectedRecords.push(patient);
        }
    }
    stopNavigating(event) {
        event.stopPropagation();
    }
    nextUpload() {
        for (var i = 0; i < this.selectedRecords.length; i++) {
            this.selectedRecords[i].pdfFilePage = undefined;
        }
        this.step = 'fileUpload';
    }
    backSelectedRecords() {
        for (var i = 0; i < this.selectedRecords.length; i++) {
            this.selectedRecords[i].pdfFilePage = undefined;
        }
        this.step = 'selectRecords';
    }
    visibleControl(patient) {
        for (var i = 0; i < this.selectedRecords.length; i++) {
            if (this.selectedRecords[i].id === patient.id) {
                return true;
            }
        }
        return false;
    }
    processFile(file) {
        const reader = new FileReader();
        reader.addEventListener('load', (event) => {
            this.selectedFile = new ImageSnippet(event.target.result, file);
            this.selectedFile.pending = true;
            this.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file));
            this.getAllOptions(this.selectedFile);
        });
        reader.readAsDataURL(file);
    }
    onSelect(event) {
        if (this.files.length != 0) {
            this.files = [];
        }
        this.files.push(...event.addedFiles);
        this.processFile(event.addedFiles[0]);
    }
    getAllOptions(file) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.allOptions = [];
            const pdfDoc = yield PDFDocument.load(file.src);
            for (var i = 0; i < pdfDoc.getPageCount(); i++) {
                this.allOptions.push(i + 1);
            }
        });
    }
    pdfSplit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.showLoading();
            this.uploadFilePageModel = [];
            for (var i = 0; i < this.selectedRecords.length; i++) {
                let startPage = 1;
                let endPage = this.allOptions.length;
                var pdfFilePage = this.selectedRecords[i].pdfFilePage;
                if (pdfFilePage) {
                    var minus = pdfFilePage.search('-');
                    if (minus == -1) {
                        startPage = parseInt(pdfFilePage);
                        endPage = parseInt(pdfFilePage);
                    }
                    else {
                        const pdfFilePageSplit = pdfFilePage.split('-');
                        if (pdfFilePageSplit.length - 1 > 1) {
                            Swal.close();
                            this.toast.showDanger(this.translate.instant('uploadDiary.invalidPageRange'));
                            return false;
                        }
                        if (pdfFilePageSplit[0] == '' && pdfFilePageSplit[1] != '') {
                            endPage = parseInt(pdfFilePageSplit[1]);
                            startPage = endPage;
                        }
                        else if (pdfFilePageSplit[0] != '' && pdfFilePageSplit[1] == '') {
                            startPage = parseInt(pdfFilePageSplit[0]);
                            endPage = startPage;
                        }
                        else if (pdfFilePageSplit[0] == '' && pdfFilePageSplit[1] == '') {
                            startPage = 0;
                            endPage = 0;
                        }
                        startPage = parseInt(pdfFilePageSplit[0]);
                        endPage = parseInt(pdfFilePageSplit[1]);
                    }
                }
                if (endPage < startPage) {
                    const veriable = startPage;
                    startPage = endPage;
                    endPage = veriable;
                }
                if (startPage > 0 && endPage > 0 && startPage < this.allOptions.length + 1 && endPage < this.allOptions.length + 1) {
                    let multiplePatientFileUPload = {
                        patient: this.selectedRecords[i],
                        fileName: this.selectedRecords[i].pidNo,
                        base64: '',
                        cradleSerialNumber: this.selectedRecords[i].cradleSerialNumber
                    };
                    const pdfDocNew = yield PDFDocument.create();
                    const existingPdfBytes = this.selectedFile.src;
                    const pdfDoc = yield PDFDocument.load(existingPdfBytes);
                    try {
                        if (startPage != endPage || startPage < endPage) {
                            for (var y = 0; y < (endPage - startPage) + 1; y++) {
                                var test = startPage + y;
                                const copiedPagesMultiple = yield pdfDocNew.copyPages(pdfDoc, [test - 1]);
                                const [page] = copiedPagesMultiple;
                                pdfDocNew.addPage(page);
                            }
                        }
                        else {
                            const copiedPages = yield pdfDocNew.copyPages(pdfDoc, [startPage - 1]);
                            const [first] = copiedPages;
                            pdfDocNew.addPage(first);
                        }
                    }
                    catch (e) {
                        Swal.close();
                        this.toast.showDanger(e);
                        return false;
                    }
                    yield pdfDocNew.saveAsBase64().then((res) => {
                        multiplePatientFileUPload.base64 = 'data:application/pdf;base64,' + res;
                    });
                    this.uploadFilePageModel.push(multiplePatientFileUPload);
                }
                else {
                    Swal.close();
                    this.toast.showDanger(this.translate.instant('uploadDiary.invalidPageRange'));
                    return false;
                }
            }
            this.dagboekService.sendMultiplePatientPdf(this.uploadFilePageModel).subscribe(res => {
                if (res.data) {
                    this.paging.index = 1;
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Successful.',
                        showConfirmButton: false,
                        timer: 3000,
                        onClose: () => {
                        }
                    });
                    this.clearData();
                    this.selectedRecords = [];
                    this.refresh();
                    this.step = 'selectRecords';
                }
            }, err => {
                Swal.close();
                this.toast.showDanger(err.error.message);
            });
        });
    }
    showLoading() {
        Swal.fire({
            position: 'center',
            imageUrl: 'assets/gifs/loading.gif',
            showConfirmButton: false,
            timer: 44000,
        });
    }
    onRemove(event) {
        this.clearData();
    }
    clearData() {
        this.files = [];
        this.pdfFile = undefined;
        this.selectedFile = undefined;
        this.allOptions = [];
        this.uploadFilePageModel = [];
        for (var i = 0; i < this.selectedRecords.length; i++) {
            this.selectedRecords[i].pdfFilePage = undefined;
        }
    }
    numberOnly(event) {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode == 45) {
            return true;
        }
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }
}
