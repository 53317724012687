import { ActivatedRoute } from "@angular/router";
import { DataItemService } from "src/app/common/data/data-item.service";
import { DataApiResult, DataList, } from "src/app/common/data/result";
import * as i0 from "@angular/core";
import * as i1 from "../../../common/auth/auth-api-http-client";
import * as i2 from "@angular/router";
export class DateRangeService extends DataItemService {
    constructor(httpClient, route) {
        super({
            httpClient,
            url: "",
        });
        this.projectList = this.httpClient.get("health/platformPerformance/GetProjects");
    }
    getModuleList(projectName) {
        return this.httpClient.get("health/platformPerformance/GetModules?projectName=" + projectName);
    }
    getErrorLogModuleList(projectName) {
        return this.httpClient.get("health/errorLogReport/GetModules?projectName=" + projectName);
    }
    getLineData(params) {
        const actionUrl = "health/PlatformPerformance/Search";
        const needPost = params && (params.columns || params.filters);
        const method = needPost
            ? this.args.httpClient.post(params, actionUrl)
            : this.args.httpClient.get(actionUrl);
        return method;
    }
}
DateRangeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DateRangeService_Factory() { return new DateRangeService(i0.ɵɵinject(i1.AuthApiHttpClient), i0.ɵɵinject(i2.ActivatedRoute)); }, token: DateRangeService, providedIn: "root" });
