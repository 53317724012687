import * as i0 from "@angular/core";
import * as i1 from "../common/auth/auth-api-http-client";
export class RedisServerHealthService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    getServerHealth(serviceGroupName) {
        return this.httpClient.get('RedisHealthCheckApi/GetServicesHealth/' + serviceGroupName);
    }
}
RedisServerHealthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RedisServerHealthService_Factory() { return new RedisServerHealthService(i0.ɵɵinject(i1.AuthApiHttpClient)); }, token: RedisServerHealthService, providedIn: "root" });
