import * as tslib_1 from "tslib";
import { HttpParams } from '@angular/common/http';
import { DataItemService } from '../common/data/data-item.service';
import * as i0 from "@angular/core";
import * as i1 from "../common/auth/auth-api-http-client";
export class CradleItemService extends DataItemService {
    constructor(httpClient) {
        super({
            httpClient,
            url: 'health/cradles/item'
        });
        this.hospitalList = this.httpClient.get('health/cradles/customerCodes');
        this.userList = this.httpClient.get('auth/useraccounts');
    }
    setCradleId(req) {
        let params = new HttpParams();
        Object.keys(req).forEach(v => {
            if (req[v] && req[v] != '') {
                params = params.append(v, req[v]);
            }
        });
        return this.httpClient.get(`health/cradles/cradlelogs`, {
            params: params
        });
    }
    setCradleLogs(id) {
        //Cradle logları ceker.
        return this.httpClient.get('health/cradles/cradlelogs?id=' + id);
    }
    getCradleByLinkedMac(mac, cradleSerialNumber) {
        return this.httpClient.get(`health/cradles/by-linked-mac?mac=${mac || ''}&cradleSerialNumber=${cradleSerialNumber}`);
    }
    getBranchListService(customerId) {
        this.branchList = this.httpClient.get('health/cradles/customerBranchs/' + customerId);
    }
    controlLastPatient(cradleId, recorderStatus) {
        return this.httpClient.get(`health/cradles/control-last-patient?cradleId=${cradleId}&recorderStatus=${recorderStatus}`);
    }
    customerCodes() {
        return this.httpClient.get('health/cradles/customerCodes');
    }
    cradlePatientClear(patientId) {
        return this.httpClient.get(`health/cradles/cradle-patient-clear?patientId=${patientId}`);
    }
    getCradleType(cradleSerialNumber) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let uri = `health/cradles/${cradleSerialNumber}`;
            const promiseData = this.httpClient.get(uri).toPromise();
            return yield promiseData;
        });
    }
}
CradleItemService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CradleItemService_Factory() { return new CradleItemService(i0.ɵɵinject(i1.AuthApiHttpClient)); }, token: CradleItemService, providedIn: "root" });
