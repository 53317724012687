<div class="nexo-root container" style=" margin-top: calc(50vh - 295px);">
  <div class="row content my-3">
    <div class="col-12">

      <form #mainForm="ngForm" [ecgMustMatch]="['password', 'confirmPassword']" class="form-signin col-4">
        <div class="login-logo-area">
          <img class="logo-image" src="assets/images/login-logo.png" i18n-alt alt="Login Logo" />
        </div>
        <p></p>
        <div class="form-label-group" *ngIf="!authPart">
          <div class="form-row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
              <input type="text" class="form-control" i18n-placeholder placeholder="Username" id="eMail" name="eMail"
                     #eMail="ngModel" [(ngModel)]="model.username" required disabled>
              <div *ngIf="eMail.invalid && (eMail.dirty || eMail.touched)" class="alert alert-danger">
                <div *ngIf="eMail.errors['required']" i18n>
                    Username is required.
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-3">
              <input type="text" name="secretQuestion" #secretQuestion="ngModel" i18n-placeholder placeholder="Secret Question"
                     [(ngModel)]="model.secretQuestion" class="form-control"
                     required autofocus>
              <div *ngIf="secretQuestion.invalid && (secretQuestion.dirty || secretQuestion.touched)" class="alert alert-danger">
                <div *ngIf="secretQuestion.errors['required']" i18n>
                    Secret question is required.
                </div>
              </div>

            </div>

            
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-3">
              <input type="password" name="secretAnswer" #secretAnswer="ngModel" i18n-placeholder placeholder="Secret Answer"
                     [(ngModel)]="model.secretAnswer" class="form-control"
                     required autofocus>
              <div *ngIf="secretAnswer.invalid && (secretAnswer.dirty || secretAnswer.touched)" class="alert alert-danger">
                <div *ngIf="secretAnswer.errors['required']" i18n>
                    Secret answer is required.
                </div>
              </div>
            </div>

          </div>
          <div class="form-row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-3">
              <input type="password" class="form-control" id="password"
                     name="password" #password="ngModel" i18n-placeholder placeholder="New password"
                     pattern="((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[-=[!#@£$%^&*()\]_+|}{:?></.,:;§'']).{8,16})" [(ngModel)]="model.password" required>
              <div *ngIf="password.invalid && (password.dirty || password.touched)" class="alert alert-danger">
                <div *ngIf="password.errors['required']" i18n>
                  New password is required.
                </div>
                <div *ngIf="password.errors['pattern']" i18n>
                  Your password must be 8-16 characters, include at least one lowercase letter, one uppercase letter, a number and one special key.
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-3">
              <input type="password" class="form-control" i18n-placeholder placeholder="Confirm Password" [(ngModel)]="model.confirmPassword"
                     id="confirmPassword" name="confirmPassword" #confirmPassword="ngModel"
                     required>
              <div *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)"
                   class="alert alert-danger">
                <div *ngIf="confirmPassword.errors['required']" i18n>
                  Confirm password is required.
                </div>
                <div *ngIf="confirmPassword.errors['mustMatch']" i18n>
                  Passwords must match.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="authPart">
          <ecg-authentication name="authPart" [(ngModel)]="model" [page]="'login'"></ecg-authentication>
        </div>
        <div class="checkbox mb-3">
        </div>
        <hr />
        <div class="form-label-group">
          <button *ngIf="!authPart" class="btn btn-lg btn-primary btn-block" type="submit" (click)="save()" [disabled]="!mainForm.form.valid" i18n>Create password</button>
          <button *ngIf="authPart && !shouldDisplaySkipAuthenticationButton()" class="btn btn-lg btn-light btn-block btn-sm" (click)="open(confirmDeleteModal, 'lg')" type="submit" i18n>
              Skip authentication
          </button>
        </div>
        <hr />
        <div class="login-footer-area">
          <p>Fysiologic&#174; ECGAccess &copy; {{year}}</p>
          <small>ECGAccess Version <span *ngIf="version">{{version.appVersion}}</span></small>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #confirmDeleteModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" i18n id="modal-title">Deactivate</h4>
    <button (click)="modal.dismiss('Cross click');deactivited = true;" aria-describedby="modal-title" aria-label="Close button" class="close"
            type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="two-factor-auth-message">
      Information security is of the utmost importance to Fysiologic ECG Services BV. We work with large amounts of medical and privacy sensitive data. Fysiologic ECG Services BV is ISO 27001 certified and NEN 7510 accredited.\n\nIn this way, we comply with European laws and regulations. These laws and regulations stipulate that 2-factor authentication is a requirement to access a medical information system: 'Information systems that process patient data should apply authentication based on at least two separate characteristics'.\n\nYou are about to deactivate the two-factor authentication! In accordance with the applicable information security standard (ISO 27001 & NEN 7510), it is mandatory to secure information systems that process patient data with an additional authentication factor. By not activating this extra security, Fysiologic ECG Services BV transfers responsibility for the adequate security of data to you. If agreed, you can decide to deactivate the two-factor authentication, this setting you can always reactivate.
    </p>
  </div>
  <div class="modal-footer btn-group">
    <button (click)="deactiveAuthentication();modal.dismiss('cancel click');" class="btn btn-danger" i18n type="button">
      I deactivate two-factor authentication
    </button>
    <button (click)="modal.dismiss('cancel click');deactivited = true;" class="btn btn-outline-secondary" i18n ngbAutofocus type="button">
      I want to active two-factor authentication
    </button>
  </div>
</ng-template>
