import { DataItemService } from 'src/app/common/data/data-item.service';
import * as i0 from "@angular/core";
import * as i1 from "../../common/auth/auth-api-http-client";
export class ChangeLogService extends DataItemService {
    constructor(httpClient) {
        super({ httpClient, url: 'health/changeLog/item' });
    }
    getLogs() {
        return this.httpClient.get('health/changeLog/get-logs');
    }
    create(model) {
        return this.httpClient.post(model, 'health/changeLog/item');
    }
    update(model) {
        return this.httpClient.put(model, 'health/changeLog/item');
    }
    deleteItem(id) {
        return this.httpClient.delete(null, `health/changeLog/item?id=${id}`);
    }
    createFile(model) {
        return this.httpClient.post(model, 'health/changeLog/create-file');
    }
    deleteFile(id) {
        return this.httpClient.delete(null, `health/changeLog/delete-file?id=${id}`);
    }
}
ChangeLogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChangeLogService_Factory() { return new ChangeLogService(i0.ɵɵinject(i1.AuthApiHttpClient)); }, token: ChangeLogService, providedIn: "root" });
