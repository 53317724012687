import * as tslib_1 from "tslib";
import { AfterViewChecked, OnDestroy, OnInit } from '@angular/core';
import { supportedLocales } from '../../../environments/app.config.json';
import { getVersion } from '../../common/utilities/getVersion.utility';
import Swal from "sweetalert2";
import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js';
const FINGER_PRINT_KEY = "fingerPrintKey";
export class LoginComponent {
    constructor(authService, httpClient, router, cookieService, route, logger, localStorageService, translate, toast, modalService, storageMap, http, cradleHubService) {
        this.authService = authService;
        this.httpClient = httpClient;
        this.router = router;
        this.cookieService = cookieService;
        this.route = route;
        this.logger = logger;
        this.localStorageService = localStorageService;
        this.translate = translate;
        this.toast = toast;
        this.modalService = modalService;
        this.storageMap = storageMap;
        this.cradleHubService = cradleHubService;
        this.message = '';
        this.divShown = false;
        this.selectedLanguage = 'Nederlands (Nederland)';
        this.loginModel = { grantType: 'password', culture: 'nl-NL', mac: '', FingerPrintKey: '' };
        this.key = 'value';
        this.trustDays = 1;
        this.showLogin = true;
        this.twoFactor = { isAuthorise: false, authenticatorKey: '', authCode: '' };
        this.profileModel = {};
        this.step = 'login';
        this.enabled = false;
        this.tempLogin = true;
        this.year = new Date().getFullYear();
        // localStorage.clear();
        getVersion(http).subscribe(res => this.version = res);
        //if (this.localStorageService.get('mac') === null) {
        //  if (route.queryParams !== undefined && route.queryParams[this.key] !== undefined
        //    && route.queryParams[this.key].mac !== undefined) {
        //    this.localStorageService.add('mac', route.queryParams[this.key].mac.split(':').join('-'));
        //  } else {
        //    this.showLogin = false;
        //  }
        //}
        //if (this.cookieService.get('mac') === undefined || this.cookieService.get('mac') !== null || this.cookieService.get('mac') === '') {
        //  this.cookieService.set('mac', this.localStorageService.get('mac'));
        //}
        var fingerPrintKey = localStorage.getItem(FINGER_PRINT_KEY);
        if (fingerPrintKey) {
            this.deviceKey = fingerPrintKey;
            console.warn("loaded");
        }
        else {
            getCurrentBrowserFingerPrint().then((fingerprint) => {
                console.warn("created");
                this.deviceKey = fingerprint;
                localStorage.setItem(FINGER_PRINT_KEY, fingerprint);
            });
        }
    }
    ngOnInit() {
        this.filterLocalStorageKeys();
        this.navigateTo = this.route.snapshot.queryParams.url || '/';
        this.navigateParams = Object.assign({}, this.route.snapshot.queryParams);
        if (this.navigateParams.url) {
            delete this.navigateParams.url;
        }
        if (!this.authService.needLogin) {
            if (this.authService.user !== null && this.authService.user.customerCodeId !== null) {
                localStorage.setItem('customerCodeId', this.authService.user.customerCodeId);
            }
            if (this.authService.user !== null && this.authService.user.username) {
                localStorage.setItem('UserEmail', this.authService.user.username);
            }
            if (this.authService.user && this.authService.user.authenticatorAsked === 'false') {
                this.step = '2fRegister';
            }
            else {
                this.redirectUser();
            }
        }
        const location = window.location.pathname;
        const splited = location.split('/');
        this.loginModel.culture = supportedLocales.filter(v => splited[1] === v)[0] || 'nl-NL';
        if (localStorage.getItem('nativeName') !== null) {
            this.selectedLanguage = localStorage.getItem('nativeName');
            this.loginModel.culture = localStorage.getItem('lang');
            this.translate.use(localStorage.getItem('language'));
        }
    }
    filterLocalStorageKeys() {
        var keys = ["language", "lang", "nativeName", "fingerPrintKey"];
        Object.keys(localStorage).forEach(function (key) {
            if (!keys.includes(key)) {
                localStorage.removeItem(key);
            }
        });
    }
    redirectUser() {
        if (this.authService.hasRole(['System Admin', 'Support', 'Support DK'])) {
            return this.router.navigate(['/cradles'], { queryParams: {} });
        }
        if (this.authService.hasRole(['Diary User'])) {
            return this.router.navigate(['/diary-upload'], { queryParams: {} });
        }
        if (this.authService.hasRole(['Staff Fysiologic'])) {
            return this.router.navigate(['/records'], { queryParams: {} });
        }
        if (this.authService.hasRole(['Finance', 'Financial Admin'])) {
            return this.router.navigate(['/specifications'], { queryParams: {} });
        }
        this.localStorageService.remove('cradleSerialNumber');
        sessionStorage.clear();
        this.storageMap.delete('step').subscribe(() => {
            let stepStorage = '';
            this.storageMap.get('step', { type: 'string' }).subscribe((result) => {
                stepStorage = result;
                if ((!stepStorage || stepStorage == null || stepStorage == 'branch' || stepStorage == 'cradleChoose') && !this.authService.hasRole(['Doctor Without Cradle', 'Super User', 'System Admin', 'Finance', 'Financial Admin'])) {
                    this.router.navigate(['/branch'], { queryParams: {} });
                }
                else {
                    if (this.authService.hasRole(['Super User'])) {
                        return this.router.navigate(['/cradles'], { queryParams: {} });
                    }
                    else {
                        return this.router.navigate(['/records'], { queryParams: {} });
                    }
                }
            });
        });
    }
    ngOnDestroy() {
        if (this.loginSubscription) {
            this.loginSubscription.unsubscribe();
        }
    }
    selectLang(lang, nativeName, language) {
        this.selectedLanguage = nativeName;
        this.loginModel.culture = lang;
        this.translate.use(language);
        localStorage.setItem('lang', lang);
        localStorage.setItem('nativeName', nativeName);
        localStorage.setItem('language', language);
    }
    ngAfterViewChecked() {
    }
    handleAnimation(anim) {
        this.anim = anim;
    }
    login() {
        this.tempLogin = false;
        this.enabled = true;
        setTimeout(() => {
            this.enabled = false;
        }, 500);
        this.message = '';
        if (this.loginSubscription) {
            this.loginSubscription.unsubscribe();
        }
        this.loginModel.mac = this.loginModel.username.toLowerCase();
        this.loginModel.FingerPrintKey = this.deviceKey;
        this.loginSubscription = this.authService.login(this.loginModel).subscribe(r => {
            localStorage.setItem('lastActivityDate', new Date().toString());
            //buraya bak!!!
            this.localStorageService.set('mac', this.loginModel.username.toLowerCase());
            this.cookieService.set('mac', this.loginModel.username.toLowerCase());
            if (r && r.uuId)
                this.localStorageService.set('user-uuid', r.uuId);
            if (r.authenticatorType) {
                this.twoFactor.authType = r.authenticatorType;
                this.tempLogin = true;
                if (this.twoFactor.authType == 1) {
                    this.twoFactor.authenticatorKey = r.authenticatorKey;
                }
                else {
                    this.toast.showSuccess(this.translate.instant('loginPage.codeSentEmail'));
                }
                this.twoFactor.userName = r.userName;
                this.twoFactor.isAuthorise = true;
                this.step = '2fAuthentication';
            }
            else {
                if (this.authService.user.authenticatorAsked === 'false') {
                    this.step = '2fRegister';
                }
                else {
                    this.getUserLogIn();
                }
            }
        }, e => {
            this.tempLogin = true;
            this.logger.error(e);
            const err = e.error;
            this.message = err.data ? err.data.message : '';
            this.showMessage(this.message);
        });
    }
    confirmLogin() {
        this.message = '';
        let request;
        if (this.twoFactor.authType == 1) {
            request = {
                AuthenticatorKey: this.twoFactor.authenticatorKey,
                UserName: this.twoFactor.userName,
                Culture: this.loginModel.culture,
                Code: this.twoFactor.authCode.trim(),
                Password: this.loginModel.password,
                Mac: this.loginModel.mac
            };
        }
        else if (this.twoFactor.authType == 2) {
            request = {
                UserName: this.twoFactor.userName,
                Culture: this.loginModel.culture,
                Code: this.twoFactor.authCode.trim(),
                Password: this.loginModel.password,
                Mac: this.loginModel.mac
            };
        }
        if (this.loginSubscription) {
            this.loginSubscription.unsubscribe();
        }
        this.loginSubscription = this.authService.authValidation(request, this.twoFactor.authType).subscribe(r => {
            this.saveTrustDevice();
            this.getUserLogIn();
        }, e => {
            this.logger.error(e);
            const err = e.error;
            this.message = err.data ? err.data.message : this.translate.instant('profileForm.invalidCode');
        });
    }
    saveTrustDevice() {
        const never = (this.trustDays == 0);
        const model = {
            key: this.deviceKey,
            never
        };
        this.httpClient.post(model, 'auth/fingerprint/create-fingerprint?periodValidityDay=' + this.trustDays).subscribe(res => {
            this.toast.showSuccess(this.translate.instant('profileForm.browserSaveSuccess'));
        });
    }
    shouldDisplaySkipAuthenticationButton() {
        return this.authService.hasRole(['System Admin', 'Super User']);
    }
    getUserLogIn() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.cookieService.set('date', Date.now() / 1000 + '');
            this.getOrganisationSettingsOfUser();
            if (this.authService.user !== null && this.authService.user.customerCodeId !== null) {
                localStorage.setItem('customerCodeId', this.authService.user.customerCodeId);
            }
            if (this.authService.user !== null && this.authService.user.username) {
                localStorage.setItem('UserEmail', this.authService.user.username);
            }
            yield this.cradleHubService.createConnection();
            this.tempLogin = true;
            return this.redirectUser();
        });
    }
    open(content, modalSize) {
        this.modalService.open(content, { size: 'xl', centered: true });
    }
    deactivateAuthentication() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const response = yield this.httpClient.post({}, 'auth/authenticator/authenticator-deactivate-first-login').toPromise();
            if (response.data) {
                yield this.authService.forceRefresh().toPromise();
                return this.getUserLogIn();
            }
        });
    }
    create_UUID() {
        var dt = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }
    getOrganisationSettingsOfUser() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const response = yield this.httpClient.get('health/customers/GetOrganisationSettingsOfUser').toPromise();
            if (response) {
                return this.localStorageService.set('organisationSettings', response);
            }
        });
    }
    hideMessage() {
        this.divShown = false;
        this.message = '';
    }
    showMessage(message) {
        Swal.fire({
            position: 'center',
            showConfirmButton: true,
            text: message,
            confirmButtonText: 'OK',
        });
    }
}
