import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID} from '@angular/core';
import { Logger } from './common/logging/logger.service';
import { LogLevel } from './common/logging/log';
import { environment } from '../environments/environment';
import { Subscription, timer } from 'rxjs';
import { Router } from '@angular/router';
import { PwaUpdateService } from './shared/services/pwa-update.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivityCheckService } from './common/auth/activity-check.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from './common/auth/auth.service';
import Swal from 'sweetalert2';
import { AuthLogoutService } from './common/auth/auth-logout.service';
import { CradleHubService } from './cradles/cradle-hub.service';

@Component({
  selector: 'ecg-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private loggerSubscription: Subscription;
  url: string;
  private logoutSubscription: Subscription;
  private timerSubscription: Subscription;

  broadcastChannel: BroadcastChannel;
  broadcastChannelReceiver: BroadcastChannel;
  broadcastChannelModal: BroadcastChannel;

  constructor(private logger: Logger,
    private router: Router,
    private translateService: TranslateService,
    private updateService: PwaUpdateService,
    private activityCheckService: ActivityCheckService,
    public modal: NgbModal,
    private auth: AuthService,
    private authLogoutService: AuthLogoutService,
    private cradleHubService: CradleHubService,
    @Inject(PLATFORM_ID) private platformId: any) {
    this.setLanguage();
    try {
      this.broadcastChannel = new BroadcastChannel('start-timer');
      this.broadcastChannelReceiver = new BroadcastChannel('start-timer');
      this.broadcastChannelModal = new BroadcastChannel('modal-close');
    } catch (error) {
      
    }
  }

  public ngOnInit() {
    this.updateService.checkForUpdate();
    this.loggerSubscription = this.logger.asObservable.subscribe(l => {
      if (l.level === LogLevel.error) {
        console.error(l.message, ...l.params);
      } else if (l.level === LogLevel.warn) {
        console.warn(l.message, ...l.params);
      } else if (l.level === LogLevel.info) {
      } else if (!environment.production) {
      }
    });


    try {
      //Logout warning
      this.broadcastChannelReceiver.onmessage = (message) => {
        this.timerStart();
      }

      this.broadcastChannelModal.onmessage = (message) => {
        if(message.data === "close"){
          Swal.close();
        }else if(message.data === "logout"){
          Swal.close();
          this.auth.saveToken(null);
         // window.location.href = "/login";
        }
      }
    } catch (error) {
      
    }
    

    window.addEventListener("storage", (e) => {
      if (e.key === "modalClose") {
        console.log("EVENT STORAGE LISTEN")
        Swal.close();
        this.auth.saveToken(null);
       // window.location.href = "/login";
      }
    });

    this.cradleHubService.createConnection();
  }

  timerStart() {
    console.log('TIMER START', new Date());
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this.timerSubscription = timer(3480000).subscribe(() => {
      console.log("TIMER END", new Date());
      const url = this.router.url;
      if ((url != '' && url != '/' && url != '/login' && url != '/forgot' && url != '/createpassword' && url != '/changepassword')) {
        this.swalFire();
      }
    });
  }

  swalFire() {
    console.log("SWAL FIRE OPEN");
    Swal.fire({
      position: 'center',
      icon: 'warning',
      title: this.translateService.instant('sessionExpiredTitle'),
      text: this.translateService.instant('sessionExpiredContent'),
      allowEnterKey: false,
      timer: 120000,
      backdrop: 'static',
      showCancelButton: true,
      cancelButtonText: 'Logout',
      showConfirmButton: true,
      confirmButtonText: 'Stay',
      allowOutsideClick: false,
      allowEscapeKey: false,
      onDestroy: () => {},
    }).then(result => {
      console.log(result);
      if(Object.keys(result).length){
        if(result.value === true){
          this.activityCheckService.updateLastActivity();
          this.broadcastChannelModal.postMessage("close");
          console.log("SWAL CLOSE");
        }
        if(result.dismiss === Swal.DismissReason.cancel || result.dismiss === Swal.DismissReason.timer){
          localStorage.setItem("modalClose", new Date().toString());
          console.log("LOGOUT")
          this.broadcastChannelModal.postMessage("logout");
            if(!this.auth.needLogin){
            this.authLogoutService.logout();
          }
        }
      }
    });
  }

  logOut(){
    localStorage.setItem("modalClose", new Date().toString());
    this.authLogoutService.logout();
    
  }

  deleteAllCookies() {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }

  ngOnDestroy() {
    this.loggerSubscription.unsubscribe();
  }

  private setLanguage() {
    const language = localStorage.getItem('language') || this.translateService.currentLang || 'nl';
    this.translateService.use(language);
    localStorage.setItem('language', language);
  }
}
