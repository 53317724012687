import { NavigationCancel } from '@angular/router';
import { first } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@angular/router";
import * as i3 from "./auth-api-http-client";
import * as i4 from "@ngx-pwa/local-storage";
import * as i5 from "./activity-check.service";
import * as i6 from "./auth-logout.service";
export class AuthGuard {
    constructor(auth, router, httpClient, storageMap, activityCheckService, authLogoutService) {
        this.auth = auth;
        this.router = router;
        this.httpClient = httpClient;
        this.storageMap = storageMap;
        this.activityCheckService = activityCheckService;
        this.authLogoutService = authLogoutService;
    }
    canActivate(route, state) {
        const needLogin = this.auth.needLogin;
        if (needLogin) {
            const indexOf = state.url.indexOf('?');
            const pathFromRoot = indexOf > -1 ? state.url.substring(0, indexOf) : state.url;
            let queryParams = { url: pathFromRoot };
            if (Object.keys(route.queryParams).length > 0) {
                queryParams = Object.assign(queryParams, route.queryParams);
            }
            this.router.navigate(['/login'], { queryParams });
        }
        this.activityCheckService.updateLastActivity();
        this.askedAuthenticatorControl();
        this.branchChooseControl();
        const roles = (route.data && route.data.roles ? route.data.roles : []);
        return this.auth.hasRole(roles);
    }
    canActivateChild(childRoute, state) {
        return this.canActivate(childRoute, state);
    }
    canLoad(route) {
        const needLogin = this.auth.needLogin;
        if (needLogin) {
            this.router.events.pipe(first(re => re instanceof NavigationCancel)).subscribe((event) => {
                const indexOf = event.url.indexOf('?');
                const pathFromRoot = indexOf > -1 ? event.url.substring(0, indexOf) : event.url;
                const pathToEnd = indexOf > -1 ? `&${event.url.substring(indexOf + 1)}` : '';
                const redirectSigninUrl = `${'/login'}?url=${pathFromRoot}${pathToEnd}`;
                this.router.navigateByUrl(redirectSigninUrl);
            });
        }
        const roles = (route.data && route.data.roles ? route.data.roles : []);
        return this.auth.hasRole(roles);
    }
    branchChooseControl() {
        if (!this.auth.hasRole(['System Admin', 'Support', 'Support DK', 'Diary User', 'Staff Fysiologic', 'Super User', 'Finance', 'Financial Admin'])) {
            this.storageMap.get('step', { type: 'string' }).subscribe((result) => {
                let stepStorage = result;
                if ((!stepStorage || stepStorage == null || stepStorage != 'recordsOrCradle')) {
                    this.router.navigate(['/branch'], { queryParams: {} });
                }
            });
        }
    }
    askedAuthenticatorControl() {
        if (this.auth.user.authenticatorAsked === "false") {
            this.authLogoutService.logout();
        }
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.AuthApiHttpClient), i0.ɵɵinject(i4.StorageMap), i0.ɵɵinject(i5.ActivityCheckService), i0.ɵɵinject(i6.AuthLogoutService)); }, token: AuthGuard, providedIn: "root" });
