import { AfterContentChecked, Component, OnInit } from '@angular/core';
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthApiHttpClient } from '../common/auth/auth-api-http-client';
import { BaseFormComponent } from '../common/components/base-form.component';
import { Logger } from '../common/logging/logger.service';
import { Charger } from '../models/charger';
import { ChargerItemService } from '../charger/charger-item.service';
import { ToastService } from '../shared/services/toast.service';

@Component({
  selector: 'ecg-charger-form',
  templateUrl: './charger-form.component.html',
  styleUrls: ['./charger-form.component.scss']
})
export class ChargerFormComponent extends BaseFormComponent<Charger> implements AfterContentChecked {

  hospitalList: any = [];
  customerName: any;
  orgNumber: number;

  constructor(
    public httpClient: AuthApiHttpClient,
    public service: ChargerItemService,
    logger: Logger,
    location: Location,
    route: ActivatedRoute,
    router: Router,
    public spinner: NgxSpinnerService,
    toast: ToastService,
    private translate: TranslateService,
    public modal: NgbModal
  ) {
    super({ service, logger, location, route, router, spinner, toast });
    
    service.hospitalList.subscribe(res => {
      for (const hospital of res) { 
        this.hospitalList.push({ id: hospital.id, name: hospital.customer.name + ' - ' + hospital.code, customerId: hospital.customerId, customerName: hospital.customer.name });
      }
    }
    );
    
    const path = window.location.href;
    if (path.includes('add')) {
      const pathSplit = path.split('=');
      this.orgNumber = parseInt(pathSplit[1]);
    }
  }
  changeOrganisation(id) {
    const index = this.hospitalList.findIndex(hospital => hospital.id === +id);
    this.model.customerId = this.hospitalList[index].customerId;
    this.customerName = this.hospitalList[index].customerName;
  }

  ngAfterContentChecked() {
    if (this.model && this.hospitalList.length > 0 && this.operation == 'add' && this.orgNumber) {
      const index = this.hospitalList.findIndex(hospital => hospital.customerId === this.orgNumber);
      this.model.customerCodeId = this.hospitalList[index].id;
      this.model.customerId = this.orgNumber;
    }
  }
}
