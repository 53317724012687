import {Injectable} from '@angular/core';
import {AuthApiHttpClient} from '../common/auth/auth-api-http-client';
import {DataListService} from '../common/data/data-list.service';
import {IpWhitelist} from '../models/ip-whitelist';

@Injectable({providedIn: 'root'})
export class IpWhiteListsService extends DataListService<IpWhitelist> {

  accessRequestSubscribe: any;
  allowAccessSubscribe: any;

  constructor(httpClient: AuthApiHttpClient) {
    super({
      httpClient,
      url: 'auth/ipwhitelist',
      initial: []
    });
  }

  getAccessRequest() {
    this.accessRequestSubscribe = this.httpClient.get<any>('auth/ipwhitelist/access-request');
  }

  setAllowAccess(model) {
    this.allowAccessSubscribe = this.httpClient.post(model, 'auth/ipwhitelist/set-allow-access');
  }

  getIPFilterStatus() {
    return this.httpClient.get<any>('auth/ipwhitelist/ip-filter-status');
  }

  switchFilterStat(stat) {
    return this.httpClient.post(stat, 'auth/ipwhitelist/ip-filter-status-change');
  }

  postRangeIps(ranges) {
    return this.httpClient.post(ranges, 'auth/ipwhitelist/range-ip-add');
  }
}
