
import { Component, OnInit } from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Version } from '../../models/version';
import { getVersion } from '../../common/utilities/getVersion.utility';
import { ToastService } from 'src/app/shared/services/toast.service';
import { timeoutWith } from 'rxjs/operators';
import { ChangeLog, ChangeLogFile, ChangeLogService } from './change-log.service';
import { AuthService } from 'src/app/common/auth/auth.service';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { ChangeVersionService } from './change-version.service';

import { LocalStorageService } from 'angular-2-local-storage';
import { CradleItemService } from '../../cradles/cradle-item.service';
import { Cradle } from "../../models";
import { LogoutService } from "../../shared/services/logout-service";

class ImageSnippet {
  pending = false;
  status = 'init';

  constructor(public src: string, public file: File) {
  }
}
@Component({
  selector: 'ecg-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  providers: [DatePipe]
})
export class AboutComponent implements OnInit {
  version: Version;

  changeLogs: Array<ChangeLog> = [];
  _auth: AuthService;

  platformCategory: { id: number, title: string } = { id: 1, title: "Platform" };
  categories: { id: number, title: string }[] = [
    this.platformCategory,
    { id: 2, title: "Cradle" },
    { id: 3, title: "Recorder" },
    { id: 4, title: "Mobile" },
    { id: 5, title: "Connector" }


  ];
  activeCategory: { id: number, title: string } = this.platformCategory;
  public mask = [/[0-3]/, /\d/, '-', /[0-1]/, /\d/, '-', /[0-2]/, /\d/, /\d/, /\d/];

  dragulaSubs = new Subscription();
  year: number;


  constructor(
    private location: Location,
    private changeLogService: ChangeLogService,
    private toast: ToastService,
    private auth: AuthService,
    private httpClient: HttpClient,
    private datePipe: DatePipe,
    private dragulaService: DragulaService,
    private modalService: NgbModal,
    public sanitizer: DomSanitizer,
    private translate: TranslateService,
    private changeVersionService : ChangeVersionService, 

    public localStorageService: LocalStorageService,
    private cradleItemService: CradleItemService,
    private logoutService: LogoutService,
  ) {
    this._auth = auth;
    getVersion(httpClient).subscribe(res => {
      this.version = res;
    });

    this.year = new Date().getFullYear();
  }

  filterChangeLogs(id: number) {
    return this.changeLogs.filter(x => x.category == this.activeCategory.id);
  }

  ngOnInit() {

    this.cradleItemService.getCradleByLinkedMac(this.localStorageService.get("mac"),this.localStorageService.get("cradleSerialNumber"))
    .subscribe((cradle: Cradle) => {
      debugger;
      if (cradle && cradle.linkedPC !== this.localStorageService.get("mac")) {
        //Farklı browserdan cradle alırsa atsın
        if (cradle && this.localStorageService.get("cradleSerialNumber") !== null && this.localStorageService.get("cradleSerialNumber") !== "skip") 
        {
          console.log("logoutUser-about");
          this.logoutService.logoutUser(cradle.connectedUser, "takeOver");
        }
      }
    });

    this.loadChangeLogs();
  }
  loadChangeLogs() {
    this.changeLogService.getLogs().subscribe(response => {
      this.changeLogs = response;
      this.changeLogs.map(x => { x.dateTime = this.datePipe.transform(x.dateTime, 'dd-MM-yyyy'); })


      var flags = [],
        output = [],
        l = this.changeLogs.length,
        i;
      for (i = 0; i < l; i++) {
        if (flags[this.changeLogs[i].category]) continue;
        flags[this.changeLogs[i].category] = true;
        this.changeLogs[i].opened = true;
      }
    }, error => {

    });
   
    this.setLastVersion();
   
  }
  orderChanged(event) {
    console.warn(event);
  }

  getBadgeClassByStatus(status: number) {
    if (status == 2) {
      return "success";
    } else if (status == 3) {
      return "warning";
    } else if (status == 4) {
      return "primary";
    } else if (status == 5) {
      return "danger";
    }
  }
  getBadgeTextByStatus(status: number) {
    if (status == 2) {
      return "Added";
    } else if (status == 3) {
      return "Updated";
    } else if (status == 4) {
      return "Fixed";
    } else if (status == 5) {
      return "Removed";
    }
  }
  back() {
    this.location.back();
  }

  addVersion() {
    var newItemOrderNumber = 1;
    if (this.changeLogs && this.changeLogs.length > 0) {
      newItemOrderNumber = this.changeLogs[0].orderNumber + 1;
    }

    this.changeLogService.create({
      orderNumber: newItemOrderNumber,
      text: '0.0',
      dateTime: '11-10-2021',
      type: 1,
      parentLog: null,
      category: this.activeCategory.id
    }).subscribe(response => {
      this.loadChangeLogs();
    })
  }

  addChangeLog(parentLog: ChangeLog) {
    parentLog.loading = true;
    this.changeLogService.create({
      orderNumber: 0,
      text: 'Type here',
      dateTime: '11-10-2021',
      type: 2,
      parentLogId: parentLog.id,
      category: this.activeCategory.id
    }).subscribe(response => {
      parentLog.loading = false;
      if (!parentLog.subLogs) parentLog.subLogs = [];
      parentLog.subLogs.push(response.data)
    }, error => {
      parentLog.loading = false;
    })

    this.setLastVersion();
  }

  addFileError = "";
  updateChangeLog(model: ChangeLog) {
    model.loading = true;
    var uptoDateModel = { ...model };
    if (model.type == 1) {
      const inputDate = uptoDateModel.dateTime.split('-');
      var newDate = new Date(inputDate[1] + '.' + (parseInt(inputDate[0]) + 1) + '.' + inputDate[2]);
      uptoDateModel.dateTime = newDate.toISOString();
    }
    this.changeLogService.update(uptoDateModel).subscribe(response => {
      this.toast.showSuccess('Success');
      model.changed = false;
      model.loading = false;
      if (model.type === 1) {
        this.loadChangeLogs();
      }
    }, error => {
      model.changed = false;
      model.loading = false;
      this.toast.showDanger(error.error.message);
    })

    this.setLastVersion();
  }

  deleteChangeLog(model: ChangeLog, changeLog: ChangeLog) {
    Swal.fire({
      position: 'center',
      icon: 'warning',
      title: this.translate.instant("about.deleteTitle"),
      text: this.translate.instant("about.deleteText"),
      showConfirmButton: true,
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonText: this.translate.instant("about.deleteButton"),
      confirmButtonColor: '#dc3545',
      cancelButtonText: this.translate.instant("about.cancelButton"),
      cancelButtonColor: '#8a8787',
    }).then((result) => {
      if (result.value) {
        model.loading = true;
        this.changeLogService.deleteItem(model.id).subscribe(response => {
          this.toast.showSuccess('Success');
          model.changed = false;
          model.loading = false;
          if (model.type === 1) {
            this.loadChangeLogs();
          } else {
            changeLog.subLogs.splice(changeLog.subLogs.indexOf(model), 1);
          }
        }, error => {

        })
      }
    })

    this.setLastVersion();
  }

  deleteChangeLogFile(changeLog: ChangeLog, changeLogFile: ChangeLogFile) {
    changeLog.loading = true;
    this.changeLogService.deleteFile(changeLogFile.id).subscribe(response => {
      this.toast.showSuccess('Success');
      changeLog.changed = false;
      changeLog.loading = false;
      changeLog.files.splice(changeLog.files.indexOf(changeLogFile), 1);
    }, error => {
      changeLog.loading = false;
    })
  }


  checkDate(changeLog: ChangeLog) {
    if (changeLog.dateTime !== undefined && changeLog.dateTime.search('-') !== -1) {
      const inputDate = changeLog.dateTime.split('-');
      var newDate = new Date((parseInt(inputDate[1]) + 1) + '-' + inputDate[0] + '-' + inputDate[2]);
      changeLog.dateTime = newDate.toISOString();
    }
  }
  autoGrowTextZone(e) {
    e.target.style.height = "0px";
    e.target.style.height = (e.target.scrollHeight + 25) + "px";
  }


  fileTypes = [
    { id: 1, name: 'File' },
    { id: 2, name: 'Image' },
    { id: 3, name: 'Url' },
  ];
  addFileModel: ChangeLogFile = {};
  modalReference: NgbModalRef;
  addToFileChangeLog: ChangeLog;
  addFile(modal, changeLog: ChangeLog) {
    this.addToFileChangeLog = changeLog;
    this.modalReference = this.modalService.open(modal, { ariaLabelledBy: 'modal-basic-title' });
  }
  fileSubmitLoading = false;
  fileSubmit() {
    this.fileSubmitLoading = true;
    this.addFileModel.changeLogId = this.addToFileChangeLog.id;
    if (this.addFileModel.type == 1 || this.addFileModel.type == 2) {
      this.addFileModel.path = this.selectedFile.src;
      this.addFileModel.fileName = this.selectedFile.file.name;
    }
    this.changeLogService.createFile(this.addFileModel).subscribe(response => {
      this.fileSubmitLoading = false;
      console.warn(response);
      if (!this.addToFileChangeLog.files) this.addToFileChangeLog.files = [];
      this.addToFileChangeLog.files.push(response.data);
      this.modalReference.close();
      this.addFileModel = {};
    }, error => {
      this.fileSubmitLoading = false;
      this.toast.showDanger(error.error.message);
    });
  }

  files: File[] = [];
  selectedFile: ImageSnippet;
  pdfFile: any;
  onSelect(event) {
    if (this.files.length != 0) {
      this.files = [];
    }
    this.files.push(...event.addedFiles);
    this.processFile(event.addedFiles[0]);
  }
  processFile(file) {
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.selectedFile = new ImageSnippet(event.target.result, file);
      this.selectedFile.pending = true;
      this.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file));
      console.warn(this.selectedFile);

    });
    reader.readAsDataURL(file);
  }

  onRemove(event) {
    this.files = [];
    this.pdfFile = undefined;
    this.selectedFile = undefined;
  }

  changeLogFiles(changeLog: ChangeLog) {
    if (changeLog.files) return changeLog.files.filter(x => x.type == 1 || x.type == 3);
    return [];
  }
  changeLogImages(changeLog: ChangeLog) {
    if (changeLog.files) return changeLog.files.filter(x => x.type == 2);
    return [];
  }

  //Set last version for only platform
  setLastVersion()
  {    
     this.changeVersionService.setVersion().subscribe(result => {});
  }
}
