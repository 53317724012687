
<div *ngFor="let data of metaData | async"  (keydown.enter)="onKeyUp ($event)">
  <div *ngFor="let chunk of data.items | chunk:groupSize" class="row">
    <div class="{{colSize}} form-group" *ngFor="let meta of chunk" [class.filter-button]="meta.type == -1">
      <ng-container [ngSwitch]="meta.type">
        <div *ngSwitchCase="1"><!--ComboBox-->
          <select [(ngModel)]="meta._value" id="{{meta.key}}" (ngModelChange)="onSelectChange($event,meta.key)" class="form-control" [name]="meta.title">
            <option [value]="undefined" value="undefined" hidden>{{meta.placeHolder}}</option>
            <option *ngFor="let value of meta.values" [value]="value"
                    [innerText]="('filterForm.' + data?.title + '.' + meta?.title + '.' + (value || 'all')) | translate"
            ></option>
          </select>
        </div>
        <div *ngSwitchCase="2"><!--Search-->
          <input type="text" [(ngModel)]="meta._value"  class="form-control"  [attr.id]="meta.title" [name]="meta.title"
                 [placeholder]="getTranslate(data, meta.title) | translate" [attr.maxlength] = "meta.title == 'PIDNo' ? 10 : 250">
        </div>
        <div *ngSwitchCase="3"><!--DatePicker-->
          <ecg-range-datepicker [(ngModel)]="meta._value" [name]="meta.title" [maxDate]="maxDate"
                                [placeholder]="getTranslate(data, meta.title) | translate"></ecg-range-datepicker>
        </div>
        <div *ngSwitchCase="-1">
          <ecg-button (click)="emitFilters()" name="filterButton"  class="btn btn-block btn-primary" i18n>{{'filterButton' | translate }}</ecg-button>
        </div>
      </ng-container>
    </div>
  </div>
</div>

