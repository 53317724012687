import * as i0 from "@angular/core";
import * as i1 from "../../../common/auth/auth-api-http-client";
import * as i2 from "../../../common/auth/auth.service";
export class BranchChooseService {
    constructor(httpClient, auth) {
        this.httpClient = httpClient;
        this.authUser = auth.user;
    }
    getBranchListService() {
        this.branchListService = this.httpClient.get('health/customers/get-branchs-by-customer/' + this.authUser.customerId);
    }
    getBranchIdService() {
        this.branchIdService = this.httpClient.get('health/customers/get-branchId-by-user/' + this.authUser.id);
    }
    getSetUserBranchAndCradle(branchId, cradleSerialNumberForBranch, mac, uuid) {
        this.setUserBranchAndCradleService = this.httpClient.post({}, 'auth/useraccounts/set-user-branch-and-cradle/' + branchId + '/' + cradleSerialNumberForBranch + '/' + mac + '/' + uuid);
    }
    getGetCradlesByBranch(branchId) {
        this.getCradleByBranch = this.httpClient.get('auth/useraccounts/get-cradles-by-branch/' + branchId);
    }
    getGetCradlesByCustomer(customerId) {
        this.getCradleByCustomer = this.httpClient.get('auth/useraccounts/get-cradles-by-customer/' + customerId);
    }
    getSetUserBranch(branchId) {
        this.setUserBranch = this.httpClient.post({}, 'auth/useraccounts/set-user-branch/' + branchId);
    }
    getUsedControl(cradleSerialNumberForBranch) {
        this.usedControl = this.httpClient.get('auth/useraccounts/used-cradle-control/' + cradleSerialNumberForBranch);
    }
    getCradleTakeOverLogCrateService(previousUserName, inheritingUserName, cradleSerialNumber) {
        this.createCradleTakeOverLog = this.httpClient.post({}, 'auth/useraccounts/create-log-takeOverCradle/' + previousUserName + "/" + inheritingUserName + "/" + cradleSerialNumber);
    }
}
BranchChooseService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BranchChooseService_Factory() { return new BranchChooseService(i0.ɵɵinject(i1.AuthApiHttpClient), i0.ɵɵinject(i2.AuthService)); }, token: BranchChooseService, providedIn: "root" });
