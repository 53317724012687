import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertDate'
})
export class ConvertDatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value ? value.includes('Z') ? value : `${value}Z` : value;
  }
}
