import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

import { AuthService } from '../auth/auth.service';
import { Injectable } from '@angular/core';
import { apiLocations } from '../../../environments/app.config.json';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  constructor(protected authService: AuthService) {}

  connect(url: string) {
    const response =
      this.authService.getAccessToken().toPromise().then(token => {
        const uri = `${apiLocations.hub}${url}`;

        return new HubConnectionBuilder()
          .withUrl(uri, {
            accessTokenFactory: () => token,
            logger: environment.production ? LogLevel.Critical : LogLevel.Trace,
          })
          .withAutomaticReconnect()
          .build();
      });

    return response;
  }
}
