<div class="nexo-root container" style=" margin-top: calc(50vh - 315px);">
  <div class="row content my-3">
    <div class="col-12">

      <form #mainForm="ngForm" class="form-signin col-4">
        <div class="login-logo-area">
          <img class="logo-image" src="assets/images/login-logo.png" i18n-alt alt="Login Logo"/>
        </div>
        <p></p>
        <div class="form-label-group">
          <div class="form-row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
              <input type="text" class="form-control" i18n-placeholder placeholder="{{'profileForm.username' | translate }}" id="eMail" name="eMail"
                     #eMail="ngModel" [(ngModel)]="model.username" required>
              <div *ngIf="eMail.invalid && (eMail.dirty || eMail.touched)" class="alert alert-danger">
                <div *ngIf="eMail.errors['required']" i18n>
                  {{'required.username' | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="checkbox mb-3">
        </div>
        <hr/>
        <div class="form-label-group">
          <button class="btn btn-lg btn-primary btn-block" type="submit" (click)="save()" [disabled]="!mainForm.form.valid" i18n>{{'profileForm.changePassword' | translate }}</button>
          <button class="btn btn-lg btn-light btn-block btn-sm" routerLink="/login" type="submit" i18n>{{ 'cancel' | translate }}</button>
        </div>
        <hr/>
        <div class="login-footer-area">
          <p>Fysiologic&#174; ECGAccess &copy; {{year}}</p>
          <small>ECGAccess Version <span *ngIf="version">{{version.appVersion}}</span></small>
        </div>
      </form>


    </div>
  </div>
</div>
