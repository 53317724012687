import { Injectable } from '@angular/core';
import { AuthApiHttpClient } from '../common/auth/auth-api-http-client';

@Injectable({
  providedIn: 'root'
})
export class ServerHealthService {

  constructor(public httpClient: AuthApiHttpClient) { }


  getServerHealth(){
   return this.httpClient.get('ServerHealth');
  }

  restartService() {
    return this.httpClient.get('ServerHealth/restart-service');
  }
}
