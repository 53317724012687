import {ControlValueAccessor} from "@angular/forms";
import {EventEmitter} from "@angular/core";

export abstract class BaseControlValueAccessor<T> implements ControlValueAccessor {
    protected onChange = new EventEmitter<T>()
    protected onTouched = new EventEmitter()

    registerOnChange(fn: any): void {
        this.onChange.subscribe(fn);
    }

    registerOnTouched(fn: any): void {
        this.onTouched.subscribe(fn);
    }

    abstract setDisabledState(isDisabled: boolean): void;

    abstract writeValue(obj: any): void ;

}
