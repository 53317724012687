import { Injectable } from '@angular/core';
import { UserAccount, UserRole } from '../models';
import { AuthApiHttpClient } from '../common/auth/auth-api-http-client';
import { DataItemService } from '../common/data/data-item.service';

@Injectable({ providedIn: 'root' })
export class PcsItemService extends DataItemService<UserAccount> {
  public hospitalList: any;
  constructor(httpClient: AuthApiHttpClient) {
    super({
      httpClient,
      url: 'auth/pcs/item'
    });

    this.hospitalList =  this.httpClient.get<Array<string>>('health/cradles/customerCodes');
  }
  roles = this.httpClient.get<UserRole[]>('auth/useraccounts/roles');

}
