import { Injectable } from '@angular/core';
import { AuthApiHttpClient } from 'src/app/common/auth/auth-api-http-client';
import { DataItemService } from 'src/app/common/data/data-item.service';

export interface ChangeLog {
  id?: number;
  createdDateTime?: string;
  createdUser?: number;
  updatedDateTime?: string;
  updatedUser?: number;
  deletedDateTime?: string;
  deletedUser?: number;

  text: string;
  category: number;
  type: number;
  orderNumber: number;
  dateTime?: string;

  parentLog?: ChangeLog;
  parentLogId?: number;

  subLogs?: ChangeLog[];
  files?: ChangeLogFile[];

  changed?: boolean;
  loading?: boolean;
  orderedList?: any;
  opened?: boolean;
}

export interface ChangeLogFile{
  id?: number;
  createdDateTime?: string;
  createdUser?: number;
  updatedDateTime?: string;
  updatedUser?: number;
  deletedDateTime?: string;
  deletedUser?: number;

  text?: string;
  path?: string;
  type?: number;

  changeLog?: ChangeLog;
  changeLogId?: number;

  fileName?: string;
}

@Injectable({ providedIn: 'root' })
export class ChangeLogService extends DataItemService<ChangeLog> {
  constructor(httpClient: AuthApiHttpClient) {
    super({ httpClient, url: 'health/changeLog/item' });
  }

  getLogs() {
    return this.httpClient.get<ChangeLog[]>('health/changeLog/get-logs');
  }

  create(model: ChangeLog) {
    return this.httpClient.post<any, any>(model, 'health/changeLog/item');
  }
  update(model: ChangeLog) {
    return this.httpClient.put<any, any>(model, 'health/changeLog/item');
  }
  
  deleteItem(id: number){
    return this.httpClient.delete<any>(null, `health/changeLog/item?id=${id}`);
  }

  createFile(model: ChangeLogFile) {
    return this.httpClient.post<any, any>(model, 'health/changeLog/create-file');
  }
  deleteFile(id: number){
    return this.httpClient.delete<any>(null, `health/changeLog/delete-file?id=${id}`);
  }
}
