import { DataItemService } from '../common/data/data-item.service';
import * as i0 from "@angular/core";
import * as i1 from "../common/auth/auth-api-http-client";
export class DiaryItemService extends DataItemService {
    constructor(httpClient) {
        super({
            httpClient,
            url: 'health/diaries/item'
        });
        this.patient = (patientId) => this.httpClient.get(`health/diaries/item/patient/${patientId}`);
    }
}
DiaryItemService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DiaryItemService_Factory() { return new DiaryItemService(i0.ɵɵinject(i1.AuthApiHttpClient)); }, token: DiaryItemService, providedIn: "root" });
