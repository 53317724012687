/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toasts.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "./toasts.component";
import * as i6 from "../services/toast.service";
var styles_ToastsComponent = [i0.styles];
var RenderType_ToastsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToastsComponent, data: {} });
export { RenderType_ToastsComponent as RenderType_ToastsComponent };
function View_ToastsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_ToastsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToastsComponent_3)), i1.ɵdid(1, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.textOrTpl; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ToastsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.textOrTpl; _ck(_v, 0, 0, currVal_0); }); }
function View_ToastsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ngb-toast", [["aria-atomic", "true"], ["role", "alert"]], [[8, "className", 0], [1, "aria-live", 0], [2, "toast", null], [2, "show", null]], [[null, "hide"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("hide" === en)) {
        var pd_0 = (_co.toastService.remove(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_NgbToast_0, i3.RenderType_NgbToast)), i1.ɵdid(1, 1622016, null, 1, i4.NgbToast, [[8, null], i4.NgbToastConfig], { delay: [0, "delay"], autohide: [1, "autohide"] }, { hideOutput: "hide" }), i1.ɵqud(335544320, 1, { contentHeaderTpl: 0 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ToastsComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["text", 2]], 0, 0, null, View_ToastsComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_4 = (_v.context.$implicit.delay || 2000); var currVal_5 = true; _ck(_v, 1, 0, currVal_4, currVal_5); var currVal_6 = _co.isTemplate(_v.context.$implicit); var currVal_7 = i1.ɵnov(_v, 5); _ck(_v, 4, 0, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.classname; var currVal_1 = i1.ɵnov(_v, 1).ariaLive; var currVal_2 = true; var currVal_3 = true; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_ToastsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToastsComponent_1)), i1.ɵdid(1, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.toastService.toasts; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ToastsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ecg-toasts", [], [[2, "ngb-toasts", null]], null, null, View_ToastsComponent_0, RenderType_ToastsComponent)), i1.ɵdid(1, 49152, null, 0, i5.ToastsComponent, [i6.ToastService], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).ngbToastsClass; _ck(_v, 0, 0, currVal_0); }); }
var ToastsComponentNgFactory = i1.ɵccf("ecg-toasts", i5.ToastsComponent, View_ToastsComponent_Host_0, {}, {}, []);
export { ToastsComponentNgFactory as ToastsComponentNgFactory };
