<nav class="navbar sticky-top navbar-expand-lg navbar-dark" style="background-color: #3AA8E0;">
  <a class="navbar-brand" [style.pointer-events]="controlUrlLogo() ? 'auto' : 'none'"  [routerLink]="[ '/' ]">
    <ecg-logo width="100px"></ecg-logo>
  </a>

  <ng-container *ngIf="user">
    <button class="navbar-toggler" type="button" data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
            (click)="showMenu=!showMenu">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngbCollapse]="showMenu">
      <ul class="navbar-nav mr-auto">
      </ul>
      <div ngbDropdown class="mr-md-4 my-2 my-lg-0 d-inline-block">
        <a class="nav-link color-me p-0" id="user-name-drop-down" ngbDropdownToggle style="cursor:pointer"
           i18n>{{user.name}}</a>
        <div class="w-100" ngbDropdownMenu aria-labelledby="user-name-drop-down">
          <button ngbDropdownItem (click)="logout()" i18n>{{ 'navBar.logOut' | translate }}</button>
        </div>
      </div>
    </div>
  </ng-container>
</nav>
<router-outlet></router-outlet>
<ecg-toasts aria-live="polite" aria-atomic="true"></ecg-toasts>
<ngx-spinner bdOpacity=0.7 bdColor="rgba(51,51,51,0.5)" size="default" color="#fff" type="ball-clip-rotate"
             [fullScreen]="true">
  <p style="color: white" i18n> Loading... </p>
</ngx-spinner>
