import { AccessToken } from './token';

export class JwtHelper {
  constructor() {
    // noop
  }

  urlBase64Decode(str: string): string {
    let output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
      case 0: { /* noop */ }  break;
      case 2: { output += '=='; } break;
      case 3: { output += '='; } break;
      default: {
        throw new Error('Illegal base64url string!');
      }
    }
    return this.b64DecodeUnicode(output);
  }

  b64DecodeUnicode(str: string): string {
    return decodeURIComponent(Array.prototype.map.call(atob(str), c => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

  decodeToken(token: string): AccessToken {
    const parts = token.split('.');
    if (parts.length !== 3) {
      throw new Error('JWT must have 3 parts');
    }
    const decoded = this.urlBase64Decode(parts[1]);
    if (!decoded) {
      throw new Error('Cannot decode the token');
    }
    return JSON.parse(decoded);
  }

  getTokenExpirationDate(token: AccessToken): Date | null {
    if (!token.hasOwnProperty('exp')) {
      return null;
    }
    const date = new Date(0);
    date.setUTCSeconds(token.exp);
    return date;
  }

  isTokenExpired(token: AccessToken, offsetSeconds?: number): boolean {
    const date = this.getTokenExpirationDate(token);
    if (date === null) {
      return false;
    }
    offsetSeconds = offsetSeconds || 0;
    return !(date.valueOf() > (new Date().valueOf() + (offsetSeconds * 1000)));
  }
}
