import { HttpHeaders, HttpParams } from '@angular/common/http';

export interface SetParamsArgsForOptions {
  method?: string;
  url?: string;
  options?: HttpRequestOptions;
  data?: { [key: string]: any };
}

export type HttpMethod = 'GET' | 'DELETE' | 'POST' | 'PUT';
export type HttpObserve = 'body' | 'events' | 'response';
export type HttpResponseType = 'arraybuffer' | 'blob' | 'json' | 'text';

export interface HttpRequestOptions {
  body?: any;
  headers?: HttpHeaders;
  params?: HttpParams;
  observe?: HttpObserve;
  reportProgress?: boolean;
  responseType?: HttpResponseType;
  withCredentials?: boolean;
  setParams?: (args?: SetParamsArgsForOptions) => void;
  getPath?: (url?: string) => string;
}

export function getOptionsForDataKeyParams(args: SetParamsArgsForOptions): HttpRequestOptions {
  args.options = args.options || { params: null };
  args.options.params = args.options.params || new HttpParams();
  args.options.setParams = args.options.setParams || setDataKeyParams;
  return getRequestOptions(args);
}

export function setDataKeyParams(args: SetParamsArgsForOptions) {
  if (args.data) {
    Object.keys(args.data).forEach(key => {
      const value = args.data[key] === undefined ? null : args.data[key];
      if (value instanceof Array) {
        value.forEach(v => {
          if (v) {
            args.options.params = args.options.params.append(key, v);
          }
        });
      } else {
        if (value) {
          args.options.params = args.options.params.set(key, value);
        }
      }
    });
  }
}

export function getRequestOptions(args: SetParamsArgsForOptions): HttpRequestOptions {
  if (args.options && args.options.setParams) {
    args.options.setParams(args);
  }
  return args.options;
}
