import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { EntityHistory } from 'src/app/models/entityHistory';
import { LogHistoryListService } from './log-history-list.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { AuthService } from 'src/app/common/auth/auth.service';
import { BaseListComponent } from 'src/app/common/components/base-list.component';

@Component({
  selector: 'ecg-history-list',
  templateUrl: './log-history-list.component.html',
  styleUrls: ['./log-history-list.component.scss'],
})
export class LogHistoryListComponent extends BaseListComponent<EntityHistory> implements OnInit {

  constructor(
    public service: LogHistoryListService,
    spinner: NgxSpinnerService,
    router: Router,
    route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public auth: AuthService,
    protected toast: ToastService
  ) {
    super({
      service,
      spinner,
      router,
      route,
      idColumn: 'id',
      formRoute: '/history'
    });


  }
}
