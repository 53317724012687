import * as tslib_1 from "tslib";
import { BaseListComponent } from 'src/app/common/components/base-list.component';
import { PDFDocument } from 'pdf-lib';
import { UploadInvoice } from 'src/app/models/upload-invoice';
import Swal from 'sweetalert2';
import { DeleteInvoiceModalComponent } from '../components/delete-invoice-modal/delete-invoice-modal.component';
class ImageSnippet {
    constructor(src, file) {
        this.src = src;
        this.file = file;
        this.pending = false;
        this.status = 'init';
    }
}
export class InvoiceUploadComponent extends BaseListComponent {
    constructor(service, spinner, router, route, logger, auth, modal, sanitizer, translate, invoiceService, modalService, translateService) {
        super({
            service, spinner, router, route,
            idColumn: 'id',
            formRoute: '/invoices'
        });
        this.logger = logger;
        this.auth = auth;
        this.modal = modal;
        this.sanitizer = sanitizer;
        this.translate = translate;
        this.invoiceService = invoiceService;
        this.modalService = modalService;
        this.translateService = translateService;
        this.files = [];
        this.allOptions = [];
        service.data.subscribe((res) => {
            this.datas = res;
        });
    }
    onSelectionChanged(selection) {
        this.selectedMonth = selection.month;
        this.selectedYear = selection.year;
        if (this.selectedMonth > 0 && this.selectedYear > 0) {
            this.datas.forEach(organization => {
                this.checkExistingInvoice(organization);
            });
        }
    }
    checkExistingInvoice(organization) {
        organization.hasExistingInvoice = false;
        if (organization.invoices.length > 0 && organization.invoiceFiles.length > 0) {
            const existingInvoice = organization.invoices.find(invoice => invoice.month === this.selectedMonth && invoice.year === this.selectedYear);
            if (existingInvoice != undefined) {
                const existingInvoiceFile = organization.invoiceFiles.find(invoiceFile => invoiceFile.invoiceId === existingInvoice.id);
                if (existingInvoice && existingInvoiceFile) {
                    organization.hasExistingInvoice = true;
                }
                else {
                    organization.hasExistingInvoice = false;
                }
            }
        }
    }
    isClickable() {
        return this.selectedMonth > 0 && this.selectedYear > 0;
    }
    uploadItemModal(modal, id, name) {
        this.customerCodeId = id;
        this.customerName = name;
        if (!this.isClickable())
            return;
        this.modal.open(modal, {
            centered: true
        });
    }
    processFile(file) {
        if (!file.name.includes(this.customerName)) {
            this.fileNotContainsCustomerCodeModal();
            this.closeModal();
        }
        else {
            const reader = new FileReader();
            reader.addEventListener('load', (event) => {
                this.selectedFile = new ImageSnippet(event.target.result, file);
                this.selectedFile.pending = true;
                this.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file));
                this.getAllOptions(this.selectedFile);
            });
            reader.readAsDataURL(file);
        }
    }
    closeModal() {
        this.modal.dismissAll();
        this.clearData();
    }
    onSelect(event) {
        if (this.files.length != 0) {
            this.files = [];
        }
        this.files.push(...event.addedFiles);
        this.processFile(event.addedFiles[0]);
    }
    getAllOptions(file) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.allOptions = [];
            const pdfDoc = yield PDFDocument.load(file.src);
            for (var i = 0; i < pdfDoc.getPageCount(); i++) {
                this.allOptions.push(i + 1);
            }
        });
    }
    onRemove(event) {
        this.clearData();
    }
    clearData() {
        this.files = [];
        this.pdfFile = undefined;
        this.selectedFile = undefined;
        this.allOptions = [];
    }
    uploadFile() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.spinner.show();
            const uploadInvoice = new UploadInvoice();
            uploadInvoice.customerCodeId = this.customerCodeId;
            uploadInvoice.fileName = this.selectedFile.file.name;
            uploadInvoice.pdfPath = this.selectedFile.src;
            uploadInvoice.year = this.selectedYear.toString();
            uploadInvoice.month = this.selectedMonth.toString();
            yield this.invoiceService.uploadInvoice(uploadInvoice, () => {
                this.spinner.hide();
                this.showUploadModal();
                this.refresh();
            }, (error) => {
                Swal.close();
                console.warn(error);
            });
        });
    }
    checkBeforeDelete(customerCode) {
        const matchingInvoice = customerCode.invoices.find(invoice => {
            return customerCode.invoices.some(invoiceItem => customerCode.customerCodeId == invoiceItem.customerCodeId && invoice.month == this.selectedMonth && invoice.year == this.selectedYear);
        });
        const modalRef = this.modalService.open(DeleteInvoiceModalComponent, {
            size: 'lg',
            centered: true,
            backdrop: 'static',
            keyboard: false
        });
        modalRef.componentInstance.deleteEvent.subscribe(() => {
            this.deleteInvoice(matchingInvoice.id);
        });
    }
    deleteInvoice(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.spinner.show();
            yield this.invoiceService.remove(id, () => {
                this.spinner.hide();
                this.showDeleteModal();
            });
        });
    }
    showDeleteModal() {
        return Swal.fire({
            position: 'center',
            icon: 'success',
            title: this.translateService.instant('invoiceForm.deleted'),
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            timer: 1500,
            onClose: () => {
                this.refresh();
            }
        });
    }
    fileNotContainsCustomerCodeModal() {
        Swal.fire({
            position: 'center',
            title: this.translate.instant('invoiceForm.containsCustomerCode'),
            icon: 'error',
            showCloseButton: true,
            timer: 5000,
            allowOutsideClick: false,
        });
    }
    showUploadModal() {
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Successful.',
            showConfirmButton: true,
            timer: 3000,
            onClose: () => {
                this.closeModal();
            }
        });
    }
}
