/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./delete-invoice-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./delete-invoice-modal.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
var styles_DeleteInvoiceModalComponent = [i0.styles];
var RenderType_DeleteInvoiceModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DeleteInvoiceModalComponent, data: {} });
export { RenderType_DeleteInvoiceModalComponent as RenderType_DeleteInvoiceModalComponent };
export function View_DeleteInvoiceModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h4", [["class", "modal-title"], ["id", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["aria-describedby", "modal-title"], ["aria-label", "Close button"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close("Close click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "strong", [["style", "font-size: 20px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, null, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["class", "btn btn-danger"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(14, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(16, 0, null, null, 2, "button", [["class", "btn btn-outline-secondary"], ["ngbAutofocus", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close("Close click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(17, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("invoiceForm.deletion")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("invoiceForm.deleteInvoice")); _ck(_v, 10, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("invoiceForm.ok")); _ck(_v, 14, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform("cancel")); _ck(_v, 17, 0, currVal_3); }); }
export function View_DeleteInvoiceModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ecg-delete-invoice-modal", [], null, null, null, View_DeleteInvoiceModalComponent_0, RenderType_DeleteInvoiceModalComponent)), i1.ɵdid(1, 49152, null, 0, i3.DeleteInvoiceModalComponent, [i4.NgbModal, i4.NgbActiveModal], null, null)], null, null); }
var DeleteInvoiceModalComponentNgFactory = i1.ɵccf("ecg-delete-invoice-modal", i3.DeleteInvoiceModalComponent, View_DeleteInvoiceModalComponent_Host_0, {}, {}, []);
export { DeleteInvoiceModalComponentNgFactory as DeleteInvoiceModalComponentNgFactory };
