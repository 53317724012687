import { Component } from '@angular/core';
import { BaseListComponent } from '../common/components/base-list.component';
import { Cradle } from '../models';
import { CradlesListService } from '../cradles/cradles-list.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import { Logger } from '../common/logging/logger.service';
import { HubConnection } from '@microsoft/signalr';
import { SignalRService } from '../common/signalr/signal-r.service';

@Component({
  selector: 'ecg-firmware',
  templateUrl: './firmware.component.html',
  styleUrls: ['./firmware.component.scss']
})
export class FirmwareComponent extends BaseListComponent<Cradle>   {
  cradleNew: any;
  statusFilter: any = '';
  private hubConnection: HubConnection;
  constructor(service: CradlesListService, spinner: NgxSpinnerService, router: Router, route: ActivatedRoute, public logger: Logger, protected signalRService: SignalRService) {
    super({
      service, spinner, router, route,
      idColumn: 'id',
      formRoute: '/cradle',
      namePipe: (d) => d && d.cradleSerialNumber ? d.cradleSerialNumber : d.id.toString()
    });
    this.createConnection();
    service.data.subscribe((data) => {
      this.cradleNew = data;
    });
  }
  protected async createConnection() {
    this.hubConnection = await this.signalRService.connect('/loghub');
    await this.hubConnection.start();
    this.hubConnection.on('updateCradle', res => {
      const jsonRes = JSON.parse(res);
      const index = this.cradleNew.findIndex(cradle => cradle.id === jsonRes.Id);
      if (index > 0) {
        this.cradleNew[index].cradleStatus = jsonRes.CradleStatus;
        this.cradleNew[index].updatedDateTime = jsonRes.UpdatedDateTime;
      }
    });
    this.hubConnection.on('cradleForm', res => {
      var resParse = JSON.parse(res);
      const index = this.cradleNew.findIndex(cradle => cradle.id === resParse.Id);
      if (index > 0) {
        this.cradleNew[index].room = resParse.Room;
        this.cradleNew[index].cradleStatus = resParse.CradleStatus;
        this.cradleNew[index].systoleSerialNumber = resParse.SystoleSerialNumber;
        this.cradleNew[index].linkedPC = resParse.LinkedPC;
        this.cradleNew[index].updatedDateTime = resParse.UpdatedDateTime;
        this.cradleNew[index].customer.name = resParse.Customer.Name;
      }
    });
  }
  changeStatus(event) {
    const  newPaging: any = {
      size: this.paging.size,
      index: this.paging.index,
      status: event
    };
    this.service.getSearch(newPaging, {filters: this.filters});
    this.service.data.subscribe(r => {
      this.cradleNew = r;
    });
  }
}
