import { DataListService } from '../../common/data/data-list.service';
import * as i0 from "@angular/core";
import * as i1 from "../../common/auth/auth-api-http-client";
export class AccessRequestsService extends DataListService {
    constructor(httpClient) {
        super({
            httpClient,
            url: 'auth/accessRequest',
            initial: []
        });
    }
    setAllowAccess(model) {
        this.allowAccessSubscribe = this.httpClient.post(model, 'auth/ipwhitelist/set-allow-access');
    }
}
AccessRequestsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccessRequestsService_Factory() { return new AccessRequestsService(i0.ɵɵinject(i1.AuthApiHttpClient)); }, token: AccessRequestsService, providedIn: "root" });
