<div style="display: flex; flex-wrap: wrap;" class="g-3 ml-1 mt-3">
    <div class="col-3">
        <select class="form-control" id="month" [(ngModel)]="selectedMonth" (change)="onSelectionChange()">
            <option value="0" disabled selected>{{'invoiceForm.selectMonth' | translate}}</option>
            <option *ngFor="let month of months" [value]="month.value">{{ month.name }}</option>
        </select>      
    </div>
    <div class="col-3">
        <select class="form-control" id="year" [(ngModel)]="selectedYear" (change)="onSelectionChange()">
            <option value="0" disabled selected>{{'invoiceForm.selectYear' | translate}}</option>
            <option *ngFor="let year of years" [value]="year">{{ year }}</option>
        </select>
    </div>
</div>