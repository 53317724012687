import { Component, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from '../shared/services/toast.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { TranslateService } from '@ngx-translate/core';
import { Note } from '../models/note';
import { NoteItemService } from './note-item.service';
import { Paging } from '../common/data/result';
import { AuthApiHttpClient } from '../common/auth/auth-api-http-client';
import { combineLatest } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'ecg-note-form',
  templateUrl: './note-form.component.html',
  styleUrls: ['./note-form.component.scss'],
})
export class NoteFormComponent {
  firstTime = true;
  noteModel: Note = {noteText: ''};
  hospitalList: any = [];
  isLoad = false;
  branchLoad = false;
  customerName: any;

  public op;
  branchList: any = [];
  hospitalUserList: any = [];
  paging: Paging = {
    size: 1000,
    index: 1,
    search: null,
  };

  public Editor = ClassicEditor;
  public config = {
    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote']
  };

  constructor(

    public service: NoteItemService,
    protected location: Location,
    public route: ActivatedRoute,
    public router: Router,
    private spinner: NgxSpinnerService,
    private toast: ToastService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    public modal: NgbModal,
    public httpClient: AuthApiHttpClient
  ) {
    const url = this.router.url.split('/');
    this.op = url[2].split('?')[0];
    if (this.op != 'edit') {
      service.hospitalList.subscribe(res => {
        for (const hospital of res) {
          this.hospitalList.push({ id: hospital.id, name: hospital.code, customerId: hospital.customerId, customerName: hospital.customer.name });
        }
      });
    }
    let id = 0;
    this.route.queryParams.subscribe(params => {
      id = params.id;
    });

    if (this.op == 'edit') {
      combineLatest(
        httpClient.get('health/cradles/customerCodes'),
        httpClient.get('health/notes/item?id=' + id))
        .subscribe(data => {
          this.hospitalList = [];
          const hlist: any = [];
          const [hospitals, model] = data;
          // @ts-ignore
          for (const hospital of hospitals) {
            hlist.push({ id: hospital.id, name: hospital.code, customerId: hospital.customerId, customerName: hospital.customer.name });
          }
          this.hospitalList = hlist;

          setTimeout(() => {
            // @ts-ignore
            this.noteModel = model.data;

            this.changeOrganisation(this.noteModel.customerCodeId);
          }, 100);
        });
    }
  }

  formValid(form: FormGroup) {
    return form.valid;
  }

  @HostListener('window:keydown.shift.backspace')
  back() {
    this.location.back();
  }

  changeOrganisation(id) {
    const index = this.hospitalList.findIndex(hospital => hospital.id === +id);
    this.noteModel.customerId = this.hospitalList[index].customerId;
    this.customerName = this.hospitalList[index].customerName;
    // this.noteModel.customerBranchId = 0;
    this.service.getBranchListService(this.noteModel.customerId);
    this.service.branchList.subscribe(res => {
      this.branchList = [];
      for (const branch of res) {
        this.branchList.push({
          id: branch.id,
          name: branch.branchName
        });
      }
      this.branchLoad = true;
    }
    );

    this.service.getHospitalUser(this.noteModel.customerId, this.paging);
    this.service.hospitalUserList.subscribe((res) => {
      this.hospitalUserList = [];
      for (const user of res) {
        this.hospitalUserList.push({
          id: user.id,
          name: user.name,
        });
      }
    });
  }

  changeBranch(id) {
    if (id > 0) {
      const index = this.branchList.findIndex(branch => branch.id === +id);
      this.noteModel.customerBranchId = this.branchList[index].id;
    } else {
      this.noteModel.customerBranchId = -1;
    }
    
  }

  save() {
    if (this.noteModel.userAccountId == -1) {
      this.noteModel.userAccountId = null;
    }
    if (this.noteModel.customerBranchId == -1) {
      this.noteModel.customerBranchId = null;
    }
    if (this.op != 'edit' || this.op === undefined) {
      if (this.noteModel.type == undefined || this.noteModel.type == null || this.noteModel.type == '') {
        this.toast.showDanger(this.translate.instant('required.noteType'));
        return;
      }
    }
    if (this.op != 'edit' || this.op === undefined) {
      this.httpClient.post(this.noteModel, 'health/notes/item').subscribe(res => {
        this.toast.showSuccess(this.translate.instant('noteForm.success'));
        this.router.navigate(['/notes']);

      }, error => {
        this.toast.showDanger(this.translate.instant('noteForm.failed'));
      });
    } else {
      this.httpClient.put(this.noteModel, 'health/notes/item').subscribe(res => {
        this.toast.showSuccess(this.translate.instant('noteForm.success'));
        this.router.navigate(['/notes']);
      },
        error => {
          this.toast.showDanger(this.translate.instant('noteForm.failed'));

        });
    }
  }

  changeUser(id) {
    const index = this.hospitalUserList.findIndex(user => user.id === +id);
    this.noteModel.userAccountId = this.hospitalUserList[index].id;
  }

  delete() {
    if (this.op == 'edit') {
      const params = { id: this.noteModel.id };
      this.service.delete(params);
      this.router.navigate(['/notes']);

    }
  }

  public onChange({ editor }: ChangeEvent) {
    // this.editorValid = true;
    this.noteModel.noteText = editor.getData();
  }
}
