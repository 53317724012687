import {Injectable} from '@angular/core';
import {AuthApiHttpClient} from '../common/auth/auth-api-http-client';
import {DataItemService} from '../common/data/data-item.service';
import {ActivatedRoute} from '@angular/router';
import { IpWhitelist } from '../models/ip-whitelist';

@Injectable({providedIn: 'root'})
export class IpWhiteListItemService extends DataItemService<IpWhitelist> {
  public hospitalList: any;
  constructor(httpClient: AuthApiHttpClient, route: ActivatedRoute) {
    super({
      httpClient,
      url: 'auth/ipwhitelist/item'
    });
    this.hospitalList = this.httpClient.get<Array<string>>('health/cradles/customerCodes');
  }
}
