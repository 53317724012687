import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'func'
})
export class FuncPipe implements PipeTransform {
  // tslint:disable-next-line: ban-types
  transform(context: any, func: Function, ...args: any[]): any {
    if (context && func) {
      return func.apply(context, args);
    }
    return null;
  }
}
