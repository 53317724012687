import {Injectable} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';

@Injectable({providedIn: 'root'})
export class PwaUpdateService {
  constructor(private update: SwUpdate) {
  }

  public checkForUpdate() {
    const interval = setInterval(() => {
      if (this.update.isEnabled) {
        clearInterval(interval);
        return this.updateApp(this.update);
      }
    }, 1000);
  }

  private async updateApp(update: SwUpdate) {
    update.available.subscribe(async () => {
      await update.activateUpdate();
      location.reload();
    });
    return update.checkForUpdate();
  }
}
