import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthApiHttpClient } from '../common/auth/auth-api-http-client';
import { DataItemService } from '../common/data/data-item.service';
import { Cradle } from '../models';
import { CradleTypeResponseModel } from '../models/cradle-type-response-model';

@Injectable({ providedIn: 'root' })
export class CradleItemService extends DataItemService<Cradle> {
  hospitalList: any;
  branchList: any;
  logItem: any;
  userList: any;
  constructor(httpClient: AuthApiHttpClient) {
    super({
      httpClient,
      url: 'health/cradles/item'
    });
    this.hospitalList = this.httpClient.get<Array<string>>('health/cradles/customerCodes');
    this.userList = this.httpClient.get<Array<string>>('auth/useraccounts');


  }

  setCradleId(req: {
    id,
    startTime,
    endTime,
    prepValue,
    recorderSn,
  }) {
    let params = new HttpParams();

    Object.keys(req).forEach(v => {
      if (req[v] && req[v] != '') {
        params = params.append(v, req[v]);
      }
    });

    return this.httpClient.get<Array<string>>(
      `health/cradles/cradlelogs`
      , {
        params: params
      });
  }

  setCradleLogs(id) {
    //Cradle logları ceker.
    return this.httpClient.get<Array<string>>('health/cradles/cradlelogs?id=' + id);
  }
  getCradleByLinkedMac(mac: string, cradleSerialNumber: string) {
    return this.httpClient.get(`health/cradles/by-linked-mac?mac=${mac || ''}&cradleSerialNumber=${cradleSerialNumber}`);
  }

  getBranchListService(customerId) {
    this.branchList = this.httpClient.get<Array<string>>('health/cradles/customerBranchs/' + customerId);
  }

  controlLastPatient(cradleId,recorderStatus){
    return this.httpClient.get(`health/cradles/control-last-patient?cradleId=${cradleId}&recorderStatus=${recorderStatus}`);
  }

  customerCodes() : Observable<any> {
    return this.httpClient.get<Array<string>>('health/cradles/customerCodes');
  }

  cradlePatientClear(patientId){
    return this.httpClient.get(`health/cradles/cradle-patient-clear?patientId=${patientId}`);
  }

  async getCradleType(cradleSerialNumber: string) : Promise<CradleTypeResponseModel> {
    let uri: string = `health/cradles/${cradleSerialNumber}`;
    const promiseData: Promise<CradleTypeResponseModel> = this.httpClient.get<CradleTypeResponseModel>(uri).toPromise();
    return await promiseData;
  }
}
