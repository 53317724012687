import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { AuthLogoutService } from './auth-logout.service';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class ActivityCheckService {
  broadcastChannel: BroadcastChannel;
  broadcastChannelModal: BroadcastChannel;
  constructor(private auth: AuthService, private authLogoutService: AuthLogoutService, protected storageMap: StorageMap) {
    try {
      this.broadcastChannel = new BroadcastChannel('start-timer');
      this.broadcastChannelModal = new BroadcastChannel('modal-close');
    } catch (error) {

    }
  }

  updateLastActivity() {
    if (this.lastActivityControl()) {
      localStorage.setItem('lastActivityDate', new Date().toString());
      try {
        this.broadcastChannel.postMessage("timer start update last activity");
      } catch (error) {
        
      }
    }
  }

  lastActivityControl(): boolean {
    const lastActivityItem = localStorage.getItem('lastActivityDate');
    const lastActivityDate = new Date(lastActivityItem);
    let now = new Date();
    const lastSevenMinutes = new Date(now.setTime(now.getTime() + (-60 * 60 * 1000)));
    console.log("last", lastActivityDate)
    console.log("last seven", lastSevenMinutes);
    if (lastActivityDate < lastSevenMinutes) {
      console.log("ACTIVITY DATE RANGE OUT");
      try {
        this.broadcastChannelModal.postMessage("logout");
      } catch (error) {
        
      }
      if (!this.auth.needLogin) {
        console.log("LOGOUT (Last avtivity)");
        this.authLogoutService.logout();
      }
      return false;
    }
    return true;
  }

}
