import { ViewChildren, QueryList, ViewChild, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Params, Router, ActivatedRoute } from '@angular/router';
import { Observable, Subscription, Subject, BehaviorSubject, merge, of, forkJoin, combineLatest } from 'rxjs';
import { map, switchMap, filter, debounceTime, distinctUntilChanged, tap, take, combineAll, withLatestFrom, mergeAll, concatMap, switchMapTo } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbTabset, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

import { DataListService } from '../data/data-list.service';
import {FilterMetaData, FilterMetaDataItem, Filters, Paging, SelectChange} from '../data/result';
import { SortableHeader, SortEvent } from '../../shared/directives/sortable.directive';
import { FilterComponent } from '../../shared/components/filter.component';
import { NamedDetail, BaseListArgs } from './list';
import { DefSortEvent } from 'src/app/shared/directives/defSortable.directive';
import { DateRangeService } from 'src/app/report/platform-performance/date-range/date-range.service';

interface MetaData {
  event: string,
  key: 'Project' | 'Module'
}

export abstract class BaseListComponent<T> implements OnInit, OnDestroy {
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;
  @ViewChild(NgbTabset, { static: false }) tabset: NgbTabset;
  @ViewChild(FilterComponent, { static: false }) listFilter: FilterComponent;
  @ViewChild('searchTypehead', { static: false }) searchTypehead: NgbTypeahead;

  service: DataListService<T>;
  idColumn: string;
  lastFilter: any;
  lastPaging: any;
  reset: boolean = false;
  showFilter: boolean = false;
  currentProjectSelection: string;

  formRoute: string;

  spinner: NgxSpinnerService;
  namePipe: (detail: T) => string;

  paging: Paging = {
    size: 10,
    index: 1,
    search: null
  };
  filterMetaData: Observable<FilterMetaData[]>;
  _filterMetaData: FilterMetaData[];
  filters: Filters;
  filtersClear:Filters;
  emptyFilter: Filters = {};
  protected dataCountSubject = new BehaviorSubject(0);
  dataCount: Observable<number>;
  details: Array<NamedDetail<T>> = [];
  searchModel = new Subject<string>();
  searchFocus = new Subject<string>();
  searchClick = new Subject<string>();
  lastSearch = '';
  
  searchParams?: Params = {};
  selectedRow = 0;
 
  protected router: Router;
  protected route: ActivatedRoute;

  protected resultSubscription: Subscription;

  protected constructor(protected args: BaseListArgs<T>, private dateRangeService?: DateRangeService) {
    this.service = args.service;
    this.idColumn = args.idColumn;
    this.formRoute = args.formRoute;
    this.spinner = args.spinner;
    this.router = args.router;
    this.route = args.route;
    this.namePipe = args.namePipe ? args.namePipe : (detail: T) => detail[this.idColumn];
    this.filterMetaData = this.service.filterMetaData;
    this.dataCount = this.service.dataResult.pipe(
      map(dr => dr ? dr.count : 0)
    );
    this.filterMetaData.pipe(take(1)).subscribe(metaData => {
      this._filterMetaData = metaData;
    });
  }

  ngOnInit() {
    this.setLastFilter();
    this.dataCount.subscribe(
      c => this.dataCountSubject.next(c),
      e => this.dataCountSubject.error(e)
    );
    
    this.resultSubscription = this.route.queryParams
      .pipe(
        switchMap(params => {
          Object.keys(params).forEach(key => {
            if (params[key]) {
              this.searchParams[key] = params[key];
            } else {
              delete this.searchParams[key];
            }
          });
          return this.service.result;
        })
      ).subscribe(r => {
        if (r.data && r.data.length > 0 && this.searchParams[this.idColumn]) {
          const sDetail = r.data.filter(detail => detail[this.idColumn] === this.searchParams[this.idColumn])[0];
          this.showDetail(sDetail || Object.assign({}, this.searchParams as any));
        }
        this.spinner.hide();
      });

    this.refresh();

  }

  setLastFilter(): void{
    
    this.lastFilter = JSON.parse(localStorage.getItem(this.router.url.substring(1) + "Filter"));
    this.lastPaging = JSON.parse(localStorage.getItem(this.router.url.substring(1) + "Paging"));
    if (this.lastFilter && this.lastPaging) {
      this.showFilter = true;
      this.paging = this.lastPaging;
      this.filterChange(this.lastFilter);
      this.next();
    } else if (this.lastPaging) {
      this.paging = this.lastPaging;
      this.next();
    }
  }
 
  ngOnDestroy() {
    this.resultSubscription.unsubscribe();
  }

  refresh() {
    this.spinner.show();
    this.service.getSearch(this.paging, { filters: this.filters });
  }

  refreshClear() {
    this.spinner.show();
    this.service.getSearch(this.paging, { filters: this.emptyFilter });
  }
  onSort(sort: SortEvent) {
    this.headers.forEach(header => {
      if (header.sortable !== sort.column) {
        header.direction = '';
      }
    });
    if (sort.direction === '') {
      delete this.paging.orders;
    } else {
      this.paging.orders = sort.column.split(', ').map(column => {
        return { column, type: sort.direction as any };
      });
    }
    this.refresh();
  }

  pageCount = (count: number, size: number) => Math.ceil(count / size);

  next() {
    localStorage.setItem(this.router.url.substring(1) + "Paging", JSON.stringify(this.paging));
    const count = this.dataCountSubject.getValue();
    const pageCount = this.pageCount(count, this.paging.size);
    if (this.paging.index <= pageCount) {
      this.paging.index;
      this.refresh();
    }
  }

  previous() {
    if (this.paging.index > 1) {
      this.paging.index--;
      this.refresh();
    }
  }

  filterChange(filters: Filters) {
    this.reset = false;
    this.filters = filters;
    this.filtersClear=filters;
    localStorage.setItem(this.router.url.substring(1) + "Paging", JSON.stringify(this.paging));
    localStorage.setItem(this.router.url.substring(1) + "Filter", JSON.stringify(this.filters));
    this.refresh();
  }

  pagingSave() {
    localStorage.setItem(this.router.url.substring(1) + "Paging", JSON.stringify(this.paging));
  }

  search = (text: Observable<string>) => {
    const searchModalText = this.searchModel.asObservable().pipe(
      filter(() => this.searchTypehead.isPopupOpen())
    );
    const debouncedText = merge(searchModalText, text).pipe(debounceTime(500), distinctUntilChanged());
    const clickedText = this.searchClick.pipe(
      filter(() => !this.searchTypehead.isPopupOpen)
     
    );
    return merge(debouncedText, clickedText, this.searchFocus).pipe(
      switchMap(term => {
        if (term !== this.lastSearch) {
          this.paging.index = 1;
          this.refresh();
          this.lastSearch = term;
        }
        return of();
      })
    );
  }

  tabActivated(activeId: string) {
    if (activeId === 'ngb-tab-main') {
      delete this.searchParams[this.idColumn];
      if (this.listFilter) { this.listFilter.toggleFilter('open'); }
    } else {
      this.searchParams[this.idColumn] = activeId.slice(8);
      if (this.listFilter) { this.listFilter.toggleFilter('close'); }
    }
    this.setSearchParams();
  }

  getNamedDetail(detail: T): NamedDetail<T> {
    const named = detail as any;
    named._name = this.namePipe(detail);
    return named;
  }

  showDetail(detail: T): void {
    const namedDetail = this.getNamedDetail(detail);
    const selecteds = this.details.filter(d => d[this.idColumn] === namedDetail[this.idColumn]);
    if (namedDetail[this.idColumn] && (!selecteds || !selecteds.length)) {
      this.details = this.details.concat(namedDetail);
      this.details=this.details;

    }
    setTimeout(() => {
      if (namedDetail[this.idColumn]) {
        this.tabset.select(`ngb-tab-${namedDetail[this.idColumn]}`);
      }
    });
  }

  closeDetail(index: number, event: Event): void {
    this.details = this.details.filter((v, i) => i !== index);
    event.preventDefault();
    setTimeout(() => this.tabActivated(this.tabset.activeId));
  }

  add() {
    this.router.navigate([this.formRoute, 'add']);
  }

  edit(model: T) {
    const extras = { queryParams: {} };
    extras.queryParams[this.idColumn] = model[this.idColumn];
    this.router.navigate([this.formRoute, 'edit'], extras);
  }

  delete(model: T) {
    const form = {};
    form[this.idColumn] = model[this.idColumn];
  }

  protected setSearchParams(searchParams: Params = this.searchParams) {
    Object.keys(searchParams).forEach(k => {
      if (!k || !searchParams[k]) {
        delete searchParams[k];
      }
    });
    this.searchParams = searchParams;
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: this.searchParams,
      });
  }

  pagingReset() {
    this.paging = { size: 10, index: 1, search: null };
  }

  filterReset() {
    this.showFilter = true;
    this.pagingReset();
    this.filterChange(this.emptyFilter);
    this.refresh();
    this.reset = true;
    this.filters=this.emptyFilter;
    localStorage.removeItem(this.router.url.substring(1) + "Filter");
    this.refreshClear();
    
  }

  resetQueryFilter()
  {
    this.router.navigate(
      ['.'], 
      { relativeTo: this.route }
    );
  }

  
}
