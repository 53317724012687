import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { EMPTY } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ApiHttpClient } from '../data/api-http-client';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./auth.service";
import * as i3 from "@angular/router";
export class AuthApiHttpClient extends ApiHttpClient {
    constructor(http, auth, router) {
        super(http);
        this.auth = auth;
        this.router = router;
    }
    request(method, body, url, options = ApiHttpClient.getDefaultOptions()) {
        return this.auth.getAccessToken().pipe(switchMap(at => {
            if (at) {
                options.headers = options.headers || new HttpHeaders();
                options.headers = options.headers.set('Authorization', `Bearer ${at}`);
                return super.request(method, body, url, options).pipe(tap(() => { }, (err) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status !== 401) {
                            return;
                        }
                        this.auth.saveToken(null);
                        this.router.navigate(['login']);
                    }
                }));
            }
            return EMPTY;
        }));
    }
    requestBlob(method, body, url, options = ApiHttpClient.getDefaultOptions('blob')) {
        return this.auth.getAccessToken().pipe(switchMap(at => {
            if (at) {
                options.headers = options.headers || new HttpHeaders();
                options.headers = options.headers.set('Authorization', `Bearer ${at}`);
                return super.requestBlob(method, body, url, options);
            }
            return EMPTY;
        }));
    }
}
AuthApiHttpClient.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthApiHttpClient_Factory() { return new AuthApiHttpClient(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthService), i0.ɵɵinject(i3.Router)); }, token: AuthApiHttpClient, providedIn: "root" });
