import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ecg-platform-performance',
  templateUrl: './platform-performance.component.html',
  styleUrls: ['./platform-performance.component.scss']
})
export class PlatformPerformanceComponent implements OnInit {

  constructor(route: ActivatedRoute, public router: Router) {}

  ngOnInit() {
  }

}
