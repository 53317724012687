import * as tslib_1 from "tslib";
import { DataListService } from 'src/app/common/data/data-list.service';
import * as i0 from "@angular/core";
import * as i1 from "../../common/auth/auth-api-http-client";
export class InvoiceService extends DataListService {
    constructor(httpClient) {
        super({
            httpClient,
            url: 'invoice/invoices',
            initial: []
        });
    }
    uploadInvoice(uploadInvoice, successCallBack, errorCallBack) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let uri = `Invoice/Invoices/UploadInvoice`;
            const promiseData = this.httpClient.post(uploadInvoice, uri).toPromise();
            promiseData
                .then(() => successCallBack())
                .catch((errorResponse) => errorCallBack(errorResponse.message));
            return yield promiseData;
        });
    }
    remove(id, successCallBack) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let uri = `Invoice/Invoices/RemoveInvoice/${id}`;
            yield this.httpClient.delete({}, uri).toPromise();
            successCallBack();
        });
    }
    removeInvoiceFile(invoiceFileId, successCallBack, errorCallBack) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let uri = `Invoice/Invoices/RemoveInvoiceFile/${invoiceFileId}`;
            const promiseData = this.httpClient.delete({}, uri).toPromise();
            promiseData
                .then(() => successCallBack())
                .catch((errorResponse) => errorCallBack(errorResponse.message));
            return yield promiseData;
        });
    }
    getInvoiceCsvFile(id, successCallBack, errorCallBack) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let uri = `Invoice/Invoices/GetInvoiceCsvFile/${id}`;
            const promiseData = this.httpClient.get(uri, {
                responseType: 'text'
            }).toPromise();
            promiseData
                .then(() => successCallBack())
                .catch((errorResponse) => errorCallBack(errorResponse.message));
            return yield promiseData;
        });
    }
    downloadCsvFile(fileName, successCallBack, errorCallBack) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let uri = `Invoice/Invoices/DownloadCsvFile/${fileName}`;
            const promiseData = this.httpClient.get(uri, {
                responseType: "blob"
            }).toPromise();
            promiseData
                .then(() => successCallBack())
                .catch((errorResponse) => errorCallBack(errorResponse.message));
            return yield promiseData;
        });
    }
}
InvoiceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InvoiceService_Factory() { return new InvoiceService(i0.ɵɵinject(i1.AuthApiHttpClient)); }, token: InvoiceService, providedIn: "root" });
