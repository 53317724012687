import { DataListService } from '../common/data/data-list.service';
import * as i0 from "@angular/core";
import * as i1 from "../common/auth/auth-api-http-client";
export class CradlesListService extends DataListService {
    constructor(httpClient) {
        super({
            httpClient,
            url: 'health/cradles',
            initial: []
        });
    }
}
CradlesListService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CradlesListService_Factory() { return new CradlesListService(i0.ɵɵinject(i1.AuthApiHttpClient)); }, token: CradlesListService, providedIn: "root" });
