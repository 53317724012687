import {OnInit, ViewChild, TemplateRef, OnDestroy, HostListener} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {switchMap, filter} from 'rxjs/operators';
import {Subscription} from 'rxjs';

import {ToastService} from '../../shared/services/toast.service';
import {DataItemService} from '../data/data-item.service';
import {Logger} from '../logging/logger.service';
import {Loading, LoadingStates} from '../data/result';
import {BaseFormArgs, FormOperations} from './form';
import {FormGroup} from '@angular/forms';

export abstract class BaseFormComponent<T> implements OnInit, OnDestroy {
  @ViewChild('savedSuccessMessage', {static: false}) savedSuccessMessage: TemplateRef<any>;
  @ViewChild('savedErrorMessage', {static: false}) savedErrorMessage: TemplateRef<any>;
  @ViewChild('deletedSuccessMessage', {static: false}) deletedSuccessMessage: TemplateRef<any>;
  @ViewChild('deletedErrorMessage', {static: false}) deletedErrorMessage: TemplateRef<any>;
  model: T;
  operation: FormOperations;

  protected successMessage: TemplateRef<any>;
  protected errorMessage: TemplateRef<any>;
  protected location: Location;
  protected route: ActivatedRoute;
  protected router: Router;
  protected toast: ToastService;
  public service: DataItemService<T>;
  protected logger: Logger;
  protected idColumn: string;
  protected routeParams: Params;
  protected queryParams: Params;
  protected loadingSubscription: Subscription;
  protected resultSubscription: Subscription;
  private message: string;
  showMessageSuccess = true;
  formRoute: string;
  path: string;

  constructor(protected args: BaseFormArgs<T>) {
    this.route = args.route;
    this.router = args.router;
    this.location = args.location;
    this.toast = args.toast;
    this.service = args.service;
    this.logger = args.logger;

    this.idColumn = args.idColumn || 'id';
  }

  ngOnInit() {
    this.loadingSubscription = this.service.loadingStates.pipe(
      filter(ls => ls.old.state > Loading.getting && ls.new.state === Loading.idle)
    ).subscribe(ls => {
      this.showMessage(ls, this.message);
    });
    this.resultSubscription = this.route.params.pipe(
      switchMap(params => {
        this.setRouteParams(params);
        return this.route.queryParams;
      }),
      switchMap(params => {
        this.setQueryParams(params);
        return this.service.result;
      })
    ).subscribe(
      result => {
        if (result.error) {
          if (result.error.error && result.error.error.data && result.error.error.data.message) {
            this.message = result.error.error.data.message;
          } else {
            this.message = '';
          }
          this.setError(result.error);
        }
        if (result.data) {
          this.setData(result.data);
        }
      },
      error => this.setError(error)
    );
  }

  protected showMessage(ls: LoadingStates, message: string) {
    if (ls.new.status) {
      if (this.showMessageSuccess) {
        this.locationBack();
        this.showMessageSuccess = false;
      }
    } else {
      if (message !== '') {
        this.toast.showDanger(message);
      } else {
        this.toast.showDanger(this.errorMessage);
      }
    }
  }

  ngOnDestroy() {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
    if (this.resultSubscription) {
      this.resultSubscription.unsubscribe();
    }
  }

  formValid(form: FormGroup) {
    return form.valid;
  }

  save() {
    this.successMessage = this.savedSuccessMessage;
    this.errorMessage = this.savedErrorMessage;
    if (this.operation === FormOperations.add || this.operation === undefined) {
      this.service.post();
    } else  {
      this.service.put();
    }
  }

  delete() {
    this.successMessage = this.deletedSuccessMessage;
    this.errorMessage = this.deletedErrorMessage;
    if (this.operation === FormOperations.edit) {
      this.service.delete(this.queryParams);
    }
  }

  edit(model: T) {
    const extras = {queryParams: {}};
    extras.queryParams[this.idColumn] = model[this.idColumn];
    this.router.navigate([this.formRoute, 'edit'], extras);
  }

  @HostListener('window:keydown.shift.backspace')
  back() {
    this.location.back();
  }

  protected setRouteParams(params: Params) {
    this.routeParams = params;
    this.operation = params.op;
  }

  protected locationBack() {
    this.path = '';
    this.route.url.subscribe(r => {
      this.path = r[0].path
    });
    if (this.path + '' !== 'record') {
      this.toast.showSuccess(this.successMessage);
      this.location.back();
    }

  }

  protected setQueryParams(params: Params) {
    this.queryParams = params;
    const model: T = {} as T;
    Object.keys(params).forEach(key => model[key] = params[key]);
    this.setInitialDataValue(model);
    this.service.setDataValue(model);
    if (this.operation === FormOperations.edit && this.queryParams && this.queryParams[this.idColumn]) {
      setTimeout(() => this.service.get(this.queryParams));
    }
  }

  protected setInitialDataValue(model: T) {
  }

  protected setData(model: T) {
    this.model = model || {} as T;
  }

  protected setError(error) {
    this.logger.error(error);
  }
}
