import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { AuthApiHttpClient } from 'src/app/common/auth/auth-api-http-client';
import { DataItemService } from 'src/app/common/data/data-item.service';

@Injectable({providedIn: 'root'})
export class InstantService extends DataItemService<string> {
  public projectList: any;
  constructor(httpClient: AuthApiHttpClient, route: ActivatedRoute) {
    super({
      httpClient,
      url: ''
    });
    this.projectList = this.httpClient.get<Array<string>>('health/platformPerformance/GetProjects');
  }
  getModuleList(projectName){
    return this.httpClient.get<Array<string>>('health/platformPerformance/GetModules?projectName='+projectName);
  }
}