import { DataItemService } from '../common/data/data-item.service';
import * as i0 from "@angular/core";
import * as i1 from "../common/auth/auth-api-http-client";
export class ReportListFormEditService extends DataItemService {
    constructor(httpClient) {
        super({
            httpClient,
            url: 'health/report',
        });
    }
    getItemId(id) {
        return this.httpClient.get(`health/report/getById/${id}`);
    }
}
ReportListFormEditService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReportListFormEditService_Factory() { return new ReportListFormEditService(i0.ɵɵinject(i1.AuthApiHttpClient)); }, token: ReportListFormEditService, providedIn: "root" });
