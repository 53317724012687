import { Component, Input, EventEmitter, Output } from "@angular/core";
import { AuthApiHttpClient } from '../../common/auth/auth-api-http-client';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../services/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ecg-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss']
})
export class QrCodeComponent {
  @Input()
  imageUrl: string;
  showEmail: boolean = false;
  emailAddress = "";
  @Input()
  name: string;
  @Input()
  hour: string;
  mailLogoUrl: string;
  diaryMobileImageUrl: string;
  @Input()
  qrCodeName: string;

  constructor(public httpClient: AuthApiHttpClient,
    public activeModal: NgbActiveModal,
    private toast: ToastService,
    protected translateService: TranslateService) {
  }

  get image() {
    if (this.imageUrl) {
      if (/^https?:\/\//.test(this.imageUrl)) {
        return this.imageUrl;
      } else {
        return `data:image/gif;base64,${this.imageUrl}`;
      }
    }
  }

  print(elem) {
    this.showEmail = false;
    document.getElementById("printElement").style.display = "block";
    document.getElementById("mailDiv").style.display = "none";
    document.getElementById("mailButtonBack").style.display = "none";
    document.getElementById("mailButtonConfirm").style.display = "none";
    var mywindow = window.open('', 'PRINT', 'height=400,width=1000');

    mywindow.document.write('<html><head><title>' + document.title + '</title>' + '<style >body{ text-align:center !important; padding:75px;} @media screen, print { text-align:center !important; padding:75px;} </style> <style media="print"> @page { size: auto; margin: 0; } </style>');
    mywindow.document.write('</head><body>');
    mywindow.document.write(document.getElementById(elem).innerHTML);
    mywindow.document.write('</body></html>');

    mywindow.document.close();
    mywindow.focus();

    setTimeout(function () {
      mywindow.print();
    }, 500);
    setTimeout(function () {

      mywindow.close();
    }, 750);

    return true;
  }

  emailFieldOpen() {
    this.showEmail = true;
    document.getElementById("printElement").style.display = "none";
    document.getElementById("mailDiv").style.display = "block";
    document.getElementById("mailButtonBack").style.display = "block";
    document.getElementById("mailButtonConfirm").style.display = "block";
  }

  sendEmail() {
    this.httpClient.post({ imageUrl: this.image, email: this.emailAddress, patientName: this.name, hour: this.hour, qrCodeName: this.qrCodeName }, 'auth/useraccounts/send-email').subscribe(res => {
      if (res) {
        this.toast.showSuccess(this.translateService.instant('qrDiary.mailSended'));
        this.closeModal();
      }

    });
  }
  closeModal() {
    this.activeModal.close();
  }

  backQr() {
    this.showEmail = false;
    document.getElementById("printElement").style.display = "block";
    document.getElementById("mailDiv").style.display = "none";
    document.getElementById("mailButtonBack").style.display = "none";
    document.getElementById("mailButtonConfirm").style.display = "none";
  }

  get getImageUrls() {
    this.mailLogoUrl = location.protocol + "//" + location.host + "/assets/images/mail-logo.png";
    return this.mailLogoUrl;
  }
  get diaryMobileImage() {
    this.diaryMobileImageUrl = location.protocol + "//" + location.host + "/assets/images/diary-mobile-app.png";
    return this.diaryMobileImageUrl;
  }
}
