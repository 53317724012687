import { ApplicationRef, enableProdMode } from "@angular/core";

import { AppModule } from "./app/app.module";
import {enableDebugTools} from "@angular/platform-browser";
import { environment } from "./environments/environment";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

export function getBaseUrl() {
  return document.getElementsByTagName("base")[0].href;
}

const providers = [{ provide: "BASE_URL", useFactory: getBaseUrl, deps: [] }];

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .then(moduleRef => {
    if (!environment.debug) return;

    const applicationRef = moduleRef.injector.get(ApplicationRef);
    const componentRef = applicationRef.components[0];

    enableDebugTools(componentRef);
  })
  .catch((err) => console.error(err));
