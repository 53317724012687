import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "./auth-logout.service";
import * as i3 from "@ngx-pwa/local-storage";
export class ActivityCheckService {
    constructor(auth, authLogoutService, storageMap) {
        this.auth = auth;
        this.authLogoutService = authLogoutService;
        this.storageMap = storageMap;
        try {
            this.broadcastChannel = new BroadcastChannel('start-timer');
            this.broadcastChannelModal = new BroadcastChannel('modal-close');
        }
        catch (error) {
        }
    }
    updateLastActivity() {
        if (this.lastActivityControl()) {
            localStorage.setItem('lastActivityDate', new Date().toString());
            try {
                this.broadcastChannel.postMessage("timer start update last activity");
            }
            catch (error) {
            }
        }
    }
    lastActivityControl() {
        const lastActivityItem = localStorage.getItem('lastActivityDate');
        const lastActivityDate = new Date(lastActivityItem);
        let now = new Date();
        const lastSevenMinutes = new Date(now.setTime(now.getTime() + (-60 * 60 * 1000)));
        console.log("last", lastActivityDate);
        console.log("last seven", lastSevenMinutes);
        if (lastActivityDate < lastSevenMinutes) {
            console.log("ACTIVITY DATE RANGE OUT");
            try {
                this.broadcastChannelModal.postMessage("logout");
            }
            catch (error) {
            }
            if (!this.auth.needLogin) {
                console.log("LOGOUT (Last avtivity)");
                this.authLogoutService.logout();
            }
            return false;
        }
        return true;
    }
}
ActivityCheckService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ActivityCheckService_Factory() { return new ActivityCheckService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.AuthLogoutService), i0.ɵɵinject(i3.StorageMap)); }, token: ActivityCheckService, providedIn: "root" });
