import {Component, OnInit} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import { Router } from '@angular/router';
import { AuthLogoutService } from '../auth/auth-logout.service';

@Component({
  selector: 'ecg-public-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss']
})
export class PublicLayoutComponent implements OnInit {
  get user() {
    return this.authService.user;
  }

  showMenu = false;

  constructor(private authService: AuthService, private router: Router, private authLogoutService: AuthLogoutService) {
  }

  ngOnInit() {
  }

  controlUrlLogo() {
    const url = this.router.url;
    return !url.includes("login") && !url.includes("branch") && !url.includes("createpassword")
  }

  logout() {
    this.authLogoutService.logout();
  }
}
