import { DataListService } from 'src/app/common/data/data-list.service';
import * as i0 from "@angular/core";
import * as i1 from "../../common/auth/auth-api-http-client";
export class LogHistoryListService extends DataListService {
    constructor(httpClient) {
        super({
            httpClient,
            url: 'health/history',
            initial: []
        });
    }
    getHistoryById(id) {
        return this.httpClient.get('health/history/get-history-by-id?id=' + id);
    }
}
LogHistoryListService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LogHistoryListService_Factory() { return new LogHistoryListService(i0.ɵɵinject(i1.AuthApiHttpClient)); }, token: LogHistoryListService, providedIn: "root" });
