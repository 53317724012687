import { log } from 'console';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { AuthService } from './auth.service';
import { Subscription } from 'rxjs';
import { StorageMap } from '@ngx-pwa/local-storage';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
@Injectable({ providedIn: 'root' })
export class AuthLogoutService {

  private logoutSubscription: Subscription;
  private storageMapSubscription: Subscription;
 
  constructor(private authService: AuthService, protected storageMap: StorageMap, @Inject(PLATFORM_ID) private platformId: any, private router: Router, private spinner: NgxSpinnerService,) {

  }

  logout() {
    this.spinner.show();
    if (this.logoutSubscription) {
      this.logoutSubscription.unsubscribe();
    }
    if (this.storageMapSubscription) {
      this.storageMapSubscription.unsubscribe();
    }

    // localStorage.clear();
  
    
    this.logoutSubscription = this.authService.logout().subscribe(
        r => {
          this.spinner.hide();
          console.log("authService");
          
          this.storageMapSubscription = this.storageMap.delete('step').subscribe(() => { });
          localStorage.removeItem("lastActivityDate");
          localStorage.removeItem("ecg-app.mac");
          localStorage.removeItem("ecg-app.organisationSettings");
          localStorage.removeItem("customerCodeId");
          localStorage.removeItem("UserEmail");
          localStorage.removeItem("mac");
          localStorage.removeItem("ecg-app.user-uuid");
          sessionStorage.clear();
          // localStorage.clear();
          window.location.reload();
        }
        ,
        e => {
          this.router.navigateByUrl('/login');
          this.spinner.hide();
        }
    );
  }

  deleteAllCookies() {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      let eqPos = cookie.indexOf('=');
      let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }
}
