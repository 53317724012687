import {Injectable} from '@angular/core';
import {AuthApiHttpClient} from '../common/auth/auth-api-http-client';
import {DataItemService} from '../common/data/data-item.service';
import {ActivatedRoute} from '@angular/router';
import { Note } from '../models/note';
import { Paging } from '../common/data/result';

@Injectable({providedIn: 'root'})
export class NoteItemService extends DataItemService<Note> {
  public hospitalList: any;
  public branchList: any;
  hospitalUserList: any;
  constructor(httpClient: AuthApiHttpClient, route: ActivatedRoute) {
    super({
      httpClient,
      url: 'health/notes/item'
    });
    this.hospitalList = this.httpClient.get<Array<string>>('health/cradles/customerCodes');
  }

  getBranchListService(customerId) {
    this.branchList = this.httpClient.get<Array<string>>('health/cradles/customerBranchs/' + customerId);
  }

  getHospitalUser(id, paging: Paging) {
    this.hospitalUserList = this.httpClient.get<Array<string>>('auth/useraccounts/customerusers?customerId=' + id + '&size=' + paging.size + '&index=' + paging.index + (paging.orders ? '&orders=' + paging.orders : ''));
  }
}
