import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: "chunk"
})
export class ChunkPipe<T> implements PipeTransform {
  transform(value: T[], size): Array<T[]> {
    size = Math.max(parseInt(size), 0)
    const length = value == null ? 0 : value.length
    if (!length || size < 1) {
      return []
    }
    let index = 0, resIndex = 0;
    const result = new Array<T[]>(Math.ceil(length / size))

    while (index < length) {
      result[resIndex++] = value.slice(index, (index += size))
    }
    return result
  }

}
