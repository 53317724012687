import {
  NgbAccordionModule,
  NgbAlertModule,
  NgbButtonsModule,
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbModule,
  NgbPaginationModule,
  NgbPopoverModule,
  NgbTabsetModule,
  NgbTimepickerModule,
  NgbToastModule,
  NgbTooltipModule,
  NgbTypeaheadModule
} from '@ng-bootstrap/ng-bootstrap';

import { AppDatePipe } from './pipes/app-date.pipe';
import { ArrayPipe } from './pipes/array.pipe';
import { AuthenticationComponent } from './components/authentication/authentication.component';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { BranchsArrayPipe } from './pipes/branchsArray';
import {ButtonComponent} from './components/button.component';
import {ChartCardComponent} from './components/chart-card/chart-card.component';
import {ChunkPipe} from './pipes/chunk.pipe';
import { CommonModule } from '@angular/common';
import { ConcatPipe } from './pipes/concat.pipe';
import { ConvertDatePipe } from './pipes/convertDate.pipe';
import { CradleDetailComponent } from './components/cradle-detail.component';
import { DefSortableHeader } from './directives/defSortable.directive';
import { DragScrollModule } from 'ngx-drag-scroll';
import { FilterComponent } from './components/filter.component';
import { FilterPipe } from './pipes/filter.pipe';
import { FormsModule } from '@angular/forms';
import { FuncPipe } from './pipes/func.pipe';
import { KeysPipe } from './pipes/keys.pipe';
import { LogoutModalComponent } from './components/logout-modal/logout-modal.component';
import { MemoizePipe } from './pipes/memoize.pipe';
import { MustMatchDirective } from './directives/must-match.directive';
import { NeedDetailDirective } from './directives/need-detail.directive';
import { NgModule } from '@angular/core';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {NgxSpinnerModule} from 'ngx-spinner';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { PdfObjectComponent } from './components/pdf-object/pdf-object.component';
import {QrCodeComponent} from './components/qrcode.component';
import {RangeDatepickerComponent} from './components/range-datepicker.component';
import { RouterModule } from '@angular/router';
import { SafePipe } from './pipes/safe.pipe';
import {ScrollDirective} from './directives/scroll.directive';
import { SortableHeader } from './directives/sortable.directive';
import { StringPipe } from './pipes/string.pipe';
import { TextMaskModule } from 'angular2-text-mask';
import { ToastsComponent } from './components/toasts.component';
import {TranslateModule} from '@ngx-translate/core';

// import { EnumToArray} from './pipes/enum-to-array.pipe';

const NGB_MODULES = [
  NgbAccordionModule, NgbDatepickerModule, NgbTabsetModule, NgbTooltipModule, NgbPaginationModule,
  NgbModule, NgbDropdownModule, NgbPopoverModule, NgbCollapseModule, NgbAlertModule, NgbButtonsModule,
  NgbToastModule, NgbTypeaheadModule, NgbTimepickerModule, NgxDropzoneModule, DragScrollModule
  // , NgbCarouselModule, NgbPopoverModule, NgbProgressbarModule, NgbRatingModule
];

@NgModule({
  imports: [CommonModule, RouterModule, FormsModule, TextMaskModule, TranslateModule, PdfJsViewerModule, NgxSpinnerModule, ...NGB_MODULES],
  declarations: [
    FilterComponent, SortableHeader, ToastsComponent, ChartCardComponent, AuthenticationComponent,
    ConcatPipe, FuncPipe, FilterPipe, AppDatePipe, StringPipe, ArrayPipe, BranchsArrayPipe,
    AutoFocusDirective, MustMatchDirective, NeedDetailDirective, KeysPipe, ChunkPipe,
    QrCodeComponent, RangeDatepickerComponent, ButtonComponent, CradleDetailComponent, LogoutModalComponent, PdfObjectComponent,
    ConvertDatePipe,DefSortableHeader,SafePipe,MemoizePipe, ScrollDirective
  ],
  exports: [
    CommonModule, NgxSpinnerModule, RouterModule, FormsModule, TextMaskModule, PdfJsViewerModule, ...NGB_MODULES,
    FilterComponent, SortableHeader, ToastsComponent, ChartCardComponent, AuthenticationComponent,
    ConcatPipe, FuncPipe, FilterPipe, AppDatePipe, StringPipe, ArrayPipe, BranchsArrayPipe,
    AutoFocusDirective, MustMatchDirective, NeedDetailDirective, KeysPipe, ChunkPipe, ButtonComponent, CradleDetailComponent, PdfObjectComponent,
    ConvertDatePipe,DefSortableHeader,SafePipe, RangeDatepickerComponent,MemoizePipe, ScrollDirective
  ],
  entryComponents: [QrCodeComponent, LogoutModalComponent]
})
export class SharedModule {
}
