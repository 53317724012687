import * as tslib_1 from "tslib";
import { LogoutModalComponent } from '../components/logout-modal/logout-modal.component';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-pwa/local-storage";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "../../common/auth/auth-logout.service";
export class LogoutService {
    constructor(storageMap, modal, authLogoutService) {
        this.storageMap = storageMap;
        this.modal = modal;
        this.authLogoutService = authLogoutService;
    }
    logoutUser(inheritingUserName, operation) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modalRef = this.modal.open(LogoutModalComponent, {
                size: 'lg',
                backdrop: 'static',
                keyboard: false,
                centered: true
            });
            modalRef.componentInstance.inheritingUserName = inheritingUserName;
            modalRef.componentInstance.operation = operation;
            yield this.delay(1000);
            this.authLogoutService.logout();
        });
    }
    delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}
LogoutService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LogoutService_Factory() { return new LogoutService(i0.ɵɵinject(i1.StorageMap), i0.ɵɵinject(i2.NgbModal), i0.ɵɵinject(i3.AuthLogoutService)); }, token: LogoutService, providedIn: "root" });
