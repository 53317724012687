<div class="input-group">
  <input name="datepicker" readonly="readonly"
         class="form-control"
         ngbDatepicker
         #datepicker="ngbDatepicker"
         [autoClose]="'outside'"
         (dateSelect)="onDateSelection($event)"
         [displayMonths]="2"
         [dayTemplate]="t"
         outsideDays="hidden"
         [value]="getDate(fromDate, toDate)"
         [placeholder]="placeholder"
         (click)="datepicker.toggle()"
         [maxDate]="{year: maxDate.getFullYear(), month: (maxDate.getMonth() + 1), day:maxDate.getDate() }"
         [minDate]="{year: minDate.getFullYear(), month: (minDate.getMonth() + 1), day:minDate.getDate() }">

  <div class="input-group-append">
    <button class="btn btn-outline-secondary calendar" (click)="datepicker.toggle()" type="button"></button>
  </div>
</div>

<ng-template #t let-date let-focused="focused">
  <span class="custom-day"
        [class.focused]="focused"
        [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null">
    {{ date.day }}
  </span>
</ng-template>
