import { Injectable } from '@angular/core';
import {
  CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild, CanLoad, Route, NavigationCancel
} from '@angular/router';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { AuthApiHttpClient } from './auth-api-http-client';
import { StorageMap } from '@ngx-pwa/local-storage';
import { ActivityCheckService } from './activity-check.service';
import { AuthLogoutService } from './auth-logout.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(protected auth: AuthService, protected router: Router, public httpClient: AuthApiHttpClient, protected storageMap: StorageMap, private activityCheckService: ActivityCheckService, private authLogoutService: AuthLogoutService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const needLogin = this.auth.needLogin;
    if (needLogin) {
      const indexOf = state.url.indexOf('?');
      const pathFromRoot = indexOf > -1 ? state.url.substring(0, indexOf) : state.url;
      let queryParams = { url: pathFromRoot };
      if (Object.keys(route.queryParams).length > 0) {
        queryParams = Object.assign(queryParams, route.queryParams);
      }
      this.router.navigate(['/login'], { queryParams });
    }

    this.activityCheckService.updateLastActivity();

    this.askedAuthenticatorControl();

    this.branchChooseControl();

    const roles = (route.data && route.data.roles ? route.data.roles : []) as string[];
    return this.auth.hasRole(roles);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate(childRoute, state);
  }

  canLoad(route: Route): boolean | Observable<boolean> | Promise<boolean> {
    const needLogin = this.auth.needLogin;
    if (needLogin) {
      this.router.events.pipe(
        first(re => re instanceof NavigationCancel)
      ).subscribe((event: NavigationCancel) => {
        const indexOf = event.url.indexOf('?');
        const pathFromRoot = indexOf > -1 ? event.url.substring(0, indexOf) : event.url;
        const pathToEnd = indexOf > -1 ? `&${event.url.substring(indexOf + 1)}` : '';
        const redirectSigninUrl = `${'/login'}?url=${pathFromRoot}${pathToEnd}`;
        this.router.navigateByUrl(redirectSigninUrl);
      });
    }
    const roles = (route.data && route.data.roles ? route.data.roles : []) as string[];
    return this.auth.hasRole(roles);
  }

  branchChooseControl() {
    if (!this.auth.hasRole(['System Admin', 'Support', 'Support DK', 'Diary User', 'Staff Fysiologic','Super User', 'Finance', 'Financial Admin'])) {
      this.storageMap.get('step', { type: 'string' }).subscribe((result) => {
        let stepStorage = result;
        if ((!stepStorage || stepStorage == null || stepStorage != 'recordsOrCradle')) {
          this.router.navigate(['/branch'], { queryParams: {} });
        }
      });
    }
  }

  askedAuthenticatorControl() {
    if (this.auth.user.authenticatorAsked === "false") {
      this.authLogoutService.logout();
    }
  }
}
