import {Injectable} from '@angular/core';
import {AuthApiHttpClient} from '../common/auth/auth-api-http-client';
import {DataListService} from '../common/data/data-list.service';
import {Patient} from '../models';
import {AuthService} from '../common/auth/auth.service';

@Injectable({providedIn: 'root'})
export class PatientDiaryService extends DataListService<Patient> {
  public authUser: any;

  constructor(httpClient: AuthApiHttpClient, auth: AuthService) {
    super({
      httpClient,
      url: 'health/patientDiary',
      initial: []
    });
    this.authUser = auth.user;
  }
}