import { DataListService } from '../common/data/data-list.service';
import * as i0 from "@angular/core";
import * as i1 from "../common/auth/auth-api-http-client";
export class ReportListService extends DataListService {
    constructor(httpClient) {
        super({
            httpClient,
            url: 'health/report',
            initial: []
        });
    }
}
ReportListService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReportListService_Factory() { return new ReportListService(i0.ɵɵinject(i1.AuthApiHttpClient)); }, token: ReportListService, providedIn: "root" });
