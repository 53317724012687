import { Injectable } from '@angular/core';
import { DataListService } from '../common/data/data-list.service';
import { AuthApiHttpClient } from '../common/auth/auth-api-http-client';
import { AuthLogs } from '../models/authLogs';

@Injectable({ providedIn: 'root' })
export class AuthLogsService extends DataListService<AuthLogs> {

  constructor(httpClient: AuthApiHttpClient) {
    super({
      httpClient,
      url: 'log/log/auth-logs',
      initial: []
    });
  }
}
