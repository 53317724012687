<div class="row m-0 authentication-container" *ngIf="model">
  <div class="col-lg" style="flex-direction: column; display: flex">
    <div style="float: left;margin-left: 15px;margin-top: 15px">
      <h4 i18n>
        {{'profileForm.twoFactorAuth' | translate}} <span class="disabledBy-text">{{(!isAuthenticated() && model.authenticatorLogs) ? ('profileForm.disabledBy' | translate:{name: model.authenticatorLogs.name, date: (model.authenticatorLogs.updatedDateTime | date:'d-M-yyyy HH:mm')}): ''}}</span>

        <div class="row ml-1 mt-4">
          <div class="custom-control custom-switch custom-control-danger switch-container" *ngIf="isAuthenticated() && !shouldDisplaySkipAuthenticationButton()">
            <input (change)="open(confirmDeleteModal, 'md')" [(ngModel)]="deactivited" type="checkbox"
                   class="custom-control-input" id="example-sw-custom-danger2" name="example-sw-danger2">
            <label class="custom-control-label"
                   for="example-sw-custom-danger2">{{'profileForm.deactivate' | translate}}</label>
          </div>
          <div class="custom-control custom-switch custom-control-inline switch-container"
               *ngIf="!model.isActiveEmailAuth && !model.isActiveGoogleAuth">
            <input (change)="checkAuth()" [disabled]="emailCode" [(ngModel)]="googleQr" type="checkbox"
                   class="custom-control-input" id="example-sw-custom-inline1" name="example-sw-custom-inline1" value="1">
            <label class="custom-control-label"
                   for="example-sw-custom-inline1">{{'profileForm.googleAuth' | translate}}</label>
          </div>
          <div class="custom-control custom-switch custom-control-inline switch-container"
               *ngIf="!model.isActiveEmailAuth && !model.isActiveGoogleAuth">
            <input (change)="checkEmail()" [disabled]="googleQr" [(ngModel)]="emailCode" type="checkbox"
                   class="custom-control-input" id="example-sw-custom-inline2" name="example-sw-custom-inline2" value="1">
            <label class="custom-control-label"
                   for="example-sw-custom-inline2">{{'profileForm.emailAuth' | translate}}</label>
          </div>
        </div>
      </h4>
      <button type="button" *ngIf="(model.isActiveEmailAuth || model.isActiveGoogleAuth) && !deactivateEmail"
              class="btn btn-info mt-2" (click)="open(trustDevice, 'md')" i18n>
        Trust browser
      </button>
    </div>

    <div [class]="page=='login' ? 'row' : 'row left-margin'">
      <div *ngIf="qrResponse && googleQr" class="col-auto text-center">
        <img style="width:300px; height: 300px;" [src]="qrResponse.qrCodeImageUrl" alt="QR">
        <p class="mb-0 word-break">{{qrResponse.manuelEntrySetupCode}}</p>
        <p *ngIf="error" class="text-danger">{{errorText}}</p>
        <input type="text" class="form-control verify-input mb-3 mt-3" id="name" name="name"
               (keydown.enter)="verifyQr('validate-google-authenticator')" [(ngModel)]="qrCode">
        <button type="button" class="btn btn-info mt-2 mb-3 qr-verify"
                (click)="verifyQr('validate-google-authenticator')" i18n>
          {{'verify' | translate}}
        </button>
      </div>
    </div>
    <div class="row ml-2">
      <div *ngIf="emailCode && emailReceived" class="col-auto">
        <label for="emailCode">{{'profileForm.enterYourCode' | translate}}:</label>
        <p *ngIf="error" class="text-danger">{{errorText}}</p>
        <input type="text" style="width: 110px;" class="form-control mb-3 mt-3" id="emailCode" name="emailCode"
               (keydown.enter)="verifyQr('validate-email-authenticator')" [(ngModel)]="qrCode">
        <button type="button" class="btn btn-info mt-2 mail-verify" (click)="verifyQr('validate-email-authenticator')"
                i18n>
          {{'verify' | translate}}
        </button>
      </div>
    </div>
    <div class="row ml-1">
      <div *ngIf="deactivateEmail" class="col-auto">
        <label for="emailCode">{{'profileForm.validateYourCode' | translate}}:</label>
        <p *ngIf="error" class="text-danger">{{errorText}}</p>
        <input type="text" style="width: 110px;" class="form-control mb-3 mt-3" id="emailCode" name="emailCode"
               (keydown.enter)="deactivateAuth()" [(ngModel)]="deactivateCode">
        <button type="button" class="btn btn-danger mt-2 mail-verify" (click)="deactivateAuth()"
                i18n>
          {{'profileForm.deactivate' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #confirmDeleteModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" i18n id="modal-title">{{'profileForm.deactivate' | translate}}</h4>
    <button (click)="modal.dismiss('Cross click');deactivited = true;" aria-describedby="modal-title"
            aria-label="Close button" class="close" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="two-factor-auth-message">{{'2fa.deactivateMessage' | translate}}</p>
  </div>
  <div class="modal-footer btn-group">
    <button (click)="deactivateQr();modal.dismiss('cancel click');" class="btn btn-danger" i18n type="button">{{'2fa.confirm' | translate}}</button>
    <button (click)="modal.dismiss('cancel click');deactivited = true;" class="btn btn-outline-secondary" i18n ngbAutofocus
            type="button">
      {{'2fa.cancel' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #trustDevice let-modal>
  <div class="modal-body">
    <p class="mb-0 mt-3">
      {{'profileForm.trustDevice' | translate}}
      <select [(ngModel)]="trustDays" #status class="custom-select day-select" id="trust" name="trust">
        <option i18n selected value="1">1</option>
        <option i18n value="7">7</option>
        <option i18n value="14">14</option>
        <option i18n value="0">Never</option>
      </select>
      {{trustDays != 0 ? 'day' : ''}}
    </p>
  </div>
  <div class="modal-footer">
    <button (click)="modal.dismiss('cancel click')" class="btn btn-outline-secondary" i18n ngbAutofocus type="button">
      {{'cancel' | translate }}
    </button>
    <button (click)="saveTrustDevice(trustDays,false);modal.dismiss('cancel click');" class="btn btn-info" i18n
            type="button">
      {{'profileForm.ok' | translate}}
    </button>
  </div>
</ng-template>
