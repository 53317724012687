import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
export class NavMenuComponent {
    constructor(auth, route, router, localStorageService, translate, storageMap, httpClient, authLogoutService) {
        this.auth = auth;
        this.route = route;
        this.router = router;
        this.localStorageService = localStorageService;
        this.translate = translate;
        this.storageMap = storageMap;
        this.httpClient = httpClient;
        this.authLogoutService = authLogoutService;
        this.isCollapsed = true;
        this.url = '';
        this.dropDownIpToggle = false;
        this.localstorage = localStorageService;
        if (localStorage.getItem('language') === null) {
            localStorage.setItem('language', 'nl');
            this.translate.use('nl');
        }
        else {
            this.translate.use(localStorage.getItem('language'));
        }
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.organisationSettings = yield this.getOrganisationSettings();
        });
    }
    dropDownIp(bool) {
        this.dropDownIpToggle = bool;
    }
    controlUrlLogo() {
        const url = this.router.url;
        return !url.includes("login") && !url.includes("branch") && !url.includes("createpassword");
    }
    controlUrl() {
        var response = !this.router.url.includes('branch');
        var result = !this.router.url.includes('login');
        return response && result;
    }
    ngOnDestroy() {
        if (this.logoutSubscription) {
            this.logoutSubscription.unsubscribe();
        }
    }
    toggleCollapse(value) {
        const newValue = value === null || value === undefined ? !this.isCollapsed : value;
        this.isCollapsed = newValue;
    }
    getOrganisationSettings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.getOrganisationSettingsOfUser();
            const settings = this.localStorageService.get('organisationSettings');
            if (settings) {
                return settings;
            }
        });
    }
    getOrganisationSettingsOfUser() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const response = yield this.httpClient.get('health/customers/GetOrganisationSettingsOfUser').toPromise();
            if (response) {
                return this.localStorageService.set('organisationSettings', response);
            }
        });
    }
    preventClick(event) {
        event.stopPropagation();
    }
    logout() {
        this.authLogoutService.logout();
    }
    needlogin() {
        this.url = window.location.pathname;
        const needLogin = this.auth.needLogin;
        if (this.url !== '/login' && this.url !== '' && this.url !== '/createpassword' && this.url !== '/forgot' && this.url !== '/changepassword') {
            if (needLogin) {
                this.deleteAllCookies();
                // this.router.navigate(['login']);
            }
            return needLogin;
        }
        else {
            return needLogin;
        }
    }
    deleteAllCookies() {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];
            let eqPos = cookie.indexOf('=');
            let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
        }
    }
}
