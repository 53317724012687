<!DOCTYPE html>
<html lang="tr">
<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="ie=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1">
  <meta name="description" content="">
  <meta name="keywords" content="">
  <title>ECG</title>
</head>
<body class="login">
<div class="login-wrapper">
  <div class="container" style="margin-bottom: 20px">
    <img class="login-logo" src="../../../assets/images/mail-logo.png">
  </div>
  <div class="login-card card">
    <div class="card-body">
      <h4
        class="card-title text-center">{{permission ? translate.instant('accessPermission.granted') : translate.instant('accessPermission.expireHead')}}</h4>
      {{permission ? translate.instant('accessPermission.grantedTitle') + ipAddressKey.ipAddress : translate.instant('accessPermission.expireTitle')}}
      <hr>
    </div>
  </div>
</div>
</body>
</html>
