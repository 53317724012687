import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthApiHttpClient } from 'src/app/common/auth/auth-api-http-client';
import { DataListService } from 'src/app/common/data/data-list.service';
import { Invoice } from 'src/app/models/invoice';
import { UploadInvoice } from 'src/app/models/upload-invoice';

@Injectable({
  providedIn: 'root'
})
export class InvoiceUploadService extends DataListService<Invoice> {
  constructor(httpClient: AuthApiHttpClient) {
    super({
      httpClient,
      url: 'invoice/invoiceupload',
      initial: []
    });
  }

}
