import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/common/auth/auth.service';
import { BaseListComponent } from 'src/app/common/components/base-list.component';
import { Logger } from 'src/app/common/logging/logger.service';
import { Invoice } from 'src/app/models/invoice';
import { InvoiceService } from '../services/invoice.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { DeleteInvoiceModalComponent } from '../components/delete-invoice-modal/delete-invoice-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxCsvParser } from 'ngx-csv-parser';

@Component({
  selector: 'ecg-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent extends BaseListComponent<Invoice> {

  previewFile;
  pdfBlob: Blob;
  csvTable: any[][];

  constructor(service: InvoiceService,
    spinner: NgxSpinnerService,
    router: Router,
    route: ActivatedRoute,
    public logger: Logger,
    public auth: AuthService,
    private invoiceService: InvoiceService,
    private translateService: TranslateService,
    public modalService: NgbModal,
    private ngxCsvParser: NgxCsvParser,
  ) {
    super({
      service, spinner, router, route,
      idColumn: 'id',
      formRoute: '/invoices'
    });
  }

  checkBeforeDelete(id: number) {
    const modalRef = this.modalService.open(DeleteInvoiceModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false
    });

    modalRef.componentInstance.deleteEvent.subscribe(() => {
      this.deleteInvoice(id);
    });
  }

  async deleteInvoice(id: number) {
    this.spinner.show();
    await this.invoiceService.remove(id, () => {
      this.spinner.hide();
      this.showDeleteModal();
    });
  }

  async open(content, invoice: any) {
    this.spinner.show();
    const data = await this.invoiceService.getInvoiceCsvFile(invoice.invoiceFiles[0].invoiceFileId, () => {
      this.spinner.hide();
     }, (error: string) => console.log(error));
    this.csvTable = this.ngxCsvParser.csvStringToArray(data, ';');
    this.csvTable[0] = ['Surname', 'Date of Birth', 'PID', 'Type of recording', 'Report Send Date'];
    this.modalService.open(content, { size: 'xl', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.csvTable = null;
    }, (reason) => {
      this.csvTable = null;
    });
  }

  get csvTableBody() {
    return [...this.csvTable].slice(1, this.csvTable.length - 1);
  }

  async csvFileView(event: MouseEvent, invoice: any) {
    const fileName = invoice.invoiceFiles[0].invoiceFileName;
    this.spinner.show();
    event.stopPropagation();
    const data = await this.invoiceService.downloadCsvFile(fileName, () => {
      this.spinner.hide();
     });
    this.download(fileName, data);
  }

  download(fileName: any, data: any) {
    const blob = new Blob([data], { type: "octet/stream" });
    const url = window.URL.createObjectURL(blob);
    var hiddenUrl = document.createElement("a");
    document.body.appendChild(hiddenUrl);
    hiddenUrl.style.display = "none";
    hiddenUrl.href = url;
    hiddenUrl.download = fileName;
    hiddenUrl.click();
    document.body.removeChild(hiddenUrl);
    window.URL.revokeObjectURL(url);
    this.spinner.hide();
  }

  showDeleteModal() {
    return Swal.fire({
      position: 'center',
      icon: 'success',
      title: this.translateService.instant('invoiceForm.deleted'),
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: true,
      timer: 1500,
      onClose: () => {
        this.refresh();
      }
    });
  }
}
