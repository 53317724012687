import { DataItemService } from '../common/data/data-item.service';
import * as i0 from "@angular/core";
import * as i1 from "../common/auth/auth-api-http-client";
export class PcsItemService extends DataItemService {
    constructor(httpClient) {
        super({
            httpClient,
            url: 'auth/pcs/item'
        });
        this.roles = this.httpClient.get('auth/useraccounts/roles');
        this.hospitalList = this.httpClient.get('health/cradles/customerCodes');
    }
}
PcsItemService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PcsItemService_Factory() { return new PcsItemService(i0.ɵɵinject(i1.AuthApiHttpClient)); }, token: PcsItemService, providedIn: "root" });
