import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, OnInit, Renderer, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../common/auth/auth.service';
import { BaseListComponent } from '../common/components/base-list.component';
import { DateRangeService } from './platform-performance/date-range/date-range.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { Report } from '../models/report';
import { ReportListService } from './report-list.service';
import Swal from "sweetalert2";
import { ToastService } from '../shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ColumnType, FilterType, OperatorType } from '../common/data/result';


interface MetaData {
  event: string,
  key: 'Project' | 'Module'
}
@Component({
  selector: 'ecg-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReportListComponent extends BaseListComponent<Report> implements OnInit {

  _dateRangeService: DateRangeService;
  _paramGid: string;
  _paramProject: string;
  _paramMessage: string;
  globalInstance: any
  constructor(
    public service: ReportListService,
    spinner: NgxSpinnerService,
    router: Router,
    route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public auth: AuthService,
    protected toast: ToastService,
    dateRangeService: DateRangeService,
    protected translateService: TranslateService,
    private renderer: Renderer2
  ) {
    super({
      service,
      spinner,
      router,
      route,
      idColumn: 'id',
      formRoute: '/report'
    }, dateRangeService);

    this.filterReset();
    this._dateRangeService = dateRangeService;
    this._paramGid = this.route.snapshot.queryParamMap.get('GId');
    this._paramProject = this.route.snapshot.queryParamMap.get('Project');
    this._paramMessage = this.route.snapshot.queryParamMap.get('Message');
    this._dateRangeService.dataResult.pipe(
      //this.swalClose()
    );
  }

  swalClose()
  {

  }

  showLoading() {
    Swal.fire({
      position: 'center',
      imageUrl: 'assets/gifs/loading.gif',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      timer: 44000,
    });
  }
  _found: boolean = false;
  filterButton : HTMLButtonElement;
  ngAfterViewChecked() {
    if((this._paramGid != null || this._paramMessage != null)  && !this._found)
      this.copy(this._paramGid,this._paramProject,this._paramMessage);

    this.filterButton = document.querySelector(
      '[name="filterReset"]',
    ) as HTMLButtonElement | null;

    if(this.filterButton!= null && (this._paramGid != null || this._paramMessage != null))
    {
      this.filterButton.addEventListener('click',()=>this.clearGIdFilter());
    }
  }

  clearGIdFilter()
  {
    if(this._paramGid != null || this._paramMessage!= null)
    {
      this._paramGid = null;
      this._paramProject = null;
      this._paramMessage = null;
      this.filterReset();
      this.resetQueryFilter();
      this.filterButton.removeEventListener('click',()=>this.clearGIdFilter());
    }
  }

  onMetaSelectChange($event: MetaData) {
    if (!$event || $event.key === 'Module') return;

    this.currentProjectSelection = $event.event;

    this._dateRangeService.getModuleList(this.currentProjectSelection)
      .pipe(switchMap((moduleList) => of(moduleList)))
      .subscribe((moduleList) => {
        this.filterMetaData.subscribe(filterMetaData => {
          const setFilters = (_moduleList: string[]) => {

            const result = filterMetaData
              .filter(x => x.title === 'Report')
              .map(y => ({ ...y, items: y.items.map(z => z.key === 'Module' ? { ...z, values: this.currentProjectSelection === '' ? [''] : _moduleList } : z) }))

            this.filterMetaData = of(result);
            this._filterMetaData = result
          }
          setFilters(moduleList);
          this._dateRangeService.getModuleList(this.currentProjectSelection).subscribe(setFilters);
        })
      });

    this._dateRangeService.loading.subscribe(x => {

    });
  }

  /**
   * @description splits a string at every nth character
   * @param {string} message
   * @param {number} [indexToSplit]
   * @return {*}  {string}
   * @memberof ReportListComponent
   */
  splitToNextLine([message, indexToSplit]: [string, number?]): string {
    const rgx = new RegExp(`.{1,${indexToSplit || 30}}`, 'g');
    return ((message || '').match(rgx) || []).map(c => `${c}\n`).join();
  }

  copyText(message: string) {
    navigator.clipboard.writeText(message);
  }


  copy(gId: string,project: string,message:string) {
    var gIdInput = document.querySelector(
      '[id="GId"]',
    ) as HTMLInputElement | null;

    var messageInput = document.querySelector(
      '[id="Message"]',
    ) as HTMLInputElement | null;

    var filterInput = document.querySelector(
      '[name="filterButton"]',
    ) as HTMLButtonElement | null;

    if ((gIdInput != null || messageInput != null) && filterInput != null) {
      this._found = true;
      if(gIdInput != null)
      {
        gIdInput.value = gId;
        gIdInput.dispatchEvent(new Event('input'));
        gIdInput.dispatchEvent(new Event('blur'));
      }
      if(messageInput != null)
      {
        messageInput.value = message;
        messageInput.dispatchEvent(new Event('input'));
        messageInput.dispatchEvent(new Event('blur'));
      }
      filterInput.click();
    }

  }

  columnMessage(message: string) {
    if(this.projectChoosed() || this.moduleChoosed())
    {
        if (message && message.length > 300)
          return message.substring(0, 300).replace("\n", "").replace("\r", "");
        else
          return message;
    }
    if (message && message.length > 15)
      return message.substring(0, 15).replace("\n", "").replace("\r", "");
    else
      return message;
  }

  projectChoosed()
  {
      var input = document.querySelector(
        '[id="Project"]',
      ) as HTMLSelectElement | null;
      if(input != null)
      {
          return input.selectedIndex > 0;
      }
      return false;
  }

  moduleChoosed()
  {
      var input = document.querySelector(
        '[id="Module"]',
      ) as HTMLSelectElement | null;
      if(input != null)
      {
          return input.selectedIndex > 0;
      }
      return false;
  }
}
