import * as tslib_1 from "tslib";
import { OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { DataApiResult } from '../../common/data/result';
import { getVersion } from '../../common/utilities/getVersion.utility';
export class CreatePasswordComponent {
    constructor(storageMap, authService, location, toast, cookieService, modalService, logger, router, route, http, httpClient, localStorageService) {
        this.storageMap = storageMap;
        this.authService = authService;
        this.location = location;
        this.toast = toast;
        this.cookieService = cookieService;
        this.modalService = modalService;
        this.logger = logger;
        this.router = router;
        this.route = route;
        this.http = http;
        this.httpClient = httpClient;
        this.localStorageService = localStorageService;
        this.model = { username: '', secretQuestion: '', secretAnswer: '', password: '', confirmPassword: '', currentPassword: '', isActiveGoogleAuth: false, isActiveEmailAuth: false };
        this.fakePassword = '';
        this.key = 'value';
        this.emailcode = 'email-code';
        this.username = 'username';
        this.authPart = false;
        this.deactivited = true;
        this.authService.getCreatePassword(route.queryParams[this.key][this.emailcode]).subscribe(r => {
            if (r === null) {
                this.router.navigate(['/login']);
            }
            else {
                this.model.username = r[this.username];
            }
        });
        getVersion(http).subscribe(res => {
            this.version = res;
        });
        this.year = new Date().getFullYear();
    }
    ngOnInit() {
    }
    ngOnDestroy() {
        if (this.loadingSubscription) {
            this.loadingSubscription.unsubscribe();
        }
        if (this.resultSubscription) {
            this.resultSubscription.unsubscribe();
        }
    }
    save() {
        this.loginSubscription = this.authService.setCreatePassword(this.route.queryParams[this.key][this.emailcode], this.model, "?type=create").subscribe(r => {
            const token = r.data.data.tokenResult ? r.data.data.tokenResult : r.data.data;
            console.log(token);
            this.authService.saveToken(token);
            this.toast.showSuccess('Password saved successfully');
            this.authPart = true;
        });
    }
    getUserLogIn() {
        this.cookieService.set('date', Date.now() / 1000 + '');
        if (this.authService.user !== null && this.authService.user.customerCodeId !== null) {
            localStorage.setItem('customerCodeId', this.authService.user.customerCodeId);
        }
        if (this.authService.user !== null && this.authService.user.username) {
            localStorage.setItem('UserEmail', this.authService.user.username);
        }
        if (this.authService.hasRole(['System Admin', 'Support', 'Support DK'])) {
            return this.router.navigate(['/cradles'], { queryParams: {} });
        }
        if (this.authService.hasRole(['Diary User'])) {
            return this.router.navigate(['/diary-upload'], { queryParams: {} });
        }
        if (this.authService.hasRole(['Staff Fysiologic'])) {
            return this.router.navigate(['/records'], { queryParams: {} });
        }
        if (this.authService.hasRole(['Finance', 'Financial Admin'])) {
            return this.router.navigate(['/specifications'], { queryParams: {} });
        }
        this.localStorageService.remove('cradleSerialNumber');
        sessionStorage.clear();
        this.storageMap.delete('step').subscribe(() => {
            let stepStorage = '';
            this.storageMap.get('step', { type: 'string' }).subscribe((result) => {
                stepStorage = result;
                if ((!stepStorage || stepStorage == null || stepStorage == 'branch' || stepStorage == 'cradleChoose') && !this.authService.hasRole(['Doctor Without Cradle', 'Super User', 'System Admin', 'Finance', 'Financial Admin'])) {
                    this.router.navigate(['/branch'], { queryParams: {} });
                }
                else {
                    if (this.authService.hasRole(['Super User'])) {
                        return this.router.navigate(['/cradles'], { queryParams: {} });
                    }
                    else {
                        return this.router.navigate(['/records'], { queryParams: {} });
                    }
                }
            });
        });
    }
    deactiveAuthentication() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const response = yield this.httpClient.post({}, 'auth/authenticator/authenticator-deactivate-first-login').toPromise();
            if (response.data) {
                yield this.authService.forceRefresh().toPromise();
                return this.getUserLogIn();
            }
        });
    }
    shouldDisplaySkipAuthenticationButton() {
        return this.authService.hasRole(['System Admin', 'Super User']);
    }
    open(content, modalSize) {
        this.modalService.open(content, { size: 'xl', centered: true });
    }
    back() {
        this.location.back();
    }
}
