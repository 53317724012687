import {Injectable} from '@angular/core';
import {AuthApiHttpClient} from 'src/app/common/auth/auth-api-http-client';
import {Observable} from 'rxjs';
import { MultiplePatientFileUPload } from '../../models/MultiplePatientFileUpload';

@Injectable({
  providedIn: 'root'
})
export class DagboekService {
  constructor(public httpClient: AuthApiHttpClient,) {
  }

  sendPdf(file, name, id): Observable<any> {
    const model = {Base64: file, Filename: name};
    return this.httpClient.post(model, 'health/patients/uploadPDFByCustomerID?customerCodeId=' + id);

  }

  sendDevicePdf(file, name, id, deviceId): Observable<any> {
    const model = {Base64: file, Filename: name};
    return this.httpClient.post(model, 'health/patients/uploadPDFByPatientID?patientId=' + id + '&deviceId=' + deviceId);
  }
  getUploadedListByPatientId(index, size, id) {
    return this.httpClient.get<any>('health/patients/getPdfLogs?Index=' + index + '&Size=' + size + '&PatientId=' + id);
  }

  sendMultiplePatientPdf(model: Array<MultiplePatientFileUPload>): Observable<any> {
    return this.httpClient.post(model, 'health/patients/uploadPDFByMultiplePatient');
  }
}

