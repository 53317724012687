import * as i0 from "@angular/core";
import * as i1 from "../../common/auth/auth-api-http-client";
export class DagboekService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    sendPdf(file, name, id) {
        const model = { Base64: file, Filename: name };
        return this.httpClient.post(model, 'health/patients/uploadPDFByCustomerID?customerCodeId=' + id);
    }
    sendDevicePdf(file, name, id, deviceId) {
        const model = { Base64: file, Filename: name };
        return this.httpClient.post(model, 'health/patients/uploadPDFByPatientID?patientId=' + id + '&deviceId=' + deviceId);
    }
    getUploadedListByPatientId(index, size, id) {
        return this.httpClient.get('health/patients/getPdfLogs?Index=' + index + '&Size=' + size + '&PatientId=' + id);
    }
    sendMultiplePatientPdf(model) {
        return this.httpClient.post(model, 'health/patients/uploadPDFByMultiplePatient');
    }
}
DagboekService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DagboekService_Factory() { return new DagboekService(i0.ɵɵinject(i1.AuthApiHttpClient)); }, token: DagboekService, providedIn: "root" });
