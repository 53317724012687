import { Component, Input, OnInit,ViewChild } from "@angular/core";
import { CustomerCradle } from 'src/app/models/customer-cradle';
import { CustomerRecorder } from 'src/app/models/customer-recorder';
import { AuthApiHttpClient } from 'src/app/common/auth/auth-api-http-client';
import { DataApiResult, Filters, Order, Paging } from 'src/app/common/data/result';
import { Router } from '@angular/router';
import { Recorder } from '../../models/recorder';
import { CustomerCharger } from "src/app/models/customerCharger";
import { Charger } from "src/app/models/charger";
import { SortEvent } from "../directives/sortable.directive";
import { ToastService } from "../services/toast.service";

@Component({
  selector: 'ecg-cradle-detail',
  styleUrls: ['./cradle-detail.component.scss'],
  templateUrl: './cradle-detail.component.html',
})
export class CradleDetailComponent implements OnInit {
  @Input()


  organisationId: string;
  cradleList: Array<CustomerCradle>;
  recorderList: Array<CustomerRecorder>;
  recorderListFilter: Array<CustomerRecorder>;
  recorderListCount: number;
  sortByAsc: boolean = true;
  recorderPaging: Paging = {
    size: 10,
    index: 1,
    search: null
  };
  recorderLastPaging: any;


  chargerList: Array<CustomerCharger>;
  chargerListCount: number;
  chargerPaging: Paging = {
    size: 10,
    index: 1,
    search: null
  };
  chargerLastPaging: any;
  showFilter: boolean;
  recorderSearchText: string;
  recorderOrder: Order[];
  reset: boolean;
  paging: { size: number; index: number; search: any; };

  constructor(public httpClient: AuthApiHttpClient, private router: Router,public toast: ToastService) {

  }

  ngOnInit() {
    // this.recorderLastPaging = JSON.parse(localStorage.getItem('lastRecorderPaging'));
    // if (this.recorderLastPaging) {
    //   this.recorderPaging = this.recorderLastPaging;
    // }
    this.chargerLastPaging = JSON.parse(localStorage.getItem('lastChargerPaging'));
    if (this.chargerLastPaging) {
      this.chargerPaging = this.chargerLastPaging;
    }

    const recorderModel = {
      organizationId: this.organisationId,
      size: this.recorderPaging.size,
      index: this.recorderPaging.index,
      orders: this.recorderOrder,
      serialNumber: this.recorderSearchText
    };

    this.httpClient.post<DataApiResult<Array<CustomerRecorder>>, {}>(recorderModel, 'health/dashboard/get-customer-recorders').subscribe(res => {
      if (res.data != null) {
        this.recorderList = res.data;
        this.recorderListFilter=this.recorderList;
      }
    });

    this.httpClient.post<DataApiResult<number>, {}>(recorderModel, 'health/dashboard/get-customer-recorders-count').subscribe(res => {
        this.recorderListCount = res.data;;
    });

    this.httpClient.get<DataApiResult<{ cradles: Array<CustomerCradle>, recorders: Array<CustomerRecorder>, chargers: Array<CustomerCharger> }>>("health/dashboard/get-cradles-by-customer-id/" + this.organisationId + "/" + this.recorderPaging.size + "/" + this.recorderPaging.index).subscribe(res => {
      if (res.data.cradles != null) {
        this.cradleList = res.data.cradles;
      }
    });

    this.httpClient.get<DataApiResult<{ chargers: Array<CustomerCharger>, chargersListCount: number }>>("health/dashboard/get-chargers-by-customer-id/" + this.organisationId + "/" + this.chargerPaging.size + "/" + this.chargerPaging.index).subscribe(res => {
      if (res.data.chargers != null) this.chargerList = res.data.chargers;
      if(res.data.chargersListCount) this.chargerListCount = res.data.chargersListCount;
    });

  }

  pageCount = (count: number, size: number) => Math.ceil(count / size);

  refreshRecorders() {
    this.ngOnInit();
  }
  filterReset() {
    console.log('asdas');
    this.showFilter = true;
    this.recorderSearchText = "";
    this.recorderListFilter=this.recorderList;
    this.pagingReset();
    this.reset = false;
    localStorage.removeItem(this.router.url.substring(1) + "Filter");
    this.ngOnInit();
  }


  filterChange(filters: Filters) {
    var a = "";
    this.ngOnInit();
  }

  onSort(sort: SortEvent) {
      this.recorderOrder = sort.column.split(', ').map(c => {
        return <Order>{ column: c, type: sort.direction as any };
      });
      this.ngOnInit();
  }


  pagingReset() {
    this.paging = { size: 10, index: 1, search: null };
  }
  showLoading() {
    throw new Error("Method not implemented.");
  }

  addRecorder() {
    const extras = { queryParams: {} };
    extras.queryParams['organisation'] = this.organisationId;
    this.router.navigate(['recorder/', 'add'], extras);
  }
  editRecorder(model: Recorder) {
    const extras = { queryParams: {} };
    extras.queryParams['id'] = model['id'];
    this.router.navigate(['/recorder', 'edit'], extras);
  }
  recorderPagingSave() {
    localStorage.setItem('lastRecorderPaging', JSON.stringify(this.recorderPaging));
  }

  addCharger() {
    const extras = { queryParams: {} };
    extras.queryParams['organisation'] = this.organisationId;
    this.router.navigate(['charger/', 'add'], extras);
  }
  editCharger(model: Charger) {
    const extras = { queryParams: {} };
    extras.queryParams['id'] = model['id'];
    this.router.navigate(['/charger', 'edit'], extras);
  }
  chargerPagingSave() {
    localStorage.setItem('lastChargerPaging', JSON.stringify(this.chargerPaging));
  }

  RecordDelete(serialNumber,recorderId){
    if(recorderId>0)
    {
      this.httpClient.get<DataApiResult<number>>("health/dashboard/delete-recorder-by-id/" + serialNumber).subscribe(res => {
        if(res.succeded)
          this.ngOnInit();
        else
        this.toast.showDanger(res.message);
      });
    }
    else
    {
      this.httpClient.delete({},"health/patients/delete-patient-recorder?recorderSerialNo="+serialNumber).subscribe(res => {
        this.ngOnInit();
      });
    }

  }
}
