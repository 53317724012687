import { DataItemService } from '../common/data/data-item.service';
import * as i0 from "@angular/core";
import * as i1 from "../common/auth/auth-api-http-client";
import * as i2 from "@angular/router";
export class ChargerItemService extends DataItemService {
    constructor(httpClient, route) {
        super({
            httpClient,
            url: 'health/chargers/item'
        });
        this.hospitalList = this.httpClient.get('health/cradles/customerCodes');
    }
}
ChargerItemService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChargerItemService_Factory() { return new ChargerItemService(i0.ɵɵinject(i1.AuthApiHttpClient), i0.ɵɵinject(i2.ActivatedRoute)); }, token: ChargerItemService, providedIn: "root" });
