import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@angular/router";
import * as i3 from "@ngx-pwa/local-storage";
export class RedirectGuard {
    constructor(authService, router, storageMap) {
        this.authService = authService;
        this.router = router;
        this.storageMap = storageMap;
    }
    canActivate(route, state) {
        if (this.authService.needLogin) {
            if (route.routeConfig.path === 'login') {
                return true;
            }
            return this.router.parseUrl('/login');
        }
        else {
            if (this.authService.hasRole(['System Admin', 'Support', 'Support DK', 'Super User'])) {
                return this.router.parseUrl('/cradles');
            }
            else if (this.authService.hasRole(['Diary User'])) {
                return this.router.parseUrl('/diary-upload');
            }
            else if (this.authService.hasRole(['Staff Fysiologic', 'Fysiologic Assistant'])) {
                return this.router.parseUrl('/records');
            }
            else if (this.authService.hasRole(['Finance', 'Financial Admin'])) {
                return this.router.navigate(['/specifications'], { queryParams: {} });
            }
            else {
                this.storageMap.get('step', { type: 'string' }).subscribe((result) => {
                    if ((!result || result == null || result != 'recordsOrCradle') && !this.authService.hasRole(['Super User', 'System Admin'])) {
                        this.router.navigate(['/branch'], { queryParams: {} });
                    }
                    else {
                        if (this.authService.hasRole(['Super User'])) {
                            this.router.navigate(['/cradles'], { queryParams: {} });
                        }
                        else {
                            this.router.navigate(['/records'], { queryParams: {} });
                        }
                    }
                });
            }
        }
    }
}
RedirectGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RedirectGuard_Factory() { return new RedirectGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.StorageMap)); }, token: RedirectGuard, providedIn: "root" });
