/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./activity.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./activity.component";
var styles_ActivityComponent = [i0.styles];
var RenderType_ActivityComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ActivityComponent, data: {} });
export { RenderType_ActivityComponent as RenderType_ActivityComponent };
export function View_ActivityComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["activity works!"]))], null, null); }
export function View_ActivityComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ecg-activity", [], null, null, null, View_ActivityComponent_0, RenderType_ActivityComponent)), i1.ɵdid(1, 114688, null, 0, i2.ActivityComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ActivityComponentNgFactory = i1.ɵccf("ecg-activity", i2.ActivityComponent, View_ActivityComponent_Host_0, {}, {}, []);
export { ActivityComponentNgFactory as ActivityComponentNgFactory };
