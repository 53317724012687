import { Injectable } from '@angular/core';
import { AuthApiHttpClient } from '../common/auth/auth-api-http-client';

@Injectable({
  providedIn: 'root'
})
export class RedisServerHealthService {

  constructor(public httpClient: AuthApiHttpClient) { }


  getServerHealth(serviceGroupName:string){
   return this.httpClient.get('RedisHealthCheckApi/GetServicesHealth/'+serviceGroupName);
  }
}
