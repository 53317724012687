import { Routes } from '@angular/router';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
const ɵ0 = { roles: ["System Admin", "Super User", "Finance", "Financial Admin"], };
const routes = [
    {
        path: '', component: InvoiceListComponent,
        data: ɵ0,
    },
];
export class InvoicesModule {
}
export { ɵ0 };
