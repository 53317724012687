import { Injectable } from '@angular/core';
import { AuthApiHttpClient } from '../common/auth/auth-api-http-client';
import { DataListService } from '../common/data/data-list.service';
import { Note } from '../models/note';

@Injectable({ providedIn: 'root' })
export class NoteListService extends DataListService<Note> {

  constructor(httpClient: AuthApiHttpClient) {
    super({
      httpClient,
      url: 'health/notes',
      initial: []
    });
  }
}
