import { DataItemService } from 'src/app/common/data/data-item.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/auth/auth-api-http-client";
import * as i2 from "@angular/router";
export class InstantService extends DataItemService {
    constructor(httpClient, route) {
        super({
            httpClient,
            url: ''
        });
        this.projectList = this.httpClient.get('health/platformPerformance/GetProjects');
    }
    getModuleList(projectName) {
        return this.httpClient.get('health/platformPerformance/GetModules?projectName=' + projectName);
    }
}
InstantService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InstantService_Factory() { return new InstantService(i0.ɵɵinject(i1.AuthApiHttpClient), i0.ɵɵinject(i2.ActivatedRoute)); }, token: InstantService, providedIn: "root" });
