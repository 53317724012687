import { Component, Input, EventEmitter, Output, OnInit } from "@angular/core";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ecg-logout-modal',
  templateUrl: './logout-modal.component.html'
})
export class LogoutModalComponent implements OnInit {
  @Input()
  inheritingUserName: string;
  @Input()
  operation: string;
  message = "";

  constructor(
    public activeModal: NgbActiveModal,
    protected translateService: TranslateService) {
  }

  ngOnInit() {
    if (this.operation == "takeOver")
    {
      if (this.inheritingUserName == null || this.inheritingUserName == undefined || this.inheritingUserName == "")
      {
        this.message = this.translateService.instant("logoutDifferentUserMessage");
      }
      else
      {
        this.message = this.translateService.instant("logoutTakeOverMessage");
        this.message = this.message.replace("{inheritingUserName}", this.inheritingUserName);
      }
    }
    else if (this.operation == "deleteUser")
    {
      this.message = this.translateService.instant("logoutDeleteMessage");
    }
    else if (this.operation == "disconnectedByAdmin")
    {
      this.message = this.translateService.instant("logoutDisconnectedByAdminMessage");
      this.message = this.message.replace("{inheritingUserName}", this.inheritingUserName);
    }
    else if (this.operation == "deleteCradle")
    {
      this.message = this.translateService.instant("logoutCradleDeleteMessage");
    }
    else if (this.operation == "disconnected")
    {
      this.message = this.translateService.instant("logoutDisconnectedMessage");
    }
  }

  closeModal() {
    this.activeModal.close();
  }
}
