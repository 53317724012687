import { DataListService } from '../common/data/data-list.service';
import * as i0 from "@angular/core";
import * as i1 from "../common/auth/auth-api-http-client";
export class IpWhiteListsService extends DataListService {
    constructor(httpClient) {
        super({
            httpClient,
            url: 'auth/ipwhitelist',
            initial: []
        });
    }
    getAccessRequest() {
        this.accessRequestSubscribe = this.httpClient.get('auth/ipwhitelist/access-request');
    }
    setAllowAccess(model) {
        this.allowAccessSubscribe = this.httpClient.post(model, 'auth/ipwhitelist/set-allow-access');
    }
    getIPFilterStatus() {
        return this.httpClient.get('auth/ipwhitelist/ip-filter-status');
    }
    switchFilterStat(stat) {
        return this.httpClient.post(stat, 'auth/ipwhitelist/ip-filter-status-change');
    }
    postRangeIps(ranges) {
        return this.httpClient.post(ranges, 'auth/ipwhitelist/range-ip-add');
    }
}
IpWhiteListsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IpWhiteListsService_Factory() { return new IpWhiteListsService(i0.ɵɵinject(i1.AuthApiHttpClient)); }, token: IpWhiteListsService, providedIn: "root" });
