import { Component, HostListener, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { supportedLocales } from '../../../environments/app.config.json';
import { Profile } from '../../models/profile';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { ToastService } from '../../shared/services/toast.service';
import { Logger } from '../../common/logging/logger.service';
import { filter, switchMap } from 'rxjs/operators';
import { DataApiResult, ExceptionDetail, Loading } from '../../common/data/result';
import { FormControl } from '@angular/forms';
import { AuthService } from "../../common/auth/auth.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Version } from '../../models/version';
import { getVersion } from '../../common/utilities/getVersion.utility'

@Component({
  selector: 'ecg-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnDestroy {
  @ViewChild('savedSuccessMessage', { static: false }) savedSuccessMessage: TemplateRef<any>;
  @ViewChild('savedErrorMessage', { static: false }) savedErrorMessage: TemplateRef<any>;
  model: Profile = { username: '', secretQuestion: '', secretAnswer: '', password: '', confirmPassword: '', currentPassword: '' };
  version: Version;
  readonly fakePassword = '';
  year: number;

  private loadingSubscription: Subscription;
  private resultSubscription: Subscription;
  private loginSubscription: Subscription;
  private navigateTo: string;
  private navigateParams: Params;
  private restoreSubscription: Subscription;

  constructor(private authService: AuthService, private location: Location, private toast: ToastService,
    private logger: Logger, private router: Router, route: ActivatedRoute, httpClient: HttpClient) {
    getVersion(httpClient).subscribe(res => {
      this.version = res;
    });

    this.year = new Date().getFullYear();
  }

  ngOnDestroy() {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
    if (this.resultSubscription) {
      this.resultSubscription.unsubscribe();
    }
  }

  save() {
    this.loginSubscription = this.authService.forgotPassword(this.model, 'changepassword').subscribe(
      r => {

        this.toast.showSuccess('Your activation link has been send to your email!');

        setTimeout(() => {
          this.router.navigateByUrl('/login');
        }, 3000);
        
      },
      e => {
        if (e.error !== undefined && e.error.text !== undefined) {
          this.toast.showDanger(e.error.text);
        }
        else if (e.error !== undefined && e.error.data !== undefined && e.error.data.message !== undefined) {
          this.toast.showDanger(e.error.data.message);
        } else if (e.message) {
          this.toast.showDanger(e.error.message);
        }
        this.logger.error(e);
        const err = e.error as DataApiResult<ExceptionDetail>;
      }
    );

  }

  @HostListener('window:keydown.shift.backspace')
  back() {
    this.location.back();
  }

  blurPassword(control: FormControl) {
    if (!control.value) {
      control.setValue(this.fakePassword);
    }
  }

  focusPassword(control: FormControl) {
    if (control.value === this.fakePassword) {
      control.setValue(null);
    }
  }

}
