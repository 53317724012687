import { TemplateRef } from '@angular/core';
import * as i0 from "@angular/core";
export class ToastService {
    constructor() {
        this.toasts = [];
        this.success = { classname: 'bg-success text-light' };
        this.danger = { classname: 'bg-danger text-light' };
    }
    showSuccess(textOrTpl, options = {}) {
        const newOptions = Object.assign({}, this.success, options);
        this.show(textOrTpl, newOptions);
    }
    showDanger(textOrTpl, options = {}) {
        const newOptions = Object.assign({}, this.danger, options);
        this.show(textOrTpl, newOptions);
    }
    show(textOrTpl, options = {}) {
        if (textOrTpl !== undefined) {
            this.toasts.push(Object.assign({ textOrTpl }, options));
        }
    }
    remove(toast) {
        this.toasts = this.toasts.filter(t => t !== toast);
    }
}
ToastService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ToastService_Factory() { return new ToastService(); }, token: ToastService, providedIn: "root" });
