import * as i0 from "@angular/core";
import * as i1 from "../common/auth/auth-api-http-client";
export class ServerHealthService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    getServerHealth() {
        return this.httpClient.get('ServerHealth');
    }
    restartService() {
        return this.httpClient.get('ServerHealth/restart-service');
    }
}
ServerHealthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ServerHealthService_Factory() { return new ServerHealthService(i0.ɵɵinject(i1.AuthApiHttpClient)); }, token: ServerHealthService, providedIn: "root" });
