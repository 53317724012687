import { Component, OnInit, Input, forwardRef, ChangeDetectionStrategy, EventEmitter } from '@angular/core';
import Swal from 'sweetalert2';
import { AuthApiHttpClient } from 'src/app/common/auth/auth-api-http-client';
import { DataApiResult } from 'src/app/common/data/result';
import { ToastService } from '../../services/toast.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/common/auth/auth.service';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Profile } from 'src/app/models/profile';
import { CookieService } from 'ngx-cookie-service';
import { Router, Params } from '@angular/router';
import * as Fingerprint2 from 'fingerprintjs2'
import { TranslateService } from '@ngx-translate/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { LocalStorageService } from 'angular-2-local-storage';
import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js';

const FINGER_PRINT_KEY = "fingerPrintKey";
@Component({
  selector: 'ecg-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.css'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AuthenticationComponent), multi: true }],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AuthenticationComponent implements OnInit {
  onChange = new EventEmitter();

  model: Profile;
  isActivated = false;
  googleQr = false;
  emailCode = false;
  emailReceived = false;
  deactivateEmail = false;
  deactivateCode = "";
  deactivited = true;
  deviceKey = "";
  qrResponse: any;
  qrCode: any;
  error = false;
  errorText = "";
  trustDays = 1;

  private navigateParams: Params;

  @Input()
  page: string;

  constructor(public auth: AuthService, public httpClient: AuthApiHttpClient, private modalService: NgbModal, private toast: ToastService, private translate: TranslateService,
    private cookieService: CookieService, private router: Router, protected storageMap: StorageMap, private localStorageService: LocalStorageService) {
    var fingerPrintKey = localStorage.getItem(FINGER_PRINT_KEY);
    if(fingerPrintKey){
      this.deviceKey = fingerPrintKey;
      console.warn("loaded");
      
    } else {
      getCurrentBrowserFingerPrint().then((fingerprint) => {
        console.warn("created");
        this.deviceKey = fingerprint;
        localStorage.setItem(FINGER_PRINT_KEY, fingerprint);
      });
    }
  }

  ngOnInit() {
  }

  registerOnChange(fn: any): void {
    this.onChange.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(value: any): void {
    this.model = value;
  }

  change($event) {
    this.model = $event;
    this.onChange.emit($event);
  }

  checkEmail() {
    this.emailReceived = false;
    if (this.emailCode) {
      this.showWait();
      this.httpClient.post<DataApiResult<boolean>, {}>({}, 'auth/authenticator/get-email-authenticator').subscribe(res => {
        Swal.close();
        if (res.data) {
          this.emailReceived = true;
          this.toast.showSuccess(this.translate.instant('loginPage.codeSentEmail'));
        }
      });
    }
  }

  checkAuth() {
    this.errorText = "";
    if (this.googleQr) {
      this.qrResponse = null;
      this.getQr();
    }
  }

  getQr() {
    this.showWait();
    this.httpClient.get('auth/authenticator/get-google-authenticator').subscribe(res => {
      this.qrResponse = res;
      Swal.close();
    });
  }

  async verifyQr(key) {
    this.error = false;
    const res = await this.httpClient.post<DataApiResult<any>, {}>({ code: this.qrCode }, 'auth/authenticator/' + key).toPromise();
    if (res.data) {
      this.qrResponse = null; this.emailReceived = false;
      this.deactivited = true;
      this.isActivated = true;
      this.model.isActive = true;
      this.model.isActiveEmailAuth = true;
      await this.auth.forceRefresh().toPromise();
      if (this.page && this.page === 'login') {
        this.toast.showSuccess(this.translate.instant('profileForm.authenticationDeactivate'));
        this.getUserLogIn();
      }
    } else {
      this.error = true;
      this.errorText = res.message;
    }
    Swal.close();
    this.deactivited = true;
  }

  deactivateQr() {
    this.httpClient.post<DataApiResult<boolean>, {}>({}, 'auth/authenticator/authenticator-deactivate?userId=' + this.auth.user.id).subscribe(res => {
      if (res.data) {
        this.toast.showSuccess(this.translate.instant('loginPage.codeSentEmail'));
        this.deactivateEmail = true;
      } else {  
        this.deactivateEmail = false;
        this.model.isActive = true
        this.deactivited = true;
        this.toast.showDanger(res.message)
      }
    });
  }

  deactivateAuth() {
    this.httpClient.post<DataApiResult<any>, {}>({}, 'auth/authenticator/authenticator-deactivate-validate?emailCode=' + this.deactivateCode).subscribe(res => {
      if (res.data) {
        this.authDeactivated();
      } else {
        this.error = true;
        this.errorText = res.message;
      }
      Swal.close();
      this.deactivited = true;
    },
    );
  }

  authDeactivated() {
    this.deactivateEmail = false;
    this.model.isActive = false;
    this.isActivated = false;
    this.model.isActiveGoogleAuth = false;
    this.model.isActiveEmailAuth = false;
    this.googleQr = false;
    this.emailCode = false;
    const currentDate = new Date().toISOString();
    this.model.authenticatorLogs = { name: this.model.name, updatedDateTime: currentDate }
  }

  open(content, modalSize) {
    this.modalService.open(content, { size: 'xl', centered: true });
  }

  showWait() {
    Swal.fire({
      position: 'center',
      imageUrl: 'assets/gifs/loading.gif',
      title: 'Please Wait...',
      showConfirmButton: false,
      timer: 44000,
    });
  }

  isAuthenticated() {
    return this.model.isActiveEmailAuth || this.model.isActiveGoogleAuth;
  }

  saveTrustDevice(day, never) {
    never = (day == 0 ? true : false);
    var model = {
      key: this.deviceKey,
      never: never
    }

    this.httpClient.post(model, 'auth/fingerprint/create-fingerprint?periodValidityDay=' + day).subscribe(res => {
      this.toast.showSuccess(this.translate.instant('profileForm.browserSaveSuccess'));
    });
  }

  shouldDisplaySkipAuthenticationButton() {
    return this.auth.hasRole(['System Admin', 'Super User']);
  }

  getUserLogIn() {
    this.cookieService.set('date', Date.now() / 1000 + '');

    if (this.auth.user !== null && this.auth.user.customerCodeId !== null) {
      localStorage.setItem('customerCodeId', this.auth.user.customerCodeId);
    }
    if (this.auth.user !== null && this.auth.user.username) {
      localStorage.setItem('UserEmail', this.auth.user.username);
    }

    if (this.auth.hasRole(['System Admin', 'Support', 'Support DK'])) {
      return this.router.navigate(['/cradles'], { queryParams: {} });
    }
    if (this.auth.hasRole(['Diary User'])) {
      return this.router.navigate(['/diary-upload'], { queryParams: {} });
    }
    if (this.auth.hasRole(['Staff Fysiologic'])) {
      return this.router.navigate(['/records'], { queryParams: {} });
    }
    if (this.auth.hasRole(['Finance', 'Financial Admin'])) {
      return this.router.navigate(['/specifications'], { queryParams: {} });
    }

    this.localStorageService.remove('cradleSerialNumber');
    sessionStorage.clear();

    this.storageMap.delete('step').subscribe(() => {
      let stepStorage = '';
      this.storageMap.get('step', { type: 'string' }).subscribe((result) => {
        stepStorage = result;
        if ((!stepStorage || stepStorage == null || stepStorage == 'branch' || stepStorage == 'cradleChoose') && !this.auth.hasRole(['Doctor Without Cradle','Super User','System Admin', 'Finance', 'Financial Admin'])) {
          this.router.navigate(['/branch'], { queryParams: {} });
        } else {
          if (this.auth.hasRole(['Super User'])) {
            return this.router.navigate(['/cradles'], { queryParams: {} });
          } else {
            return this.router.navigate(['/records'], { queryParams: {} });
          }
        }
      });
    });
  }
}
