import * as tslib_1 from "tslib";
import { BaseListComponent } from 'src/app/common/components/base-list.component';
import Swal from 'sweetalert2';
import { DeleteInvoiceModalComponent } from '../components/delete-invoice-modal/delete-invoice-modal.component';
export class InvoiceListComponent extends BaseListComponent {
    constructor(service, spinner, router, route, logger, auth, invoiceService, translateService, modalService, ngxCsvParser) {
        super({
            service, spinner, router, route,
            idColumn: 'id',
            formRoute: '/invoices'
        });
        this.logger = logger;
        this.auth = auth;
        this.invoiceService = invoiceService;
        this.translateService = translateService;
        this.modalService = modalService;
        this.ngxCsvParser = ngxCsvParser;
    }
    checkBeforeDelete(id) {
        const modalRef = this.modalService.open(DeleteInvoiceModalComponent, {
            size: 'lg',
            centered: true,
            backdrop: 'static',
            keyboard: false
        });
        modalRef.componentInstance.deleteEvent.subscribe(() => {
            this.deleteInvoice(id);
        });
    }
    deleteInvoice(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.spinner.show();
            yield this.invoiceService.remove(id, () => {
                this.spinner.hide();
                this.showDeleteModal();
            });
        });
    }
    open(content, invoice) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.spinner.show();
            const data = yield this.invoiceService.getInvoiceCsvFile(invoice.invoiceFiles[0].invoiceFileId, () => {
                this.spinner.hide();
            }, (error) => console.log(error));
            this.csvTable = this.ngxCsvParser.csvStringToArray(data, ';');
            this.csvTable[0] = ['Surname', 'Date of Birth', 'PID', 'Type of recording', 'Report Send Date'];
            this.modalService.open(content, { size: 'xl', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                this.csvTable = null;
            }, (reason) => {
                this.csvTable = null;
            });
        });
    }
    get csvTableBody() {
        return [...this.csvTable].slice(1, this.csvTable.length - 1);
    }
    csvFileView(event, invoice) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const fileName = invoice.invoiceFiles[0].invoiceFileName;
            this.spinner.show();
            event.stopPropagation();
            const data = yield this.invoiceService.downloadCsvFile(fileName, () => {
                this.spinner.hide();
            });
            this.download(fileName, data);
        });
    }
    download(fileName, data) {
        const blob = new Blob([data], { type: "octet/stream" });
        const url = window.URL.createObjectURL(blob);
        var hiddenUrl = document.createElement("a");
        document.body.appendChild(hiddenUrl);
        hiddenUrl.style.display = "none";
        hiddenUrl.href = url;
        hiddenUrl.download = fileName;
        hiddenUrl.click();
        document.body.removeChild(hiddenUrl);
        window.URL.revokeObjectURL(url);
        this.spinner.hide();
    }
    showDeleteModal() {
        return Swal.fire({
            position: 'center',
            icon: 'success',
            title: this.translateService.instant('invoiceForm.deleted'),
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            timer: 1500,
            onClose: () => {
                this.refresh();
            }
        });
    }
}
