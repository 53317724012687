import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotFoundComponent} from './not-found/not-found.component';
import {HomeComponent} from './home/home.component';
import {LoginComponent} from './login/login.component';
import {SharedModule} from '../shared/shared.module';
import {NavMenuComponent} from './nav-menu/nav-menu.component';
import {LogoComponent} from './logo/logo.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {TranslateModule} from '@ngx-translate/core';
import {AboutComponent} from './about/about.component';
import {ActivityComponent} from './activity/activity.component';
import {CreatePasswordComponent} from './create-password/create-password.component';
import {NewPasswordComponent} from './new-password/new-password.component';
import { AccessPermissionComponent } from '../ip-whitelist/access-allow/access-permission.component';
import { EditableModule } from '@ngneat/edit-in-place';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragulaModule } from 'ng2-dragula';

@NgModule({
  declarations: [
    NotFoundComponent, HomeComponent, NavMenuComponent, NewPasswordComponent,
    LogoComponent, LoginComponent, ForgotPasswordComponent, AboutComponent,
    ActivityComponent, CreatePasswordComponent, AccessPermissionComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    EditableModule,
    NgbModule,
    DragulaModule.forRoot()
  ],
  exports: [
    NotFoundComponent, HomeComponent, NavMenuComponent,
    LogoComponent, LoginComponent
  ]
})
export class PublicModule {
}
