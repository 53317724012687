import {BaseListComponent} from '../common/components/base-list.component';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../common/auth/auth.service';
import {DomSanitizer} from '@angular/platform-browser';
import {ToastService} from '../shared/services/toast.service';
import {IpWhitelist} from '../models/ip-whitelist';
import {Component, OnInit} from '@angular/core';
import {IpWhiteListsService} from './ip-whitelists.service';
import { Filters } from '../common/data/result';
import { IpAddressKey } from '../models/ipAddressKey';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ecg-ip-whitelists',
  templateUrl: './ip-whitelists.component.html',
  styleUrls: ['./ip-whitelists.component.scss'],
})
export class IpWhitelistsComponent extends BaseListComponent<IpWhitelist> implements OnInit {

  accessRequests: [IpAddressKey];
  whiteListStatus: unknown = false;

  constructor(
    public service: IpWhiteListsService,
    spinner: NgxSpinnerService,
    public router: Router,
    route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public auth: AuthService,
    protected toast: ToastService,
    public modal: NgbModal
  ) {
    super({
      service,
      spinner,
      router,
      route,
      idColumn: 'id',
      formRoute: 'ip-whitelist'
    });
    this.service.getIPFilterStatus().subscribe(res => {
      this.whiteListStatus = res;
    });


  }

  preventDefault(e) {
    e.preventDefault();
  }

  ipFilterSwitch() {
    if (this.whiteListStatus) {
      this.service.switchFilterStat('false').subscribe(res => {
        this.whiteListStatus = res;
      });
    } else {
      this.service.switchFilterStat('true').subscribe(res => {
        this.whiteListStatus = res;
      });
    }
  }

  allow(ipAddress) {
    this.service.setAllowAccess(ipAddress);
    this.service.allowAccessSubscribe.subscribe(res => {
      if (res) {
        this.service.getAccessRequest();
        this.service.accessRequestSubscribe.subscribe(res => {
          this.accessRequests = res;
          this.refresh();
        });
      }
    });
  }
}
