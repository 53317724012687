import { BehaviorSubject, Observable } from 'rxjs';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

import { AuthApiHttpClient } from '../common/auth/auth-api-http-client';
import { AuthService } from '../common/auth/auth.service';
import { Cradle } from '../models/cradle';
import { CradleItemService } from './cradle-item.service';
import { CradleStatus } from '../models/cradleStatus';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { SignalRService } from '../common/signalr/signal-r.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { apiLocations } from '../../environments/app.config.json';

@Injectable({
  providedIn: 'root'
})
export class CradleHubService {
  private hubConnectionLog: HubConnection;
  private hubConnectionDevice: HubConnection;

  //cradle: Cradle;
  cradleSerialNumber;
  cradleConnectedStatus: CradleStatus;

  private _onRecorderUpdateClicked: BehaviorSubject<any>;

  channel: BroadcastChannel;

  constructor(
    private signalRService: SignalRService,
    public httpClient: AuthApiHttpClient,
    private cradleItemService: CradleItemService,
    public localStorageService: LocalStorageService,
    private translate: TranslateService,
    private authService: AuthService,
  ) {
    this._onRecorderUpdateClicked = new BehaviorSubject(null);
    try {
      this.channel = new BroadcastChannel('app-data');
      this.channel.addEventListener('message', (event) => {
        Swal.close();
      });
    } catch (error) {

    }
  }

  get onRecorderUpdateClicked(): Observable<any> {
    return this._onRecorderUpdateClicked.asObservable();
  }

  async createConnection() {
    this.cradleItemService.getCradleByLinkedMac(this.localStorageService.get('mac'), this.getCradleSerialNumber()).subscribe((cradle: Cradle) => {
      if (cradle) {
        this.cradleSerialNumber = cradle && cradle.cradleSerialNumber;
        this.cradleConnectedStatus = cradle.cradleStatus;
        this.deviceHubConnection();
        if (!this.authService.needLogin) { this.logHubConnection(); }
      }
    });
  }

  private async deviceHubConnection() {
    this.hubConnectionDevice = new HubConnectionBuilder().withUrl(`${apiLocations.hub}/devicehub`).build();
    this.hubConnectionDevice.start()
      .then(() => this.hubConnectionDevice.on('DeviceState', (device, state) => {
        if (device !== this.cradleSerialNumber) 
          return;

        if (state === 'CRADLE_NOT_CONNECTED' && this.getCradleSerialNumber() === device && !this.cradleDisconnectedModalShow) {
          this.cradleDisconnectedModal();
        }
        else if(state !== 'CRADLE_NOT_CONNECTED' && this.getCradleSerialNumber() === device && this.cradleDisconnectedModalShow)
        {
          Swal.close();
          this.cradleDisconnectedModalShow = false;
            
          console.log("cradle-hub 1");
        }
          
      }));
  }

  private async logHubConnection() {
    this.cradleItemService.getCradleByLinkedMac(this.localStorageService.get('mac'), this.getCradleSerialNumber()).subscribe((cradle: Cradle) => {
      //this.cradle = cradle;
      if (cradle) {
        this.cradleSerialNumber = cradle && cradle.cradleSerialNumber;
        this.cradleConnectedStatus = cradle.cradleStatus;
      }
    });

    this.hubConnectionLog = await this.signalRService.connect('/loghub');
    await this.hubConnectionLog.start();
    this.hubConnectionLog.on('updateCradle', (res) => {
      const { RecorderUpdateAvailable, RecorderUpdating, LinkedPC, CradleRecorderStatus, CradleStatus, CradleSerialNumber, CradleConnected, RecorderSerialNumber, MacAddress } = JSON.parse(res);

      var currentCradle = false;
      if ((this.getCradleSerialNumber() === null || this.getCradleSerialNumber() === undefined) && LinkedPC === this.getMac()) {
        currentCradle = false;
      } else if (LinkedPC === this.localStorageService.get('mac') && CradleSerialNumber === this.getCradleSerialNumber()) {
        currentCradle = true;
      }

      if (!currentCradle) return;


      if (CradleStatus === 1 && !this.cradleDisconnectedModalShow) {
        this.cradleDisconnectedModal();
      } else if (CradleStatus === 2 && this.cradleDisconnectedModalShow) {
        Swal.close();
        this.cradleDisconnectedModalShow = false;
        console.log("cradle-hub 2");
      }

      this.controlRecorderStatus(CradleRecorderStatus);

      this.controlRecorderUpdateAvailablePopup(CradleRecorderStatus, RecorderUpdating, MacAddress, CradleSerialNumber);

      this.controlRecorderUpdating(CradleStatus, CradleRecorderStatus);

      if (this.updateAvailable && !RecorderUpdating) {
        Swal.close();
        this.updateAvailable = false;
      }
    });
  }
  
  controlRecorderUpdating(CradleStatus, CradleRecorderStatus) {
    if (CradleRecorderStatus === 7 && CradleStatus === 2) {
      this.updateAvailable = true;
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: this.translate.instant("cradle.recorderUpdating"),
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        timer: 60000
      });
    }
  }
  controlRecorderUpdateAvailablePopup(CradleRecorderStatus, RecorderUpdating, MacAddress, CradleSerialNumber) {
    if (CradleRecorderStatus === 8 && RecorderUpdating) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: this.translate.instant("cradle.recorderUpdateAvailable"),
        showConfirmButton: true,
        showCancelButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: this.translate.instant("cradle.update"),
        cancelButtonText: this.translate.instant("cradle.dontUpdate"),
        cancelButtonColor: '#8a8787',
      }).then((result) => {
        if (result.value) {
          this.updateRecorder(MacAddress);
          this._onRecorderUpdateClicked.next({ CradleSerialNumber, value: false });
        } else {
          this._onRecorderUpdateClicked.next({ CradleSerialNumber, value: false });
        }
        try {
          this.channel.postMessage("close");
        } catch (error) {
          
        }
      })

    }
  }

  updateRecorder(macAddress) {
    this.httpClient.post(null, 'health/cradles/update-recorder/' + macAddress).subscribe(res => {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: this.translate.instant("cradle.recorderUpdating"),
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        timer: 60000
      });
    });
  }

  controlRecorderStatus(recorderStatus: number) {
    if (recorderStatus === 4) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: this.translate.instant("cradle.recorderPreparedFailed"),
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    }
    if (recorderStatus === 10) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: this.translate.instant("cradle.recorderUpdateFailed"),
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    }
    if (recorderStatus === 1 && this.cradleUpdateQuestionButton) {
      Swal.close();
    }
  }
  cradleUpdateQuestionButton = false;
  controlRecorderUpdateAvailable(CradleRecorderStatus, CradleSerialNumber, RecorderSerialNumber) {
    this.cradleUpdateQuestionButton = true;
    if (CradleRecorderStatus === 2) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: this.translate.instant("cradle.recorderUpdateAvailable"),
        showConfirmButton: true,
        showCancelButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: this.translate.instant("cradle.update"),
        cancelButtonText: this.translate.instant("cradle.dontUpdate"),
        cancelButtonColor: '#8a8787',
      }).then((result) => {
        this.cradleUpdateQuestionButton = false
        if (result.value) {
          this.uploadFileDevice("recorder", CradleSerialNumber, RecorderSerialNumber);
        }
      })
    }
  }
  updateAvailable = false;
  inUpdate = false;
  uploadFileDevice(deviceType, cradleSerialNumber, serialNumber) {
    this.basePopup(this.translate.instant("cradle.recorderUpdateFileUploading"), "info", false)
    this.httpClient.post(null, 'health/cradles/' + deviceType + '/' + cradleSerialNumber + '/' + serialNumber).subscribe(res => {
      Swal.fire({
        position: 'center',
        icon: "warning",
        title: this.translate.instant("cradle.recorderReadyForUpdate"),
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: true,
        confirmButtonText: 'OK',
      });
      this.updateAvailable = true;
    });
  }


  //Modals
  cradleDisconnectedModalShow = false;
  cradleDisconnectedModal() {
    Swal.fire({
      position: 'center', 
      icon: 'warning',
      title: this.translate.instant("cradle.disconnected"),
      showConfirmButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    this.cradleDisconnectedModalShow = true;
  }
  recorderDownloadingFailedModal() {
    Swal.fire({
      position: 'center',
      icon: 'error',
      title: this.translate.instant("cradle.recorderDownloadingFailed"),
      showConfirmButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }
  recorderRemoveFailedModal() {
    Swal.fire({
      position: 'center',
      icon: 'error',
      title: this.translate.instant("cradle.recorderDownloadingFailed"),
      showConfirmButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }


  private basePopup(title, icon, confirmButton = true) {
    Swal.fire({
      position: 'center',
      icon: icon,
      title: title,
      showConfirmButton: confirmButton,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }
  getCradleSerialNumber(): string {
    return this.localStorageService.get('cradleSerialNumber');
  }
  getMac(): string {
    return this.localStorageService.get('mac');
  }
}
