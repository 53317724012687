<div>
  <nav class="navbar navbar-light bg-light">
    <div class="row m-0 w-100">
      <div class="col-12 align-self-center text-center">
        <h3 i18n>{{ "report.liveStream" | translate }}</h3>
      </div>
      <div class="col-3 pt-1 pr-0"></div>
    </div>
  </nav>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <label i18n>{{ "report.projects" | translate }}</label>
          <select
            #organization="ngModel"
            (ngModelChange)="projectChange($event)"
            [(ngModel)]="project"
            class="custom-select cursorProp"
            name="selectedContinent"
          >
            <option selected value="">All</option>
            <option *ngFor="let proje of projects" [value]="proje">
              {{ proje }}
            </option>
          </select>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <label i18n>{{ "report.module" | translate }}</label>
          <select
            #organization="ngModel"
            (ngModelChange)="moduleChange($event)"
            [(ngModel)]="module"
            class="custom-select cursorProp"
            disabled="{{ isSelectProject }}"
            name="selectedContinent "
          >
            <option selected value="">All</option>
            <option *ngFor="let module of modules" [value]="module">
              {{ module }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-end">
    <div class="col-lg-4 col-md-6" style="text-align: right; margin-right: 20px; margin-top: 20px;">
      <button (click) = "clearStreamData()" class="btn btn-danger saveButton w-80 ng-star-inserted"  type="button">
        <i class="fa fa-trash" style="color:white"></i>Clear</button> &nbsp;

     <button (click) = "startStopStream()" *ngIf= "startedStream" class="btn btn-danger saveButton w-80 ng-star-inserted"  type="button">
      <i class="fa fa-stop" style="color:white"></i>
      {{streamButtonText}}
      </button>

      <button (click) = "startStopStream()" *ngIf= "!startedStream" class="btn btn-info saveButton w-80 ng-star-inserted"  type="button">
        <i class="fa fa-play" style="color:white"></i>
        {{streamButtonText}}
        </button>
    </div>
  </div>

  <div class="chart-wrapper">
    <canvas
      baseChart
      height="100"
      [datasets]="lineChartData"
      [labels]="lineChartLabels"
      [options]="lineChartOptions"
      [colors]="lineChartColors"
      [legend]="lineChartLegend"
      [chartType]="lineChartType"
      [plugins]="lineChartPlugins"
      (chartClick)="chartClicked($event)"
    >
    </canvas>
  </div>
</div>
