<!-- <agm-map [latitude]="lat" [longitude]="lng" style="height: 100vh" (mapClick)="placeMarker($event)"> -->
  <agm-map  [zoom]="zoom" [latitude]="lat" [longitude]="lng" style="height: 100vh">
  <agm-marker  (markerClick)="markerClicked($event, index)"  *ngFor="let d of data; let index = index" [latitude]="d.latitude"
    [longitude]="d.longitude" [iconUrl]="d.cradleConnected ===1 ? 'assets/icons/cradle_green_flag_48x48_border.png' : 'assets/icons/cradle_red_flag_48x48_border.png'" [label]="d.CradleSerialNumber">
    <agm-info-window  #window [isOpen]="markerSelectIndex === index">
      <!-- <div class="card-deck">
        <div class="card">
          <div class="card-header"> <h6>  Longitude & Latitude</h6></div>
            <div class="card-body text-white" style="background-color:#226597 ;">
              <h6 class="card-title"><span style="font-family:Arial, Helvetica, sans-serif; font-size: 13px;">Longitude : {{d.longitude}}</span></h6>
              <h6 class="card-title"> <span style="font-family:Arial, Helvetica, sans-serif; font-size: 13px;"> Latitude  : {{d.latitude}} </span> </h6>
              <p class="card-text"> <span style="font-family:Arial, Helvetica, sans-serif; font-size: 13px;"> The above information gives the longitude and latitude of Cradle. </span></p>
            </div>
        </div>
        <div class="card">
          <div class="card-header"> <h6>  Serial Number & MacAddress</h6> </div>
            <div class="card-body text-white" style="background-color: #226597;">
              <h6 class="card-title"><span style="font-family:Arial, Helvetica, sans-serif; font-size: 13px;"> SerialNumber : {{d.cradleSerialNumber}}</span></h6>
              <h6 class="card-title"><span style="font-family:Arial, Helvetica, sans-serif; font-size: 13px;">Mac Address  : {{d.macAddress}}</span></h6>
              <p class="card-text"><span style="font-family:Arial, Helvetica, sans-serif; font-size: 13px;"> The above information gives the serialNumber and macAddress of X.</span></p>
            </div>
        </div>
      </div> -->

      <div class="card-deck mt-1">
        <div class="card">
          <div class="card-header"> <h6>Cradle Information</h6> </div>
            <div class="card-body text-white" style="background-color: #113F67;">
              <table>
                <tbody>
                  <tr>
                    <td><h6 class="card-title"> <span style="font-family:Arial, Helvetica, sans-serif; font-size: 13px;"> Customer Name : {{d.customerName===''?"Unknown":d.customerName}}</span></h6></td>
                    &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                    <td colspan="10">  <img [attr.src]="d.cradleConnected===1 ? cradleConnectedUrl :cradleNotConnectedUrl" alt="img"></td>
                  </tr>
                  <tr>
                    <td><h6 class="card-title"><span style="font-family:Arial, Helvetica, sans-serif; font-size: 13px;">  State           :{{d.cradleConnected===1 ? cradleConnected : cradleNotConnected}}</span></h6></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td><h6 class="card-title"> <span style="font-family:Arial, Helvetica, sans-serif; font-size: 13px;"> Connected Date  : {{d.cradleConnectedDate | convertDate | date: 'dd-MM-yyyy, HH:mm'}}</span></h6></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td><h6 class="card-title"> <span style="font-family:Arial, Helvetica, sans-serif; font-size: 13px;"> Longitude  : {{d.longitude}}</span></h6></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td><h6 class="card-title"> <span style="font-family:Arial, Helvetica, sans-serif; font-size: 13px;"> Latitude  : {{d.latitude}}</span></h6></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td><h6 class="card-title"> <span style="font-family:Arial, Helvetica, sans-serif; font-size: 13px;"> SerialNumber  : {{d.cradleSerialNumber}}</span></h6></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td><h6 class="card-title"> <span style="font-family:Arial, Helvetica, sans-serif; font-size: 13px;"> Mac Address  : {{d.macAddress }}</span></h6></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td><p class="card-text"> <span style="font-family:Arial, Helvetica, sans-serif; font-size: 13px; font-size: 13px;"> The above information includes Cradle's status and last connection time.</span></p></td>
                  </tr>
                </tbody>
              </table>
              
            </div>
        </div>
      </div>
    <br/>
    

    <div class="card-deck mt-1">
      <div class="card">
          <div class="card-body text-white">
            <button type="button" class="btn btn-success" style="float:right ;"  (click)="redirectCradleDetail(d.id)"
            i18n>
           Cradle Detail
          </button>
          </div>
      </div>
    </div>

    </agm-info-window>
  </agm-marker>
</agm-map>