import { Injectable } from '@angular/core';
import { AuthApiHttpClient } from 'src/app/common/auth/auth-api-http-client';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ManualService {
    constructor(public httpClient: AuthApiHttpClient,) {
    }

    files(): Observable<any> {
        return this.httpClient.get('health/manual/files');
    }
    uploadPdf(file, name): Observable<any> {
        return this.httpClient.post({ Base64: file, Filename: name }, 'health/manual/upload');
    }
    delete(fileName): Observable<any> {
        return this.httpClient.delete({ Filename: fileName }, 'health/manual/delete');
    }
}