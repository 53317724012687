<ng-container *ngIf="model">
  <nav class="navbar navbar-light bg-light">
    <ul class="nav navbar px-0">
      <li class="m-1">
        <button type="button" class="btn btn-secondary" (click)="back();" i18n>{{'back' | translate }}</button>
      </li>
    </ul>
    <div class="text-center display-large">
      <h3 i18n>{{'organisationForm.organisation' | translate }}</h3>
    </div>
    <ul class="nav navbar-right">
      <li *ngIf="operation === 'edit'" class="m-1">
        <button type="button" class="btn btn-danger" [disabled]="false" (click)="modal.open(confirmDeleteModal)" i18n>
          {{'delete' | translate }}
        </button>
      </li>
      <li class="m-1">
        <ecg-button class="btn btn-info btnSave" [disabled]="!formValid(mainForm.form)" (onClick)="saveOrganisation()"
          i18n>
          {{'save' | translate }}
        </ecg-button>
      </li>
    </ul>
    <div class="display-small w-100 pt-2">
      <h3 class="text-center" i18n>{{'organisationForm.organisation' | translate }}</h3>
    </div>
  </nav>
  <form #mainForm="ngForm">
    <div class="card border-0">
      <div class="row m-0">
        <div class="col-12" style="flex-direction: column; display: flex">
          <div style="float: left;margin-left: 15px;margin-top: 15px">
            <h4 i18n>{{'organisationForm.organisationDetail' | translate }}</h4>
          </div>
          <div class="card m-3">
            <div class="card-body">
              <div class="row">
                <div class="form-group col-xs-12  col-md-6  col-lg-3 pl-2 pr-2">
                  <label class="required" for="type" i18n>{{'organisationForm.type' | translate }}</label>
                  <div class="input-group">
                    <select class="custom-select cursorProp" id="type" name="type" #type="ngModel"
                      [(ngModel)]="model.type" required>
                      <!--                    <option *ngFor="let item of organizationType | keys" [value]="item.key">{{item.value}}</option>-->
                      <!--                  </select> -->
                      <!--                  <select #type="ngModel" id="type" [(ngModel)]="model.type"  (ngModelChange)="validation()" class="custom-select" id="specialism"-->
                      <!--                                    name="specialism">'-->
                      <option value="1" i18n>{{'organisationForm.all' | translate }}</option>
                      <option value="2" i18n>{{'organisationForm.regular' | translate }}</option>
                      <option value="3" i18n>{{'organisationForm.children' | translate }}</option>
                      <option value="4" i18n>{{'organisationForm.dogs' | translate }}</option>
                      <option value="5" i18n>{{'organisationForm.pacemaker' | translate }}</option>
                      <option value="6" i18n>{{'organisationForm.twelveChannel' | translate }}</option>
                    </select>
                  </div>
                  <div *ngIf="type.invalid && (type.dirty || type.touched)" class="alert alert-danger">
                    <div *ngIf="type.errors['required']" i18n>
                      {{'required.type' | translate }}
                    </div>
                  </div>
                </div>
                <div class="form-group col-xs-12 col-md-6 col-lg-3 pl-2 pr-2">
                  <label class="required" for="name" i18n>{{'organisationForm.organisationName' | translate }}</label>
                  <input type="text" oncopy="return false" oncut="return false" onpaste="return false"
                    class="form-control" id="name" name="name" #name="ngModel" [(ngModel)]="model.name" required>
                  <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger">
                    <div *ngIf="name.errors['required']" i18n>
                      {{'required.organisationName' | translate }}
                    </div>
                  </div>
                </div>
                <div class="form-group col-xs-12 col-md-6 col-lg-3 pl-2 pr-2">
                  <label for="eMailReport" i18n>{{'organisationForm.emailReport' | translate }}</label>
                  <input type="text" class="form-control" id="eMailReport" name="eMailReport" #eMailReport="ngModel"
                    [(ngModel)]="model.eMailReport">
                </div>
                <div class="form-group col-xs-12 col-md-6  col-lg-3 pl-2 pr-2">
                  <label class="required" for="code" i18n>{{'organisationForm.organisationCode' | translate }}</label>
                  <div class="input-group">
                    <input #organisationCode="ngModel" minlength="4" maxlength="4" [ngModel]="getCustomerCode(model)"
                      (ngModelChange)="setCustomerCode($event)" pattern="[a-zA-Z]{2}[A-Za-z0-9]{2}" class="form-control"
                      id="organisationCode" name="organisationCode" onpaste="return false" required>
                  </div>
                  <div *ngIf="organisationCode.invalid && (organisationCode.dirty || organisationCode.touched)"
                    class="alert alert-danger">
                    <div *ngIf="organisationCode.errors['required']" i18n>
                      {{'required.organizationCode' | translate }}
                    </div>
                    <div *ngIf="organisationCode.errors['pattern']" i18n>
                      <span *ngIf="model.codes[0].code.length < 4;else invalidCode">{{'required.customerCodeFourDigit' |
                        translate }}</span>
                      <ng-template #invalidCode>
                        {{'required.customerCodeFirstTwoDigit' | translate }}
                      </ng-template>
                    </div>
                  </div>
                </div>

              </div>
              <div class="row">
                <div class="form-group col-xs-12 col-md-6 col-lg-3 pl-2 pr-2">
                  <label for="eMailGeneral" i18n>{{'organisationForm.emailGeneral' | translate }}</label>
                  <input type="text" class="form-control" id="eMailGeneral" name="eMailGeneral" #eMailGeneral="ngModel"
                    [(ngModel)]="model.eMailGeneral">
                </div>
                <div class="form-group col-xs-12 col-md-6 col-lg-3 pl-2 pr-2">
                  <label for="address1" i18n>{{'organisationForm.streetNumber' | translate }}</label>
                  <input type="text" class="form-control" oncopy="return false" oncut="return false"
                    onpaste="return false" id="address1" name="address1" #address1="ngModel"
                    [(ngModel)]="model.address1">
                </div>
                <div class="form-group col-xs-12 col-md-6 col-lg-3 pl-2 pr-2">
                  <label for="address2" i18n>{{'organisationForm.cityCountry' | translate }}</label>
                  <input type="text" class="form-control" id="address2" name="address2" #address2="ngModel"
                    [(ngModel)]="model.address2">
                </div>
                <div class="form-group col-xs-12 col-md-6 col-lg-3 pl-2 pr-2">
                  <label class="required" for="pidNumberLength" i18n>{{'organisationForm.pidNumberLength' | translate
                    }}</label>
                  <input type="number" class="form-control" id="pidNumberLength" name="pidNumberLength"
                    #pidNumberLength="ngModel" [(ngModel)]="model.pidNumberLength" placeholder="6-15" min="6" max="15"
                    required>
                  <div *ngIf="model.pidNumberLength < 6 || model.pidNumberLength > 15" class="alert alert-danger">
                    {{'required.pidNoLength' | translate }}
                  </div>
                  <div *ngIf="pidNumberLength.invalid && (pidNumberLength.dirty || pidNumberLength.touched)"
                    class="alert alert-danger">
                    <div *ngIf="pidNumberLength.errors['required']" i18n>
                      {{'required.pidNumberLength' | translate }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-xs-12 col-md-6 col-lg-3 pl-2 pr-2">
                  <label for="useBittium" i18n>{{'organisationForm.useBittium' | translate }}</label>
                  <input type="checkbox" class="form-check" id="useBittium" name="useBittium" #useBittium="ngModel"
                    [(ngModel)]="model.useBittium" />
                </div>
              </div>
              <div class="row" id="formBittium" *ngIf="model.useBittium">

                <div class="form-group col-xs-12 col-md-6 col-lg-3 pl-2 pr-2">
                  <label for="bittiumChannel" i18n>{{'organisationForm.bittiumChannel' | translate }}</label>
                  <select class="custom-select cursorProp" id="bittiumChannel" name="bittiumChannel"
                    #bittiumChannel="ngModel" [(ngModel)]="model.bittiumChannel">
                    <option value="2" i18n selected>2</option>
                    <option value="3" i18n>3</option>
                  </select>
                </div>


                <div class="form-group col-xs-12 col-md-6 col-lg-3 pl-2 pr-2">
                  <label for="bittiumSampleRate" i18n>{{'organisationForm.bittiumSampleRate' | translate }}</label>
                  <select class="custom-select cursorProp" id="bittiumSampleRate" name="bittiumSampleRate"
                    #bittiumSampleRate="ngModel" [(ngModel)]="model.bittiumSampleRate">
                    <option value="125" i18n selected>125</option>
                    <option value="250" i18n>250</option>
                  </select>
                </div>



                <div class="form-group col-xs-12 col-md-6 col-lg-3 pl-2 pr-2">
                  <label for="bittiumHighPassFilter" i18n>{{'organisationForm.bittiumHighFilter' | translate }}</label>
                  <select class="custom-select cursorProp" id="bittiumHighPassFilter" name="bittiumHighPassFilter"
                    #bittiumHighPassFilter="ngModel" [(ngModel)]="model.bittiumHighPassFilter">
                    <option value="0.05" i18n selected>0.05</option>
                  </select>
                </div>

                <div class="form-group col-xs-12 col-md-6 col-lg-3 pl-2 pr-2">
                  <label for="bittiumAutoStart" i18n>{{'organisationForm.bittiumAutoStart' | translate }}</label>
                  <select class="custom-select cursorProp" id="bittiumAutoStart" name="bittiumAutoStart"
                    #bittiumAutoStart="ngModel" [(ngModel)]="model.bittiumAutoStart">
                    <option value="1" i18n selected>Yes</option>
                    <option value="0" i18n>No</option>
                  </select>
                </div>

                <div class="form-group col-xs-12 col-md-6 col-lg-3 pl-2 pr-2">
                  <label for="bittiumHrvRecorded" i18n>{{'organisationForm.bittiumHrvRecorded' | translate }}</label>
                  <select class="custom-select cursorProp" id="bittiumHrvRecorded" name="bittiumHrvRecorded"
                    #bittiumHrvRecorded="ngModel" [(ngModel)]="model.bittiumHrvRecorded">
                    <option value="1" selected>Yes</option>
                    <option value="0">No</option>
                  </select>
                </div>

                <div class="form-group col-xs-12 col-md-6 col-lg-3 pl-2 pr-2">
                  <label for="bittiumMode" i18n>{{'organisationForm.bittiumMode' | translate }}</label>
                  <select class="custom-select cursorProp" id="bittiumMode" name="bittiumMode" #bittiumMode="ngModel"
                    [(ngModel)]="model.bittiumMode">
                    <option value="Offline" i18n selected>Offline</option>
                    <option value="Online" i18n>Online</option>
                  </select>
                </div>


                <div class="form-group col-xs-12 col-md-6 col-lg-3 pl-2 pr-2">
                  <label for="bittiumVbusEndsMeans" i18n>{{'organisationForm.bittiumVbusEndsMeas' | translate }}</label>
                  <select class="custom-select cursorProp" id="bittiumVbusEndsMeans" name="bittiumVbusEndsMeans"
                    #bittiumVbusEndsMeans="ngModel" [(ngModel)]="model.bittiumVbusEndsMeans">
                    <option value="1" i18n selected>Yes</option>
                    <option value="0" i18n>No</option>
                  </select>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-xs-12 col-md-6 col-lg-3 pl-2 pr-2">
                  <label for="canViewMedicalInfo" i18n>{{'organisationForm.canViewMedicalInfo' | translate }}</label>
                  <input type="checkbox" class="form-control" id="canViewMedicalInfo" name="canViewMedicalInfo"
                    #canViewMedicalInfo="ngModel" [(ngModel)]="model.canViewMedicalInfo">
                </div>
                <div class="form-group col-xs-12 col-md-6 col-lg-3 pl-2 pr-2">
                  <label for="canUseHolterAtHome" i18n>{{'organisationForm.canUseHolterAtHome' | translate }}</label>
                  <input type="checkbox" class="form-control" id="canUseHolterAtHome" name="canUseHolterAtHome"
                    #canUseHolterAtHome="ngModel" [(ngModel)]="model.canUseHolterAtHome">
                </div>
                <div class="form-group col-xs-12 col-md-6 col-lg-3 pl-2 pr-2">
                  <label for="hl7Service" i18n>{{'organisationForm.canUseHl7Service' | translate }}</label>
                  <input type="checkbox" class="form-control" id="hl7Service" name="hl7Service" #hl7Service="ngModel"
                    [(ngModel)]="model.hl7Service">
                </div>
                <div class="form-group col-xs-12 col-md-6 col-lg-3 pl-2 pr-2">
                  <label for="useAnalyseReport" i18n>{{'organisationForm.canUseAnalyseReport' | translate }}</label>
                  <input type="checkbox" class="form-control" id="useAnalyseReport" name="useAnalyseReport"
                    #useAnalyseReport="ngModel" [(ngModel)]="model.canUseAnalyzeReport">
                </div>
                <div class="form-group col-xs-12 col-md-6 col-lg-3 pl-2 pr-2 ">
                  <label for="canTransferToAnalysis" i18n>{{'Can Transfer To Analysis' | translate }}</label>
                  <input type="checkbox" class="form-control" id="canTransferToAnalysis" name="canTransferToAnalysis"
                    #useAnalyseReport="ngModel" [(ngModel)]="model.canTransferToAnalysis">
                </div>
                
                <div class="form-group col-xs-12 col-md-6 col-lg-3 pl-2 pr-2 ">
                  <label for="isEmailAuthenticatorActive" i18n>{{'organisationForm.isEmailAuthenticatorActive' | translate }}</label>
                  <input type="checkbox" class="form-control" id="isEmailAuthenticatorActive" name="isEmailAuthenticatorActive" #isEmailAuthenticatorActive="ngModel"
                    [(ngModel)]="model.isEmailAuthenticatorActive" />
                </div>

                <div class="form-group col-xs-12 col-md-6 col-lg-3 pl-2 pr-2 ">
                  <label for="isUsingNewAnalysisApplication" i18n>{{'organisationForm.isUsingNewAnalysisApplication' | translate }}</label>
                  <input type="checkbox" class="form-control" id="isUsingNewAnalysisApplication" name="isUsingNewAnalysisApplication" #isUsingNewAnalysisApplication="ngModel"
                    [(ngModel)]="model.isUsingNewAnalysisApplication" />
                </div>
<!-- 
                 <div class="form-group col-xs-12 col-md-6 col-lg-3 pl-2 pr-2 ">
                  <label for="isOsasAnalysis" i18n>{{'organisationForm.OsasAnalysisApply' | translate }}</label>
                  <input type="checkbox" class="form-control" id="isOsasAnalysis" name="isOsasAnalysis"
                    #isOsasAnalysis="ngModel" [(ngModel)]="model.isOsasAnalysis">
                </div>  -->
              </div>

            </div>
          </div>
        </div>


        <div class="col-12" style="flex-direction: column; display: flex">
          <div style="float: left;margin-left: 15px;margin-top: 15px">
            <h4 i18n>{{'organisationForm.canDevice' | translate }}</h4>
          </div>
          <div class="card m-3">
            <div class="card-body">
              <div class="row devices">

                <div class="col-3" *ngFor="let device of devices">
                  <table class="table table-hover" i18n-aria-describedby aria-describedby="doctor list">
                    <thead>
                      <tr (click)="toggleActive(device)">
                        <th i18n class="border-top-0">{{('organisationForm.' + device.type) | translate }}</th>
                        <th class="border-top-0">
                          <input type="checkbox" class="form-control float-right" [id]="device.type"
                            [name]="device.type" [(ngModel)]="device.active" />
                        </th>
                      </tr>
                    </thead>
                    <tbody *ngIf="device.active">
                      <tr *ngFor="let time of device.time" style="background-color: #e3e5e6;"
                        (click)="toggleActive(time)">
                        <td colspan="2" class="pl-5">
                          <label class="mb-0 mr-5" [for]="device.type + time.value" i18n>{{time.value | translate
                            }}</label>
                          <input type="checkbox" class="form-control d-flex float-right" [id]="device.type + time.value"
                            [name]="device.type + time.value" [(ngModel)]="time.active">
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>



              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>



  <div class="container-fluid mb-5" *ngIf="hospitalUserList && hospitalUserList.length > 0"
    style="flex-direction: column; display: flex">
    <div style="float: left;margin-left: 15px;margin-top: 15px">
      <h4 i18n>{{'organisationForm.usersOf' | translate }}</h4>
    </div>
    <div class="m-3 mb-0" style="margin-bottom: 0px !important;">
      <div class="card table-responsive mt-0">
        <table class="table table-hover" i18n-aria-describedby aria-describedby="doctor list">
          <thead>
            <tr>
              <th sortable="name" (sort)="refresh($event)" scope="col" i18n>{{'profileForm.name' | translate }}</th>
              <th sortable="email" (sort)="refresh($event)" scope="col" i18n>{{'profileForm.email' | translate }}</th>
              <th sortable="UserAccountRoles.FirstOrDefault().userRole.name" (sort)="refresh($event)" scope="col" i18n>
                {{'profileForm.role' | translate }}
              </th>
              <th>{{'profileForm.ipAddress' | translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="clickable-row" *ngFor="let hospitalUser of hospitalUserList; let index=index"
              [tabindex]="index + 1">
              <td>{{ hospitalUser.name }}</td>
              <td>{{ hospitalUser.eMail }}</td>
              <td>
                <ng-container *ngIf="hospitalUser?.userAccountRoles && hospitalUser?.userAccountRoles.length">
                  <ng-container *ngFor="let userAccountRole of hospitalUser?.userAccountRoles">
                    <span *ngIf="userAccountRole?.userRole?.name" class="mr-1">
                      <span class="badge badge-secondary">{{userAccountRole.userRole.name}}</span>
                    </span>
                  </ng-container>
                </ng-container>
              </td>
              <td>
                {{hospitalUser.ipAddress}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="mr-3 ml-3 mt-0" style="margin-top: 0px;" *ngIf="(dataCount) as count">
      <div class="bg-ecg text-white px-3 pt-lg-3" *ngIf="dataCount>=10">
        <div class="row justify-content-between w-100 m-0">
          <div class="col-12 d-block d-lg-none">
            <div class="pt-1 text-center" i18n>
              {{count}} {{'itemsIn' | translate }}
              {{this | func:pageCount:count:paging.size}} {{ 'pages' | translate }}
            </div>
          </div>
          <div class="col-auto pl-0 pr-0">
            <ngb-pagination [boundaryLinks]="true" [rotate]="true" [maxSize]="3" [(page)]="paging.index"
              [pageSize]="paging.size" [collectionSize]="count" (pageChange)="refresh('pageRefresh')" #pagination>
            </ngb-pagination>
          </div>
          <div class="col-12 col-lg d-none d-lg-block">
            <div class="pt-2 text-center pagination-text" i18n>
              {{count}} {{'itemsIn' | translate }}
              {{this | func:pageCount:count:paging.size}} {{'pages' | translate }}
            </div>
          </div>
          <div class="col pl-0 pagination-dropdown-container">
            <div>
              <select class="custom-select pagination-dropdown pt-2 cursorProp" [(ngModel)]="paging.size"
                (change)="refresh('pageRefresh')">
                <option selected [ngValue]="10" i18n>{{'records10' | translate }}</option>
                <option [ngValue]="15" i18n>{{'records15' | translate }}</option>
                <option [ngValue]="20" i18n>{{'records20' | translate }}</option>
                <option [ngValue]="50" i18n>{{'records50' | translate }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="container-fluid" *ngIf="model.branchs" style="flex-direction: column; display: flex">
    <div class="row justify-content-between">
      <div style="float:left;margin-left: 30px">
        <h4 i18n>{{'organisationForm.branchsOf' | translate }}</h4>
      </div>
      <div style="margin-right:30px">
        <button type="button" class="btn btn-info float-right" (click)="modal.open(branchAddModal)" i18n>
          {{'profileForm.new' | translate }}
        </button>
      </div>
    </div>
    <div class="m-3" *ngIf="model.branchs | branchsArray">
      <div class="card table-responsive mt-0">
        <table class="table table-hover" i18n-aria-describedby aria-describedby="doctor list">
          <thead>
            <tr>
              <th scope="col" i18n>{{'organisationForm.branchName' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="clickable-row" *ngFor="let branch of model.branchs | branchsArray; let index=index"
              [tabindex]="index + 1" (click)="editBranchModal(branch);modal.open(branchEditModal);">
              <td>{{ branch.branchName }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="ml-3" *ngIf="model.branchs.length  <= 0">
      <h6>No Data Branch</h6>
    </div>
  </div>


  <ecg-cradle-detail *ngIf="model.id" [organisationId]="model.id"></ecg-cradle-detail>
</ng-container>

<ng-template #confirmDeleteModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title" i18n>{{'profileForm.deletion' | translate }}</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p><strong i18n>{{'organisationForm.deleteCustomer' | translate }}</strong></p>
    <p i18n>
      {{'allInfoDeleted' | translate }}
    </p>
    <span class="text-danger" i18n> {{'cannotUndone' | translate }} </span>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="delete(); modal.dismiss('deleted');" i18n>
      {{'profileForm.ok' | translate }}
    </button>
    <button type="button" ngbAutofocus class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')" i18n>
      {{'cancel' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #branchAddModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title" i18n>{{'organisationForm.branchAdd' | translate }}</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group pl-2 pr-2">
      <label class="required" for="branchName" i18n>{{'organisationForm.branchName' | translate }}</label>
      <div class="plus">

        <input type="text" (keypress)="inputKeyPress($event);" oncopy="return false" oncut="return false"
          onpaste="return false" class="form-control input-with-icon" id="branchName" name="branchName"
          #branchName="ngModel" [(ngModel)]="branchNamee" required>
        <span class="fa fa-plus text-success" (click)="addBranchtoTable()"></span>
      </div>
      <div *ngIf="branchName.invalid && (branchName.dirty || branchName.touched)" class="alert alert-danger">
        <div *ngIf="branchName.errors['required']" i18n>
          {{'required.branchName' | translate }}
        </div>
      </div>
      <div>
        <table class="table table-hover">
          <thead>
            <tr>
              <th i18n>{{'organisationForm.branchs' | translate }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let branch of newBranchList; let index=index" [tabindex]="index + 1">
              <td width="95%">
                {{branch}}
              </td>
              <td><i class="fa fa-minus text-danger" (click)="removeBranchtoTable(branch)"></i></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-info" [disabled]="newBranchList.length < 1"
      (click)="addBranch(); modal.dismiss('added');" i18n>
      {{'organisationForm.Add' | translate }}
    </button>
    <button type="button" ngbAutofocus class="btn btn-outline-secondary"
      (click)="modal.dismiss('cancel click');clearBranchList();" i18n>
      {{'cancel' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #branchEditModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title" i18n>{{'organisationForm.branchEdit' | translate }}</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group pl-2 pr-2">
      <label class="required" for="editBranchName" i18n>{{'organisationForm.branchName' | translate }}</label>
      <input type="text" oncopy="return false" oncut="return false" onpaste="return false" class="form-control"
        id="editBranchName" name="editBranchName" #editBranchName="ngModel" [(ngModel)]="branchModal.branchName"
        required>
      <div *ngIf="editBranchName.invalid && (editBranchName.dirty || editBranchName.touched)"
        class="alert alert-danger">
        <div *ngIf="editBranchName.errors['required']" i18n>
          {{'required.branchName' | translate }}
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" [disabled]="!branchModal.branchName"
      (click)="deleteBranch(); modal.dismiss('deleted');" i18n>
      {{'organisationForm.Delete' | translate }}
    </button>
    <button type="button" class="btn btn-info" [disabled]="!branchModal.branchName"
      (click)="editBranch(); modal.dismiss('edited');" i18n>
      {{'organisationForm.Edit' | translate }}
    </button>
    <button type="button" ngbAutofocus class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')" i18n>
      {{'cancel' | translate }}
    </button>
  </div>
</ng-template>


<ng-template #savedSuccessMessage>
  <span i18n>
    {{'customerSaved' | translate }}
  </span>
</ng-template>
<ng-template #savedErrorMessage>
  <span i18n>
    {{'customerCannotsaved' | translate }}
  </span>
</ng-template>
<ng-template #deletedSuccessMessage>
  <span i18n>
    {{'customerDeleted' | translate }}
  </span>
</ng-template>
<ng-template #deletedErrorMessage>
  <span i18n>
    {{'customerCannotDeleted' | translate }}
  </span>
</ng-template>
