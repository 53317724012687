<div *ngIf="(service.data | async) as userAccounts">
  <nav class="navbar navbar-light bg-light navbarHeaderforCenter">
    <form class="form-inline my-2 my-lg-0">
      <input class="form-control mr-sm-2" type="text" placeholder="Search" aria-label="Search"
             [(ngModel)]="paging.search" [ngModelOptions]="{standalone: true}" [ngbTypeahead]="search"
             (click)="searchClick.next($event.target.value)" (focus)="searchFocus.next($event.target.value)"
             (ngModelChange)="searchModel.next($event)" #searchTypehead="ngbTypeahead">
    </form>
    <div class="text-center divHeaderforCenter">
      <h3 i18n>Linked PCs</h3>
    </div>
  </nav>
  <div class="table-responsive">
    <table class="table table-hover" i18n-aria-describedby aria-describedby="doctor list">
      <thead>
      <tr>
        <th scope="col" sortable="username" (sort)="onSort($event)" i18n>
          {{'username' | translate }}
          </th>
        <th scope="col" i18n>
          {{'organisation' | translate }}
          </th>
        <th scope="col" sortable="linkedpc" (sort)="onSort($event)" i18n>PC Id</th>
        <th scope="col" i18n>
          {{'role' | translate }}
        </th>
      </tr>
      </thead>
      <tbody *ngIf="userAccounts && userAccounts.length; else norecord" tabindex="0" autofocus>
      <tr class="clickable-row" *ngFor="let userAccount of userAccounts; let index=index" [tabindex]="index + 1"
          (click)="edit(userAccount)" (keydown.enter)="edit(userAccount)" [ecgAutoFocus]="index === 0">

        <td>{{ userAccount.username }}</td>
        <td>{{ userAccount?.customer?.name }}</td>
        <td>{{ userAccount.linkedPc }}</td>
        <td>
          <ng-container *ngIf="userAccount?.userAccountRoles && userAccount?.userAccountRoles.length">
            <ng-container *ngFor="let userAccountRole of userAccount?.userAccountRoles">
                <span *ngIf="userAccountRole?.userRole?.name" class="mr-1">
                  <span class="badge badge-secondary">{{userAccountRole.userRole.name}}</span>
                </span>
            </ng-container>
          </ng-container>
        </td>
      </tr>
      </tbody>
      <ng-template #norecord>
        <tbody>
        <tr>
          <td colspan="10" class="text-danger" i18n>
            {{'noRecords' | translate }}
            </td>
        </tr>
        </tbody>
      </ng-template>
    </table>
  </div>
  <div *ngIf="(dataCount | async) as count" class="ecg-pagination-wrapper">
    <div class="bg-ecg fix-to-bottom px-3 pt-2" *ngIf="count > 10">
      <div class="mt-5 mb-1 d-none-pagination-text px-3 text-white" i18n>{{count}}&nbsp;{{'itemsIn' | translate }}&nbsp;{{this | func:pageCount:count:paging.size}}&nbsp;
        {{'pages' | translate }}
      </div>
      <div class="pagination-padding-unset text-white px-3 pt-1 d-flex justify-content-between responsive-display">
        <ngb-pagination class="pagination-small pagination-sm" [boundaryLinks]="true" [rotate]="true" [maxSize]="3" [(page)]="paging.index"
                        [pageSize]="paging.size" [collectionSize]="count" (pageChange)="refresh()"
                        #pagination></ngb-pagination>
        <ngb-pagination class="pagination-large" [boundaryLinks]="true" [rotate]="true" [maxSize]="3" [(page)]="paging.index"
                        [pageSize]="paging.size" [collectionSize]="count" (pageChange)="refresh()"
                        #pagination></ngb-pagination>
        <div class="pt-2 pagination-text" i18n>{{count}}&nbsp;{{'itemsIn' | translate }}&nbsp;{{this | func:pageCount:count:paging.size}}&nbsp;
          {{'pages' | translate }}
        </div>
        <div>
          <select class="custom-select " [(ngModel)]="paging.size" (change)="refresh()">
            <option [ngValue]="10" i18n>{{'records10' | translate }}</option>
            <option selected [ngValue]="15" i18n>{{'records15' | translate }}</option>
            <option [ngValue]="20" i18n>{{'records20' | translate }}</option>
            <option [ngValue]="50" i18n>{{'records50' | translate }}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>
