import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@ngx-pwa/local-storage";
import * as i3 from "@angular/router";
import * as i4 from "ngx-spinner";
export class AuthLogoutService {
    constructor(authService, storageMap, platformId, router, spinner) {
        this.authService = authService;
        this.storageMap = storageMap;
        this.platformId = platformId;
        this.router = router;
        this.spinner = spinner;
    }
    logout() {
        this.spinner.show();
        if (this.logoutSubscription) {
            this.logoutSubscription.unsubscribe();
        }
        if (this.storageMapSubscription) {
            this.storageMapSubscription.unsubscribe();
        }
        // localStorage.clear();
        this.logoutSubscription = this.authService.logout().subscribe(r => {
            this.spinner.hide();
            console.log("authService");
            this.storageMapSubscription = this.storageMap.delete('step').subscribe(() => { });
            localStorage.removeItem("lastActivityDate");
            localStorage.removeItem("ecg-app.mac");
            localStorage.removeItem("ecg-app.organisationSettings");
            localStorage.removeItem("customerCodeId");
            localStorage.removeItem("UserEmail");
            localStorage.removeItem("mac");
            localStorage.removeItem("ecg-app.user-uuid");
            sessionStorage.clear();
            // localStorage.clear();
            window.location.reload();
        }, e => {
            this.router.navigateByUrl('/login');
            this.spinner.hide();
        });
    }
    deleteAllCookies() {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];
            let eqPos = cookie.indexOf('=');
            let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
        }
    }
}
AuthLogoutService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthLogoutService_Factory() { return new AuthLogoutService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.StorageMap), i0.ɵɵinject(i0.PLATFORM_ID), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.NgxSpinnerService)); }, token: AuthLogoutService, providedIn: "root" });
