import { Injectable } from '@angular/core';
import { AuthApiHttpClient } from '../../common/auth/auth-api-http-client';
import { DataListService } from '../../common/data/data-list.service';
import { Report } from '../../models/report';

@Injectable({ providedIn: 'root' })
export class ReportErrorLogListService extends DataListService<Report> {

  constructor(httpClient: AuthApiHttpClient) {
    super({
      httpClient,
      url: 'health/errorLogReport',
      initial: []
    });
  }
}
