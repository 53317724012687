import { Injectable } from '@angular/core';
import { Diary } from '../models';
import { AuthApiHttpClient } from '../common/auth/auth-api-http-client';
import { DataItemService } from '../common/data/data-item.service';
import { Patient } from '../models/patient';

@Injectable({ providedIn: 'root' })
export class DiaryItemService extends DataItemService<Diary> {
  constructor(httpClient: AuthApiHttpClient) {
    super({
      httpClient,
      url: 'health/diaries/item'
    });
  }

  patient = (patientId: number) => this.httpClient.get<Patient>(`health/diaries/item/patient/${patientId}`);

}
