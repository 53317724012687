<div *ngIf="(service.data | async) as customerCodes">
    <nav class="navbar navbar-light bg-light">
      <div class="row m-0 w-100">
        <div class="col-3 pl-0 pt-1 pr-0">
          <button class="btn btn-info mr-2 filterBttn" (click)="showFilter = !showFilter" i18n>
            <i class="fa fa-filter"></i> {{'filterButton' | translate}}
          </button>
          <button type="button" class="btn btn-outline-secondary" (click)="filterReset();" *ngIf="showFilter"  i18n>{{'filterReset' | translate }}</button>
        </div>
        <div class="col-6 align-self-center text-center">
          <h3 i18n>{{'invoiceForm.upload' | translate }}</h3>
        </div>
      </div>
    </nav>
    <div class="card" *ngIf="showFilter">
      <div class="card-body">
        <ecg-filter (filterChange)="pagingReset();filterChange($event);" [metaData]="filterMetaData" [reset]="reset"></ecg-filter>
      </div>
    </div>

    <ecg-month-year-selector (selectionChanged)="onSelectionChanged($event)"></ecg-month-year-selector>
    
    <div [class]="(dataCount | async) > 10 ? 'table-responsive' : 'table-responsive table-responsive-no-paginate'">
      <table class="table table-hover invoice-list-table" i18n-aria-describedby aria-describedby="doctor list">
        <thead>
          <tr>
            <th scope="col" sortable="name" (sort)="onSort($event)" i18n>{{'invoiceForm.customerCode' | translate }}</th>
            <th class="text-center" scope="col" (sort)="onSort($event)" i18n>{{'invoiceForm.attachmentInvoice' | translate }}</th>
          </tr>
        </thead>

        <tbody *ngIf="customerCodes && customerCodes.length; else norecord">
          <tr class="clickable-row cursorProp" *ngFor="let customerCode of customerCodes; let index=index" [tabindex]="index + 1">
            <td>{{ customerCode.name }}</td>
            <td class="text-center" *ngIf="!customerCode.hasExistingInvoice; else haveAnInvoice">
                <a href="javascript:void(0)" (click)="uploadItemModal(uploadNewFileModal, customerCode.customerCodeId, customerCode.name)" [ngStyle]="{'cursor': isClickable() ? 'pointer' : 'not-allowed'}">{{'invoiceForm.uploadTitle' | translate }}</a>
            </td>
            <ng-template #haveAnInvoice>
              <td class="text-center">
                <a (click)="checkBeforeDelete(customerCode)" href="javascript:void(0)" style="position: relative; display: inline-block;">
                  <i class="fa fa-file-o"></i>
                  <i style="color: red; position: absolute; top: 20px; left: 10px;" class="fa fa-times" aria-hidden="true"></i>
                </a>
              </td>
            </ng-template>
          </tr>
        </tbody>

        <ng-template #norecord>
          <tbody>
          <tr>
            <td colspan="10" class="text-danger" i18n>{{'patientList.noRecords' | translate }}</td>
          </tr>
          </tbody>
        </ng-template>
      </table>
    </div>

    <div *ngIf="(dataCount | async) as count">
      <div class="bg-ecg fix-to-bottom text-white px-3 pt-lg-3" *ngIf="count>=10">
        <div class="row justify-content-between w-100 m-0">
          <div class="col-12 d-block d-lg-none">
            <div class="pt-1 text-center"
                 i18n>{{count}} {{'itemsIn' | translate }} {{this | func:pageCount:count:paging.size}} {{'pages'
              | translate }}
            </div>
          </div>
          <div class="col-auto pl-0 pr-0">
            <ngb-pagination [boundaryLinks]="true" [rotate]="true" [maxSize]="3" [(page)]="paging.index"
                            [pageSize]="paging.size" [collectionSize]="count" (pageChange)="refresh();pagingSave();"
                            #pagination></ngb-pagination>
          </div>
          <div class="col-12 col-lg d-none d-lg-block">
            <div class="pt-2 text-center"
                 i18n>{{count}} {{'itemsIn' | translate }} {{this | func:pageCount:count:paging.size}} {{'pages'
              | translate }}
            </div>
          </div>
          <div class="col pr-0 pl-0 pagination-dropdown-container">
            <div>
              <select class="custom-select pagination-dropdown cursorProp" [(ngModel)]="paging.size" (change)="refresh();pagingSave();">
                <option [ngValue]="10" i18n>{{'records10' | translate }}</option>
                <option selected [ngValue]="15" i18n>{{'records15' | translate }}</option>
                <option [ngValue]="20" i18n>{{'records20' | translate }}</option>
                <option [ngValue]="50" i18n>{{'records50' | translate }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <ng-template #uploadNewFileModal let-modal>
    <div class="modal-header">
      <button type="button" class="close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12 mb-3">
          <ngx-dropzone class="mt-2 justify-content-center" (change)="onSelect($event)" [accept]="'.csv'" [multiple]="false">
            <ngx-dropzone-label i18n>{{'dropFilesUpload' | translate}}</ngx-dropzone-label>
              <ngx-dropzone-preview class="pdf-upload-box" *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                <ngx-dropzone-label>
                  {{ f.name }} ({{ f.type }})
                </ngx-dropzone-label>
              </ngx-dropzone-preview>
            </ngx-dropzone>
            <div class="modal-footer">
              <button type="button" class="btn btn-info " *ngIf="selectedFile" (click)="uploadFile()">
                {{'upload' | translate }}
              </button>
            </div>
        </div>
      </div>
    </div>
  </ng-template>